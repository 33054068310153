import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import { ClientDashboard, DashboardCategory } from '../types/clientDashboard';
import getClientDashboards from '../services/clientDashboardsApi';

const useClientDashboardStore = defineStore('clientDashboard', () => {
  const dashboards = ref<ClientDashboard[]>([]);
  const criticalInformationDashboard = ref<DashboardCategory[]>();
  let initialized = false;
  let initializing = false;

  initialize();

  async function initialLoad() {
    const dashboardsResponse = await getClientDashboards();
    if (dashboardsResponse instanceof Error) {
      throw dashboardsResponse;
    }
    dashboards.value = dashboardsResponse;
    if (Array.isArray(dashboardsResponse)) {
      const dashboard = getDashboard('critical_information').value;
      if (dashboard !== 'loading') {
        criticalInformationDashboard.value = dashboard?.categories;
      }
    }
  }

  async function initialize() {
    if (!initialized && !initializing) {
      initializing = true;
      await initialLoad();
      initialized = true;
      initializing = false;
    }
    if (!initialized && initializing) {
      while (initializing) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(resolve, 50);
        });
      }
    }
  }

  const getDashboard = (codename: string) => computed(() => {
    if (!codename || codename === 'loading') return 'loading';
    return dashboards.value.find((dashboard) => dashboard.dashboardCodename === codename);
  });

  const getCategoryDataPoints = (dashboardCodename: string, categoryCodename: string) => computed(() => {
    if (!dashboardCodename || !categoryCodename) return null;
    const dashboard = getDashboard(dashboardCodename).value as ClientDashboard;
    const category = dashboard?.categories?.find((c) => c.categoryCodename === categoryCodename);
    return category?.dataPoints?.map((dataPointCodename) => valueToCamel(dataPointCodename));
  });

  return {
    criticalInformationDashboard,
    dashboards,
    getCategoryDataPoints,
    getDashboard,
    initialize,
  };
});

export default useClientDashboardStore;
