import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-card-actions" }

import { BaseButton } from '@/_shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCardActions',
  props: {
  primaryButtonText: {
    type: String,
    default: 'Save',
  },
  secondaryButtonText: {
    type: String,
    default: 'Cancel',
  },
  primaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ['primaryButtonClicked', 'secondaryButtonClicked'],
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BaseButton), {
      onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('primaryButtonClicked'))),
      class: "v-card-actions-button v-card-actions-button--primary",
      "button-type": "primary",
      size: "small",
      disabled: __props.primaryButtonDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.primaryButtonText), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_unref(BaseButton), {
      onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('secondaryButtonClicked'))),
      class: "v-card-actions-button v-card-actions-button--secondary",
      "button-type": "secondary",
      size: "small"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.secondaryButtonText), 1)
      ]),
      _: 1
    })
  ]))
}
}

})