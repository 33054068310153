import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-event-list-entry" }
const _hoisted_2 = { class: "v-event-date-text v-event-date-text-primary" }
const _hoisted_3 = {
  key: 0,
  class: "v-event-date-text v-event-date-text-secondary"
}

import { safeTranslate } from '@/_shared/translations/i18n';
import { computed, onBeforeMount, PropType } from 'vue';
import { EventPwsInstance } from '@/_shared/types/NourishInstance';
import InstanceListEntry, { InstanceListEntryAdditionalHeaders } from '@/_shared/components/display/InstanceListEntry.vue';
import InstanceStatusBadge from '@/_shared/components/display/InstanceStatusBadge.vue';
import InstanceListEntryBadge from '@/_shared/components/display/InstanceListEntryBadge.vue';
import { getUpdatedAgoText } from '@/_shared/helpers/useDateTimeHelper';
import { format } from 'date-fns';
import { formatDisplayTime } from '@/timeline/helper/interactionsHelper';
import { BaseIcon } from '@/_shared/components';
import useImageGalleryStore from '@/_shared/store/imageGallery';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import { useRoute, useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventListEntry',
  props: {
  instance: {
    type: Object as PropType<EventPwsInstance>,
    required: true,
  },
  showGalleryButton: {
    type: Boolean,
    default: true,
  },
  isInstanceView: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  'selectInstance',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const additionalHeaders: InstanceListEntryAdditionalHeaders = [
  { header: safeTranslate(`datasets.${props.instance.metadata.specificType}`) },
  { header: safeTranslate(`datasets.${props.instance.metadata.severity}`), nonBold: true },
];

const unknownDate = safeTranslate('common.unknown_date');
const [unknownDateFirstRow, unknownDateSecondRow] = unknownDate.split(' ');

const dateFirstRow = computed(() => (props.instance.metadata.eventDateTime ? format(new Date(props.instance.metadata.eventDateTime), 'd MMM yy') : unknownDateFirstRow));
const dateSecondRow = computed(() => (props.instance.metadata.eventDateTime ? formatDisplayTime(props.instance.metadata.eventDateTime) : unknownDateSecondRow));

const missedInteractionCount = computed(() => props.instance.missedInteractions?.length ?? 0);
const missedInteractionInfo = computed(() => safeTranslate('common.missed_interactions'));
const showMissedInteractions = computed(() => props.instance.state === 'active' && missedInteractionCount && !props.isInstanceView);
const identifiedWhen = computed(() => getUpdatedAgoText(new Date(props.instance.createdAt)));
const showIdentifiedWhen = computed(() => props.instance.state === 'new' && !props.isInstanceView);

const router = useRouter();
const route = useRoute();
const { featureToggles } = storeToRefs(useUserStore());
const { fetchGalleryViewData } = useImageGalleryStore();
const { hasImages } = storeToRefs(useImageGalleryStore());

onBeforeMount(async () => {
  if (featureToggles.value.instancesGalleryView && props.showGalleryButton) {
    await fetchGalleryViewData(props.instance.clientId, props.instance.id);
  }
});
const showGalleryView = async () => {
  await router.push({
    name: 'client.events.instance.gallery_view',
    params: { ...route.params, instanceId: props.instance.id },
    query: route.query,
  });
};

return (_ctx: any,_cache: any) => {
  const _directive_base_tooltip = _resolveDirective("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstanceListEntry, {
      title: _unref(safeTranslate)(`datasets.${__props.instance.subType}`),
      "additional-headers": additionalHeaders,
      reference: __props.instance.reference,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selectInstance', __props.instance)))
    }, {
      eventDateTime: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(dateFirstRow.value), 1),
        (dateSecondRow.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(dateSecondRow.value), 1))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        (__props.isInstanceView)
          ? (_openBlock(), _createBlock(InstanceStatusBadge, {
              key: 0,
              label: _unref(safeTranslate)(`events.sections.${__props.instance.state}`)
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (showMissedInteractions.value)
          ? (_openBlock(), _createBlock(InstanceListEntryBadge, {
              key: 1,
              label: missedInteractionInfo.value,
              counter: missedInteractionCount.value
            }, null, 8, ["label", "counter"]))
          : _createCommentVNode("", true),
        (showIdentifiedWhen.value)
          ? (_openBlock(), _createBlock(InstanceListEntryBadge, {
              key: 2,
              label: `${_unref(safeTranslate)('events.sections.new')} ${identifiedWhen.value}`
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (__props.showGalleryButton && _unref(hasImages))
          ? _withDirectives((_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 3,
              "data-id": "image-gallery-button",
              name: "v-icon-image-gallery",
              onClick: _withModifiers(showGalleryView, ["stop"]),
              compact: true,
              "foreground-color": 'black',
              "background-color": 'pale-teal',
              square: "",
              "no-hover-background": "",
              noHover: "",
              size: "2x",
              style: {"margin-left":"6px"}
            }, null, 512)), [
              [_directive_base_tooltip, {
                    tooltipText: _unref(safeTranslate)('body_map.image_gallery_tooltip'),
                    position: 'right'
                  }]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "reference"])
  ]))
}
}

})