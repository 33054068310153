import useUserStore from '@/_shared/store/user';
import { defineStore, storeToRefs } from 'pinia';
import {
  computed, ComputedRef, Ref, ref, watch,
} from 'vue';
import {
  createInteraction,
  fetchInteraction,
  newInteraction,
  recalculateComputedParameters,
  updateInteraction,
  getLastInteraction,
  newNeedInteraction,
} from '@/timeline/services/interactionsApi';
import IInteraction from '@/timeline/types/IInteraction';
import {
  FileWrapper,
  Parameter,
  ShowIf,
  CoercedSelection,
  CoercedChoice,
  PictureWrapper, CoercedValue, CoercedSingleSelect, CoercedSkinInstance, CoercedEventPWSInstance,
  ParameterState,
  CoercedMultiSelect,
  CoercedPersonPicker,
} from '@/timeline/types/Parameter';
import { Alarm } from '@/timeline/types/Alarm';
import {
  createFile,
  deleteFile, deleteGenericFile,
  softDeleteFile,
  updateFile,
} from '@/generic_file/services/GenericFileApi';
import {
  endOfDay, isAfter, isToday, parseISO,
} from 'date-fns';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import { GenericFile } from '@/_shared/types/genericFile';
import use from '@/_shared/compositionApi';
import useInteractionsStore from '@/_shared/store/interactions';
import {
  getFirstConfigOption,
  hasMultipleChoices,
  isOldVersionSignatureParameter,
  parseNumberDataPointValues,
} from '@/timeline/helper/parametersHelper';
import { carerStore } from '@/_shared/store/carers';
import equal from 'fast-deep-equal/es6';
import { isManualOrTargetAlarm } from '@/timeline/helper/interactionsHelper';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
import { uuid } from 'vue-uuid';
import { Datapoint, Datapoints, dataPointsStore } from '@/_shared/store/dataPoints';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import { shallowEqual, untilWithTimeout } from '@/_shared/services/UseUtils';
import useAuditTrailStore from '@/_shared/store/auditTrail';
import useInteractionActionStore from '@/_shared/store/interactionActionsStore';
import ParameterWrapper from '../helper/parameterWrapper';

const { translate } = use.helpers();

const { clientStore } = use.store.clients();

const useCurrentInteractionStore = defineStore('currentInteraction', () => {
  const interactionStore = useInteractionsStore();
  const interactionActionsStore = useInteractionActionStore();

  const {
    userHasPermission,
    userCan,
    getFeatureToggle,
  } = useUserStore();

  const { getIconUrl } = useCategoryIconStore();
  const currentClientId = ref<number>();
  const { bulkInteractionIds } = storeToRefs(useMultiSelectRegister());
  const { currentUser } = storeToRefs(useUserStore());
  const currentInteraction: Ref<IInteraction> = ref({} as IInteraction);
  const initialInteraction: Ref<IInteraction> = ref({} as IInteraction);
  const lastInteractionCache: Ref<IInteraction | null | undefined> = ref(undefined);
  const iconUrl = computed(() => getIconUrl(currentInteraction.value.categoryCodename)?.value);
  const interactionLoaded = ref(false);
  const { interactions } = storeToRefs(useInteractionsStore());
  // set from query parameter
  const timelineDate = ref(new Date());
  const wasSavedInBackEnd = ref(false);
  const skinInstances = computed(() => {
    if (currentClientId.value || currentInteraction.value?.clientId) {
      return clientStore.skinInstances(currentClientId.value || currentInteraction.value?.clientId).value;
    }
    return [];
  });
  const eventPWSInstances = computed(() => {
    if (currentClientId.value || currentInteraction.value?.clientId) {
      return clientStore.eventPWSInstances(currentClientId.value || currentInteraction.value?.clientId).value;
    }
    return [];
  });
  const shouldHideEmptyParameters = computed(() => currentInteraction.value.hideEmptyParameters && currentInteraction.value.state === 'closed');

  const archivedValues = ref<Record<string, boolean>>({});

  const paramTypesWithAddedRemoved = ['combined_multi_search', 'multi', 'person_picker'];

  interface PictureToDelete {
    pictureFile: GenericFile,
    parameterId: number | string
  }

  const picturesToDelete: Ref<PictureToDelete[]> = ref([] as PictureToDelete[]);

  // **** computed Properties *******
  const isNotBulkClose = computed(() => !bulkInteractionIds.value.length);
  const isClosed = computed(() => currentInteraction.value?.state === 'closed');
  const isSticky = computed(() => currentInteraction.value?.state === 'sticky');
  const isCancelled = computed(() => currentInteraction.value?.state === 'cancelled');
  const isNew = computed(() => !currentInteraction.value?.id);
  const isConfidential = computed(() => {
    if (currentInteraction.value?.confidential === true || currentInteraction.value?.confidential === false) return currentInteraction.value?.confidential;
    if (currentInteraction.value?.serviceConfidentiality === 'always') return true;
    return currentInteraction.value?.serviceConfidentiality === 'default_on';
  });
  const rolesWithConfidentialViewAccess = computed(() => currentInteraction.value?.rolesWithConfidentialViewAccess);
  const showConfidentialityToggle = computed(() => ['default_on', 'default_off', 'always'].includes(currentInteraction.value?.serviceConfidentiality as string));
  const confidentialityLevel = computed(() => currentInteraction.value?.serviceConfidentiality);
  const isCareplanReview = computed(() => !!currentInteraction.value?.needId);

  const cannotBeClosed = computed(() => hasManualAlarm.value
    || (isClosed.value
      && (hasSavedSignatureParam.value || (hasAlarm.value || hasWarning.value))));

  const isPlannedInFuture = computed(() => {
    if (currentInteraction.value?.state !== 'planned') return false;
    if (!currentInteraction.value?.finishAt) return false;

    const now = new Date();
    const finishAt = new Date(currentInteraction.value?.finishAt);
    if (isNew.value) {
      return isAfter(finishAt, now);
    }
    return isAfter(finishAt, endOfDay(now));
  });

  const hasDocuments = computed(() => !!currentInteraction.value?.carePlanDocuments?.length || !!currentInteraction.value?.supportingDocuments?.length);
  const hasOldVersionSignatureParam = computed(() => currentInteraction.value?.parameters?.some(
    (param: Parameter) => param.valueType === 'signature' && isOldVersionSignatureParameter(param),
  ) || false);
  const hasSavedSignatureParam = computed(() => isClosed.value && hasOldVersionSignatureParam.value);
  const hasManualAlarm = computed(() => {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => alarm.alarmType === 'manual')) || false;
  });
  const hasWarning = computed(() => _checkInteractionActiveAlarmsStates('warning'));
  const hasAlarm = computed(() => _checkInteractionActiveAlarmsStates('alarm'));
  const hasActions = computed(() => interactionActionsStore.interactionHasActions());
  const hasDataPoint = computed(() => (currentInteraction.value?.parameters
    && currentInteraction.value?.parameters.some((param: Parameter) => (param?.dataPoint && param?.dataPoint.length) || param?.valueType === 'combined_multi_search')) || false);
  const hasNFC = computed(() => !!currentInteraction.value?.nfcTagId);

  const createdByAction = computed(() => currentInteraction.value?.protocolParentId !== null);
  const protocolParentAccessible = computed(() => currentInteraction.value?.protocolParentAccessible === true);
  const isClosedWithNoWarning = computed(() => isClosed.value && (!hasWarning.value && !hasAlarm.value));

  // TODO this logic could be calculated by and coming from backend ???
  const isViewOnly = computed(() => {
    const isReadOnlyAfterClose = !userHasPermission('editAfterInteractionClose') || (currentInteraction.value?.readOnlyAfterClose === true);
    const isReadOnly = (hasDataPoint.value || hasOldVersionSignatureParam.value || isReadOnlyAfterClose) && isClosed.value;
    const currentPersonCannotManageInteraction = !userCan('manageInteractionsForServiceId', currentInteraction.value?.serviceId);
    const aoFabAndIsCareplanReview = !getFeatureToggle('vueCareplanReview') && (getFeatureToggle('aoFabMenu') && currentInteraction.value.serviceCodename === 'careplan_review');
    return currentPersonCannotManageInteraction || isReadOnly || aoFabAndIsCareplanReview;
  });

  const hasChanged = computed(() => {
    if (isViewOnly.value) return false;
    if ((!initialInteraction.value.id || !currentInteraction?.value.id)
      && !getFeatureToggle('saveForLaterInteractionHeader')) {
      return false;
    }

    return initialInteraction.value.parameters
      ?.some((param: Parameter) => !shallowEqual(param.coercedValue, _getParameter(param.id)?.coercedValue))
      || initialInteraction.value.notePublic !== currentInteraction?.value.notePublic
      || !equal(initialInteraction.value.responsiblePersonIds, currentInteraction?.value.responsiblePersonIds);
  });

  const hasChangedForSignature = computed(() => {
    if (isViewOnly.value) return false;

    const initialParams = initialInteraction.value.parameters;
    const currentNotePublic = currentInteraction?.value.notePublic;
    const initialNotePublic = initialInteraction.value.notePublic;
    const anyParameterChanged = initialParams
      .filter((param) => param.valueType !== 'signature')
      .some((param) => !shallowEqual(param.coercedValue, _getParameter(param.id)?.coercedValue));
    const notePublicChanged = initialNotePublic !== currentNotePublic && !isCancelled.value;

    return anyParameterChanged || notePublicChanged;
  });

  const canUpdateExistingPlannedInteraction = computed(
    () => ((currentInteraction.value?.state === 'planned' || isSticky.value)
      && userCan('manageInteractionsForServiceId', currentInteraction.value?.serviceId)
      && !isNew.value),
  );

  const hasSaveForLater = computed(() => (!isCareplanReview.value
      && !hasOldVersionSignatureParam.value)
    && (canUpdateExistingPlannedInteraction.value || isNew.value)
    && isNotBulkClose.value
    && (!hasDataPoint.value || getFeatureToggle('saveForLaterStates')));

  const showSaveForLaterConfirmationModal = ref(false);
  const saveForLaterTriggerSource = ref('default');

  const alarmTypes = ['due', 'target_not_met', 'manual'];
  const hasAnyCloseAlarmToDisplay = computed(() => {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => isManualOrTargetAlarm(alarm, isClosed.value) && alarm.closed));
  });
  const closedAlarms = computed(() => currentInteraction.value.alarms
    ?.filter((a: Alarm) => alarmTypes.includes(a.alarmType as string) && a.closed)
    ?.map((alarm: Alarm) => ({
      ...alarm,
      closerName: carerStore.name(alarm.closerId as number),
      closerPhoto: carerStore.photo(alarm.closerId as number),
    })));
  const hasNourishInstanceParameter = computed(() => currentInteraction.value.parameters?.some((param: Parameter) => param.valueType === 'nourish_instance'));

  const hasFilledInSignature = computed(() => currentInteraction.value.parameters?.filter((param) => param.valueType === 'signature').some((param) => param.coercedValue !== null));
  const newSignatureCVSaved = ref(false);
  const showSignatureResetWarningModal = computed(() => hasChangedForSignature.value && hasFilledInSignature.value && !newSignatureCVSaved.value);

  watch(() => currentInteraction.value?.clientId, (newId, oldId) => {
    if (newId === oldId) {
      return;
    }
    currentClientId.value = newId;
  });

  // eslint-disable-next-line no-new
  function _checkInteractionActiveAlarmsStates(state: string) {
    const alarms = currentInteraction.value?.alarms;
    return (alarms && alarms.some((alarm: Alarm) => alarm.state === state)) || false;
  }

  function setPreviousInteractionState(interaction: IInteraction | undefined) {
    initialInteraction.value = JSON.parse(JSON.stringify(interaction));
  }

  function setIsConfidential(confidential: boolean) {
    currentInteraction.value.confidential = confidential;
  }

  function mergeDateAndTime(date: Date, time: Date) {
    const mergedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
    return mergedDate;
  }

  function _resetTimes(interaction: IInteraction) {
    let now = new Date().toISOString();
    if (timelineDate.value) {
      if (!isToday(timelineDate.value)) {
        now = mergeDateAndTime(timelineDate.value, new Date()).toISOString();
      }
    }
    if (interaction.state === 'planned') {
      if (!interaction.finishAt || isToday(parseISO(interaction.finishAt))) {
        interaction.finishAt = now;
      }
      if (interaction.needsStartAt) {
        if (!interaction.startAt || isToday(parseISO(interaction.startAt))) {
          interaction.startAt = now;
        }
      } else {
        interaction.startAt = null;
      }
    }
  }

  async function setCurrentInteraction(id: number) {
    // TODO better type below
    _resetStoreState();
    await fetchInteraction(id)
      .then((fetchedInteraction) => {
        const interaction = fetchedInteraction as IInteraction;
        if (getFeatureToggle('useUserAudit')) useAuditTrailStore().logInteractionAccess(interaction.id, interaction.clientId);
        currentInteraction.value = interaction;
        if (interaction.finishAt) timelineDate.value = new Date(interaction.finishAt);
        return interaction;
      }).then(async (interaction) => {
        await interactionActionsStore.$reset(interaction.serviceCodename);
        await _maybePatchParametersWithDataPoints();
        await prefillNourishInstanceParameters();
        setPreviousInteractionState(interaction);
        if (!interaction.needsStartAt) {
          interaction.startAt = null;
        }
        maybePatchCoercedChoices(interaction.parameters);
        if (bulkInteractionIds.value?.length) {
          interaction.responsiblePersonIds = [];
          interaction.defaultNotes = {};
          interaction.notePublic = '';
        }
      });
    interactionLoaded.value = true;
  }

  async function setNewInteraction(clientId: number, serviceId: number, instanceId?: string) {
    _resetStoreState();
    await newInteraction(clientId, serviceId)
      .then(async (fetchedInteraction) => {
        const interaction = fetchedInteraction as IInteraction;
        currentInteraction.value = interaction;
        if (instanceId) currentInteraction.value.nourishInstanceId = +instanceId;
        _resetTimes(interaction);
        await prefillNourishInstanceParameters();
        return interaction;
      }).then(async (interaction) => {
        await interactionActionsStore.$reset(interaction.serviceCodename);
        await _maybePatchParametersWithDataPoints();
        maybePatchCoercedChoices(interaction.parameters);
        setPreviousInteractionState(interaction);
      });
    interactionLoaded.value = true;
  }

  async function setNewNeedInteraction(clientId: number, params: Record<string, string | string[]>) {
    _resetStoreState();
    await newNeedInteraction(clientId, params)
      .then(async (fetchedInteraction) => {
        const interaction = fetchedInteraction as IInteraction;
        currentInteraction.value = interaction;
        _resetTimes(interaction);
        await prefillNourishInstanceParameters();
        return interaction;
      }).then(async (interaction) => {
        await interactionActionsStore.$reset(interaction.serviceCodename);
        await _maybePatchParametersWithDataPoints();
        maybePatchCoercedChoices(interaction.parameters);
        setPreviousInteractionState(interaction);
      });
    interactionLoaded.value = true;
  }

  function changeCurrentInteraction() {
    _resetStoreState();
    let newCurrentInteraction = interactions.value.find((interaction) => interaction.id === bulkInteractionIds.value[0]) as IInteraction;
    setPreviousInteractionState(newCurrentInteraction);
    newCurrentInteraction = {
      ...newCurrentInteraction,
      ..._getCommonProperties(currentInteraction.value),
    };
    currentInteraction.value = newCurrentInteraction;
    interactionLoaded.value = true;
  }

  function _getParameter(id: number | string): Parameter | undefined {
    return currentInteraction?.value?.parameters?.find((parameter: Parameter) => parameter.id === id);
  }

  function _parameterCodenamesMap(): Map<string, Parameter> {
    const parameterCodenames = new Map();
    currentInteraction?.value?.parameters.forEach((parameter) => {
      if (parameter.codename) {
        parameterCodenames.set(parameter.codename, parameter);
      }
    });
    return parameterCodenames;
  }

  function _allRequiredParametersFilledIn(interaction: IInteraction): boolean {
    // TODO HACK for now. We need to check that this interaction has actions and if it does then ignore the all requried filled check.
    if (interaction.parameters && currentInteraction.value.computable) return true;
    if (!interaction.parameters) return false;
    return interaction.parameters
      .filter((parameter: Parameter) => !parameter.hidden && parameter.required && parameter.coercedValue === null)
      .length === 0;
  }

  function _errorMessageExist(interaction: IInteraction): boolean {
    if (!interaction.parameters) return false;
    return interaction.parameters
      .filter((parameter: Parameter) => parameter.required && parameter.coercedValue !== null
        && useParameterValidator(parameter).errorMessage.value !== undefined)
      .length > 0;
  }

  async function _runCalculators(runForActions = false) {
    const response = await recalculateComputedParameters(currentInteraction.value, runForActions);
    if (!(response.computedParameters && currentInteraction.value)) return;
    if (runForActions) {
      currentInteraction.value.computedParameters = _getUniqueComputedParameters(response.computedParameters);
    } else {
      const existingActions = currentInteraction.value.computedParameters.filter((param) => param.id === 'actions');
      currentInteraction.value.computedParameters = _getUniqueComputedParameters([...response.computedParameters, ...existingActions]);
    }
  }

  const _getUniqueComputedParameters = (computedParameters: Parameter[]) => {
    const computedParameterMap = computedParameters?.map((computedParameter) => [computedParameter.id, computedParameter]);
    return [...new Map(computedParameterMap as []).values()] as Parameter[];
  };

  async function _buildInteractionsForBulkClose(templateInteraction: IInteraction): Promise<IInteraction[]> {
    const bulkCloseUuid = uuid.v4();
    let bulkInteractions = interactions.value.filter((interaction) => interaction.id !== currentInteraction.value.id
      && bulkInteractionIds.value.includes(interaction.id as number));
    // need it to preserve reactivity on save
    bulkInteractions.push(currentInteraction.value);
    // TODO investigate why there are duplications sometimes
    bulkInteractions = bulkInteractions.filter((interaction, index) => bulkInteractions.indexOf(interaction) === index);
    return bulkInteractions.map((interaction) => ({
      ...interaction,
      ..._getCommonProperties(templateInteraction),
      bulkCloseUuid,
    }));
  }

  const _getCommonProperties = (templateInteraction: IInteraction) => ({
    serviceName: templateInteraction.serviceName,
    serviceId: templateInteraction.serviceId,
    providedServiceId: templateInteraction.providedServiceId,
    parameters: templateInteraction.parameters,
    computedParameters: templateInteraction.computedParameters,
    startAt: templateInteraction.startAt,
    needsStartAt: templateInteraction.needsStartAt,
    finishAt: templateInteraction.finishAt,
    responsiblePersonIds: templateInteraction.responsiblePersonIds,
    state: templateInteraction.state,
    manualAlarmState: templateInteraction.manualAlarmState,
    defaultNotes: templateInteraction.defaultNotes,
    notePublic: templateInteraction.notePublic,
  });
  const _getInteractionState = (state: string): string => (_unclosedState(state) ? state : 'closed');
  const _getManualAlarmState = (state: string): string => (_unclosedState(state) ? 'ok' : state);
  const _unclosedState = (state: string): boolean => ['planned', 'cancelled', 'sticky'].includes(state);

  function _valid(): boolean {
    if (!currentInteraction.value) return false;
    if (currentInteraction.value.handover && !currentInteraction.value.notePublic) {
      currentInteraction.value.errors = { handover: [translate('timeline.interaction.handoverRequiresNote')] };
      return false;
    }
    const hiddenParameters = currentInteraction.value.parameters.filter((param) => param.hidden);
    const hiddenKeys = hiddenParameters.map((param) => param.name);
    const hiddenIds = hiddenParameters.map((param) => param.id);
    currentInteraction.value.parameters.forEach((param) => {
      if (hiddenIds.includes(Math.floor(param.id as number))) {
        hiddenKeys.push(param.name);
      }
    });

    if (archivedValues.value) {
      const errors: string[] = [];
      Object.entries(archivedValues.value).forEach(([key, value]) => {
        if (value && !hiddenKeys.includes(key)) errors.push(`${translate('message.missing_value')} ${key}`);
      });
      if (errors.length) {
        currentInteraction.value.errors = { parameterValuesArchivedValue: errors };
        return false;
      }
    }
    return true;
  }

  // this when new interactions?service is selected
  function _resetStoreState() {
    interactionLoaded.value = false;
    wasSavedInBackEnd.value = false;
    initialInteraction.value = {} as IInteraction;
    archivedValues.value = {};
    lastInteractionCache.value = undefined;
  }

  // this when the right panel is unmounted
  function resetInteraction() {
    interactionLoaded.value = false;
    lastInteractionCache.value = undefined;
    currentInteraction.value = {} as IInteraction;
  }

  function setParameterHidden(id: number | string, hidden: boolean) {
    const parameter = _getParameter(id);
    if (parameter) parameter.hidden = hidden;
  }

  function setParameterEdited(id: number | string) {
    const parameter = _getParameter(id);
    if (parameter) parameter.edited = true;
  }

  function getParameterShowIf(id: number | string): ShowIf | null {
    if (!currentInteraction.value) return null;
    const parameter = _getParameter(id);
    if (parameter?.config?.showIf) {
      const showIf = parameter.config.showIf.values();
      const targetCodename = showIf.next().value;
      if (!targetCodename?.length) return null;
      const regex = showIf.next().value;
      const target = _parameterCodenamesMap().get(targetCodename);
      return { target, regex };
    }
    return null;
  }

  function getParameterField(id: number | string) {
    const param = _getParameter(id);
    if (param?.valueType === 'file') {
      return param?.tempFile;
    }
    return param?.coercedValue;
  }

  async function calculateComputedParameters() {
    if (hasFilledInSignature.value) newSignatureCVSaved.value = false;
    if (!(currentInteraction.value?.parameters && currentInteraction.value.computable)) return;
    if (_errorMessageExist(currentInteraction.value)) return;
    const hasStateActions = interactionActionsStore.shouldRunActions(currentInteraction.value);
    if (_allRequiredParametersFilledIn(currentInteraction.value) || hasStateActions) {
      await _runCalculators(hasStateActions);
    }
  }

  async function calculateActions() {
    if (_errorMessageExist(currentInteraction.value)) return;
    await _runCalculators(true);
  }

  function filterHiddenFilePictureParams(interaction: IInteraction): Parameter[] {
    if (interaction.parameters) {
      return interaction.parameters.filter((param) => {
        const wrappedParam = new ParameterWrapper(param, getParameterShowIf);
        return !wrappedParam.isHidden();
      });
    }
    return [];
  }

  async function save() {
    // do not allow saving in conflicted state
    const interaction = currentInteraction.value;
    if (!interaction) return;
    delete interaction.errors;

    _clearHiddenParametersValue(interaction);

    const interactionsToSave = bulkInteractionIds.value.length <= 1 ? [interaction] : await _buildInteractionsForBulkClose(interaction);
    await Promise.all(interactionsToSave.map(async (interactionToSave) => {
      await _handleFiles(interactionToSave);
      const updatedInteraction = isNew.value
        ? await createInteraction(interactionToSave)
        : await updateInteraction(interactionToSave);
      updatedInteraction.parameters = filterHiddenFilePictureParams(updatedInteraction);
      if (updatedInteraction.id) {
        if (updatedInteraction.noAccess === true) interactionStore.removeInteraction(updatedInteraction.id);
        else if (!updatedInteraction.errors) interactionStore.updateInteraction(updatedInteraction);
      }
      return updatedInteraction;
    })).then(async (updatedInteractions) => {
      if (updatedInteractions.length > 1) currentInteraction.value.errors = _getInteractionsErrors(updatedInteractions);
      if (!currentInteraction.value.errors) {
        if (updatedInteractions.length === 1 && updatedInteractions[0]?.id && hasActions.value) {
          await _updateStoresWithActionsEffects(updatedInteractions[0].id);
        }
        clientStore.updateNourishInstances(updatedInteractions);
        if (hasNourishInstanceParameter.value) {
          clientStore.reFetchInstances(currentClientId.value as number);
        }
        wasSavedInBackEnd.value = true;
        if (hasDataPoint.value) dataPointsStore.reFetchById(currentClientId.value as number);
        if (bulkInteractionIds.value.length) useMultiSelectRegister().$reset();
      }
    });
  }

  function _getInteractionsErrors(updatedInteractions: IInteraction[]) {
    return updatedInteractions.map((i) => i.errors)
      .filter((error) => !!error)
      .reduce(
        (acc, error) => ({ ...acc, ...error }),
        undefined,
      );
  }

  async function _updateStoresWithActionsEffects(currentInteractionId: number) {
    clientStore.reFetchById(currentInteraction.value?.clientId);
    interactionStore.loadChildInteractions(currentInteraction.value?.clientId, currentInteractionId);
  }

  function _backupCloseState(interaction: IInteraction): ISaveState {
    return {
      state: interaction.state,
      manualAlarmState: interaction.manualAlarmState,
      closedAt: interaction.closedAt,
      responsiblePersonIds: interaction.responsiblePersonIds,
    };
  }

  function restoreCloseState(interaction: IInteraction, closeState: ISaveState) {
    interaction.state = closeState.state;
    interaction.manualAlarmState = closeState.manualAlarmState;
    interaction.closedAt = closeState.closedAt;
    interaction.responsiblePersonIds = closeState.responsiblePersonIds;
  }

  function _prepareForSave(interaction: IInteraction, state: string) {
    const now = new Date().toISOString();
    currentInteraction.value.state = _getInteractionState(state);
    currentInteraction.value.manualAlarmState = _getManualAlarmState(state);
    if (currentInteraction.value.state === 'closed') {
      currentInteraction.value.closedAt = now;
      if (currentInteraction.value.responsiblePersonIds.length === 0 && currentUser.value.role !== 'informal_carer') {
        currentInteraction.value.responsiblePersonIds.push(currentUser.value.id);
      }
    }

    if (!interaction.finishAt && interaction.state !== 'sticky') {
      interaction.finishAt = now;
    } else if (interaction.state === 'sticky') {
      interaction.finishAt = null;
    }
  }

  async function saveInteraction(state: string): Promise<boolean> {
    if (!_valid()) return false;

    const oldState = _backupCloseState(currentInteraction.value);
    _prepareForSave(currentInteraction.value, state);
    await save();

    if (currentInteraction.value.errors) {
      restoreCloseState(currentInteraction.value, oldState);
      return false;
    }

    return true;
  }

  function getCoercedValueByParameterId(id: number): ComputedRef<CoercedValue | undefined> {
    return computed(() => {
      // TODO improve this, it loop over all parameters each time
      const parameter = currentInteraction.value?.parameters?.find((param) => param.id === id);
      if (!parameter) return undefined;
      return parameter.coercedValue;
    });
  }

  function setCoercedValueByParameterId(id: number, cv: CoercedValue): void {
    const parameter = currentInteraction.value?.parameters?.find((param) => param.id === id);
    if (!parameter) return;
    parameter.coercedValue = cv;
  }

  function _clearHiddenParametersValue(interaction: IInteraction) {
    interaction.parameters
      .filter((param: Parameter) => param.hidden).forEach((param) => {
        param.coercedValue = null;
      });
  }

  async function _handleFiles(interaction: IInteraction) {
    if (interaction?.parameters && interaction?.parameters.length) {
      const fileParameters = interaction.parameters.filter((parameter) => parameter.valueType === 'file' || parameter.valueType === 'picture');
      await Promise.all(fileParameters.map(async (parameter) => {
        const coercedValue = parameter.coercedValue as FileWrapper;
        if (parameter.valueType === 'file') {
          const file = (coercedValue && coercedValue.file)
            || parameter.genericFile || null;
          if (file) {
            if (file.deleted && parameter.dataPoint) {
              await softDeleteFile(file, parameter);
            } else if (file.deleted) {
              await deleteFile(file, parameter);
            } else if (file.updatedLabel && interaction?.organisationUnitId) {
              await updateFile(file, parameter);
            }
          }
          if (parameter.tempFile) {
            await createFile(parameter, interaction);
          }
        } else {
          const pictureCoercedValue = parameter.coercedValue as PictureWrapper;
          if (pictureCoercedValue?.pictures?.length) {
            await createFile(parameter, interaction);
          }
          if (blurredUpdated(parameter)) {
            const filesToUpdate: GenericFile[] = [];
            pictureCoercedValue.pictures.forEach((picture) => {
              if (picture.pictureFile) {
                picture.pictureFile.sensitive = pictureCoercedValue.blurred;
                filesToUpdate.push(picture.pictureFile);
              }
            });
            await Promise.all(filesToUpdate.map(async (fileToUpdate) => {
              await updateFile(fileToUpdate as GenericFile, parameter);
            }));
          }
          if (picturesToDelete.value.length && !parameter.dataPoint) {
            picturesToDelete.value.forEach(async (pictureToDelete) => {
              if (pictureToDelete.parameterId === parameter.id) {
                picturesToDelete.value = picturesToDelete.value
                  .filter((picture) => picture.pictureFile.id !== pictureToDelete.pictureFile.id);
                await deleteGenericFile(pictureToDelete.pictureFile as GenericFile);
              }
            });
          }
        }
      }));
    }
  }

  const blurredUpdated = (parameter: Parameter) => {
    const param = initialInteraction.value?.parameters?.find((tempParam) => tempParam.id === parameter.id);
    return !!(param && param.coercedValue && parameter.coercedValue && (param.coercedValue as PictureWrapper).blurred !== (parameter.coercedValue as PictureWrapper).blurred);
  };

  let prefilled = false;

  async function fetchLastInteraction(): Promise<IInteraction> {
    if (!currentInteraction.value?.clientId || !currentInteraction.value?.serviceId) {
      return {} as IInteraction;
    }
    if (!lastInteractionCache.value) {
      lastInteractionCache.value = await getLastInteraction(currentInteraction.value?.clientId, currentInteraction.value?.serviceId);
    }
    return lastInteractionCache.value;
  }

  async function prefill() {
    if (currentInteraction.value?.clientId && currentInteraction.value?.serviceId) {
      prefilled = false;
      await fetchLastInteraction()
        .then((lastInteraction: IInteraction) => {
          if (lastInteraction) {
            setPreviousInteractionState(currentInteraction.value);
            _prefillParameters(lastInteraction);
            _prefillNotePublic(lastInteraction);
            calculateComputedParameters();
            prefilled = true;
          }
        });
    }
    return prefilled;
  }

  function unprefill() {
    if (initialInteraction.value && prefilled) {
      _prefillParameters(initialInteraction.value);
      _prefillNotePublic(initialInteraction.value);
      calculateComputedParameters();
      prefilled = false;
    }
  }

  function _prefillParameters(lastInteraction: IInteraction) {
    currentInteraction.value?.parameters.forEach((parameter: Parameter) => {
      if (prefillableParameter(parameter)) {
        const lastParameter = _parameterFrom(lastInteraction, parameter.id);
        if (!lastParameter) {
          return;
        }
        parameter.coercedValue = JSON.parse(JSON.stringify(lastParameter.coercedValue));
      }
    });
  }

  async function getConflictingParameterData(parameter: Parameter): Promise<[Parameter, number | null, string | null]> {
    let conflictingParam = {} as Parameter;
    let lastInteraction: IInteraction | undefined;
    if ((parameter.dataPoint || parameter.valueType === 'combined_multi_search') && currentClientId.value) {
      const fetchedDatapoints = await dataPointsStore.asyncById(currentClientId.value);
      const parameterDataPoints = getParameterDataPoints(parameter, fetchedDatapoints);
      if (parameterDataPoints) {
        conflictingParam = createParamFromDatapoints(parameter, parameterDataPoints);
        let dataPointInteractionId;
        if (Array.isArray(parameterDataPoints)) {
          dataPointInteractionId = parameterDataPoints.length ? parameterDataPoints[0].interactionId : null;
        } else {
          dataPointInteractionId = parameterDataPoints.interactionId;
        }
        if (dataPointInteractionId) {
          lastInteraction = await fetchInteraction(dataPointInteractionId as unknown as number);
        }
      }
    } else if (prefillableParameter(parameter)) {
      lastInteraction = await fetchLastInteraction();
      conflictingParam = _parameterFrom(lastInteraction, parameter.id) || {} as Parameter;
    }
    conflictingParam.state = 'clone';
    const closerId = lastInteraction?.closerId || null;
    const finishAt = lastInteraction?.finishAt || null;
    return [conflictingParam, closerId, finishAt];
  }

  function createParamFromDatapoints(parameter: Parameter, parameterDataPoints: Datapoint | Datapoint[]): Parameter {
    const parameterCopy: Parameter = JSON.parse(JSON.stringify(parameter));
    const dataPointValue = setParamDataPointValue(parameter, parameterDataPoints)[0];
    parameterCopy.coercedValue = dataPointValue as CoercedValue;
    return parameterCopy;
  }

  function clearPrefillStatus() {
    prefilled = false;
  }

  async function setParameterStates() {
    if (currentInteraction.value.state === 'closed' || currentInteraction.value.state === 'cancelled' || !getFeatureToggle('saveForLaterStates')) { return; }
    const lastInteraction = await fetchLastInteraction();
    currentInteraction.value?.parameters.forEach((parameter: Parameter) => {
      setParameterState(parameter, lastInteraction);
    });
  }

  async function setParameterState(parameter: Parameter, lastInteraction: IInteraction) {
    const lastParameter = _parameterFrom(lastInteraction, parameter.id);
    const lastInteractionFinishAt = lastInteraction?.finishAt ? new Date(lastInteraction.finishAt) : null;
    const currentInteractionSavedAt = currentInteraction.value?.savedAt ? new Date(currentInteraction.value.savedAt) : null;
    let state: ParameterState = null;
    if (prefilled) { state = 'prefilled'; }
    if (parameter.state !== 'resolved') {
      if (lastInteractionFinishAt
        && currentInteractionSavedAt
        && prefillableParameter(parameter)
        && !_dataPointPresent(parameter)
        && lastInteractionFinishAt > currentInteractionSavedAt
        && !shallowEqual(parameter.coercedValue, lastParameter?.coercedValue)) { state = 'conflict'; }
      state = await getStateForDatapoints(parameter, currentInteractionSavedAt, state);
      if (isRequiredAndBlank(parameter)) { state = 'error'; }
      parameter.state = state;
    }
  }

  function isRequiredAndBlank(parameter: Parameter): boolean {
    if (!parameter.required) { return false; }
    if (parameter.coercedValue && typeof parameter.coercedValue === 'object' && 'metadata' in parameter.coercedValue) {
      if (parameter.coercedValue.metadata.specificType === ''
        || ('location' in parameter.coercedValue.metadata && parameter.coercedValue.metadata.location === '')
        || ('severity' in parameter.coercedValue.metadata && parameter.coercedValue.metadata.severity === '')
      ) {
        return true;
      }
    }
    return isBlank(parameter);
  }

  function isBlank(parameter: Parameter): boolean {
    return (parameter.coercedValue === null
      || parameter.coercedValue === undefined
      || parameter.coercedValue === ''
      || JSON.stringify(parameter.coercedValue) === '{"pictures":[]}'
      || JSON.stringify(parameter.coercedValue) === '{"values":[]}');
  }

  async function getStateForDatapoints(parameter: Parameter, currentInteractionSavedAt: Date | null, state: ParameterState) {
    const fetchedDatapoints = await dataPointsStore.asyncById(currentInteraction.value.clientId);
    if (_dataPointPresent(parameter) && fetchedDatapoints && fetchedDatapoints.dataPoints) {
      const parameterDataPoints = getParameterDataPoints(parameter, fetchedDatapoints);
      if (parameterDataPoints) {
        const [dataPointValue, dataPointFinishAt] = setParamDataPointValue(parameter, parameterDataPoints);
        if (currentInteractionSavedAt
          && dataPointValue !== undefined
          && dataPointValue !== ''
          && !shallowEqual(dataPointValue as CoercedValue, simplifyParameter(parameter).coercedValue)
          && (dataPointFinishAt === undefined || dataPointFinishAt > currentInteractionSavedAt)
        ) {
          state = 'conflict';
        } else if (shallowEqual(dataPointValue as CoercedValue, parameter.coercedValue)) {
          state = 'prefilled';
        }
      }
    }
    return state;
  }

  function setParamDataPointValue(parameter: Parameter, parameterDataPoints: Datapoint | Datapoint[]) {
    let dataPointValue;
    let dataPointFinishAt: Date | undefined;
    if (Array.isArray(parameterDataPoints)) {
      dataPointValue = parameterDataPoints.map((dataPoint) => dataPoint.value).flat(Infinity);
      dataPointFinishAt = parameterDataPoints[0].finishAt ? new Date(parameterDataPoints[0].finishAt) : undefined;
    } else if (typeof parameterDataPoints === 'object' && 'value' in parameterDataPoints) {
      dataPointValue = parameterDataPoints.value;
      dataPointFinishAt = parameterDataPoints.finishAt ? new Date(parameterDataPoints.finishAt) : undefined;
    }
    if (['combined_multi_search', 'multi', 'single_selector_search', 'person_picker'].includes(parameter.valueType)) {
      dataPointValue = { values: dataPointValue };
    }
    return [dataPointValue, dataPointFinishAt];
  }

  function simplifyParameter(parameter: Parameter): Parameter {
    if (paramTypesWithAddedRemoved.includes(parameter.valueType)) {
      const newCoercedValue = { ...parameter.coercedValue as CoercedMultiSelect | CoercedPersonPicker };
      if ('addedValues' in newCoercedValue) {
        newCoercedValue.addedValues = [];
      }
      if ('removedValues' in newCoercedValue) {
        newCoercedValue.removedValues = [];
      }
      if ('answer' in newCoercedValue) {
        delete newCoercedValue.answer;
      }
      return { ...parameter, coercedValue: newCoercedValue };
    }
    return parameter;
  }

  // eslint-disable-next-line no-restricted-globals
  const refreshPage = () => { location.reload(); };

  async function prefillNourishInstanceParameters() {
    const instanceId = currentInteraction.value?.nourishInstanceId;
    if (instanceId && currentInteraction.value.state !== 'closed') {
      try {
        await untilWithTimeout(
          () => skinInstances.value.find(
            (instance) => instance.id === instanceId,
          )
            || eventPWSInstances.value.find((instance) => instance.id === instanceId),
          3000,
        );
      } catch (e) {
        refreshPage();
      }
      currentInteraction.value?.parameters
        .filter((parameter: Parameter) => parameter.valueType === 'nourish_instance')
        .forEach((parameter: Parameter) => {
          parameter.coercedValue = instanceToCoercedValue(instanceId);
          prefillSubParameters(parameter);
        });
      return true;
    }
    return false;
  }

  function instanceToCoercedValue(instanceId: number) {
    const skinInstance = skinInstances.value.find((instance) => instance.id === instanceId);
    const eventInstance = eventPWSInstances.value.find((instance) => instance.id === instanceId);
    let result = {} as CoercedSkinInstance | CoercedEventPWSInstance;

    if (skinInstance) {
      result = {
        existing: true,
        metadata: skinInstance.metadata,
        subType: skinInstance.subType,
      };
    }
    if (eventInstance) {
      result = {
        metadata: eventInstance.metadata,
        subType: eventInstance.subType,
      };
    }
    return result;
  }

  function prefillSubParameters(parameter: Parameter) {
    if (parameter.config?.nourishInstanceType === 'skin') {
      const locationSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_location`);
      const typeSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_specifictype`);
      const currentCoercedValue = parameter.coercedValue as CoercedSkinInstance;
      const location = currentCoercedValue.metadata?.location;
      const specificType = currentCoercedValue.metadata?.specificType;
      if (locationSubParameter) {
        (locationSubParameter.coercedValue as CoercedSingleSelect).values = location ? [location] : [];
      }
      if (typeSubParameter) {
        (typeSubParameter.coercedValue as CoercedSingleSelect).values = specificType ? [specificType] : [];
      }
    }
    if (parameter.config?.nourishInstanceType === 'event_pws') {
      const dateTimeSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_eventdatetime`);
      const typeSubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_specifictype`);
      const severitySubParameter = currentInteraction?.value?.parameters.find((subParameter: Parameter) => subParameter.codename === `${parameter.id}_severity`);
      const currentCoercedValue = parameter.coercedValue as CoercedEventPWSInstance;
      const dateTime = currentCoercedValue.metadata?.eventDateTime;
      const specificType = currentCoercedValue.metadata?.specificType;
      const severity = currentCoercedValue.metadata?.severity;
      if (dateTimeSubParameter) {
        dateTimeSubParameter.coercedValue = dateTime || '';
      }
      if (typeSubParameter) {
        (typeSubParameter.coercedValue as CoercedSingleSelect).values = specificType ? [specificType] : [];
      }
      if (severitySubParameter) {
        (severitySubParameter.coercedValue as CoercedSingleSelect).values = severity ? [severity] : [];
      }
    }
  }

  function prefillableParameter(parameter: Parameter) {
    return !nonPrefillableParameter(parameter);
  }

  function nonPrefillableParameter(parameter: Parameter) {
    return ['signature', 'nourish_instance', ''].includes(parameter.valueType) || parameter.dataPoint;
  }

  function _prefillNotePublic(lastInteraction: IInteraction) {
    if (currentInteraction.value) {
      currentInteraction.value.notePublic = lastInteraction.notePublic;
    }
  }

  function _parameterFrom(interaction: IInteraction, parameterId: number | string) {
    return interaction?.parameters?.find((parameter) => parameter.id === parameterId);
  }

  async function _maybePatchParametersWithDataPoints() {
    const { parameters } = currentInteraction.value;
    if (!isClosed.value && parameters && hasDataPoint.value && currentClientId.value) {
      const complexParam = ['multi', 'medication', 'file', 'person_picker', 'single_selector_search', 'combined_multi_search'];
      const fetchedDatapoints = await dataPointsStore.asyncById(currentClientId.value);
      if (!fetchedDatapoints || !fetchedDatapoints.dataPoints) return;
      const paramsToProcess = parameters.filter(
        (param) => _dataPointPresent(param) && (isBlank(param)),
      );
      paramsToProcess.forEach((parameter) => {
        const paramType = parameter.valueType;
        const parameterDataPoints = getParameterDataPoints(parameter, fetchedDatapoints);
        if (parameterDataPoints) {
          const dataPointValues = Array.isArray(parameterDataPoints) ? parameterDataPoints.map((dataPoint) => dataPoint.value).flat(Infinity) : parameterDataPoints.value;
          if (dataPointValues !== undefined) {
            if (!complexParam.includes(paramType)) {
              parameter.coercedValue = (paramType === 'number' && dataPointValues !== '')
                ? parseNumberDataPointValues(dataPointValues as string | CoercedChoice[] | CoercedChoice, parameter.isArray)
                : dataPointValues as string | CoercedChoice | CoercedChoice[];
            } else if (paramType === 'file') {
              parameter.coercedValue = dataPointValues ? { file: dataPointValues as GenericFile } as FileWrapper : null;
            } else if (paramType === 'picture') {
              parameter.coercedValue = (dataPointValues && dataPointValues instanceof Object) ? dataPointValues as FileWrapper : {};
            } else if (dataPointValues?.constructor === Array) {
              // TODO find a better way in back-end to send combined data point values when one/or more is as a NO question answer
              parameter.coercedValue ||= {};
              const coercedValue = parameter.coercedValue as CoercedSelection;
              coercedValue.values = (dataPointValues as string[]).filter((option) => !_getGlobalOptions()?.includes(option)) as string[];
              coercedValue.answer = coercedValue.values.length && parameter.config?.options ? getFirstConfigOption(parameter) : '';
            } else {
              const coercedSelection: Partial<CoercedSelection> = {
                values: [],
              };
              if (parameter.config?.question?.trim() === 'Yes') coercedSelection.answer = (typeof dataPointValues === 'string' || dataPointValues instanceof String) ? dataPointValues as string : '';
              parameter.coercedValue = coercedSelection;
            }
          }
        }
        setParameterEdited(parameter.id);
      });
      if (parameters.length && parameters.some((param) => param.dataPoint)) {
        await calculateComputedParameters();
      }
    }
  }

  function getParameterDataPoints(parameter: Parameter, fetchedDatapoints: Datapoints) {
    let parameterDataPoints;
    if (!fetchedDatapoints || !fetchedDatapoints.dataPoints) { return; }
    if (parameter.valueType === 'combined_multi_search') {
      const keys = (parameter.config?.combinedMulti?.map((item) => valueToCamel(item.dataPoint as string))) || [];
      parameterDataPoints = Object.entries(fetchedDatapoints!.dataPoints!)
        .filter((entry) => keys.includes(entry[0]))
        .flatMap((entry) => entry[1]);
    } else {
      const key = parameter.dataPoint ? valueToCamel(parameter.dataPoint as string) : 'contactPerson';
      parameterDataPoints = fetchedDatapoints!.dataPoints![key];
    }
    // eslint-disable-next-line consistent-return
    return parameterDataPoints;
  }

  function _getGlobalOptions() {
    type NestedRecord = Record<string, Record<string, Record<string, Record<string, string[]>>>>
    const globalTranslations = window.I18n as NestedRecord;
    let globalOptions: Array<string> = [];
    const languages = Object.keys(globalTranslations);
    languages.forEach((language) => {
      const localisedOptions: Array<string> = (globalTranslations[language]).provided_service?.data_sets?.options;
      if (localisedOptions) {
        Object.values(localisedOptions).forEach((str) => {
          globalOptions = globalOptions.concat(str.split(' / '));
        });
      }
    });
    globalOptions = [...Array.from(new Set(globalOptions))];
    return globalOptions;
  }

  function _dataPointPresent(parameter: Parameter) {
    return (parameter.dataPoint && parameter.dataPoint.length)
      || (parameter.config?.combinedMulti && parameter.config.combinedMulti
        .filter((item) => item.dataPoint !== null)?.length > 0);
  }

  // TODO: Get from specifications of parameters
  const needsSummary = computed(() => true);
  const maybePatchCoercedChoices = (parameters: Parameter[]) => {
    parameters
      .filter((param) => hasMultipleChoices(param.coercedChoices))
      .forEach((param) => {
        if (param.coercedValue) {
          if (Array.isArray(param.coercedValue)) {
            param.coercedValue.forEach((value) => {
              _maybeAddCoercedValue(param, value);
            });
          } else {
            _maybeAddCoercedValue(param, param.coercedValue as CoercedChoice);
          }
        }
      });
  };

  const _maybeAddCoercedValue = (parameter: Parameter, coercedValue: CoercedChoice) => {
    if (!parameter.coercedChoices.some((choice) => shallowEqual(choice, coercedValue))) {
      parameter.coercedChoices.push(coercedValue);
    }
  };

  function resetAllSignatureParameters() {
    currentInteraction.value.parameters
      .filter((param) => param.valueType === 'signature')
      .forEach((param) => {
        param.coercedValue = null;
      });
    newSignatureCVSaved.value = false;
  }

  function undoTypedValues() {
    if (initialInteraction.value) {
      _prefillParameters(initialInteraction.value);
      _prefillNotePublic(initialInteraction.value);
      calculateComputedParameters();
    }
  }

  interface ISaveState {
    state: string;
    manualAlarmState?: string | boolean;
    closedAt: string | null;
    responsiblePersonIds: number[];
  }

  return {
    calculateComputedParameters,
    createdByAction,
    currentInteraction,
    changeCurrentInteraction,
    iconUrl,
    isPlannedInFuture,
    isNew,
    isCancelled,
    isClosed,
    isSticky,
    cannotBeClosed,
    closedAlarms,
    hasActions,
    hasAlarm,
    hasAnyCloseAlarmToDisplay,
    hasChanged,
    hasWarning,
    hasManualAlarm,
    hasDocuments,
    hasOldVersionSignatureParam,
    hasSavedSignatureParam,
    hasDataPoint,
    hasNFC,
    isClosedWithNoWarning,
    isViewOnly,
    needsSummary,
    isConfidential,
    setCurrentInteraction,
    setNewInteraction,
    setNewNeedInteraction,
    getParameterField,
    setParameterHidden,
    setParameterEdited,
    setParameterStates,
    setParameterState,
    getConflictingParameterData,
    simplifyParameter,
    shouldHideEmptyParameters,
    getParameterShowIf,
    save,
    picturesToDelete,
    fetchLastInteraction,
    prefill,
    prefillNourishInstanceParameters,
    unprefill,
    clearPrefillStatus,
    interactionLoaded,
    resetInteraction,
    saveInteraction,
    timelineDate,
    wasSavedInBackEnd,
    calculateActions,
    maybePatchCoercedChoices,
    initialInteraction,
    isNotBulkClose,
    getCoercedValueByParameterId,
    setCoercedValueByParameterId,
    archivedValues,
    showSignatureResetWarningModal,
    resetAllSignatureParameters,
    undoTypedValues,
    newSignatureCVSaved,
    setPreviousInteractionState,
    showConfidentialityToggle,
    confidentialityLevel,
    setIsConfidential,
    rolesWithConfidentialViewAccess,
    protocolParentAccessible,
    paramTypesWithAddedRemoved,
    hasSaveForLater,
    showSaveForLaterConfirmationModal,
    saveForLaterTriggerSource,
  };
});

export default useCurrentInteractionStore;
