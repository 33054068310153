import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-help-banner--info-faded v-help-banner u-border-bottom"
}

import { computed, onMounted } from 'vue';
import { watchDebounced } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import use from '@/_shared/compositionApi';
import useCurrentInteractionStore from '../store/currentInteractionStore';
import { Parameter } from '../types/Parameter';
import InteractionSection from '../components/InteractionSection.vue';
import { isParameterFilled } from '../helper/parametersHelper';
import getParameterType from '../helper/parameterComponentCreator';


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterWrapper',
  props: {
  readOnly: {
    type: Boolean,
    required: true,
  },
  inSaveForLaterMode: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const { currentInteraction, shouldHideEmptyParameters } = storeToRefs(useCurrentInteractionStore());

const { calculateComputedParameters, clearPrefillStatus, setParameterStates } = useCurrentInteractionStore();

const visibleComputedParameters = computed(() => (currentInteraction.value?.computedParameters || [])
  .filter((computedParameter) => computedParameter.id !== 'actions'));

const hasOnlyActionsParameter = computed(() => currentInteraction.value.computedParameters.length && currentInteraction.value.computedParameters.filter((p) => p.valueType === 'actions').length
  === currentInteraction.value.computedParameters.length);

const showComputedInfoText = computed(() => currentInteraction.value?.computable && !props.readOnly && !hasOnlyActionsParameter.value);

// eslint-disable-next-line vue/return-in-computed-property
const sortedParameters = computed(() => [...currentInteraction.value?.parameters.filter((param) => !param.id.toString().includes('.')) || []]);
// TODO move it in onBeforeMount
// fix pb of number value comparision  2.0 != 2 in multi choic question
// check if added removed value cause a problem

// TODO -- fix me I am terrible!!
// function timedUpdate() {
//   if (interaction.value.computable) {
//     console.log('timedUpdate');
//     // props.
//     interaction.value.recalculateComputedParameters();
//     // props.interaction.recalculateActions();
//     computedParameters.value = interaction.value.computedParameters!;
//     // interaction.value = props.interaction;
//
//     setTimeout(timedUpdate, 30000);
//   }
// }

// timedUpdate();
// TODO: Once the save for later feature toggle is removed, we should remove the read-only application of this.
const isDataPointToSaveForLater = (parameter: Parameter) => ((!!parameter.dataPoint || parameter.config?.combinedMulti) && props.inSaveForLaterMode);
watchDebounced(
  // per https://vuejs.org/guide/essentials/watchers.html#basic-example
  // this should be a getter but that doesn't seem to work
  currentInteraction.value.parameters,
  calculateComputedParameters,
  { debounce: 500 },
);

const shouldShowParameter = (parameter: Parameter) => (shouldHideEmptyParameters.value
  ? !parameter.hidden && isParameterFilled(parameter, currentInteraction.value.closedAt, true, true)
  : !parameter.hidden);

onMounted(() => {
  clearPrefillStatus();
  setParameterStates();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedParameters.value, (parameter, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: parameter.id
      }, [
        _withDirectives(_createVNode(InteractionSection, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getParameterType)(parameter)), {
              parameter: parameter,
              readOnly: __props.readOnly || parameter.readOnly || isDataPointToSaveForLater(parameter),
              index: index,
              clientId: (parameter.valueType == 'picture') ? _unref(currentInteraction).clientId : '',
              iconUrl: (parameter.valueType == 'picture') ? _unref(currentInteraction).iconUrl : ''
            }, null, 8, ["parameter", "readOnly", "index", "clientId", "iconUrl"]))
          ]),
          _: 2
        }, 1536), [
          [_vShow, shouldShowParameter(parameter)]
        ])
      ]))
    }), 128)),
    (showComputedInfoText.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(translate)('timeline.interaction.calculated_data')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleComputedParameters.value, (computedParameter) => {
      return (_openBlock(), _createElementBlock("div", {
        key: computedParameter.id
      }, [
        _createVNode(InteractionSection, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getParameterType)(computedParameter)), {
              parameter: computedParameter,
              readOnly: true
            }, null, 8, ["parameter"]))
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ], 64))
}
}

})