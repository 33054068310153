import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import { ref, onBeforeMount } from 'vue';
import use from '@/_shared/compositionApi';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';
import { fetchInteraction } from '@/timeline/services/interactionsApi';
import BaseTableRow from '@/_shared/types/baseTableRow';


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionsHistory',
  props: {
  showModal: {
    type: Boolean,
    required: true,
  },
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const { carerStore } = use.store.carers();
const { translate } = use.helpers();



onBeforeMount(async () => {
  const currentInteractionStore = useCurrentInteractionStore();
  const { currentInteraction } = storeToRefs(currentInteractionStore);
  const { protocolParentId, protocolParentAccessible } = currentInteraction.value;
  if (!protocolParentId || protocolParentAccessible === false) return;
  const parentInteraction = await fetchInteraction(protocolParentId);
  if (!parentInteraction) return;
  const parentDate = formatDateTime(new Date(parentInteraction.finishAt as string));

  // TODO check for optional trigger type when user is able to deselect actions
  const triggerType = translate('common.mandatory');

  // get closer name of parent
  const closerId = parentInteraction.closerId!;
  const closerName = carerStore.name(closerId);
  rows.value.push({
    date: parentDate,
    triggeredFrom: parentInteraction.name,
    triggerType,
    closedBy: closerName,
  });
});

const currentInteractionStore = useCurrentInteractionStore();
const { iconUrl, currentInteraction } = storeToRefs(currentInteractionStore);

const emit = __emit;

const closeModal = () => {
  emit('close');
};

const rows = ref<BaseTableRow[]>([]);

const tableHeaders = [
  {
    keyName: 'date',
    displayName: translate('common.date'),
    type: 'string',
  },
  {
    keyName: 'triggeredFrom',
    displayName: translate('timeline.actions.triggeredFrom'),
    type: 'string',
  },
  {
    keyName: 'triggerType',
    displayName: translate('timeline.actions.triggerType'),
    type: 'string',
  },
  {
    keyName: 'closedBy',
    displayName: translate('timeline.actions.closedBy'),
    type: 'string',
  },
];


return (_ctx: any,_cache: any) => {
  return (__props.showModal)
    ? (_openBlock(), _createBlock(BaseModal, {
        key: 0,
        title: _unref(currentInteraction).name,
        "icon-url": _unref(iconUrl),
        onCloseModal: closeModal,
        footer: false
      }, {
        content: _withCtx(() => [
          _createVNode(BaseTable, {
            class: "v-actions-history-table",
            headers: tableHeaders,
            items: rows.value
          }, null, 8, ["items"])
        ]),
        _: 1
      }, 8, ["title", "icon-url"]))
    : _createCommentVNode("", true)
}
}

})