import nourishAuthStore from '@/_shared/store/userAuth';
import { carerStore } from '@/_shared/store/carers';
import clientStore from '@/_shared/store/clients';
import { alarmStore } from '@/_shared/store/alarms';
import categoryIconStore from '@/_shared/store/categoryIcons';
import interactionStore from '@/_shared/store/interactions';
import i18n from '@/_shared/translations/i18n';
import * as nourishHelpers from '@/_shared/services/nourishHelpers';
import { personStore } from '@/_shared/store/people';

export interface Events {
  interactionUpdated: () => void,
  parameterUpdated: () => void
}

// const emitter = createNourishEvents<Events>();

// TODO: Do we really need this? It's more faff with imports than just having separate imports
// If the only reason is to tidy imports, then lets use index.ts
const use = {
  helpers: () => ({
    linkify: nourishHelpers.linkify,
    translate: i18n.global.t,
    locale: i18n.global.locale.value,
    toast: nourishHelpers.toast,
    ToastType: nourishHelpers.ToastType,
  }),
  store: {
    alarms: () => ({
      alarmStore,
    }),
    person: () => ({
      personStore,
    }),
    carers: () => ({
      carerStore,
    }),
    categoryIcons: () => ({
      categoryIconStore,
    }),
    clients: () => ({
      clientStore,
    }),
    interactions: () => ({
      interactionStore,
    }),
    nourish: () => ({
      nourishAuthStore,
    }),
    // providedServices: () => ({
    //   providedServicesStore,
    // }),
  },
};

export default use;
