import apiClient from '@/_shared/services/apiClient';
import {
  AllReportsResponse, Report, ReportResponse,
} from '@/_shared/types/report';
import useUserStore from '@/_shared/store/user';

export const fetchAllReports = async (orgUnitId = useUserStore().currentOrgUnitId as number): Promise<Report[]> => {
  try {
    const response: AllReportsResponse = await apiClient.get(`/api/v2/organisation_units/${orgUnitId}/reports`);
    return response.reports;
  } catch (error) {
    return error;
  }
};

export const createReport = async (report: Report) => {
  const reportData = { report };
  const orgUnitId = useUserStore().currentOrgUnitId;
  try {
    return await apiClient.post(`/api/v2/organisation_units/${orgUnitId}/reports`, reportData);
  } catch (error) {
    return error;
  }
};

export const fetchReport = async (reportId: number): Promise<Report> => {
  try {
    const orgUnitId = useUserStore().currentOrgUnitId;
    const response: ReportResponse = await apiClient.get(`/api/v2/organisation_units/${orgUnitId}/reports/${reportId}`);
    return response.report;
  } catch (error) {
    return error;
  }
};

export const deleteReport = async (reportId: number) => {
  try {
    const orgUnitId = useUserStore().currentOrgUnitId;
    await apiClient.delete(`/api/v2/organisation_units/${orgUnitId}/reports/${reportId}`);
    return true;
  } catch (error) {
    return error;
  }
};

export const downloadReport = async (report: Report) => {
  try {
    const downloadURL = getReportDownloadURL(report, 'attachment');
    const link = document.createElement('a');
    link.href = downloadURL;
    const fileName = `${report.title}.${report.format}`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadURL);
    return true;
  } catch (error) {
    return error;
  }
};

export const previewReport = async (report: Report) => {
  try {
    const previewUrl = getReportDownloadURL(report, 'inline');
    window.open(previewUrl, '_blank');
    return true;
  } catch (error) {
    return error;
  }
};

const getReportDownloadURL = (report: Report, disposition: string) => {
  const deviceUuid = useUserStore().currentDeviceUuid;
  return `${window.location.origin}${report.file.url}?device_uuid=${deviceUuid}&disposition=${disposition}`;
};
