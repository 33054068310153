import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-edit-risks" }
const _hoisted_2 = { class: "v-edit-risks-title" }
const _hoisted_3 = {
  key: 0,
  class: "v-edit-risks-item"
}
const _hoisted_4 = { class: "v-edit-risks-item-title" }
const _hoisted_5 = { class: "v-edit-risks-item-actions" }
const _hoisted_6 = { class: "v-edit-risks-item-settings" }
const _hoisted_7 = { class: "v-edit-risks-item-settings-item" }
const _hoisted_8 = { class: "v-edit-risks-item-settings-item-label" }
const _hoisted_9 = { class: "v-edit-risks-item-settings-item" }
const _hoisted_10 = { class: "v-edit-risks-item-settings-item-label" }
const _hoisted_11 = { class: "v-edit-risks-item-settings-item" }
const _hoisted_12 = { class: "v-edit-risks-item-settings-item-label" }
const _hoisted_13 = {
  key: 0,
  class: "v-edit-risks-item-settings-item-result"
}
const _hoisted_14 = { key: 1 }

import {
  computed, PropType,
} from 'vue';
import { RiskAttribute } from '@/timeline/types/Need';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import { calculateRiskResultClass, calculateRiskScoreClass } from '../utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditRiskAssessments',
  props: {
  items: {
    type: Object as PropType<RiskAttribute[]>,
    required: true,
  },
},
  emits: ['update:items'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const itemsProxy = computed({
  get: () => props.items,
  set: (newVal) => emit('update:items', newVal),
});

const addRisk = () => {
  itemsProxy.value.unshift({
    likelihood: 0,
    impact: 0,
  } as unknown as RiskAttribute);
  emit('update:items', [...itemsProxy.value]);
};

const removeRisk = (risk: RiskAttribute, index: number) => {
  if (risk.id) {
    risk.archived = true;
    emit('update:items', [...itemsProxy.value]);
  } else {
    emit('update:items', [...itemsProxy.value.splice(index, 1)]);
  }
};

const riskLikelihoodOptions = computed(() => [1, 2, 3, 4, 5].map((n) => ({
  value: n,
  text: translate(`need.risk_likelihood_score_options.${n}`),
})));

const riskLikeImpactOptions = computed(() => [1, 2, 3, 4, 5].map((n) => ({
  value: n,
  text: translate(`need.risk_impact_score_options.${n}`),
})));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(translate)('need.risks')) + " ", 1),
      _createVNode(BaseIcon, {
        name: "nr-icon-plus",
        onClick: addRisk,
        testId: "addRisk"
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemsProxy.value, (risk, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: risk.id
      }, [
        (!risk.archived)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(BaseIcon, {
                  name: "archive",
                  onClick: ($event: any) => (removeRisk(risk, index)),
                  class: "v-edit-risks-item-title-icon",
                  testId: "removeRisk"
                }, null, 8, ["onClick"]),
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "v-edit-risks-item-title-required" }, "*", -1)),
                _createVNode(BaseInput, {
                  border: true,
                  modelValue: risk.description,
                  "onUpdate:modelValue": ($event: any) => ((risk.description) = $event),
                  required: true,
                  placeholder: _unref(translate)('need.placeholders.risk_title_placeholder'),
                  class: "v-edit-risks-item-title-input",
                  testId: "riskDescription"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(BaseInput, {
                  border: true,
                  textarea: true,
                  modelValue: risk.actions,
                  "onUpdate:modelValue": ($event: any) => ((risk.actions) = $event),
                  placeholder: _unref(translate)('need.placeholders.risk_actions_placeholder')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(translate)('need.risk_likelihood')), 1),
                  _createVNode(BaseSelect, {
                    border: true,
                    boldLabel: "",
                    options: riskLikelihoodOptions.value,
                    modelValue: risk.likelihood,
                    "onUpdate:modelValue": ($event: any) => ((risk.likelihood) = $event)
                  }, {
                    singlelabel: _withCtx(({ value: option }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskScoreClass)(option.value)}`, "v-risks-icon"])
                      }, _toDisplayString(option.value), 3),
                      _createTextVNode(" " + _toDisplayString(option.label), 1)
                    ]),
                    option: _withCtx(({ option }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskScoreClass)(option.value)}`, "v-risks-icon"])
                      }, _toDisplayString(option.value), 3),
                      _createTextVNode(" " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["options", "modelValue", "onUpdate:modelValue"])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-edit-risks-item-settings-separator" }, " x ", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(translate)('need.risk_impact')), 1),
                  _createVNode(BaseSelect, {
                    border: true,
                    boldLabel: "",
                    options: riskLikeImpactOptions.value,
                    modelValue: risk.impact,
                    "onUpdate:modelValue": ($event: any) => ((risk.impact) = $event)
                  }, {
                    singlelabel: _withCtx(({ value: option }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskScoreClass)(option.value)}`, "v-risks-icon"])
                      }, _toDisplayString(option.value), 3),
                      _createTextVNode(" " + _toDisplayString(option.label), 1)
                    ]),
                    option: _withCtx(({ option }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskScoreClass)(option.value)}`, "v-risks-icon"])
                      }, _toDisplayString(option.value), 3),
                      _createTextVNode(" " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["options", "modelValue", "onUpdate:modelValue"])
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "v-edit-risks-item-settings-separator" }, " = ", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(translate)('need.risk')), 1),
                  (risk.impact && risk.likelihood)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("span", {
                          class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskResultClass)(risk.impact * risk.likelihood)}`, "v-risks-icon"])
                        }, _toDisplayString(risk.impact * risk.likelihood), 3),
                        _createTextVNode(" " + _toDisplayString(_unref(translate)('need.risk_score')), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("span", {
                          class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskResultClass)(risk.impact * risk.likelihood)}`, "v-risks-icon"])
                        }, " 0 ", 2)
                      ]))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})