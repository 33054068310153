import { Person } from '@/_shared/store/people';
import CollectionStore from '@/_shared/store/collectionStore';
import apiClient from '@/_shared/services/apiClient';
import use from '@/_shared/compositionApi';
import { computed } from 'vue';
import { CompositeOption, OptGroup } from '@/_shared/types/baseSelect';

export interface Relation {
  person: Person;
  supportRoleLabel?: string;
  relationship: string;
  archived?: boolean;
  id: number,
  clientId? : number,
  livesWith? : null,
  // emergencyPriority?: null,
  supportRole?: string,
   nextOfKin? : boolean,
  familyPortalAccess? : boolean,
  organisationRoleId? : number,
  observations?: string[]
}

export type ClientRelations = Record<string, unknown> & {
id : number,
relations: Relation[]
}
export type RelationsApiResponse = {
  relations: Relation[]
}
interface Relationships extends Record<string, unknown>{
  relationships: RelationsApiResponse[]
}
const { translate } = use.helpers();
class ClientsRelationsStore extends CollectionStore<ClientRelations, Relationships> {
  protected extractData(resp: Relationships): ClientRelations[] {
    if (resp?.relationships) {
      return this.extractClientRelations(resp?.relationships);
    }
    return [];
  }

  protected fetch(ids: number[]): Promise<Relationships> {
    const calls = ids.map((id) => apiClient.get<RelationsApiResponse>(`/api/v2/clients/${id}}/relations`));
    return new Promise((resolve, reject) => {
      Promise.all(calls)
        .then((resps) => {
          resolve({ relationships: resps });
        })
        .catch((reason) => reject(reason));
    });
  }

  private extractClientRelations(resps: RelationsApiResponse[]): ClientRelations[] {
    const rst = resps.filter((resp) => resp?.relations && resp.relations.length)
      .map((resp) => ({ id: (resp.relations[0].clientId as number), relations: resp.relations }));
    return rst;
  }

  getRelationFormatedName(clientId: number, relationId : number) {
    const clientRelations: ClientRelations = this.byId(clientId);
    return computed(() => {
      const relation = clientRelations?.relations?.find((r) => r.person.id === relationId);
      return (relation && this.formatRelationName(relation)) || '';
    });
  }

  refreshRelations(clientId: number) {
    this.reFetchById(clientId);
  }

  getRelationsAsOptions(clientId: number, asOptGroup = true) {
    const clientRelations: ClientRelations = this.byId(clientId);
    return computed(() => {
      const options: CompositeOption[] = [];
      clientRelations?.relations?.forEach((relation: Relation) => {
        const option: CompositeOption = {
          value: `${relation.person.id}`,
          text: this.formatRelationName(relation),
          photo: relation.person.photo,
          state: relation.archived ? 'archived' : 'active',
        };
        options.push(option);
      });
      if (!asOptGroup) return options;
      const tempOptions: OptGroup[] = [];
      if (options.length) {
        const compositeOptionValues: CompositeOption[] = options;
        tempOptions.push({
          optGroup: translate('common.all_options'),
          options: compositeOptionValues,
        });
      }
      return tempOptions;
    });
  }

  private formatRelationName(relation: Relation) {
    let formattedValue = relation.person.name;
    if (relation.relationship) {
      formattedValue = `${formattedValue} - ${relation.relationship}`;
    }
    if (relation.supportRoleLabel) {
      formattedValue = `${formattedValue} - ${relation.supportRoleLabel}`;
    }
    if (relation.archived) {
      formattedValue = `${formattedValue} (${translate('common.archived')})`;
    }
    return formattedValue;
  }

  public setupEvents() {
    window.addEventListener('RelationsChanged', (e) => {
      const event = e as unknown as CustomEvent;
      const clientId = event.detail.id;
      this.refreshRelations(clientId);
    });
  }
}
export const clientsRelationsStore: ClientsRelationsStore = new ClientsRelationsStore();
