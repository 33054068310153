import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  PropType, provide, ref, inject,
} from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import {
  Options, DataSetCompositeOption,
} from '@/_shared/types/baseSelect';
import NestedDatasetModalContent from '@/_shared/components/modals/DatasetModal/NestedDatasetModalContent.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'NestedDatasetModal',
  props: {
  title: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
  },
  searchInputPlaceholder: {
    type: String,
  },
  allSelectableEntries: {
    type: Object as PropType<DataSetCompositeOption[]>,
    default: () => [] as DataSetCompositeOption[],
  },
  selectedEntries: {
    type: Object as PropType<Options>,
  },
  primaryTextKey: {
    type: String as PropType<keyof DataSetCompositeOption>,
    required: true,
  },
  secondaryTextKey: {
    type: String,
  },

},
  emits: ['updatedSelectedEntries', 'closeModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

provide('canClearBaseSelect', false);

const emit = __emit;
const localSelectedEntries = ref(props.selectedEntries);
const selectedEntriesValues: string[] = Object.values(localSelectedEntries.value || []);
const updateSelectedEntries = ((updatedSelectedEntries: Options) => {
  localSelectedEntries.value = updatedSelectedEntries;
});

const updatedSelectedEntries = () => {
  emit('updatedSelectedEntries', localSelectedEntries.value);
};

const modalConfig = inject('dataSetModalConfig', undefined) as DataSetModalConfig|undefined;
const modalWidth = modalConfig?.leftContentComponent ? 860 : 800;
const modalTitle = () => modalConfig?.modalTitle || props.title;
const modalSearchInputPlaceHolder = () => modalConfig?.modalSearchInputPlaceholder || props.searchInputPlaceholder;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    title: modalTitle(),
    footer: false,
    width: _unref(modalWidth),
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal'))),
    isDatasetModal: ""
  }, {
    header: _withCtx(() => [
      _createVNode(BaseButton, {
        class: "v-save-dataset",
        onClicked: updatedSelectedEntries,
        size: "large"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.buttonText), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(NestedDatasetModalContent, {
        searchInputPlaceholder: modalSearchInputPlaceHolder(),
        allSelectableEntries: __props.allSelectableEntries,
        primaryTextKey: __props.primaryTextKey,
        selectedEntries: _unref(selectedEntriesValues),
        onSelectedEntries: updateSelectedEntries
      }, null, 8, ["searchInputPlaceholder", "allSelectableEntries", "primaryTextKey", "selectedEntries"])
    ]),
    _: 1
  }, 8, ["title", "width"]))
}
}

})