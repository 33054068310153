import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-data-redact-label" }

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import { BaseSelect } from '@/_shared/components';
import { RedactionOptionValue } from '@/_shared/types/report';
import use from '@/_shared/compositionApi';
import useReportStore from '@/_shared/store/reports';
import { storeToRefs } from 'pinia';

type RedactionOption = {
  value: RedactionOptionValue,
  text: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportRedaction',
  setup(__props) {


const { translate } = use.helpers();

const { newReport } = storeToRefs(useReportStore());

const redactionOptions: RedactionOption[] = [
  { value: 'show_all', text: translate('reports.data_redaction.redaction_options.show_all') },
  { value: 'only_carer_data', text: translate('reports.data_redaction.redaction_options.only_carer_data') },
  { value: 'redact_all', text: translate('reports.data_redaction.redaction_options.redact_all') },
  { value: 'only_client_data', text: translate('reports.data_redaction.redaction_options.only_client_data') },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCardSection, {
    title: _unref(translate)('reports.data_redaction.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(translate)('reports.data_redaction.description')), 1),
      _createVNode(_unref(BaseSelect), {
        "standard-input-style": "",
        label: _unref(translate)('reports.data_redaction.label'),
        modelValue: _unref(newReport).params.redactionOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.redactionOption) = $event)),
        options: redactionOptions
      }, null, 8, ["label", "modelValue"])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})