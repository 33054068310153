export const reportClasses = ['ClientInteractionsReport', 'ClientCareplanReport', 'ClientProfileReport', 'CarerProfileReport', 'WoundSinglePhotoReport', 'WoundMultiPhotoReport', 'InteractionChangesReport', 'EmergencyPackReport', 'InteractionReport'];
// | 'EmergencyPackReport' | 'GpConnectUserAccessReport' | 'InteractionReport' | ;
export type ReportClass = typeof reportClasses[number];

export type ReportFormat = 'html' | 'pdf' | 'csv' | 'excel';
export type RedactionOptionValue = 'show_all' | 'only_carer_data' | 'redact_all' | 'only_client_data'

export type InteractionState = 'closed' | 'cancelled' | 'planned';
export type AlarmState = 'ok' | 'warning' | 'alarm';

export type NotesFields = 'note_public' | 'parameters';
export type WarningsFields = 'show_alarms' | 'close_justification' | 'late_alarms';

export type CareplanFields = 'previous_reviews' | 'need_provided_services' | 'interaction_logs' | 'profile' | 'emergency_critical_info' | 'critical_information'
export type ClientProfileFields = 'status_in_organisation' | 'biography' | 'documents' | 'circle_of_care'

export type DataViewKey = 'normal' | 'extended'

export type WoundOptions = 'AllPhotosForThisWound' | 'MostRecentPhoto' | 'FirstAndMostRecentPhoto' | 'Last5Photos' | 'Last10Photos'

export type Report = {
  id: number,
  organisationUnitId: number,
  title: string,
  format: ReportFormat,
  state: string,
  stateDetail?: string,
  params: ReportParams,
  file: {
      url: string,
  },
  createdAt: Date,
  updatedAt: Date,
  reportClass: ReportClass,
  uuid: string | null,
  viewType: DataViewKey
};

export interface ReportTemplate {
  id: number,
  format: ReportFormat,
  title: string,
  reportClass: ReportClass,
  params: ReportParams,
  viewType: DataViewKey
}

export type ReportParams = {
  alarmState?: AlarmState[],
  clientId?: number,
  clientIds?: number[] | number
  carerIds?: number[],
  eRedBag?: boolean,
  fields: string[],
  filter?: string,
  fromDate?: Date | string,
  groupBy?: string,
  interactionState?: InteractionState[],
  lastUpdated?: boolean;
  lastCompleted?: boolean;
  pageBreak: boolean,
  pageNumbering: boolean,
  photoOption?: WoundOptions
  providedServiceIds?: number[],
  redactionOption: RedactionOptionValue,
  state?: string,
  toDate?: Date | string,
  woundId?: number
}

export type AllReportsResponse = {
  reports: Report[]
};

export type ReportResponse = {
  report: Report
}
