import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import useReportStore from '@/_shared/store/reports';
import { useRouter } from 'vue-router';
import BaseCardActions from '@/_shared/components/display/BaseCard/BaseCardActions.vue';
import use from '@/_shared/compositionApi';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportActions',
  setup(__props) {

const router = useRouter();

const { translate } = use.helpers();

const runReport = async () => {
  if (await createReport()) {
    await router.push({ name: 'admin.reports' });
  } else {
    toast(translate('reports.error'), ToastType.Danger);
  }
};

const reportStore = useReportStore();
const { reportValid } = storeToRefs(reportStore);
const { createReport, buildNewReport } = reportStore;

const cancelReport = () => {
  buildNewReport('ClientInteractionsReport');
  router.push({ name: 'admin.reports' });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCardActions, {
    onPrimaryButtonClicked: runReport,
    onSecondaryButtonClicked: cancelReport,
    "primary-button-disabled": !_unref(reportValid)
  }, null, 8, ["primary-button-disabled"]))
}
}

})