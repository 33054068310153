import { defineStore } from 'pinia';
import { ref } from 'vue';
import { OrganisationRole } from '@/_shared/services/organisationRolesApi';
import {
  memorizedDivisions,
  memorizedOrgUnits,
  memorizedRegions,
} from '@/_shared/services/organisationUnitsApi';
import { CompositeOption } from '@/_shared/types/baseSelect';

import { deleteNotificationConfiguration, getNotificationConfigurations } from '@/notifications/services/notificationConfigurationApi';
import type { NotificationConfiguration, NotificationConfigurationListResponse } from '@/notifications/types/NotificationConfiguration';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';

const useNotificationsStore = defineStore('notifications', () => {
  const selectedRole = ref<OrganisationRole | null>(null);
  const divisions = ref<string[]>([]);
  const regions = ref<string[]>([]);
  const orgUnits = ref<CompositeOption[]>([]);
  const notificationsLoaded = ref<boolean>(false);
  const organisationUnits = ref<OrganisationUnit[]>([]);

  const notificationConfigurationListResponse = ref<NotificationConfigurationListResponse>();
  const notificationConfigurations = ref<NotificationConfiguration[]>([]);

  const fetchNotificationSectionsOptions = async () => {
    divisions.value = await memorizedDivisions();
    regions.value = await memorizedRegions();
    organisationUnits.value = await memorizedOrgUnits();
    if (organisationUnits.value) {
      orgUnits.value = organisationUnits.value.map((unit) => ({ value: unit.id, text: unit.name }));
    }
    notificationsLoaded.value = true;
  };

  const fetchNotificationConfigurations = async () => {
    notificationConfigurations.value = [];

    if (selectedRole.value && selectedRole.value.id) {
      notificationConfigurationListResponse.value = await getNotificationConfigurations({ roleId: selectedRole.value.id });
    } else if (selectedRole.value && selectedRole.value.name === 'Superuser') {
      notificationConfigurationListResponse.value = await getNotificationConfigurations({ superUser: true });
    }
    notificationConfigurations.value = notificationConfigurationListResponse.value?.notificationConfigurations || [];

    notificationsLoaded.value = true;
    return notificationConfigurations.value;
  };

  const addConfiguration = (notificationConfiguration: NotificationConfiguration) => {
    notificationConfigurations.value.push(notificationConfiguration);
  };

  const deleteConfiguration = async (notificationConfiguration: NotificationConfiguration) => {
    await deleteNotificationConfiguration(notificationConfiguration.id);
    const notificationIndex = notificationConfigurations.value.findIndex((entry) => entry.id === notificationConfiguration.id);
    if (notificationIndex > -1) {
      notificationConfigurations.value.splice(notificationIndex, 1);
    }
  };

  const selectRole = (role: string, roleId: number) => {
    selectedRole.value = { name: role, id: roleId };
  };

  const $reset = () => {
    selectedRole.value = null;
    notificationsLoaded.value = false;
    notificationConfigurations.value = [];
  };

  return {
    $reset,
    selectRole,
    selectedRole,
    divisions,
    regions,
    orgUnits,
    organisationUnits,
    notificationsLoaded,
    configurations: notificationConfigurations,
    fetchNotificationSectionsOptions,
    fetchNotificationConfigurations,
    addConfiguration,
    deleteConfiguration,
  };
});

export default useNotificationsStore;
