import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

import { computed, ComputedRef, ref } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import useUserStore from '@/_shared/store/user';
import clientStore from '@/_shared/store/clients';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import vBaseTooltip from '@/_shared/directives/BaseTooltip';
import use from '@/_shared/compositionApi';
import { IconColor } from '@/_shared/types/iconColor';
import InteractionSection from './InteractionSection.vue';

// TODO NP-???? make async setup work with webpack
// this currently fails to compile with
// 'TS2305: Module '"vue"' has no exported member 'withAsyncContext'.' error
// await useNourishStore().ensureLoaded();

type QuickAction = {
  action: () => void,
  text: string,
  icon: string, // TODO type this once we use material icons
  show: boolean,
  colour?: ComputedRef<'grey' | 'black'> | 'alarm',
  class?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickActions',
  emits: [
  'showAssignCarersModal',
  'setSaveForLaterMode',
  'showLog',
  'showPastVersions',
  'cancelInteraction',
  'snoozeInteraction',
  'showActionsHistory',
  'dryRunActions',
],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { translate } = use.helpers();
const currentInteractionStore = useCurrentInteractionStore();
const { currentUser } = storeToRefs(useUserStore());

const {
  currentInteraction,
  isClosed,
  isSticky,
  hasOldVersionSignatureParam,
  hasDataPoint,
  isViewOnly,
  createdByAction,
  protocolParentAccessible,
  isNotBulkClose,
} = storeToRefs(currentInteractionStore);
const nourishStore = useUserStore();

const showContextMessageButton = () => !isNew.value
  && nourishStore.userHasPermission('viewMessages')
  && nourishStore.getFeatureToggle('messaging')
  && isNotBulkClose.value;

const showAssignCarersButton = () => !isViewOnly.value;

const isCareplanReview = computed(() => !!currentInteraction.value?.needId);
const isNew = computed(() => !currentInteraction.value?.id);
// TODO is the below correct?
const canUpdateExistingPlannedInteraction = computed(() => ((currentInteraction.value?.state === 'planned' || isSticky.value)
  && nourishStore.userCan('manageInteractionsForServiceId', currentInteraction.value?.serviceId)
  && !isNew.value));

const showSaveForLaterButton = () => (!isCareplanReview.value
  && !hasOldVersionSignatureParam.value)
  && (canUpdateExistingPlannedInteraction.value || isNew.value)
  && isNotBulkClose.value
  && (!hasDataPoint.value || nourishStore.getFeatureToggle('saveForLaterStates'))
  && !nourishStore.getFeatureToggle('saveForLaterInteractionHeader');

const isInSaveForLaterMode = ref(false);
const saveForLaterColour = computed(() => (isInSaveForLaterMode.value ? 'black' : 'grey'));
const toggleSaveForLater = () => {
  isInSaveForLaterMode.value = !isInSaveForLaterMode.value;
  emit('setSaveForLaterMode', isInSaveForLaterMode.value);
};

const showLogButton = computed(() => isNotBulkClose.value && !isCareplanReview.value);

const showPastVersionsButton = computed(() => !!currentInteraction.value.id && isNotBulkClose.value);

const showCancelButton = () => !isClosed.value
  && !isNew.value
  && !isCareplanReview.value
  && canUpdateExistingPlannedInteraction.value
  && isNotBulkClose.value;

const showSnoozeButton = () => !isClosed.value
  && !isNew.value
  && !isCareplanReview.value
  && canUpdateExistingPlannedInteraction.value
  && !isSticky.value
  && isNotBulkClose.value;

const showActionsHistoryButton = () => createdByAction.value && protocolParentAccessible.value;

const showDryRunActions = () => currentUser.value.isImpersonated
  && currentInteraction.value?.state === 'closed'
  && !isCareplanReview.value;

const sendContextMessage = () => {
  const clientId = currentInteraction.value?.clientId;
  if (clientId) {
    const clientName = computed(() => clientStore.name(clientId));
    if (clientName.value) {
      // TODO use router.push once messages are in Vue (without feature toggle)

      window.location.assign(createMessageUrl(clientName.value, !!currentInteraction.value?.confidential));
    }
  }
};

const createMessageUrl = (clientName: string, confidential: boolean) => {
  const toValue = `${clientName} - ${currentInteraction.value?.name}`;
  const url = window.location.href;
  const date = currentInteraction.value?.finishAt || new Date().toISOString();
  const contentValue = `---\n${toValue}: ${url}?date=${date}`;

  // Use to access in Vue messages URL
  let messageUrl = `${window.location.origin}${window.location.pathname}#/messages/new?body=${encodeURIComponent(contentValue)}&subject=${encodeURIComponent(toValue)}`;
  if (confidential) {
    messageUrl += '&confidential=true';
  }
  return messageUrl;
};

const assignCarers = () => {
  emit('showAssignCarersModal');
};

const openLog = () => {
  emit('showLog');
};

const openPastVersions = () => {
  emit('showPastVersions');
};

const cancel = () => {
  emit('cancelInteraction');
};

const snooze = () => {
  emit('snoozeInteraction');
};

const openActionsHistory = () => {
  emit('showActionsHistory');
};

const dryRunActions = () => {
  emit('dryRunActions');
};

const quickActions = ref<QuickAction[]>([
  {
    action: sendContextMessage,
    text: translate('timeline.quickActions.contextMessage'),
    icon: 'nr-icon-message',
    show: showContextMessageButton(),
    class: 'v-interaction-context-message',
  },
  {
    action: assignCarers,
    text: translate('timeline.quickActions.assignCarers'),
    icon: 'nr-icon-assign-carer',
    show: showAssignCarersButton(),
    class: 'v-interaction-assign-carers',
  },
  {
    action: toggleSaveForLater,
    text: translate('timeline.quickActions.later'),
    icon: 'nr-icon-pencil',
    show: showSaveForLaterButton(),
    colour: saveForLaterColour, // 'grey',
    class: 'v-edit-interaction',
  },
  {
    action: openLog,
    text: translate('timeline.quickActions.log'),
    icon: 'nr ncp-log',
    show: showLogButton.value,
    class: 'v-interaction-log',
  },
  {
    action: openPastVersions,
    text: translate('timeline.quickActions.pastVersions'),
    icon: 'nr-icon-audit-trail',
    show: showPastVersionsButton.value,
    class: 'v-interaction-past-versions',
  },
  {
    action: cancel,
    text: translate('common.cancel'),
    icon: 'nr-icon-cancel',
    show: showCancelButton(),
    class: 'v-cancel-interaction',
  },
  {
    action: snooze,
    text: translate('common.snooze'),
    icon: 'nr-icon-snooze',
    show: showSnoozeButton(),
    class: 'v-snooze-interaction',
  },
  {
    action: openActionsHistory,
    text: translate('timeline.quickActions.actionsHistory'),
    icon: 'nr-icon-actions-history',
    show: showActionsHistoryButton(),
    class: 'v-interaction-actions-history',
  },
  {
    action: dryRunActions,
    text: 'Dry Run Actions',
    icon: 'nr-icon-actions-history',
    show: showDryRunActions(),
    colour: 'alarm',
    class: 'v-dry-run-actions-btn',
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InteractionSection, { class: "quick-actions-section" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quickActions.value, (quickAction) => {
        return (_openBlock(), _createElementBlock("span", {
          key: quickAction.text
        }, [
          (quickAction.show)
            ? _withDirectives((_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["quick-action-wrapper", `${quickAction.class || ''}`]),
                onClick: quickAction.action
              }, [
                _createVNode(BaseIcon, {
                  name: quickAction.icon,
                  size: "lg",
                  transparent: "",
                  "foreground-hover-color": "black",
                  "foreground-color": (`${quickAction.colour  || 'grey'}`) as IconColor
                }, null, 8, ["name", "foreground-color"])
              ], 10, _hoisted_1)), [
                [_unref(vBaseTooltip), {position: 'top', tooltipText: quickAction.text}]
              ])
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})