import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-params-entry-info-col"
}

import { ref, computed, inject } from 'vue';
import use from '@/_shared/compositionApi';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { IconColor } from '@/_shared/types/iconColor';
import { Parameter, ParameterState } from '../types/Parameter';

interface Props {
  parameterState?: ParameterState,
  showStatus?: boolean,
  parameter: Parameter,
}

interface IconData {
  highlightColor: string;
  iconColor: IconColor;
  icon: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterIcon',
  props: {
    parameterState: {},
    showStatus: { type: Boolean },
    parameter: {}
  },
  emits: ['clicked'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

const isModal = inject('isModal', false);

const backgroundColor = computed(() => {
  const { config } = props.parameter;
  if (config && config.backgroundColor && config.color) { return config.backgroundColor; }
  if (isModal) { return '#F7F5F3'; }
  return '';
});

const iconData = ref<{[key: string]: IconData}>(
  {
    conflict: {
      highlightColor: '#66cccc', iconColor: 'teal', icon: 'v-icon-conflict',
    },
    prefilled: {
      highlightColor: 'grey', iconColor: 'grey', icon: 'nsx-font-icon--help',
    },
    error: {
      highlightColor: '#D10711', iconColor: 'alarm', icon: 'v-icon-conflict',
    },
    clone: {
      highlightColor: backgroundColor.value, iconColor: 'teal', icon: 'v-icon-clone',
    },
  },
);

const highlightStyle = computed(() => [
  {
    '--highlight-color': props.parameterState && props.showStatus !== false && props.parameterState !== 'resolved' && !isModal
      ? iconData.value[props.parameterState]?.highlightColor
      : backgroundColor.value,
    '--background-color': backgroundColor.value,
  },
]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "highlighter highlighted-edge",
    style: _normalizeStyle(highlightStyle.value)
  }, [
    (props.parameterState && props.showStatus !== false && props.parameterState !== 'resolved')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(BaseTooltip, {
            position: "right",
            class: "v-icon-tooltip",
            tooltipText: _unref(translate)(`parameter.${props.parameterState}`),
            hide: _unref(isModal)
          }, {
            default: _withCtx(() => [
              (props.parameterState)
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    name: iconData.value[props.parameterState].icon,
                    class: _normalizeClass(`sp-${props.parameterState}`),
                    foregroundColor: iconData.value[props.parameterState].iconColor,
                    noHover: "",
                    size: "2x",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('clicked')))
                  }, null, 8, ["name", "class", "foregroundColor"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["tooltipText", "hide"])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})