import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-interaction-restricted-access-container" }
const _hoisted_2 = { key: 0 }

import use from '@/_shared/compositionApi';
import { computed, ref } from 'vue';
import { formatDateRangeStr } from '@/timeline/helper/interactionsHelper';
import OrgUnitData from '../types/OtherOrganisationUnit';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionOrgUnitRestrictedView',
  props: {
    data: {}
  },
  setup(__props: any) {

const { translate } = use.helpers();

const props = __props;

const multipleInteractionWithOrgUnitAccess = (orgUnitData: OrgUnitData) => translate('timeline.interaction.multiple_with_org_unit_access', {
  count: orgUnitData.count,
  care_home: orgUnitData.orgUnitName,
  date_range: `${formatDateRangeStr(orgUnitData.startDate, orgUnitData.endDate)}`,
});
const singleInteractionWithOrgUnitAccess = (orgUnitData: OrgUnitData) => translate('timeline.interaction.single_with_org_unit_access', {
  count: orgUnitData.count,
  care_home: orgUnitData.orgUnitName,
  date_range: `${formatDateRangeStr(orgUnitData.startDate, orgUnitData.endDate)}`,
});
const singleInteractionWithoutOrgUnitAccess = (orgUnitData: OrgUnitData) => translate('timeline.interaction.single_restricted_org_unit_access', {
  count: orgUnitData.count,
  date_range: `${formatDateRangeStr(orgUnitData.startDate, orgUnitData.endDate)}`,
});
const multipleInteractionWithoutOrgUnitAccess = (orgUnitData: OrgUnitData) => translate('timeline.interaction.multiple_restricted_org_unit_access', {
  count: orgUnitData.count,
  date_range: `${formatDateRangeStr(orgUnitData.startDate, orgUnitData.endDate)}`,
});
const getOrgAccessInteractionMessage = (orgUnitData: OrgUnitData) => {
  if (orgUnitData.count === 1) {
    return singleInteractionWithOrgUnitAccess(orgUnitData);
  }
  return multipleInteractionWithOrgUnitAccess(orgUnitData);
};
const getInteractionMessageWithoutOrgAccess = (orgUnitData: OrgUnitData) => {
  if (orgUnitData.count === 1) {
    return singleInteractionWithoutOrgUnitAccess(orgUnitData);
  }
  return multipleInteractionWithoutOrgUnitAccess(orgUnitData);
};
const interactionRestrictMessageForOU = (orgUnitData: OrgUnitData) => {
  if (orgUnitData.orgUnitName) {
    return getOrgAccessInteractionMessage(orgUnitData);
  }
  return getInteractionMessageWithoutOrgAccess(orgUnitData);
};

const interactionRestrictMessage = computed(() => {
  const messageArray: string[] = [];
  props.data.forEach((orgUnitData) => {
    messageArray.push(interactionRestrictMessageForOU(orgUnitData));
  });
  return messageArray;
});

const isVisible = ref(false);

// Toggle visibility function
function toggleVisibility() {
  isVisible.value = !isVisible.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_unref(translate)('timeline.interaction.interactionsInAnotherOrgUnit')), 1),
    (isVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interactionRestrictMessage.value, (message, index) => {
            return (_openBlock(), _createElementBlock("p", {
              class: "v-interaction-restricted-access-body",
              key: index
            }, _toDisplayString(message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      onClick: toggleVisibility,
      class: "toggle-visibility"
    }, [
      _createElementVNode("strong", null, "Show " + _toDisplayString(isVisible.value ? 'less' : 'more'), 1)
    ])
  ]))
}
}

})