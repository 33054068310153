<template>
  <div class="v-spinner">
    <div class="v-icon-spinner_1"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/icon";

.v-spinner {
  font-family: "v-nourish-icons";
  color: $v-color-mid-grey;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-size: 24px;
  height: 24px;
  width: 24px;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>
