import { ref, watch, Ref } from 'vue';
import { ParameterPicture } from '@/timeline/types/Parameter';
import exifr from 'exifr';
import { sanitizeFileName } from '@/_shared/helpers/fileHelper';
import use from '@/_shared/compositionApi';

export const print = (imgSrc: string, infoText: HTMLElement, imageName: string) => {
  const printFrame = document.createElement('iframe');
  printFrame.style.visibility = 'hidden';

  document.body.appendChild(printFrame);

  const frameDoc = printFrame.contentDocument || printFrame.contentWindow?.document;
  if (!frameDoc) return;

  writeHtmlContent(frameDoc, imgSrc, infoText, imageName);

  const img = frameDoc.getElementById('print-image') as HTMLImageElement;
  img.onload = () => {
    if (printFrame.contentWindow) {
      printFrame.contentWindow.print();
      printFrame.contentWindow.onafterprint = () => {
        document.body.removeChild(printFrame);
      };
    }
  };
};

const writeHtmlContent = (frameDoc: Document, imgSrc: string, infoText: HTMLElement, imageName: string) => {
  frameDoc.open();
  frameDoc.write(`
    <html lang="">
      <head>
        <title>${imageName}</title>
        <style>
          body {
            margin: 20px;
            font-family: Arial, sans-serif;
            text-align: center;
          }
          #print-info-text {
            text-align: left;
          }
          #print-image {
            max-height: 80vh;
            text-align: center;
            margin-top: 10px;
            width: auto;
            max-width: 100%;
            height: auto;
          }
          #print-info-text, #print-image {
            break-inside: avoid;
          }
        </style>
      </head>
      <body>
        <div id="print-info-text">
          ${infoText?.innerHTML || ''}
        </div>
        <img id="print-image" src="${imgSrc}" alt="${imageName}" />
      </body>
    </html>
  `);
  frameDoc.close();
};

export interface Image {
  src: string;
  dateTime: string;
  position: number;
  fileName: string;
}

export const useImages = (pictures: Ref<ParameterPicture[]>) => {
  const images = ref<Image[]>([]);
  const imageMap = new Map<number, Image>();

  const deviceUuid = window.localStorage.getItem('deviceUUID');
  const params = `?device_uuid=${deviceUuid}`;

  const buildImage = async (picture: ParameterPicture) => {
    const updateImage = (image: Image) => {
      imageMap.set(picture.position, image);
    };

    if (picture.tempFile) {
      const dataUrl = await readFileAsDataURL(picture.tempFile);
      const dateTime = await extractMetadata(dataUrl);
      imageMap.set(picture.position, {
        src: dataUrl,
        dateTime,
        position: picture.position,
        fileName: sanitizeFileName(picture.tempFile.name || ''),
      });
    } else if (picture.pictureFile) {
      const url = picture.pictureFile.relativeUrl || picture.pictureFile.url || '';
      const src = `${url}${params}`;
      const dateTime = await extractMetadata(src);
      updateImage({
        src,
        dateTime,
        position: picture.position,
        fileName: sanitizeFileName(picture.pictureFile?.fileName || ''),
      });
    }
  };

  const readFileAsDataURL = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target.result as string);
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });

  const setImages = async () => {
    imageMap.clear();
    await Promise.all(pictures.value?.map(async (picture) => buildImage(picture)));
    images.value = Array.from(imageMap.values());
  };

  watch(pictures, async () => {
    await setImages();
  }, { immediate: true, deep: true });

  return { images };
};

export const extractMetadata = async (src: string) => {
  const { translate } = use.helpers();
  try {
    const metadata = await exifr.parse(src);
    return metadata?.DateTime?.toGMTString()
      || metadata?.DateTimeOriginal?.toGMTString()
      || translate('timeline.parameters.picture.noDate');
  } catch (e) {
    return translate('timeline.parameters.picture.noDate');
  }
};
