import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import use from '@/_shared/compositionApi';
import { computed, PropType } from 'vue';
import BaseInteractionRestictedView from '@/timeline/components/InteractionRestriction/BaseInteractionRestictedView.vue';
import { formatInteractionDate } from '@/_shared/helpers/useDateTimeHelper';
import { InteractionGroup } from '@/_shared/types/interactionExt';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionRestrictedView',
  props: {
  data: {
    type: Object as PropType<InteractionGroup[]>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();
const props = __props;

const generateMultipleDayInteractionMessage = (interactionGroup: InteractionGroup[]) => {
  let count = 0;
  const interactionGroupLength = interactionGroup.length;
  interactionGroup.forEach((group) => {
    count += group.interactions.length;
  });

  return translate('timeline.interaction.multiple_resticted_current_org_unit_access', {
    count,
    date_range: `${formatInteractionDate(interactionGroup[0]?.date)} - ${formatInteractionDate(interactionGroup[interactionGroupLength - 1]?.date)}`,
  });
};

const generateSingleDaySingleInteractionMessage = (interactionGroup: InteractionGroup[]) => translate('timeline.interaction.single_resticted_current_org_unit_access', {
  count: interactionGroup[0].interactions?.length,
  date_range: `${formatInteractionDate(interactionGroup[0]?.date)}`,
});

const generateSingleDayMultipleInteractionMessage = (interactionGroup: InteractionGroup[]) => translate('timeline.interaction.singleday_multiple_resticted_current_org_unit_access', {
  count: interactionGroup[0].interactions?.length,
  date_range: `${formatInteractionDate(interactionGroup[0]?.date)}`,
});

const generateSingleDayInteractionMessage = (interactionGroup: InteractionGroup[]) => {
  const count = interactionGroup[0].interactions?.length || 0;

  return count === 1
    ? generateSingleDaySingleInteractionMessage(interactionGroup)
    : generateSingleDayMultipleInteractionMessage(interactionGroup);
};

const interactionRestrictMessage = computed(() => {
  const interactionGroups = props.data;
  if (!interactionGroups) return '';

  if (interactionGroups?.length === 1) {
    return generateSingleDayInteractionMessage(interactionGroups);
  }
  return generateMultipleDayInteractionMessage(interactionGroups);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseInteractionRestictedView, { data: interactionRestrictMessage.value }, null, 8, ["data"]))
}
}

})