import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-params-entry" }
const _hoisted_2 = { class: "v-params-entry-help-row sp-interaction-prefill" }
const _hoisted_3 = { class: "v-params-entry-help-row__content" }
const _hoisted_4 = { class: "v-params-entry-help-row__action" }

import { ref, watch, nextTick } from 'vue';
import BaseToggle from '../../_shared/components/inputs/BaseToggle.vue';
import use from '../../_shared/compositionApi';
import useCurrentInteractionStore from '../store/currentInteractionStore';
// import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'PrefillToggle',
  setup(__props) {

const currentInteractionStore = useCurrentInteractionStore();
const { prefill, unprefill, setParameterStates } = currentInteractionStore;

const checkboxToggle = ref(false);
const { toast, ToastType, translate } = use.helpers();

function updateValue() {
  checkboxToggle.value = !checkboxToggle.value;
}
watch(checkboxToggle, async (value) => {
  if (value === true) {
    const done = await prefill();
    if (!done) {
      showNotice();
      checkboxToggle.value = false;
      await nextTick();
    }
  } else if (value === false) {
    await unprefill();
  }
  setParameterStates();
});

function showNotice() {
  toast(translate('interaction.cannot_prefill'), ToastType.Warning);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-params-entry-help-row__icon-spacer" }, null, -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)('interaction.prefill')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(BaseToggle, {
          checked: checkboxToggle.value,
          onToggleChange: updateValue,
          direction: "row-reverse"
        }, null, 8, ["checked"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-params-entry-help-row__icon-spacer" }, null, -1))
    ])
  ]))
}
}

})