import IInteraction from '@/timeline/types/IInteraction';
import {
  LocationQuery, RouteLocationNormalizedLoaded,
  RouteParamsRaw,
} from 'vue-router';
import { Alarm } from '@/timeline/types/Alarm';
import use from '@/_shared/compositionApi';
import { isParameterFilled } from '@/timeline/helper/parametersHelper';
import { Parameter } from '@/timeline/types/Parameter';
import { compareDateAsc } from '@/_shared/helpers/useDateTimeHelper';
import useInstanceRoutes from '@/_shared/router/helpers/useInstanceRoutes';

const { translate } = use.helpers();

export type InteractionLink = {
  name?: string,
  params?: RouteParamsRaw,
  path?: string,
  query: LocationQuery
  force?: boolean
}
// todo review the way is used, it's not effesent , the code is ran for every interaction
function interactionLink(route : RouteLocationNormalizedLoaded, interaction: IInteraction, toAssessments = false): InteractionLink {
  const routeName = (route.name as string)?.split('.').slice(0, 2).join('.');
  const needInteraction = interaction.needId && interaction.state !== 'cancelled';
  let partialRoute: Partial<InteractionLink> = {
    name: `${routeName}.${needInteraction ? 'needinteraction' : 'interaction'}`,
    params: { id: interaction.id },
  };

  if (toAssessments) {
    partialRoute.name = 'client.assessments.interaction';
  }

  if (window.familyPortal) {
    partialRoute.name = 'portalClient.timeline.interaction';
  }

  const { isWithinInstanceNestedRoutes, currentTimelineViewStartPath } = useInstanceRoutes(route);
  if (isWithinInstanceNestedRoutes.value) {
    partialRoute = { path: `${currentTimelineViewStartPath.value}/interactions/${interaction.id}` };
  }

  const queryCopy = { ...(route.query || {}) };
  if (queryCopy?.serviceId) { delete queryCopy.serviceId; }

  return {
    ...partialRoute,
    query: queryCopy,
    force: true,
  };
}

function responsiblePersonId(interaction: IInteraction) {
  const responsibleIds = interaction.responsiblePersonIds;
  if (responsibleIds?.find((id: number) => id === interaction.closerId)) {
    return interaction.closerId;
  }
  return responsibleIds[0];
}

function overdueTimeToWords(diffTime: number) {
  let diffHours = Math.floor(diffTime / 3600 / 1000);
  let diffMinutes = Math.ceil((diffTime / 60 / 1000) - (60 * diffHours));
  const overdue = translate('common.overdue');

  if (diffHours === 0) {
    const stringForMins = diffMinutes < 2 ? translate('common.min') : translate('common.mins');
    return `${diffMinutes} ${stringForMins}\n${overdue}`;
  }
  if (diffMinutes === 60) {
    diffHours += 1;
    diffMinutes = 0;
  }
  const stringForHours = diffHours < 2 ? translate('common.hour') : translate('common.hours');
  const minutesText = `0${diffMinutes}`;
  return `${diffHours}:${(minutesText).slice(-2)} ${stringForHours}\n${overdue}`;
}

function alarmStateCssClasses(alarm: Alarm) {
  const classes = ['v-state-icon'];
  classes.push(`v-state-alarm ${alarm.state}`);
  classes.push(alarm.closedAt ? 'closed' : 'open');
  return classes;
}

function isSnoozed(interaction: IInteraction) {
  return (interaction.state === 'planned' || interaction.state === 'sticky')
  && isAfterPlannedTime(interaction);
}

const isAfterPlannedTime = (interaction: IInteraction) => plannedBeforeCurrent(interaction.finishAt, interaction.plannedFinishAt)
  || plannedBeforeCurrent(interaction.startAt, interaction.plannedStartAt);

const plannedBeforeCurrent = (currentTimestamp: string | null, plannedTimestamp: string | null) => {
  if (!plannedTimestamp || !currentTimestamp) { return false; }

  return new Date(plannedTimestamp) < new Date(currentTimestamp);
};

const formatDateWithYear = (date: string | number | Date) => new Date(date).toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: '2-digit',
});
const formatDateWithoutYear = (date: string | number | Date) => new Date(date).toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
});
const isSameDate = (startDate: Date, endDate: Date) => startDate.getFullYear() === endDate.getFullYear()
  && startDate.getMonth() === endDate.getMonth()
  && startDate.getDate() === endDate.getDate();

const formatDateRangeStr = (startDate: string | number | Date, endDate: string | number | Date) => {
  if (!isSameDate(new Date(startDate), new Date(endDate))) {
    return `${formatDateWithoutYear(startDate)} - ${formatDateWithYear(endDate)}`;
  }
  return formatDateWithYear(startDate);
};

const highestAlarm = (interaction: IInteraction) => interaction?.alarms.find((alarm: Alarm) => alarm.state === 'alarm') || interaction.alarms[0];

function interactionStateCss(interaction: IInteraction) {
  const stateIconClasses = ['v-state-icon'];
  if (interaction?.alarms.length) { return alarmStateCssClasses(highestAlarm(interaction)); }
  if (interaction.state === 'closed') {
    stateIconClasses.push('v-state-ok');
    return stateIconClasses;
  }
  if (interaction.state === 'cancelled') {
    stateIconClasses.push('v-state-cancelled');
    return stateIconClasses;
  }
  if (isSnoozed(interaction)) {
    stateIconClasses.push('v-state-snoozed');
    return stateIconClasses;
  }
  return [];
}

function formatDisplayTime(dateTime: string | null) {
  if (dateTime) {
    return new Date(dateTime)
      .toLocaleTimeString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
      })
      .replace('-', ' ')
      .replace(',', '');
  }
  return '';
}

function filledParametersPresent(interaction: IInteraction, checkClosed: boolean) {
  if ((checkClosed && unclosedState(interaction.state))
      || (!checkClosed && cancelledOrSticky(interaction.state))) {
    return false;
  }
  return Boolean(!!interaction.parameters?.find((parameter: object) => isParameterFilled(parameter as Parameter, interaction.closedAt, checkClosed, interaction.hideEmptyParameters))
    || (interaction.computedParameters?.length > 0 && interaction.closedAt));
}

const isPlanned = (interaction: IInteraction) => interaction.state === 'planned' || interaction.state === 'sticky';

const additionalInfoPresent = (interaction: IInteraction) => interaction.responsiblePersonIds?.length
    || !!interaction.notePublic
    || filledParametersPresent(interaction, true)
    || isSnoozed(interaction)
    || !isPlanned(interaction);

const isManualOrTargetAlarm = (alarm: Alarm, closedInteraction: boolean): boolean => {
  switch (alarm.alarmType) {
    case 'manual': return closedInteraction;
    case 'target_not_met': return !closedInteraction;
    default: return false;
  }
};
const isDueAlarm = (alarm : Alarm, interactionState:string): boolean => alarm.alarmType === 'due' && interactionState === 'planned';
const isAnAlarmToDisplay = (alarm : Alarm, interactionState:string): boolean => {
  if (isDueAlarm(alarm, interactionState) || isManualOrTargetAlarm(alarm, interactionState === 'closed')) {
    return true;
  }
  return false;
};
const compareAlarmState = (a: string|null|undefined, b: string|null|undefined) => {
  if (a == null && b == null) return 0;
  if (a == null) return 1;
  if (b == null) return -1;
  return (a as string) < (b as string) ? -1 : 1;
};
const compareInteraction = (i1 : IInteraction, i2 :IInteraction) => compareDateAsc(i1.closedAt, i2.closedAt) || compareDateAsc(i1.plannedStartAt, i2.plannedStartAt) || i1.serviceId - i2.serviceId;

const getInteractionState = (state: string): string => (unclosedState(state) ? state : 'closed');
const getManualAlarmState = (state: string): string => (unclosedState(state) ? 'ok' : state);
const unclosedState = (state: string): boolean => ['planned', 'cancelled', 'sticky'].includes(state);
const cancelledOrSticky = (state: string): boolean => ['cancelled', 'sticky'].includes(state);

const oneAssessmentParPs = (assessments: IInteraction[]) => {
  if (!assessments.length) return [];
  const uniquePsId = [...Array.from(new Set(assessments.map((assessment) => assessment.providedServiceId)))];
  const rst = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < assessments.length; i++) {
    if (!uniquePsId.length) break;
    const assessment = assessments[i];
    const location = uniquePsId.indexOf(assessment.providedServiceId);
    if (location >= 0) {
      rst.push(assessment);
      uniquePsId.splice(location, 1);
    }
  }
  return rst;
};
export {
  formatDisplayTime,
  filledParametersPresent,
  additionalInfoPresent,
  interactionLink,
  interactionStateCss,
  overdueTimeToWords,
  responsiblePersonId,
  isAnAlarmToDisplay,
  isManualOrTargetAlarm,
  isSnoozed,
  compareInteraction,
  compareAlarmState,
  getInteractionState,
  getManualAlarmState,
  oneAssessmentParPs,
  formatDateRangeStr,
};
