import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-pending-deletions-table" }
const _hoisted_2 = { class: "centered-content" }
const _hoisted_3 = { class: "v-restore-clients-content-text" }
const _hoisted_4 = { class: "centered-content" }
const _hoisted_5 = { class: "v-restore-clients-content-text" }

import { ref } from 'vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { storeToRefs } from 'pinia';
import useDataRetentionStore from '@/_shared/store/dataRetention';
import use from '@/_shared/compositionApi';
import dataRetentionIcon from '@/_shared/assets/icons/data_retention.svg';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import clientStore from '@/_shared/store/clients';
import { PendingDeletionRow } from './types/DataRetentionTableData';
import DataRetentionReportTable from './DataRetentionReportTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PendingDeletions',
  setup(__props) {

const dataRetentionStore = useDataRetentionStore();

const { translate } = use.helpers();

const clientTerm = clientStore.clientTerm();
const { activeStates } = dataRetentionStore;

const { pendingDeletionsTableData } = storeToRefs(dataRetentionStore);
const showRestoreModal = ref(false);
const clientNameAndId = ref('');
const clientId = ref(0);

const showRestoredModal = ref(false);

const restore = async () => {
  showRestoreModal.value = false;
  await dataRetentionStore.restoreSoftDeletedClient(clientId.value);
  showRestoredModal.value = true;
};

const restoreClientHandler = (row: PendingDeletionRow) => {
  clientNameAndId.value = `${row.client} ${row.clientId}`;
  showRestoreModal.value = true;
  clientId.value = row.clientId;
};

const reverseOrder = () => {
  dataRetentionStore.reversePendingDeletedTableOrder();
  tableComponent.value!.el.scrollTo(0, 0);
};

const tableComponent = ref<typeof DataRetentionReportTable | null>(null);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { withBGImg: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(DataRetentionReportTable, {
          ref_key: "tableComponent",
          ref: tableComponent,
          class: "v-pending-data-retention-table",
          data: _unref(pendingDeletionsTableData),
          onRestoreClient: restoreClientHandler,
          onReverseOrder: reverseOrder,
          containsArray: false,
          activeStates: _unref(activeStates),
          emptyMessage: 
          _unref(translate)('data_retention.no_pending_deletions', { clientTerm: _unref(clientTerm) })
        
        }, null, 8, ["data", "activeStates", "emptyMessage"]),
        (showRestoreModal.value)
          ? (_openBlock(), _createBlock(BaseModal, {
              key: 0,
              title: _unref(translate)('data_retention.pending_deletions_modal.restore_a_person'),
              onConfirm: restore,
              "confirm-text": _unref(translate)('data_retention.restore'),
              "icon-svg": _unref(dataRetentionIcon),
              "cancel-hidden": false,
              "cancel-text": _unref(translate)('data_retention.pending_deletions_modal.cancel'),
              onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (showRestoreModal.value = false)),
              fromRestoreModal: ""
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(translate)(
                  "data_retention.pending_deletions_modal.do_you_want_to_restore"
                ) +
                " " +
                clientNameAndId.value), 1)
                ])
              ]),
              _: 1
            }, 8, ["title", "confirm-text", "icon-svg", "cancel-text"]))
          : _createCommentVNode("", true),
        (showRestoredModal.value)
          ? (_openBlock(), _createBlock(BaseModal, {
              key: 1,
              title: _unref(translate)('data_retention.pending_deletions_modal.restored'),
              "icon-svg": _unref(dataRetentionIcon),
              onConfirm: _cache[1] || (_cache[1] = ($event: any) => (showRestoredModal.value = false)),
              "confirm-text": _unref(translate)('data_retention.pending_deletions_modal.close'),
              onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (showRestoredModal.value = false)),
              class: "v-restored-modal"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(clientNameAndId.value +
                " " +
                _unref(translate)("data_retention.pending_deletions_modal.has_been_restored")), 1)
                ])
              ]),
              _: 1
            }, 8, ["title", "icon-svg", "confirm-text"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})