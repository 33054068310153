import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-log-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "v-service-not-visible-warning"
}

import { computed, onBeforeMount, ref } from 'vue';
import { compareDesc, subMonths } from 'date-fns';
import serviceLogGraph from '@/_shared/services/serviceLogGraph';
import serviceLogTable from '@/_shared/services/serviceLogTable';
import useUserStore from '@/_shared/store/user';
import { ClientTypeServices, servicesForClientTypes } from '@/client_types/services/clientTypesApi';
import use from '@/_shared/compositionApi';
import { InteractionLogGraphData, InteractionLogTableData } from '@/timeline/types/InteractionLogData';
import { getInteractions } from '@/timeline/services/interactionsApi';
import IInteraction from '@/timeline/types/IInteraction';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import { watchDebounced } from '@vueuse/core';
import clientStore from '@/_shared/store/clients';
import { handleNourishInstanceParams } from '@/timeline/helper/parametersHelper';
import CareplanNoData from './CareplanNoData.vue';
import LogNoData from './LogNoData.vue';
import LogOptions from './LogOptions.vue';
import LogTable from './LogTable.vue';
import LogGraph from './LogGraph.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceLog',
  props: {
  serviceId: {
    type: Number,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
  showTotal: {
    type: Boolean,
    default: false,
  },
  showDailyAvg: {
    type: Boolean,
    default: false,
  },
  showTotalDailyAvg: {
    type: Boolean,
    default: false,
  },
  showLastInteraction: {
    type: Boolean,
    default: false,
  },
  darkTheme: {
    type: Boolean,
    default: false,
  },
  forCarePlanConsent: {
    type: Boolean,
    default: false,
  },
  noDataMessage: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const loading = ref(true);

const fromDate = ref(subMonths(new Date(), 1));
const toDate = ref(new Date());
const clientType = computed(() => clientStore.clientType(props.clientId));

const lastInteraction = ref<IInteraction[]>([]);
const skipDateChange = ref(false);

onBeforeMount(async () => {
  userCanReadInteractions.value = useUserStore().userCan('readInteractionsForServiceId', props.serviceId);
  if (userCanReadInteractions.value) {
    lastInteraction.value = await fetchLastInteraction();
  }

  await changeTimeSpan(fromDate.value, toDate.value, true);
  const showTableFirst = serviceLogGraphData.value.length === 0;
  showTable.value = showTableFirst;
  showGraph.value = !showTableFirst;
  loading.value = false;
  const { currentOrganisationUnit } = useUserStore();
  clientTypeServices.value = await servicesForClientTypes(currentOrganisationUnit.organisationId);
});

const serviceLogGraphData = ref<InteractionLogGraphData[]>([]);
const serviceLogTableData = ref<InteractionLogTableData | null>(null);
const lastInteractionTableData = ref<InteractionLogTableData | null>(null);
const clientTypeServices = ref<ClientTypeServices>();
const userCanReadInteractions = ref(false);

const fetchInteractions = async (from: Date = fromDate.value, to: Date = toDate.value) => {
  const query = {
    fromDate: from,
    toDate: to,
    serviceId: props.serviceId,
    clientId: props.clientId,
  };
  let fetchedInteractions = await getInteractions(query, { limit: 50, sort: '-time' });
  fetchedInteractions = handleNourishInstanceParams(fetchedInteractions);
  return fetchedInteractions.sort((a: IInteraction, b: IInteraction) => compareDesc(new Date(a.finishAt as string), new Date(b.finishAt as string)));
};

const fetchLastInteraction = async () => {
  const query = {
    state: 'closed',
    limit: 1,
    serviceId: props.serviceId,
    clientId: props.clientId,
  };
  return getInteractions(query, { limit: 1, sort: '-time' });
};

const showTable = ref(false);
const toggleTable = () => {
  showTable.value = showTable.value && !showGraph.value ? showTable.value : !showTable.value;
};

const showGraph = ref(false);
const toggleGraph = () => {
  showGraph.value = showGraph.value && !showTable.value ? showGraph.value : !showGraph.value;
};

const changeTimeSpan = async (from: Date, to: Date, setup = false) => {
  if (skipDateChange.value) {
    skipDateChange.value = false;
    return;
  }
  lastInteractionTableData.value = null;

  const interactions = userCanReadInteractions.value ? await fetchInteractions(from, to) : [];
  serviceLogGraphData.value = serviceLogGraph(interactions);
  serviceLogTableData.value = await serviceLogTable(interactions, props.showTotal, props.showDailyAvg, props.showTotalDailyAvg, props.forCarePlanConsent);
  if (!serviceLogTableData.value) {
    if (!lastInteraction.value.length || !lastInteraction.value[0].finishAt) { return; }
    if (setup) {
      lastInteractionTableData.value = await serviceLogTable(lastInteraction.value);
      skipDateChange.value = true;
      fromDate.value = new Date(lastInteraction.value[0].finishAt);
    }
  }
};

const serviceVisibleToClientType = computed(() => {
  if (!clientType.value) return true;
  const clientTypeCodename = valueToCamel(clientType.value);
  if (!clientTypeCodename || !clientTypeServices.value
    || !(clientTypeCodename in clientTypeServices.value)) {
    return true;
  }

  const clientTypeServiceIds = clientTypeServices.value[clientTypeCodename] || [];
  const allAvailableServices = clientTypeServiceIds.concat(clientTypeServices.value.idsNotFromLibraries);

  return allAvailableServices.includes(props.serviceId);
});

watchDebounced(
  [fromDate, toDate],
  ([newFrom, newTo]) => changeTimeSpan(newFrom, newTo),
  { debounce: 100 },
);


return (_ctx: any,_cache: any) => {
  return (!loading.value)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (!serviceVisibleToClientType.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(translate)('log.serviceNotVisible')), 1))
          : _createCommentVNode("", true),
        _createVNode(LogOptions, {
          onToggleGraph: toggleGraph,
          onToggleTable: toggleTable,
          fromDate: fromDate.value,
          "onUpdate:fromDate": _cache[0] || (_cache[0] = ($event: any) => ((fromDate).value = $event)),
          toDate: toDate.value,
          "onUpdate:toDate": _cache[1] || (_cache[1] = ($event: any) => ((toDate).value = $event)),
          showGraph: showGraph.value,
          showTable: showTable.value
        }, null, 8, ["fromDate", "toDate", "showGraph", "showTable"]),
        (serviceLogGraphData.value.length)
          ? _withDirectives((_openBlock(), _createBlock(LogGraph, {
              key: 1,
              data: serviceLogGraphData.value
            }, null, 8, ["data"])), [
              [_vShow, showGraph.value]
            ])
          : _createCommentVNode("", true),
        (!__props.showLastInteraction && showGraph.value && !serviceLogGraphData.value.length)
          ? (_openBlock(), _createBlock(LogNoData, {
              key: 2,
              messageType: "graph"
            }))
          : _createCommentVNode("", true),
        (!__props.showLastInteraction && showTable.value && !serviceLogTableData.value)
          ? (_openBlock(), _createBlock(LogNoData, {
              key: 3,
              messageType: "table"
            }))
          : _createCommentVNode("", true),
        (__props.showLastInteraction && ((showGraph.value && !serviceLogGraphData.value.length) || (showTable.value && !serviceLogTableData.value)))
          ? (_openBlock(), _createBlock(CareplanNoData, {
              key: 4,
              "last-interaction": !!lastInteractionTableData.value,
              noDataMessage: __props.noDataMessage,
              "can-read-interactions": userCanReadInteractions.value
            }, null, 8, ["last-interaction", "noDataMessage", "can-read-interactions"]))
          : _createCommentVNode("", true),
        (serviceLogTableData.value)
          ? _withDirectives((_openBlock(), _createBlock(LogTable, {
              key: 5,
              data: serviceLogTableData.value,
              darkTheme: __props.darkTheme
            }, null, 8, ["data", "darkTheme"])), [
              [_vShow, showTable.value]
            ])
          : _createCommentVNode("", true),
        (!serviceLogTableData.value && lastInteractionTableData.value)
          ? (_openBlock(), _createBlock(LogTable, {
              key: 6,
              data: lastInteractionTableData.value
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})