import {
  ref, watchEffect, onUnmounted, Ref,
} from 'vue';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

export default function usePanzoom(
  imageElem: Ref<HTMLElement | null>,
  minScale = 1,
  maxScale = 5,
  scaleStep = 0.5,
  options: Record<string, unknown> = {},
) {
  const panzoom = ref<PanzoomObject | null>(null);
  const zoomScale = ref(minScale);

  watchEffect(() => {
    if (imageElem.value) {
      panzoom.value = Panzoom(imageElem.value, {
        minScale,
        maxScale,
        ...options,
      });

      imageElem.value.addEventListener('panzoomchange', () => {
        zoomScale.value = Math.round(panzoom.value!.getScale() * 10) / 10;
      });
    }
  });

  const zoomIn = () => {
    if (panzoom.value) {
      panzoom.value.zoomIn({ step: scaleStep });
    }
  };

  const zoomOut = () => {
    if (panzoom.value) {
      panzoom.value.zoomOut({ step: scaleStep });
    }
  };

  onUnmounted(() => {
    if (panzoom.value) {
      panzoom.value.destroy();
    }
  });

  return {
    panzoom, zoomScale, zoomIn, zoomOut,
  };
}
