declare global {
    interface Window {
        gtag?: (type: 'event', eventName: string, eventData?: string) => void;
        amplitude?: {
            getInstance: () => { logEvent: (eventName: string, eventData?: string) => void }

        }
    }
}

export default function sendAnalyticsEvent(eventName: string, eventData?: string) {
  if (eventData) {
    if (window.gtag) { window.gtag('event', eventName, eventData); }
    if (window.amplitude) { window.amplitude.getInstance().logEvent(eventName, eventData); }
  } else {
    if (window.gtag) { window.gtag('event', eventName); }
    if (window.amplitude) { window.amplitude.getInstance().logEvent(eventName); }
  }
}
