import { computed, Ref } from 'vue';
import { formatDisplayTime, isSnoozed, overdueTimeToWords } from '@/timeline/helper/interactionsHelper';
import { format } from 'date-fns';
import { Alarm } from '@/timeline/types/Alarm';
import IInteraction from '@/timeline/types/IInteraction';

const useInteractionListEntryTime = (interaction: Ref<IInteraction>) => {
  const overdueTime = computed(() => {
    const diffTime = Date.now() - new Date(timeToCalculateOverdue.value).getTime();
    return maybeOverdue.value && diffTime > 0 ? overdueTimeToWords(diffTime) : false;
  });

  const timeToCalculateOverdue = computed(() => interaction.value.finishAt
    || interaction.value.plannedFinishAt
    || new Date().toDateString());

  const maybeOverdue = computed(() => (getDueAlarm.value || isSnoozed(interaction.value)) && interaction.value.finishAt);

  const finishAt = computed(() => formatDisplayTime(interaction.value.finishAt));

  const startAt = computed(() => formatDisplayTime(interaction.value.startAt));

  const dueInDate = computed(() => (interaction.value.finishAt ? format(new Date(interaction.value.finishAt), 'd MMM yy') : false));

  const timeFirstRow = computed(() => {
    if (overdueTime.value) {
      return overdueTime.value;
    }
    if (interaction.value.needsStartAt) {
      return `${dueInDate.value} ${startAt.value}`;
    }
    return dueInDate.value;
  });

  const timeSecondRow = computed(() => (overdueTime.value ? false : startAt.value || finishAt.value));

  const timeClass = computed(() => (getDueAlarm.value ? getDueAlarm.value.state : ''));

  const getDueAlarm = computed(() => interaction.value.alarms?.find((alarm: Alarm) => alarm.alarmType === 'due' && !alarm.closedAt));

  return {
    overdueTime,
    startAt,
    finishAt,
    dueInDate,
    timeFirstRow,
    timeSecondRow,
    timeClass,
  };
};

export default useInteractionListEntryTime;
