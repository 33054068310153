import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-new-notification-header-container" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-new-notification-title" }
const _hoisted_5 = { class: "v-content" }
const _hoisted_6 = { class: "v-flex-column" }
const _hoisted_7 = { class: "v-new-notification-header-container" }
const _hoisted_8 = { class: "v-header-left" }
const _hoisted_9 = { class: "v-new-notification-title" }

import { computed, onBeforeMount, watch } from 'vue';

import { BaseCard } from '@/_shared/components';
import use from '@/_shared/compositionApi';
import useNotificationsStore from '@/_shared/store/notifications';
import useUserStore from '@/_shared/store/user';
import BaseRightPanel from '../../_shared/components/general/BaseRightPanel.vue';
import NotificationsList from './NotificationsList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsMain',
  setup(__props) {

const { translate } = use.helpers();
const { userHasPermission } = useUserStore();

const notificationsStore = useNotificationsStore();
const hasPermissionsAndRoleSelected = computed(() => userHasPermission('manageRoles') && notificationsStore.selectedRole);

onBeforeMount(() => {
  notificationsStore.fetchNotificationSectionsOptions();
});

watch(
  () => notificationsStore.selectedRole,
  () => {
    if (notificationsStore.selectedRole) {
      notificationsStore.fetchNotificationConfigurations();
    }
  },
);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, null, {
    default: _withCtx(() => [
      (!hasPermissionsAndRoleSelected.value)
        ? (_openBlock(), _createBlock(_unref(BaseCard), {
            key: 0,
            padding: false,
            "content-top-border": ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, _toDisplayString(_unref(translate)('notifications.email_notifications')), 1)
                    ])
                  ])
                ])
              ])
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(translate)('notifications.please_select_a_role_to_manage')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (hasPermissionsAndRoleSelected.value)
        ? (_openBlock(), _createBlock(_unref(BaseCard), {
            key: 1,
            padding: false,
            "content-top-border": ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", null, _toDisplayString(_unref(translate)('notifications.new', { selected_role: _unref(notificationsStore).selectedRole?.name })), 1)
                    ])
                  ])
                ])
              ])
            ]),
            content: _withCtx(() => [
              _createVNode(NotificationsList)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})