import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "v-params-entry-info-col" }
const _hoisted_3 = { class: "v-param-central-col v-param-first-line" }
const _hoisted_4 = { class: "v-params-title-flex" }
const _hoisted_5 = { class: "v-params-entry-title" }
const _hoisted_6 = { class: "v-params-entry-value" }
const _hoisted_7 = { class: "v-params-entry-description" }
const _hoisted_8 = { class: "v-params-entry-score-col" }
const _hoisted_9 = { class: "v-params-entry-info-col" }
const _hoisted_10 = { class: "v-param-central-col" }
const _hoisted_11 = { class: "v-base-parameter-second-line" }
const _hoisted_12 = { class: "v-params-entry-score-col" }
const _hoisted_13 = {
  key: 2,
  class: "v-params-entry-error-msg"
}
const _hoisted_14 = { class: "v-param-central-col" }
const _hoisted_15 = {
  key: 0,
  class: "v-interaction-alert-messages"
}
const _hoisted_16 = {
  class: "v-alert v-alert--parameter",
  style: {"display":"flex","align-items":"end"}
}
const _hoisted_17 = { style: {"word-wrap":"break-word","width":"100%"} }
const _hoisted_18 = { class: "alert-message sp-validation-error" }

import use from '@/_shared/compositionApi';
import addPreferredTermsToText from '@/_shared/services/clientHelpers';
import {
  computed, inject, onBeforeMount, ref, watch,
} from 'vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import useInteractionActionStore from '@/_shared/store/interactionActionsStore';
import { storeToRefs } from 'pinia';
import BaseExpandingText from '@/_shared/components/display/BaseExpandingText.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { watchDebounced } from '@vueuse/core';
import useUserStore from '@/_shared/store/user';
import { testShowIf, getValues } from '../helper/dynamicParameterHelper';
import { CoercedMultiSelect, CoercedPersonPicker, Parameter } from '../types/Parameter';
import ParameterScore from './ParameterScore.vue';
import ParameterIcon from './ParameterIcon.vue';
import ParameterConflictModal from './ParameterConflictModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  secondLine: {
    type: Boolean,
    default: false,
  },
  showScore: {
    type: Boolean,
    default: false,
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  showStatus: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const { calculateActions, fetchLastInteraction, paramTypesWithAddedRemoved } = useCurrentInteractionStore();
const { shouldRunActions } = useInteractionActionStore();

const props = __props;

const currentInteractionStore = useCurrentInteractionStore();
const { getFeatureToggle } = useUserStore();
const {
  setParameterHidden, setParameterEdited, getParameterShowIf, setParameterState,
} = currentInteractionStore;
const { currentInteraction } = storeToRefs(currentInteractionStore);
const { translate } = use.helpers();
const isModal = inject('isModal', false);
const style = buildStyle();
const linkStyling = linkStyle();
const titleStyling = titleIsBold();

function titleIsBold() {
  if (props.parameter.config && props.parameter.config.bold) {
    return props.parameter.config.bold ? 'bold' : '';
  }
  return '';
}

function buildStyle() {
  const { config } = props.parameter;
  let builtStyle = {};
  if (config && config.backgroundColor && config.color) {
    builtStyle = {
      'background-color': `${config.backgroundColor}`,
      color: `${config.color}`,
    };
  } else if (isModal) {
    builtStyle = {
      'background-color': '#F7F5F3',
      color: '#000000',
    };
  }
  return builtStyle;
}

function linkStyle() {
  const { config } = props.parameter;
  if (config && config.backgroundColor) {
    const background = config.backgroundColor;
    return background.toLowerCase() === '#66cccc' ? '#ffffff' : '#66cccc';
  }
  return '#66cccc';
}

const parameterName = computed(() => {
  const clientId = currentInteraction.value?.clientId;
  let title: string = clientId ? addPreferredTermsToText(+clientId, props.parameter?.name).value : props.parameter?.name;
  title += (props.parameter?.required && props.parameter.valueType !== 'nourish_instance') ? ' *' : '';
  title += props.parameter?.unit ? ` (${props.parameter?.unit})` : '';
  return title;
});

function parameterHasValue(parameter: Parameter): boolean {
  return getValues(parameter).length > 0;
}

const showIf = computed(() => {
  const showIfConfig = getParameterShowIf(props.parameter.id);
  if (showIfConfig) {
    if (!showIfConfig.target) return false;
    if (!showIfConfig.target.hidden) {
      if (parameterHasValue(props.parameter) && showIfConfig.target.edited !== true && (!props.parameter.edited && currentInteraction.value?.protocolContextData === null)) {
        return true;
      }
      return testShowIf(showIfConfig);
    }
    return false;
  }
  return true;
});

function maybeCalculateActions() {
  const shouldRun = shouldRunActions(currentInteraction.value, props.parameter);
  if (shouldRun) {
    return calculateActions();
  }
  return null;
}

const showConflictModal = ref(false);
const modalRect = ref({} as DOMRect);
const gridContainer = ref({} as HTMLElement);

const toggleConflictModal = async () => {
  if (isModal || props.parameter.state !== 'conflict') {
    showConflictModal.value = false;
    return;
  }
  modalRect.value = await gridContainer.value.getBoundingClientRect();
  showConflictModal.value = !showConflictModal.value;
};

const conflictingParameterData = ref<[Parameter, number | null, string | null]>([{} as Parameter, null, null]);

const { getConflictingParameterData } = useCurrentInteractionStore();

const handleSelectorsOnReject = (parameter: Parameter, conflictingParameter: Parameter) => {
  if (paramTypesWithAddedRemoved.includes(parameter.valueType)) {
    const cv = parameter.coercedValue as CoercedPersonPicker | CoercedMultiSelect;
    const conflictingCv = conflictingParameter.coercedValue as CoercedPersonPicker | CoercedMultiSelect;
    const newValues = cv.values as string[];
    const conflictingValues = conflictingCv.values as string[];
    (parameter.coercedValue as CoercedMultiSelect).addedValues = newValues.filter((value) => !conflictingValues.includes(value));
    (parameter.coercedValue as CoercedMultiSelect).removedValues = conflictingValues.filter((value: string) => !newValues.includes(value));
  }
  resolveConflict(parameter);
};

const resolveConflict = (parameter: Parameter) => {
  showConflictModal.value = false;
  updateParameter(parameter);
};

onBeforeMount(async () => {
  if (getFeatureToggle('saveForLaterStates')) {
    conflictingParameterData.value = await getConflictingParameterData(props.parameter);
  }
});

watch(showIf, (newvalue) => {
  setParameterHidden(props.parameter.id, !newvalue);
}, { immediate: true });

watch(() => props.parameter.coercedValue, () => { setParameterEdited(props.parameter.id); });

watch(() => props.parameter.coercedValue, async () => {
  if (currentInteraction.value.state !== 'closed' && getFeatureToggle('saveForLaterStates')) {
    const lastInteraction = await fetchLastInteraction();
    setParameterState(props.parameter, lastInteraction);
  }
});

// per https://vuejs.org/guide/essentials/watchers.html#basic-example
// this should be a getter but that doesn't seem to work
watchDebounced(
  props.parameter,
  maybeCalculateActions,
  { debounce: 500 },
);

function updateParameter(updatedParameter: Parameter) {
  props.parameter.coercedValue = updatedParameter.coercedValue;
  props.parameter.state = 'resolved';
}


return (_ctx: any,_cache: any) => {
  return (showIf.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (showConflictModal.value && props.parameter.state == 'conflict')
          ? (_openBlock(), _createBlock(ParameterConflictModal, {
              key: 0,
              parameter: props.parameter,
              conflictingParameterData: conflictingParameterData.value,
              modalRect: modalRect.value,
              onAccept: resolveConflict,
              onReject: handleSelectorsOnReject,
              onClose: toggleConflictModal
            }, null, 8, ["parameter", "conflictingParameterData", "modalRect"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "grid-container v-parameter-in-focus",
          ref_key: "gridContainer",
          ref: gridContainer,
          id: `v-clickable-parameter-${__props.parameter.id}`
        }, [
          _createVNode(ParameterIcon, {
            showStatus: props.showStatus,
            parameterState: props.parameter.state,
            parameter: props.parameter,
            onClicked: toggleConflictModal
          }, null, 8, ["showStatus", "parameterState", "parameter"]),
          _createElementVNode("div", null, [
            (!__props.hideHeading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["v-params-entry-heading", `sp-parameter-${__props.parameter.id}`]),
                  style: _normalizeStyle(_unref(style))
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "lhs-slot-second-line")
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h4", _hoisted_5, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["v-parameter-title", _unref(titleStyling)])
                        }, _toDisplayString(parameterName.value), 3)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _renderSlot(_ctx.$slots, "value-slot")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(BaseExpandingText, {
                        text: __props.parameter.description || '',
                        showMoreText: `${_unref(translate)('common.show_more')}`,
                        showLessText: `${_unref(translate)('common.show_less')}`,
                        linkStyle: _unref(linkStyling)
                      }, null, 8, ["text", "showMoreText", "showLessText", "linkStyle"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _renderSlot(_ctx.$slots, "rhs-slot-first-line")
                  ])
                ], 6))
              : _createCommentVNode("", true),
            (__props.secondLine || __props.hideHeading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "v-param-second-line",
                  style: _normalizeStyle(_unref(style))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "lhs-slot-second-line")
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _renderSlot(_ctx.$slots, "second-line")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    (__props.showScore && __props.parameter.coercedValue)
                      ? (_openBlock(), _createBlock(ParameterScore, {
                          key: 0,
                          parameter: __props.parameter
                        }, null, 8, ["parameter"]))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "rhs-slot-second-line")
                  ])
                ], 4))
              : _createCommentVNode("", true),
            (__props.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-params-entry-info-col" }, null, -1)),
                  _createElementVNode("div", _hoisted_14, [
                    (__props.errorMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(BaseIcon, {
                              name: "exclamation-circle",
                              foregroundColor: "alarm",
                              "no-hover": "",
                              compact: ""
                            }),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("p", _hoisted_18, _toDisplayString(__props.errorMessage), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 8, _hoisted_1)
      ], 64))
    : _createCommentVNode("", true)
}
}

})