import { computed, ComputedRef, ref } from 'vue';
import { defineStore } from 'pinia';
import { getInteractionsWithImages, getInstanceTimeRange } from '@/_shared/services/nourishInstancesApi';
import { GalleryViewInteraction, GalleryViewInfo } from '@/_shared/types/galleryViewInfo';
import { PictureWrapper } from '@/timeline/types/Parameter';

const useImageGalleryStore = defineStore('imageGallery', () => {
  const galleryViewInteractions = ref<GalleryViewInteraction[]>([]);
  const instanceTimeRange = ref<Partial<GalleryViewInfo>>({});

  const fetchGalleryViewData = async (clientId: number, instanceId: number) => {
    galleryViewInteractions.value = await getInteractionsWithImages(clientId, instanceId);
    instanceTimeRange.value = await getInstanceTimeRange(clientId, instanceId);
  };

  const hasImages = computed(() => {
    if (!galleryViewInteractions.value.length) return false;

    return galleryViewInteractions.value.some(
      (interaction) => (interaction.coercedValue?.pictures ?? []).length > 0,
    );
  });

  const galleryViewInfo: ComputedRef<GalleryViewInfo | Record<string, never>> = computed(() => {
    if (!galleryViewInteractions.value.length) {
      return {};
    }
    const totalImages = galleryViewInteractions.value
      .flatMap((interaction) => interaction.coercedValue.pictures).length;

    const isSensitive = galleryViewInteractions.value
      .some((interaction) => (interaction.coercedValue as PictureWrapper).blurred);

    const newestInteraction = galleryViewInteractions.value[0];
    const oldestInteraction = galleryViewInteractions.value[galleryViewInteractions.value.length - 1];

    const firstInteractionDate = instanceTimeRange.value?.firstInteractionDate || '';
    const lastInteractionDate = instanceTimeRange.value?.lastInteractionDate || '';

    return {
      totalImages,
      isSensitive,
      newestInteraction,
      oldestInteraction: oldestInteraction.id !== newestInteraction.id ? oldestInteraction : null,
      firstInteractionDate,
      lastInteractionDate,
    } as GalleryViewInfo;
  });

  const $reset = () => {
    instanceTimeRange.value = {};
  };

  return {
    galleryViewInteractions,
    hasImages,
    galleryViewInfo,
    instanceTimeRange,
    fetchGalleryViewData,
    $reset,
  };
});

export default useImageGalleryStore;
