import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed, ref } from 'vue';
import BodyMapListEntry from '@/timeline/components/BodyMap/BodyMapListEntry.vue';
import ShowLocations from '@/timeline/components/BodyMap/ShowLocations.vue';
import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import clientStore from '@/_shared/store/clients';
import InstanceViewTimeline from '../InstanceViewTimeline.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapViewTimeline',
  props: {
  instanceId: {
    type: String,
    required: true,
  },
  clientId: {
    type: String,
    required: true,
  },
  searchTerm: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;

const showLocations = ref(false);

const selectedInstance = computed(() => clientStore.skinInstances(+props.clientId).value.find((i) => i.id === +props.instanceId));
const { instanceLocations, defaultSide } = useBodyMapLocationsHelper().forSkinInstances(computed(() => (selectedInstance.value ? [selectedInstance.value!] : [])));


return (_ctx: any,_cache: any) => {
  return (selectedInstance.value)
    ? (_openBlock(), _createBlock(InstanceViewTimeline, {
        key: 0,
        "instance-id": +props.instanceId,
        "client-id": +props.clientId,
        "search-term": props.searchTerm,
        "selected-instance": selectedInstance.value
      }, {
        default: _withCtx(() => [
          _createVNode(ShowLocations, {
            "default-show-locations": false,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showLocations.value = !showLocations.value))
          }),
          (showLocations.value)
            ? (_openBlock(), _createBlock(BodyMapImage, {
                key: 0,
                "left-panel": true,
                "instance-locations": _unref(instanceLocations),
                "side-to-show": _unref(defaultSide)
              }, null, 8, ["instance-locations", "side-to-show"]))
            : _createCommentVNode("", true),
          _createVNode(BodyMapListEntry, {
            class: "v-selected-instance",
            instance: selectedInstance.value,
            reference: true,
            "show-gallery-button": true,
            "is-instance-view": true
          }, null, 8, ["instance"])
        ]),
        _: 1
      }, 8, ["instance-id", "client-id", "search-term", "selected-instance"]))
    : _createCommentVNode("", true)
}
}

})