import {
  computed, ComputedRef, reactive,
} from 'vue';
import { getClientsForReports } from '@/clients/services/clientsApi';
import { dataPointsStore } from '@/_shared/store/dataPoints';
import useClientBadgesHelper from '@/_shared/helpers/useClientBadgesHelper';
import { useSingletonComputed } from '@/_shared/services/UseUtils';
import {
} from '@vueuse/core';
import { instancesStore } from '@/_shared/store/Instances';
import IInteraction from '@/timeline/types/IInteraction';
import { Client, ClientForReports, ClientQueryResponse } from '@/_shared/types/client';
import { PersonStore } from './people';
import apiClient from '../services/apiClient';
import useUserStore from './user';

export type ClientInformation = Client & {
  healthNumber: string
  birthDate: string
  integrationId: string
  errorMessages?: string[]
}
export interface ClientResponse {
  client: Client;
}

class ClientStore extends PersonStore<Client, ClientQueryResponse> {
  // Todo we can link datapoint store to it whene ever we initialize or fetch we can fetch datapoints
  fetch(ids: number[]): Promise<ClientQueryResponse> {
    const data = { query: { ids } };
    const urlPath = `/api/v2/clients/query?ou_id=${window.currentOrganisationUnit?.id}`
      + '&include_health_profile=true'
      + '&only=id,state_in_org,name,photo,preferred_name,first_name,last_name,client_type,client_type_name,health_profile,organisation_unit_ids,address_caption,high_risks,biography,critical_infos,birth_date';
    return apiClient.post<ClientQueryResponse>(urlPath, data);
  }

  public clientsForReports = reactive([] as ClientForReports[]);

  async loadClientsForReports(): Promise<void> {
    const currentOrganisationUnitId = useUserStore().currentOrganisationUnit.id;
    return getClientsForReports(currentOrganisationUnitId).then((response: ClientForReports[] | null) => {
      if (response) this.clientsForReports = response;
    });
  }

  extractData(data: ClientQueryResponse): Client[] {
    return data?.clients;
  }

  preferredOrFirstName = (id: number): ComputedRef<string> => computed(() => this.byId(id).preferredName || this.byId(id).firstName);

  addressCaption = (id: number): ComputedRef<string> => computed(() => this.byId(id).addressCaption);

  fullName = (id: number): ComputedRef<string> => computed(() => {
    const { firstName } = this.byId(id);
    const { lastName } = this.byId(id);
    if (firstName) {
      if (lastName) {
        return `${firstName} ${lastName}`;
      }
      return firstName;
    }
    return lastName;
  });

  firstName = (id: number): ComputedRef<string> => computed(() => this.byId(id).firstName);

  clientType = (id: number) => this.byId(id).clientType;

  clientTypeName = (id: number) => this.byId(id).clientTypeName;

  stateInOrg = (id: number) => this.byId(id).stateInOrg;

  healthProfile = (id: number) => this.byId(id).healthProfile;

  clients = (ids: number[]) => ids.map((id) => this.byId(id));

  badges = useSingletonComputed((id: number) => {
    const clientHealthProfile = clientStore.healthProfile(id);
    const dataPoints = dataPointsStore.clientDataPoints(id);
    return clientHealthProfile && dataPoints ? useClientBadgesHelper().getClientBadges(clientHealthProfile, dataPoints) : [];
  });

  currentOrgUnitState = useSingletonComputed((id: number) => (id ? (clientStore.byId(id)?.stateInOrg || '') : ''));

  skinInstances = useSingletonComputed((id: number) => instancesStore.skinInstancesByClientId(id) || []);

  nonArchivedSkinInstances = useSingletonComputed((id: number) => instancesStore.nonArchivedSkinInstancesByClientId(id));

  eventPWSInstances = useSingletonComputed((id: number) => instancesStore.eventPWSInstancesByClientId(id) || []);

  reFetchInstances = (id: number) => instancesStore.reFetchById(id);

  updateNourishInstances = (updatedInteractions:IInteraction[]) => instancesStore.updateNourishInstances(updatedInteractions);

  clientTerm = (isLowercase = true) => {
    const clientTerm = window?.currentOrganisationUnit?.client_term_plural || 'Residents';
    return isLowercase ? clientTerm.toLowerCase() : clientTerm;
  };

  orgUnits = (id: number) => this.byId(id).organisationUnitIds;

  highRisks = (id: number) => this.byId(id).highRisks;

  biography = (id: number) => this.byId(id).biography;

  criticalInfos = (id: number) => this.byId(id).criticalInfos;

  birthDate = (id: number) => this.byId(id).birthDate;

  protected setupEvents() {
    window.addEventListener('ClientSaved', (e) => {
      const event = e as unknown as CustomEvent;
      const changedId = event.detail.id;
      this.reFetchById(changedId);
    });
  }
}

const clientStore: ClientStore = new ClientStore();

export default clientStore;
