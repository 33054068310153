import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-image-gallery-row v-image-gallery-header-2" }
const _hoisted_2 = ["data-id"]
const _hoisted_3 = ["data-id"]
const _hoisted_4 = ["data-id"]
const _hoisted_5 = ["data-id"]
const _hoisted_6 = ["data-id"]

import { PropType } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { safeTranslate } from '@/_shared/translations/i18n';
import { BaseIcon } from '@/_shared/components';
import PhotoComponent from '@/_shared/components/general/PhotoComponent.vue';
import { vBaseTooltip } from '@/_shared/directives/';
import { GalleryViewInteraction } from '@/_shared/types/galleryViewInfo';
import { format } from 'date-fns';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageGalleryInteraction',
  props: {
  galleryViewInteraction: {
    type: Object as PropType<GalleryViewInteraction>,
    required: true,
  },
  clientId: {
    type: [Number, String],
    required: true,
  },
  instanceId: {
    type: [Number, String],
    required: true,
  },
  blurImages: {
    type: Boolean,
    required: true,
  },
  interactionType: {
    type: String as PropType<'newest' | 'oldest'>,
    required: true,
  },
},
  emits: ['openInteraction', 'openImageGalleryModal'],
  setup(__props, { emit: __emit }) {

const router = useRouter();
const route = useRoute();

const emit = __emit;

const props = __props;

const onOpenInteraction = (interactionId: string) => {
  router.push({
    path: `/clients/${props.clientId}/instances/${props.instanceId}/interactions/${interactionId}`,
    query: { ...route.query },
    force: true,
  });
};

const onOpenImageGalleryModal = (position: number, type: string) => {
  emit('openImageGalleryModal', position, type);
};

const formatDateTime = (date: string) => format(Date.parse(date), 'dd/MM/yyyy, HH:mm');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", {
        "data-id": `image-gallery-${__props.interactionType}-header`
      }, _toDisplayString(_unref(safeTranslate)(`image_gallery.${__props.interactionType}_interaction_heading`)), 9, _hoisted_2),
      _withDirectives(_createVNode(_unref(BaseIcon), {
        name: 'v-icon-help',
        class: "v-image-gallery-text v-image-gallery-tooltip",
        compact: true,
        "no-hover-background": "",
        noHover: ""
      }, null, 512), [
        [_unref(vBaseTooltip), {tooltipText: _unref(safeTranslate)(`image_gallery.${__props.interactionType}_interaction_tooltip`), position: 'right'}]
      ])
    ]),
    _createElementVNode("p", {
      class: "v-image-gallery-text v-image-gallery-link",
      "data-id": `image-gallery-${__props.interactionType}-name`,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (onOpenInteraction(__props.galleryViewInteraction.id)))
    }, [
      _createTextVNode(_toDisplayString(__props.galleryViewInteraction.name) + " ", 1),
      _withDirectives(_createVNode(_unref(BaseIcon), {
        name: 'v-icon-help',
        class: "v-image-gallery-text v-image-gallery-tooltip",
        compact: true,
        "no-hover-background": "",
        noHover: ""
      }, null, 512), [
        [_unref(vBaseTooltip), {tooltipText: _unref(safeTranslate)(`image_gallery.interaction_name_tooltip`), position: 'right'}]
      ])
    ], 8, _hoisted_3),
    _createElementVNode("p", {
      class: "v-image-gallery-text",
      "data-id": `image-gallery-${__props.interactionType}-date`
    }, _toDisplayString(formatDateTime(__props.galleryViewInteraction.closedAt)), 9, _hoisted_4),
    _createElementVNode("p", {
      class: "v-image-gallery-text",
      "data-id": `image-gallery-${__props.interactionType}-closer`
    }, _toDisplayString(_unref(safeTranslate)('timeline.interaction.closedBy')) + ": " + _toDisplayString(__props.galleryViewInteraction.closerName), 9, _hoisted_5),
    _createElementVNode("div", {
      "data-id": `image-gallery-${__props.interactionType}-pictures`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.galleryViewInteraction.coercedValue.pictures, (picture) => {
        return (_openBlock(), _createBlock(PhotoComponent, {
          key: picture.pictureFile.id,
          picture: picture,
          onOpenImageGalleryModal: ($event: any) => (onOpenImageGalleryModal(picture.position, __props.interactionType)),
          "all-blurred": __props.blurImages
        }, null, 8, ["picture", "onOpenImageGalleryModal", "all-blurred"]))
      }), 128))
    ], 8, _hoisted_6)
  ], 64))
}
}

})