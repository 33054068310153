import {
  memorizedOrganisationRoles, OrganisationRole, OrganisationRolesApiResponse,
} from '@/_shared/services/organisationRolesApi';
import { computed, onBeforeMount, ref } from 'vue';
import useDatasetStore from '@/_shared/store/datasets';
import { DATA_SET_EVENT_SEVERITY } from '@/_shared/store/composables/datasetsInstances';

export default function useFetchOrganisationRoles() {
  const organisationRolesApiResponse = ref<OrganisationRolesApiResponse>();
  const organisationRoles = ref<OrganisationRole[]>([]);

  onBeforeMount(async () => {
    organisationRolesApiResponse.value = await memorizedOrganisationRoles();
    organisationRoles.value = organisationRolesApiResponse.value?.organisationRoles || [];
  });

  return organisationRoles;
}

export function fetchEventsOptions() {
  const { nourishInstanceTypeDataSets, getDatasets } = useDatasetStore();
  const eventPWSDataSets = nourishInstanceTypeDataSets('event_pws');
  const severitiesDataSet = DATA_SET_EVENT_SEVERITY;

  const getEventPWSDatasets = computed(() => getDatasets(eventPWSDataSets));
  const getSeveritiesDataset = computed(() => getDatasets([severitiesDataSet]));

  return { getEventPWSDatasets, getSeveritiesDataset };
}
