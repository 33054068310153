import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container ClientTypes" }
const _hoisted_2 = { class: "v-single-panel-header v-single-panel-header__text" }
const _hoisted_3 = {
  key: 0,
  class: "v-base-alert--dropdown"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "v-client-types--client-types-list-container" }

import useUserStore from '@/_shared/store/user';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import {
  computed, onBeforeMount, onUnmounted, ref, watch,
} from 'vue';
import use from '@/_shared/compositionApi';
import AdvancedEditClientType from '@/client_types/components/AdvancedEditClientType.vue';
import DefaultEditClientType from '@/client_types/components/DefaultEditClientType.vue';
import ClientType from '@/client_types/types/client_type';
import { Library } from '@/_shared/types/library';
import { CompositeOption, Option } from '@/_shared/types/baseSelect';
import {
  idToString, nameTaken, parseReactiveTextToStaticText,
  sortClientTypes, sortOrganisationUnitsOptions,
} from '@/_shared/helpers/useClientTypesHelpers';
import { Client } from '@/_shared/types/client';
import {
  BaseCircleButton, BaseActionsListEntry, BaseAlert, AssignModal, BaseModal, BaseSelect,
} from '@/_shared/components/';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { vBaseTooltip } from '@/_shared/directives/';
import { isMobileView, resizeHandler } from '@/_shared/helpers/mobileViewHelper';
import {
  assignToClientType,
  createClientType,
  deleteClientType,
  fetchClientType,
  fetchClientTypes,
  librariesForClientTypes,
  organisationUnitsForClientTypes,
  updateClientType,
} from './services/clientTypesApi';

const iconStyle = 'round-icon round-icon-sm temp-icon-size-reduce nr-icon-person';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientTypesMain',
  setup(__props) {

const clientTypes = ref<ClientType[]>([]);
const organisationUnitsAsOptions = ref<CompositeOption[]>([]);
const usesLibraryClientTypes = ref(false);
const showDefaultAlert = ref(false);
const showEditModal = ref(false);
const performingAction = ref('');
const defaultAlertTitle = ref('');
const defaultAlertBody = ref('');
const showAssignModal = ref(false);
const currentSelectedClientType = ref({} as ClientType);
const baseAlertSelectOptions = ref<Option[]>([]);
const selectedValue = ref(0);
const hasSelectOptions = ref(baseAlertSelectOptions?.value.length);
const valid = ref(!hasSelectOptions.value);
let clientTypeOriginalName = '';
let clientTypeOriginalLibraryCodename = '';
const selectedLibraryCodename = ref('');
const warningMessages = ref({} as Record<string, string>);
const clientTypeValid = ref(false);
const clientTypeClients = ref<Client[]>([]);
const {
  toast, ToastType, translate,
} = use.helpers();
const selectedIcons = ['edit', 'delete', 'assign'];
const addingClientType = ref(false);
const libraries = ref<Library[]>([]);
const closeEditForId = ref<number>();
const organisationUnits = ref<OrganisationUnit[]>([]);
let clientsToReassignMap: Map<number, number[]> = new Map<number, number[]>();
clientTypes.value = sortClientTypes(clientTypes.value);
const { currentOrganisationUnit } = useUserStore();

// TODO this was set to a string before so was always true, set to true for now. Needs fixing
// const confirmValid = ref(hasSelectOptions.value && selectedValue.value > 0);
const confirmValid = ref(true);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('client_types'); // TODO WHEN PURE VUE remove
  clientTypes.value = await fetchClientTypes(currentOrganisationUnit.organisationId);
  sortClientTypes(clientTypes.value);
});
watch(currentSelectedClientType, () => validateName(currentSelectedClientType.value.name));

watch(selectedValue, () => {
  if (hasSelectOptions.value) {
    valid.value = !!((selectedValue.value && selectedValue.value !== 0));
  }
});

const selectedLibraryCodenamePresent = () => selectedLibraryCodename.value?.length;
const clientTypeLibraryCodenameChanged = (clientType: ClientType) => selectedLibraryCodename.value !== clientType.libraryCodename;

const maybeUpdateClientTypeLibraryCodename = (clientType: ClientType) => {
  if (
    selectedLibraryCodenamePresent()
    && clientTypeLibraryCodenameChanged(clientType)
  ) {
    clientType.libraryCodename = selectedLibraryCodename.value;
  }
};

const saveClientType = async (clientType: ClientType) => {
  try {
    maybeUpdateClientTypeLibraryCodename(clientType);
    if (isNewClientType(clientType)) {
      addingClientType.value = false;
      const response = await createClientType(clientType, currentOrganisationUnit.organisationId);
      if (response) { clientType = setClientTypeValues(clientType, response); }
    } else {
      await updateClientType(clientType, currentOrganisationUnit.organisationId);
    }
  } catch (error) {
    toast(translate('ajax.errors.not_saved'), ToastType.Danger); // Get proper translation for i.e. could not update the status
  } finally {
    sortClientTypes(clientTypes.value);
    closeEditForId.value = clientType.id!;
    showEditModal.value = false;
    toast(translate('ajax.notices.save_success'), ToastType.Success);
  }
};

const setClientTypeValues = (clientType: ClientType, response: ClientType) => {
  clientType.id = response.id;
  clientType.codename = response.codename;
  clientType.activeClientsCount = response.activeClientsCount;
  return clientType;
};

const performToggleClientType = async (clientType: ClientType) => {
  try {
    clientType.visible = !clientType.visible;
    await updateClientType(clientType, currentOrganisationUnit.organisationId);
  } catch (error) {
    toast(translate('ajax.errors.not_saved'), ToastType.Danger); // Get proper translation for i.e. could not update the status
  } finally {
    toast(translate('ajax.notices.save_success'), ToastType.Success);
    sortClientTypes(clientTypes.value);
  }
};

const setAlert = (title: string, body: string, actionName: string) => {
  defaultAlertBody.value = body;
  defaultAlertTitle.value = title;
  performingAction.value = actionName;
  showDefaultAlert.value = true;
};

const confirmSavingClientType = () => {
  if (
    currentSelectedClientType.value.name !== clientTypeOriginalName
    && !isNewClientType(currentSelectedClientType.value)
  ) {
    setAlert(
      translate('client_types.alerts.name_save_title'),
      translate('client_types.alerts.name_save_content'),
      'save',
    );
  } else {
    saveClientType(currentSelectedClientType.value);
  }
};

const confirmToggleClientType = async (clientType: ClientType) => {
  currentSelectedClientType.value = clientType;
  if (clientType.visible) {
    setAlert(
      translate('client_types.alerts.toggle_title'),
      translate('client_types.alerts.toggle_content'),
      'toggle',
    );
  } else {
    await performToggleClientType(clientType);
  }
};

const deleteCT = async (clientType: ClientType) => {
  currentSelectedClientType.value = clientType;
  setAlert(
    translate('client_types.alerts.delete_title'),
    translate('client_types.alerts.delete_content'),
    'delete',
  );
};

const addNewClientType = () => {
  if (!addingClientType.value) {
    addingClientType.value = true;
    warningMessages.value.newClientType = '';
    const newClientType = { name: '', codename: '', visible: true } as ClientType;
    clientTypes.value.unshift(newClientType);
    currentSelectedClientType.value = newClientType;
    if (usesLibraryClientTypes.value) {
      openEditModal(newClientType);
    }
  }
};

const isNewClientType = (clientType: ClientType) => !clientType || !clientType.id;

const toggleDisabled = (clientType: ClientType) => clientType.activeClientsCount! > 0 || !!clientType.nourishOwned;

const assignDisabled = (clientType: ClientType) => clientType.activeClientsCount! <= 0;

const deleteDisabled = (clientType: ClientType) => clientType.activeClientsCount! > 0
  || clientType.nourishOwned
  || clientType.libraries;

const removeClientTypeLocally = (clientType: ClientType) => {
  clientTypes.value = clientTypes.value.filter(
    (ct) => ct.id !== clientType.id && ct.name !== clientType.name && ct.id,
  );
};

const removeNewClientTypes = () => {
  clientTypes.value = clientTypes.value.filter((ct) => ct.id);
  addingClientType.value = false;
};

const cancelEditClientType = (clientType: ClientType, originalName: string) => {
  if (isNewClientType(clientType)) {
    addingClientType.value = false;
    removeClientTypeLocally(clientType);
  } else {
    warningMessages.value[idToString(clientType)] = '';
    currentSelectedClientType.value.name = originalName;
  }
};

const performAssignClientsToClientType = async (newClientTypeId: number) => {
  try {
    const assigning: Promise<unknown>[] = [];
    clientsToReassignMap.forEach((clientIds, ouId) => {
      if (clientIds.length && ouId) {
        assigning.push(assignToClientType(newClientTypeId, clientIds, currentOrganisationUnit.organisationId, ouId));
      }
    });
    await Promise.all(assigning as Promise<unknown>[]);
  } catch (error) {
    toast(translate('message.not_sent'), ToastType.Danger);
  } finally {
    clientTypes.value = await fetchClientTypes(currentOrganisationUnit.organisationId);
    sortClientTypes(clientTypes.value);
    toast(translate('ajax.notices.save_success'), ToastType.Success);
  }
};

const confirmAssignClientsToClientType = async (
  clientsToReassign: Map<number, number[]>,
  clientsCount: number,
) => {
  const newClientType = currentSelectedClientType.value;
  if (baseAlertSelectOptions.value.length === 0) {
    await setBaseAlertSelectOptions();
  }
  if (newClientType) {
    clientsToReassignMap = clientsToReassign;
    const alertBody = translate('client_types.alerts.assign_content', clientsCount);
    setAlert(translate('client_types.alerts.assign_title'), alertBody, 'assign');
    currentSelectedClientType.value = newClientType;
    showDefaultAlert.value = true;
  }
};

const refreshSelectableEntries = async (clientType: ClientType) => {
  if (clientType.id) {
    await fetchClientTypeClients(clientType.id);
  } else {
    removeNewClientTypes();
  }
  await getOrganisationUnitsAsOptions();
};

const fetchClientTypeClients = async (clientTypeId: number) => {
  if (clientTypeId === 0) {
    clientTypeClients.value = [];
  } else {
    clientTypeClients.value = (await fetchClientType(clientTypeId, currentOrganisationUnit.organisationId)).clients;
  }
  return clientTypeClients.value;
};

const getOrganisationUnitsAsOptions = async () => {
  if (organisationUnits.value.length < 1) {
    organisationUnits.value = await organisationUnitsForClientTypes();
  }

  let clientsActiveOrganisationUnitIds: number[] = [];
  clientTypeClients.value.forEach((client: Client) => {
    clientsActiveOrganisationUnitIds = [
      ...clientsActiveOrganisationUnitIds,
      ...client.activeOrganisationUnitIds,
    ];
  });

  organisationUnitsAsOptions.value = sortOrganisationUnitsOptions(
    organisationUnits.value,
    clientsActiveOrganisationUnitIds,
  ) as CompositeOption[];
  organisationUnitsAsOptions.value.forEach((ouOption) => {
    const clientsCountPerUnit = clientTypeClients.value.filter((client: Client) => client.activeOrganisationUnitIds.includes(Number(ouOption.value))).length;
    ouOption.text = `${ouOption.text} (${clientsCountPerUnit})`;
  });
};

const searchClientsPlaceholder = () => `${translate('common.search')} ${
  currentOrganisationUnit.clientTermPlural
}`;

const cancelAndCloseAlert = () => {
  showDefaultAlert.value = false;
  baseAlertSelectOptions.value = [];
  if (!usesLibraryClientTypes.value && clientTypeOriginalName.length) {
    currentSelectedClientType.value.name = clientTypeOriginalName;
  }
};

const closeEditModal = () => {
  currentSelectedClientType.value.name = clientTypeOriginalName;
  if (isNewClientType(currentSelectedClientType.value)) {
    removeClientTypeLocally(currentSelectedClientType.value);
    addingClientType.value = false;
  }
  clientTypeValid.value = false;
};

const closeModal = () => {
  if (showEditModal.value) {
    showEditModal.value = false;
    closeEditModal();
  } else {
    showAssignModal.value = false;
    baseAlertSelectOptions.value = [];
  }
  performingAction.value = '';
};

const setBaseAlertSelectOptions = () => {
  const filteredClientTypeOptions: CompositeOption[] = [];
  if (clientTypes.value) {
    clientTypes.value
      .filter(
        (clientType: ClientType) => clientType.id !== currentSelectedClientType.value.id && clientType.visible,
      )
      .forEach((clientType: ClientType) => filteredClientTypeOptions.push({
        value: clientType.id,
        text: clientType.name,
      }));
  }
  baseAlertSelectOptions.value = filteredClientTypeOptions;
};

const performDeleteClientType = async (clientType: ClientType) => {
  try {
    await deleteClientType(clientType.id!, currentOrganisationUnit.organisationId);
  } catch (error) {
    console.log(error);

    toast(translate('ajax.errors.not_saved'), ToastType.Danger); // Get proper translation for i.e. could not update the status
  } finally {
    removeClientTypeLocally(clientType);
    toast(translate('ajax.notices.save_success'), ToastType.Success);
  }
};

const confirmAction = async (selectedClientTypeId: number) => {
  switch (performingAction.value) {
    case 'toggle':
      await performToggleClientType(currentSelectedClientType.value);
      break;
    case 'delete':
      await performDeleteClientType(currentSelectedClientType.value);
      break;
    case 'assign':
      await performAssignClientsToClientType(selectedClientTypeId);
      showAssignModal.value = false;
      break;
    case 'save':
      await saveClientType(currentSelectedClientType.value);
      break;
    default:
      return;
  }
  resetPerformingAction();
};

const resetPerformingAction = () => {
  performingAction.value = '';
  showDefaultAlert.value = false;
  showAssignModal.value = false;
  baseAlertSelectOptions.value = [];
};

const openAssignModal = async (clientType: ClientType) => {
  await refreshSelectableEntries(clientType);
  currentSelectedClientType.value = clientType;
  setBaseAlertSelectOptions();
  showAssignModal.value = true;
};

const openEditModal = (clientType: ClientType) => {
  currentSelectedClientType.value = clientType;
  selectedLibraryCodename.value = clientType.libraryCodename!;
  if (!showEditModal.value) {
    setOriginalValues(clientType);
    showEditModal.value = true;
  }
};

const setOriginalValues = (clientType: ClientType) => {
  clientTypeOriginalName = parseReactiveTextToStaticText(clientType.name!);
  clientTypeOriginalLibraryCodename = selectedLibraryCodename.value;
};

const validateClientType = (newName: string) => {
  clientTypeValid.value = false;
  if (!nameChanged() && !libraryChanged()) {
    clientTypeValid.value = false;
  } else if (nameChanged()) {
    clientTypeValid.value = validateName(newName);
  } else if (libraryChanged()) {
    clientTypeValid.value = libraryChanged() && validateName(newName);
  }
};

const validateName = (name: string) => {
  const clientTypeId = idToString(currentSelectedClientType.value);
  if (nameTaken(currentSelectedClientType.value, name, clientTypes.value)) {
    warningMessages.value[clientTypeId] = translate(
      'client_types.warnings.duplicate',
    );
    return false;
  }
  if (name.length < 1) {
    warningMessages.value[clientTypeId] = translate(
      'client_types.warnings.empty_name',
    );
    return false;
  }
  warningMessages.value[clientTypeId] = '';
  return true;
};

const nameChanged = () => clientTypeOriginalName !== currentSelectedClientType.value.name;

const libraryChanged = () => clientTypeOriginalLibraryCodename !== selectedLibraryCodename.value;

const selectLibrary = (libraryCodename: string) => {
  selectedLibraryCodename.value = libraryCodename;
  validateClientType(currentSelectedClientType.value.name);
};

const updateName = (newName: string) => {
  currentSelectedClientType.value.name = newName;
};

const setEditModeType = () => {
  usesLibraryClientTypes.value = useUserStore().getFeatureToggle('librariesClientTypes') || false;
};

const setLibraries = async () => {
  libraries.value = await librariesForClientTypes(currentOrganisationUnit.organisationId);
};

const getClientTypeLibraryName = (clientType: ClientType) => {
  if (libraries.value.length && clientType?.libraryCodename) {
    const clientTypeLibrary = libraries.value.find(
      (library: Library) => library.codename === clientType.libraryCodename,
    );
    return clientTypeLibrary?.name ? ` (${clientTypeLibrary.name})` : '';
  }
  return '';
};

const plusButtonLeftPosition = computed(() => (
  isMobileView.value ? 'auto' : 'calc(100vw - 330px)'
));

const buttonWrapperPosition = computed(() => (
  isMobileView.value ? 'relative' : 'absolute'
));

const clientTypeName = (clientType: ClientType) => (clientType.nourishOwned ? `${clientType.name} (${translate('client_types.visible_when_impersonating')})` : clientType.name);

const tooltipPosition = computed(() => (isMobileView.value ? 'top' : 'bottom'));

window.addEventListener('resize', resizeHandler);

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler);
});

setEditModeType();
setLibraries();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_unref(translate)("client_types.title")) + " ", 1),
        _withDirectives(_createVNode(_unref(BaseCircleButton), {
          top: "25px",
          left: plusButtonLeftPosition.value,
          position: buttonWrapperPosition.value,
          onClick: addNewClientType
        }, null, 8, ["left", "position"]), [
          [_unref(vBaseTooltip), {position: tooltipPosition.value, tooltipText: _unref(translate)('client_types.add_tooltip')}]
        ])
      ]),
      (showDefaultAlert.value)
        ? (_openBlock(), _createBlock(_unref(BaseAlert), {
            key: 0,
            onCancelAlert: cancelAndCloseAlert,
            onConfirmAlert: _cache[1] || (_cache[1] = ($event: any) => (confirmAction(selectedValue.value))),
            icon: "info",
            selectPlaceholder: _unref(translate)('client_types.header'),
            valid: confirmValid.value
          }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(defaultAlertTitle.value), 1)
            ]),
            body: _withCtx(() => [
              (baseAlertSelectOptions.value && baseAlertSelectOptions.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_unref(BaseSelect), {
                      options: baseAlertSelectOptions.value,
                      modelValue: selectedValue.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
                      searchable: true,
                      required: true,
                      canDeselect: false,
                      placeholder: _unref(translate)('common.select_one'),
                      class: "v-base-alert--select-box"
                    }, null, 8, ["options", "modelValue", "placeholder"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(defaultAlertBody.value), 1))
            ]),
            _: 1
          }, 8, ["selectPlaceholder", "valid"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(clientTypes.value, (clientType) => {
          return (_openBlock(), _createBlock(_unref(BaseActionsListEntry), {
            key: clientType.codename,
            advancedEditView: usesLibraryClientTypes.value,
            assignDisabled: assignDisabled(clientType),
            deleteDisabled: deleteDisabled(clientType),
            entry: clientType,
            primaryText: clientTypeName(clientType),
            primaryTextAddition: getClientTypeLibraryName(clientType),
            secondaryText: `${
          clientType.activeClientsCount ? clientType.activeClientsCount : 0
        } ${_unref(translate)('client_types.assigned')}`,
            showIcons: selectedIcons,
            toggleDisabled: toggleDisabled(clientType),
            closeEditForId: closeEditForId.value,
            onCancelEditView: cancelEditClientType,
            onDeleteEntry: deleteCT,
            onOpenAssignModal: openAssignModal,
            onOpenEdit: openEditModal,
            onToggleEntry: confirmToggleClientType
          }, {
            default: _withCtx((scope) => [
              _createVNode(DefaultEditClientType, {
                clientType: clientType,
                onConfirmSavingClientType: confirmSavingClientType,
                onValidateClientTypeName: validateName,
                onCancelEditing: scope.cancelEditing,
                warningMessage: warningMessages.value[_unref(idToString)(clientType)]
              }, null, 8, ["clientType", "onCancelEditing", "warningMessage"])
            ]),
            _: 2
          }, 1032, ["advancedEditView", "assignDisabled", "deleteDisabled", "entry", "primaryText", "primaryTextAddition", "secondaryText", "toggleDisabled", "closeEditForId"]))
        }), 128))
      ])
    ]),
    (showAssignModal.value)
      ? (_openBlock(), _createBlock(_unref(AssignModal), {
          key: 0,
          onCloseModal: closeModal,
          title: `${_unref(translate)('client_types.assign_modal_label')} ${currentSelectedClientType.value.name}`,
          buttonText: _unref(translate)('common.reassign'),
          selectAllText: _unref(translate)('common.select_all'),
          selectDropdownOptions: organisationUnitsAsOptions.value,
          selectableEntries: clientTypeClients.value,
          searchInputPlaceholder: searchClientsPlaceholder(),
          onOpenConfirmAssignEntries: confirmAssignClientsToClientType,
          primaryTextKey: "firstName",
          secondaryTextKey: "name",
          confirmText: _unref(translate)('alert.confirm'),
          icon: iconStyle
        }, null, 8, ["title", "buttonText", "selectAllText", "selectDropdownOptions", "selectableEntries", "searchInputPlaceholder", "confirmText"]))
      : _createCommentVNode("", true),
    (showEditModal.value && usesLibraryClientTypes.value)
      ? (_openBlock(), _createBlock(_unref(BaseModal), {
          key: 1,
          onCloseModal: closeModal,
          title: currentSelectedClientType.value.name,
          confirmText: _unref(translate)('common.save'),
          onConfirm: confirmSavingClientType,
          confirmDisabled: !(clientTypeValid.value && selectedLibraryCodenamePresent()),
          icon: iconStyle
        }, {
          content: _withCtx(() => [
            _createVNode(AdvancedEditClientType, {
              libraries: libraries.value,
              onValidateClientTypeChanges: validateClientType,
              selectedLibraryCodename: selectedLibraryCodename.value,
              clientType: currentSelectedClientType.value,
              warningMessage: warningMessages.value[_unref(idToString)(currentSelectedClientType.value)],
              onSelectLibrary: selectLibrary,
              onUpdateName: updateName
            }, null, 8, ["libraries", "selectedLibraryCodename", "clientType", "warningMessage"])
          ]),
          _: 1
        }, 8, ["title", "confirmText", "confirmDisabled"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})