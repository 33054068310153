import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/_shared/assets/icons/close.svg'


const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "first-row" }
const _hoisted_4 = {
  key: 0,
  class: "additional-row"
}
const _hoisted_5 = {
  key: 0,
  class: "additional-row"
}
const _hoisted_6 = {
  key: 0,
  class: "additional-row"
}
const _hoisted_7 = {
  key: 0,
  class: "additional-row"
}
const _hoisted_8 = {
  key: 0,
  class: "additional-row"
}
const _hoisted_9 = { class: "buttons" }

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationModal',
  props: {
  confirmText: { type: String },
  cancelText: { type: String },
  titleText: { type: String },
  firstRowText: { type: String },
  secondRowText: { type: String },
  thirdRowText: { type: String },
  fourthRowText: { type: String },
  fifthRowText: { type: String },
  sixthRowText: { type: String },
},
  emits: ['closeModal', 'confirm'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const emit = __emit;



const CloseSource = {
  CLOSE_ICON: 'close_icon',
  CLOSE_BUTTON: 'close_button',
} as const;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    header: false,
    footer: false,
    width: 500,
    class: "v-confirmation-modal",
    onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (emit('closeModal', CloseSource.CLOSE_ICON)))
  }, {
    content: _withCtx(() => [
      _createElementVNode("img", {
        class: "close",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('closeModal', CloseSource.CLOSE_ICON))),
        alt: "x"
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(__props.titleText || _unref(translate)("alert.confirm")), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(__props.firstRowText), 1),
        _renderSlot(_ctx.$slots, "second-row", {}, () => [
          (__props.secondRowText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.secondRowText), 1))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "third-row", {}, () => [
          (__props.thirdRowText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.thirdRowText), 1))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "fourth-row", {}, () => [
          (__props.fourthRowText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(__props.fourthRowText), 1))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "fifth-row", {}, () => [
          (__props.fifthRowText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(__props.fifthRowText), 1))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "sixth-row", {}, () => [
          (__props.sixthRowText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(__props.sixthRowText), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(BaseButton, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('closeModal', CloseSource.CLOSE_BUTTON))),
          class: "cancel",
          size: "medium",
          disabled: false,
          "button-type": "secondary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.cancelText || _unref(translate)("alert.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(BaseButton, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('confirm'))),
          "button-type": "primary",
          size: "medium",
          disabled: false,
          class: "confirm"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.confirmText || _unref(translate)("alert.confirm")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 3
  }))
}
}

})