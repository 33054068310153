
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import AssignModalContent from '@/_shared/components/modals/AssignModal/AssignModalContent.vue';
import AssignModalFilterableContent from '@/_shared/components/modals/AssignModal/AssignModalFilterableContent.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { Client } from '@/_shared/types/client';
import { Carer } from '@/_shared/store/carers';
import {
  CompositeOption,
} from '@/_shared/types/baseSelect';

export default defineComponent({
  components: {
    BaseButton, AssignModalContent, AssignModalFilterableContent, BaseModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
    selectAllText: {
      type: String,
    },
    selectDropdownOptions: {
      type: Object as PropType<CompositeOption[]>,
      default: () => [] as CompositeOption[],
    },
    searchInputPlaceholder: {
      type: String,
    },
    selectableEntries: {
      type: Object as PropType<(Client | Carer)[]>, // Can change this to add different types if we reuse this
      required: true,
    },
    selectedEntries: {
      type: Object as PropType<number[]>,
    },
    primaryTextKey: {
      type: String,
      required: true,
    },
    secondaryTextKey: {
      type: String,
      required: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    iconUrl: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  emits: ['openConfirmAssignEntries', 'closeModal'],
  setup(props) {
    const selectedToReassign: Map<number, number[]> = new Map<number, number[]>();
    let selectedEntriesCount = ref(0);
    if (props.selectedEntries) {
      selectedEntriesCount = ref(props.selectedEntries.length);
    }
    const assignButtonDisabled = computed(() => selectedEntriesCount.value < 1);

    props.selectDropdownOptions!.forEach((option: CompositeOption) => {
      selectedToReassign.set(option.value as number, []);
    });

    return {
      assignButtonDisabled,
      selectedToReassign,
      selectedEntriesCount,
    };
  },
});
