import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-picture-thumbnail"
}
const _hoisted_2 = { class: "v-blurred-picture-cover" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "v-blurred-picture-cover-content" }
const _hoisted_5 = { class: "sp-eye-crossed" }
const _hoisted_6 = { class: "v-picture-sensitive-content-text" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "sp-picture-placeholder" }
const _hoisted_10 = { class: "v-picture-thumbnail" }
const _hoisted_11 = { class: "v-picture-placeholder" }
const _hoisted_12 = { class: "sp-picture-placeholder-text" }

import { PropType, ref, toRef } from 'vue';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { syncRef } from '@vueuse/core';
import { ParameterPicture } from '@/timeline/types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'PhotoComponent',
  props: {
  picture: {
    type: Object as () => ParameterPicture,
    required: true,
  },
  allBlurred: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  emits: ['openImageGalleryModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const pictureBlurred = ref(props.allBlurred);
syncRef(toRef(props, 'allBlurred'), pictureBlurred, { direction: 'ltr' });

const { translate } = use.helpers();
const openPicture = () => {
  emit('openImageGalleryModal');
};

const togglePictureBlurred = () => {
  pictureBlurred.value = !pictureBlurred.value;
};

const resetToBlur = () => {
  pictureBlurred.value = props.allBlurred;
};

const pictureUrl = ref(props.picture?.pictureFile?.relativeUrl || props.picture?.pictureFile?.url);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-picture-thumbnail-wrapper", `sp-thumbnail-${__props.picture?.pictureFile?.uuid}`])
  }, [
    (pictureUrl.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (resetToBlur())),
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (resetToBlur()))
        }, [
          (pictureBlurred.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    alt: "Picture",
                    src: pictureUrl.value,
                    class: "v-picture-thumbnail-img"
                  }, null, 8, _hoisted_3)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(BaseIcon, {
                      name: "nsx-font-icon--eye_crossed",
                      width: "112px",
                      height: "112px",
                      "no-hover-background": "",
                      "no-hover": "",
                      foregroundHoverColor: "black"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(translate)('timeline.parameters.picture.sensitiveContent')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (togglePictureBlurred())),
                    class: "v-picture-thumbnail-view sp-un-blur-picture"
                  }, _toDisplayString(_unref(translate)('body_map.show_thumbnail')), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "v-picture-thumbnail sp-picture-thumbnail",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (openPicture()))
              }, [
                _createElementVNode("img", {
                  alt: "Picture",
                  src: pictureUrl.value,
                  class: "v-picture-thumbnail-img"
                }, null, 8, _hoisted_7)
              ]))
        ], 32))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(BaseIcon, {
                  class: "v-picture-placeholder-icon",
                  name: "nsx-font-icon--img_placeholder",
                  width: "48px",
                  height: "48px",
                  "no-hover-background": "",
                  "no-hover": "",
                  foregroundHoverColor: "black"
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(translate)('timeline.parameters.picture.imageLoading')), 1)
        ]))
  ], 2))
}
}

})