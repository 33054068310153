import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-emergency-info-container" }
const _hoisted_2 = {
  class: "v-dashboard-title",
  id: "emergency-info-page-title"
}
const _hoisted_3 = { class: "v-emergency-information-sections" }
const _hoisted_4 = { class: "col-lg-4 col-md-12 v-emergency-information-left" }
const _hoisted_5 = { class: "v-emergency-information-section" }
const _hoisted_6 = { class: "v-resident-dashboard-section-text" }
const _hoisted_7 = { class: "sp-emergency-people-heading" }
const _hoisted_8 = {
  key: 0,
  class: "v-resident-dashboard-updated-ago"
}
const _hoisted_9 = { class: "v-resident-dashboard-updated-ago--main-text sp-updated-at-main-text-paramedic-assistance" }
const _hoisted_10 = { class: "v-resident-dashboard-updated-ago--time sp-updated-at-time-paramedic-assistance" }
const _hoisted_11 = {
  key: 0,
  class: "v-emergency-information-section"
}
const _hoisted_12 = { class: "v-resident-dashboard-section-text" }
const _hoisted_13 = { class: "v-emergency-info-secondary-text sp-ambulance-heading" }
const _hoisted_14 = { class: "v-emergency-info-primary-text sp-ambulance-phone" }
const _hoisted_15 = {
  key: 0,
  class: "emergency-info-paramedic-details sp-ambulance-details"
}
const _hoisted_16 = { class: "v-resident-dashboard-section-icon" }
const _hoisted_17 = { class: "v-resident-dashboard-section-text" }
const _hoisted_18 = { class: "v-emergency-info-secondary-text" }
const _hoisted_19 = { class: "v-resident-dashboard-section-value sp-relation-name" }
const _hoisted_20 = {
  key: 0,
  class: "v-emergency-info-secondary-text sp-relation-observation"
}
const _hoisted_21 = {
  key: 1,
  class: "v-emergency-info-secondary-text sp-relation-archived"
}
const _hoisted_22 = {
  key: 1,
  class: "v-emergency-information-section"
}
const _hoisted_23 = { class: "v-resident-dashboard-section-text sp-no-relations" }
const _hoisted_24 = { class: "v-emergency-info-secondary-text" }
const _hoisted_25 = {
  key: 0,
  class: "col-lg-8 col-md-12 v-emergency-information-right"
}
const _hoisted_26 = {
  key: 0,
  class: "v-resident-dashboard-section-text"
}
const _hoisted_27 = { class: "v-emergency-info-secondary-text" }
const _hoisted_28 = { class: "v-emergency-information-sections-value" }
const _hoisted_29 = {
  key: 0,
  class: "v-resident-dashboard-updated-ago"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "v-emergency-information-section" }
const _hoisted_32 = { class: "v-resident-dashboard-section-text" }
const _hoisted_33 = { class: "v-emergency-info-secondary-text" }

import use from '@/_shared/compositionApi';
import { Datapoint, useClientDataPointsStore } from '@/_shared/store/dataPoints';
import { usePersonPreferenceStore } from '@/_shared/store/personPreference';
import { BaseAvatar } from '@/_shared/components';
import useUserStore from '@/_shared/store/user';
import { clientsRelationsStore, Relation } from '@/_shared/store/Relation';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import clientStore from '@/_shared/store/clients';
import { getUpdatedAgoText, lastSeenBeforeUpdated } from '../client.utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmergencyInformationDashboard',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const clientDataPointsStore = useClientDataPointsStore();
const { setClient } = clientDataPointsStore;
const {
  emergencyDatapoints, formattedEmergencyInformationDataPoints, formattedAmbulanceDataPointValue, contactPersonDataPointValue,
  hasEmergencyInfoDataPoints,
} = storeToRefs(clientDataPointsStore);

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

const personPreferenceStore = usePersonPreferenceStore();
const { setPersonId } = personPreferenceStore;
const { clientsLastSeen } = storeToRefs(personPreferenceStore);

const props = __props;

onMounted(() => {
  setClient(props.clientId);
  setPersonId(currentUser.value.id);
});

const relations = computed(() => (clientsRelationsStore.byId(props.clientId)).relations);

const filteredRelations = computed(() => {
  if (contactPersonDataPointValue.value) {
    const result = relations.value?.filter((relation) => contactPersonDataPointValue.value!
      .includes(relation.person.id.toString()));
    return result;
  }
  return [];
});

const sortedRelations = computed(() => {
  if (!filteredRelations.value) {
    return [];
  }
  let result: Relation[] = [];
  const supportRoleTypesOrder = ['health_worker', 'family', 'social_worker', 'community', 'friends'];
  supportRoleTypesOrder.forEach((supportRoleType) => {
    const selectedRelations = relations.value?.filter((relation) => relation.supportRole === supportRoleType) || [];
    result = [
      ...result,
      ...selectedRelations.sort((a, b) => a.person.firstName!.localeCompare(b.person.firstName!))];
  });
  return result;
});

const showUpdatedAgo = (dataPoint: Datapoint) => lastSeenBeforeUpdated(dataPoint, props.clientId, clientsLastSeen.value)
  && getUpdatedAgoText(dataPoint);

const getRelationRole = (relation: Relation) => (relation.relationship ? relation.relationship : translate(`emergency_information.support_role.${relation.supportRole}`));

const emergencyContactUpdatedAgoText = computed(() => {
  if (formattedAmbulanceDataPointValue.value && emergencyDatapoints.value) {
    const ambulanceChangedAt = formattedAmbulanceDataPointValue.value.changedAt;
    const contactPeopleChangedAt = emergencyDatapoints.value.contactPerson?.changedAt;
    if ((!ambulanceChangedAt && contactPeopleChangedAt) || (ambulanceChangedAt && contactPeopleChangedAt && ambulanceChangedAt < contactPeopleChangedAt)) {
      return getUpdatedAgoText(emergencyDatapoints.value.contactPerson);
    }
    return getUpdatedAgoText(formattedAmbulanceDataPointValue.value);
  }
  return '';
});

const showEmergencyContactUpdatedAgo = computed(() => (formattedAmbulanceDataPointValue.value && emergencyDatapoints.value) && (showUpdatedAgo(formattedAmbulanceDataPointValue.value)
  || showUpdatedAgo(emergencyDatapoints.value.contactPerson)));

const firstName = computed(() => clientStore.firstName(props.clientId).value);

const toSnakeCase = (input: string) => input.replace(/A-Z/g, (letter) => `_${letter.toLowerCase()}`);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(translate)('emergency_information.page_title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(translate)('emergency_information.left_column_heading')), 1),
            (showEmergencyContactUpdatedAgo.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("svg", {
                    width: "1.2em",
                    height: "1.2em",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                    })
                  ], -1)),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(translate)('common.updated')), 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(emergencyContactUpdatedAgoText.value), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_unref(formattedAmbulanceDataPointValue))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-resident-dashboard-section-icon" }, [
                _createElementVNode("div", { class: "v-emergency-info-default-contact-icon" }, [
                  _createElementVNode("i", { class: "nsx-font-icon--icon-emergencypack nsx-ambulance-icon-flipped" })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(translate)(_unref(formattedAmbulanceDataPointValue).heading)), 1),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(translate)(_unref(formattedAmbulanceDataPointValue).phone)), 1),
                (_unref(formattedAmbulanceDataPointValue).details)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_unref(formattedAmbulanceDataPointValue).details), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedRelations.value, (relation) => {
          return (_openBlock(), _createElementBlock("div", {
            key: relation.id,
            class: _normalizeClass(`v-emergency-information-section${relation.archived ? '--archived' : ''}`)
          }, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_unref(BaseAvatar), {
                size: "medium",
                photo: relation.person.photo
              }, null, 8, ["photo"])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("p", _hoisted_18, _toDisplayString(getRelationRole(relation)), 1),
              _createElementVNode("p", _hoisted_19, _toDisplayString(relation.person.name), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relation.person.mobilePhones, (phoneNumber) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: phoneNumber,
                  class: "v-emergency-info-primary-text sp-relation-phone"
                }, _toDisplayString(phoneNumber), 1))
              }), 128)),
              (!relation.archived && relation.observations)
                ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(relation.observations), 1))
                : _createCommentVNode("", true),
              (relation.archived)
                ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_unref(translate)('emergency_information.archived_relation')), 1))
                : _createCommentVNode("", true)
            ])
          ], 2))
        }), 128)),
        (sortedRelations.value.length === 0 && !_unref(hasEmergencyInfoDataPoints))
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("i", _hoisted_24, _toDisplayString(_unref(translate)('emergency_information.assessment_not_completed')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_unref(formattedEmergencyInformationDataPoints))
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_unref(formattedEmergencyInformationDataPoints)), ([key, value]) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: "v-emergency-information-section"
              }, [
                value
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(translate)(value.heading, { client_name: firstName.value })), 1),
                      _createElementVNode("p", _hoisted_28, _toDisplayString(value.content), 1),
                      (showUpdatedAgo(value))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _cache[2] || (_cache[2] = _createElementVNode("svg", {
                              width: "1.2em",
                              height: "1.2em",
                              fill: "currentColor",
                              xmlns: "http://www.w3.org/2000/svg"
                            }, [
                              _createElementVNode("path", {
                                "fill-rule": "evenodd",
                                d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                              })
                            ], -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(`v-resident-dashboard-updated-ago--main-text sp-updated-at-main-text-${toSnakeCase(key)}`)
                            }, _toDisplayString(_unref(translate)('common.updated')), 3),
                            _createElementVNode("span", {
                              class: _normalizeClass(`v-resident-dashboard-updated-ago--time sp-updated-at-time-${toSnakeCase(key)}`)
                            }, _toDisplayString(_unref(getUpdatedAgoText)(value)), 3)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_unref(formattedEmergencyInformationDataPoints))
        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("p", _hoisted_33, _toDisplayString(_unref(translate)('resident_dashboard.assessment_not_completed')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})