import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-instances-filter-container" }
const _hoisted_2 = {
  key: 0,
  class: "v-instance-group"
}

import {
  computed, watch, onBeforeUnmount, onMounted,
  onUnmounted,
} from 'vue';
import use from '@/_shared/compositionApi';
import clientStore from '@/_shared/store/clients';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import EventListEntry from '@/events/components/EventListEntry.vue';
import type { EventPwsInstance } from '@/_shared/types/NourishInstance';
import EventsFilter from '@/events/components/EventsFilter.vue';
import { storeToRefs } from 'pinia';
import useTimelineFiltersStore from '@/_shared/store/timelineFilters';
import instancesHelper from '@/_shared/helpers/useInstancesHelper';
import { instanceStateTranslations } from '@/_shared/services/instanceFilterHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTimeline',
  props: {
  clientId: {
    type: String,
    required: true,
  },
},
  emits: ['selectInstance'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();
const { groupInstancesByState, filterGroupedInstances } = instancesHelper();

const timelineFiltersStore = useTimelineFiltersStore();
const { timelineFilters, isTimelineFilterOpen, timelineSearchTerm } = storeToRefs(timelineFiltersStore);
const { setTimelineFilters, setTimelineFilterText, filterTimelineInstancesByStateAndSubtype } = timelineFiltersStore;

const serviceFilteringStore = useServiceFilteringStore();
const { timelineInstanceTypeMounted } = storeToRefs(serviceFilteringStore);

const eventInstances = clientStore.eventPWSInstances(+props.clientId);
const groupedInstances = computed(() => groupInstancesByState<EventPwsInstance>(eventInstances.value, 'events.sections'));

// Set default filter values.
setTimelineFilters({
  severity: { name: translate('common.all'), codename: 'all' },
});

const filterEventsBySeverity = (instance: EventPwsInstance) => {
  const { codename } = timelineFilters.value.severity;
  return codename === 'all'
    || codename === instance.metadata.severity;
};

const showInstanceForSearchTerm = (instance: EventPwsInstance) => {
  let instanceSearchable = instance.reference;
  instanceSearchable += ` ${translate(`datasets.${instance.subType}`)}`;
  instanceSearchable += ` ${translate(`datasets.${instance.metadata.severity}`)}`;
  instanceSearchable += ` ${translate(`datasets.${instance.metadata.specificType}`)}`;
  return instanceSearchable.toLowerCase().includes(timelineSearchTerm.value.toLowerCase());
};

const currentInstances = computed(() => {
  const filterFunction = (skinInstance: EventPwsInstance) => showInstanceForSearchTerm(skinInstance);
  return filterGroupedInstances(groupedInstances.value, filterFunction);
});

const currentInstancesIds = computed(() => currentInstances.value.map(({ instances }) => instances.map(({ id }) => id)).flat());

const filteredInstances = computed(() => {
  const filterFunction = (instance: EventPwsInstance) => filterTimelineInstancesByStateAndSubtype(instance) && filterEventsBySeverity(instance);
  return filterGroupedInstances(currentInstances.value, filterFunction);
});

const sectionClass = (state: string) => `v-${state}`;

watch(timelineFilters, () => {
  setTimelineFilterText([
    timelineFilters.value.state.name,
    timelineFilters.value.subType.name,
    timelineFilters.value.severity.name,
  ]);
});

onMounted(() => {
  setTimelineFilterText([
    `${instanceStateTranslations('new', 'events.sections')} & ${instanceStateTranslations('active', 'events.sections')}`,
    translate('common.all'),
  ]);

  timelineInstanceTypeMounted.value = 'event_pws';
});

onBeforeUnmount(() => {
  useTimelineFiltersStore().$reset();
});

onUnmounted(() => {
  timelineInstanceTypeMounted.value = null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(EventsFilter, {
        instancesIds: currentInstancesIds.value,
        clientId: +props.clientId
      }, null, 8, ["instancesIds", "clientId"])
    ], 512), [
      [_vShow, _unref(isTimelineFilterOpen)]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredInstances.value, (eventGroup) => {
      return (_openBlock(), _createElementBlock("div", {
        key: eventGroup.state
      }, [
        (eventGroup.instances && eventGroup.instances.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h4", {
                class: _normalizeClass(["v-event-section", sectionClass(eventGroup.state)])
              }, _toDisplayString(eventGroup.label), 3),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventGroup.instances, (instance) => {
                return (_openBlock(), _createBlock(EventListEntry, {
                  key: instance!.id,
                  reference: true,
                  instance: instance,
                  onSelectInstance: ($event: any) => (emit('selectInstance', instance))
                }, null, 8, ["instance", "onSelectInstance"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}
}

})