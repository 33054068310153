import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "toggle" }
const _hoisted_2 = {
  key: 0,
  class: "v-person-form"
}
const _hoisted_3 = { class: "v-h4" }
const _hoisted_4 = { class: "v-form-required-info" }
const _hoisted_5 = { class: "v-person-form" }
const _hoisted_6 = { class: "v-h4" }
const _hoisted_7 = { class: "v-form-required-info" }
const _hoisted_8 = { class: "v-form-actions" }

import {
  onBeforeMount, reactive, ref, PropType,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Client } from '@/_shared/types/client';
import { fetchClientTypes } from '@/client_types/services/clientTypesApi';
import { postNewClient } from '@/clients/services/clientsApi';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import BaseToggle from '@/_shared/components/inputs/BaseToggle.vue';
import ClientType from '@/client_types/types/client_type';
import use from '@/_shared/compositionApi';
import useUserStore from '@/_shared/store/user';

import { FormClient } from './formClientType';
import BaseClientForm from './BaseClientForm.vue';
import ClientFormWrapper from './ClientFormWrapper.vue';
import ClientActionSuccessPopup from './ClientActionSuccessPopup.vue';

const inputWidth = '310px';

export default /*@__PURE__*/_defineComponent({
  __name: 'ClientForm',
  props: {
  type: {
    type: String as PropType<keyof typeof forms>,
    default: 'enquiry',
    validator(value: string) {
      // vue doesn't allow using variables here - re-generate this with:
      // Object.keys(forms).map((s) => `'${s}'`).join(', ')
      return ['enquiry', 'pre_assess', 'admit', 'emergency_admission', 'pet', 'training',
        'service_management', 'careplan_template'].includes(value);
    },
  },
},
  setup(__props) {

const { translate } = use.helpers();
const router = useRouter();
const route = useRoute();

const forms = {
  enquiry: {
    fields: ['title*', 'firstName*', 'lastName*', 'birthDate', 'sex*', 'observations'],
    state: 'enquiry',
    clientType: 'residential',
    formName: 'enquiry_form',
  },
  pre_assess: {
    fields: ['title*', 'firstName*', 'lastName*', 'preferredName', 'birthDate*', 'sex*', 'clientType*',
      'clientTypeChangedLater'],
    state: 'pre_assessment',
    clientType: null,
    formName: 'pre-assess_form',
  },
  admit: {
    fields: ['title*', 'firstName*', 'lastName*', 'preferredName', 'birthDate*', 'sex*', 'clientType*', 'template*',
      'startOn*'],
    state: 'onboarding',
    clientType: null,
    formName: 'admit_form',
  },
  emergency_admission: {
    fields: ['title', 'firstName*', 'lastName*', 'clientType*', 'clientTypeChangedLater'],
    state: 'emergency_admission',
    clientType: null,
    formName: 'emergency_admission_form',
  },
  pet: {
    fields: ['name*', 'birthDate*', 'sex*'],
    state: 'admitted',
    clientType: 'animal',
    formName: 'pet_animal_form',
  },
  training: {
    fields: ['title*', 'firstName*', 'lastName*', 'preferredName', 'birthDate*', 'sex*'],
    state: 'admitted',
    clientType: 'training',
    formName: 'training_form',
  },
  careplan_template: {
    fields: ['templateName*'],
    state: 'template',
    clientType: 'care_template',
    formName: 'careplan_template_form',
  },
  service_management: {
    fields: ['serviceName*'],
    state: 'admitted',
    clientType: 'service_management',
    formName: 'service_management_form',
  },
};

const props = __props;

const clientTypes = ref([] as ClientType[]);
onBeforeMount(async () => {
  clientTypes.value = await fetchClientTypes(useUserStore().currentOrgId, false);
});

function translateRequired() {
  switch (props.type) {
    case 'emergency_admission':
    case 'pet':
    case 'training':
    case 'careplan_template':
      return translate('client_form.required');
    case 'service_management':
      return translate('client_form.provide_service_name');
    default:
      return translate('client_form.response_required');
  }
}

function translateClientDetails() {
  switch (props.type) {
    case 'enquiry':
    case 'pre_assess':
    case 'admit':
      return translate('client_form.client_details');
    default:
      return '';
  }
}

const closed = ref(false);
const loading = ref(false);
async function cancel() {
  closed.value = true;
  await router.push({ name: 'clientList', query: route.query });
}

function tomorrow() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 1);
  return date;
}

function hasAccess() {
  const clientAccess = useUserStore().currentUser?.carer?.organisationRole?.clientAccess;
  // "emergency_admission", "enquiry", "enrolled", "left", "onboarding", "admitted", "suspended"
  return clientAccess !== undefined && client.state !== undefined && (
    clientAccess.access === 'full' || clientAccess.states.includes(client.state)
  );
}

const showFullPopup = ref(false);

async function save() {
  if (!client) return;
  if (loading.value) return;
  loading.value = true;
  closed.value = true;
  if (!client.clientType && forms[props.type].clientType) client.clientType = forms[props.type].clientType as string;
  client.state = forms[props.type].state;
  if (client.startOn === 'not_known' || (client.startOn && (new Date(client.startOn) > tomorrow()))) {
    client.state = 'enrolled';
  }
  client.organisationUnitIds = [useUserStore().currentOrgUnitId];
  client.fromForm = forms[props.type].formName;
  // clone client for test - so jest mock isn't updated
  const response = await postNewClient(useUserStore().currentOrgUnitId, { ...(client as Client) });
  if (!response?.client?.id) return;
  client.id = response.client.id;
  if (props.type === 'emergency_admission' && hasAccess()) {
    await router.push({ name: 'client.timeline', params: { clientId: client.id }, query: route.query });
  } else {
    showFullPopup.value = ['pre_assess', 'admit'].includes(props.type) && hasAccess();
    showPopup.value = true;
  }
  loading.value = false;
}

const enquiringToggle = ref(true);
const saveEnabled = ref(false);
const client = reactive({} as FormClient);
const enquirer = reactive({} as FormClient);

function requiredComplete(clientToUpdate: FormClient, completeClient: FormClient) {
  Object.assign(clientToUpdate, completeClient);
  if (props.type === 'enquiry' && enquiringToggle.value) {
    saveEnabled.value = Object.keys(client).length > 0 && Object.keys(enquirer).length > 0;
  } else {
    saveEnabled.value = true;
  }
}

function requiredMissing(clientToUpdate: FormClient) {
  saveEnabled.value = false;
  Object.keys(clientToUpdate).forEach((key) => delete clientToUpdate[key]);
}

async function toClientList() {
  if (hasAccess() && client.state) {
    if (['enquiry', 'pre_assessment', 'onboarding', 'enrolled', 'emergency_admission', 'template']
      .includes(client.state)) {
      route.query.state = client.state;
      delete route.query.type;
    } else {
      route.query.state = 'admitted';
      const type = clientTypes.value.find((type_) => type_.codename === client.clientType);
      if (type) route.query.type = type.id.toString();
    }
  }
  // TODO remove next line when clientList is in vue
  window.forceAngularToReloadState = true;
  await router.push({ name: 'clientList', query: route.query });
}

const showPopup = ref(false);
async function closePopup() {
  showPopup.value = false;
  await toClientList();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { withBGImg: false }, {
    default: _withCtx(() => [
      (!showPopup.value)
        ? (_openBlock(), _createBlock(ClientFormWrapper, {
            key: 0,
            type: __props.type
          }, {
            default: _withCtx(() => [
              (__props.type === 'enquiry')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", null, _toDisplayString(_unref(translate)(`client_form.enquiry_form.toggle`)), 1),
                    _createElementVNode("span", _hoisted_1, [
                      _createElementVNode("span", null, _toDisplayString(_unref(translate)('client_form.yes')), 1),
                      _createVNode(BaseToggle, {
                        checked: enquiringToggle.value,
                        onToggleChange: _cache[0] || (_cache[0] = ($event: any) => (enquiringToggle.value = !enquiringToggle.value))
                      }, null, 8, ["checked"]),
                      _createElementVNode("span", null, _toDisplayString(_unref(translate)('client_form.no')), 1)
                    ]),
                    (enquiringToggle.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("h4", _hoisted_3, _toDisplayString(_unref(translate)('client_form.enquiry_form.subtitle')), 1),
                          _createElementVNode("p", _hoisted_4, _toDisplayString(translateRequired()), 1),
                          _createVNode(BaseClientForm, {
                            fields: ['title', 'firstName*', 'lastName*', 'enquirerPhoneNumber*', 'relationship'],
                            "input-width": inputWidth,
                            closed: closed.value,
                            onRequiredComplete: _cache[1] || (_cache[1] = (completeClient) => requiredComplete(enquirer, completeClient)),
                            onRequiredMissing: _cache[2] || (_cache[2] = () => requiredMissing(enquirer))
                          }, null, 8, ["closed"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", _hoisted_6, _toDisplayString(translateClientDetails()), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(translateRequired()), 1),
                _createVNode(BaseClientForm, {
                  fields: forms[__props.type].fields,
                  "input-width": inputWidth,
                  closed: closed.value,
                  onRequiredComplete: _cache[3] || (_cache[3] = (completeClient) => requiredComplete(client, completeClient)),
                  onRequiredMissing: _cache[4] || (_cache[4] = () => requiredMissing(client))
                }, null, 8, ["fields", "closed"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(BaseButton, {
                  class: "v-form-action-button",
                  "button-type": "secondary",
                  onClicked: cancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)('common.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(BaseButton, {
                  class: "v-form-action-button v-save-button",
                  onClicked: save,
                  disabled: !saveEnabled.value
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)('common.save')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ])
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true),
      (showPopup.value)
        ? (_openBlock(), _createBlock(ClientActionSuccessPopup, {
            key: 1,
            client: client,
            youCanNow: showFullPopup.value,
            onCloseModal: closePopup
          }, null, 8, ["client", "youCanNow"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})