import { StyleValue } from 'vue';

interface IInputProps extends Object {
  border?: boolean;
  required?: boolean;
  labelWidth?: string;
  boldLabel?: boolean;
  inputWidth?: string;
  inputHeight?: string;
  transparent?: boolean;
  inverted?: boolean;
  isDisabled?: boolean;
  icon?: string;
  standardInputStyle?: boolean;
  textarea?: boolean;
  isSelect?: boolean;
  label?: string;
}

export const useInputMethods = (props: IInputProps) => {
  function inputClass() {
    let classes = '';
    classes += props.border ? 'v-border' : 'v-no-border';
    classes += props.icon ? ' v-input-icon' : '';

    if (props.standardInputStyle) {
      classes += ' v-standard-input';
    }

    return classes;
  }

  function inputStyle() {
    const style: StyleValue = {};
    if (props.inputWidth) { style.width = props.inputWidth; }
    if (props.inputHeight) { style.minHeight = props.inputHeight; }
    if (props.transparent) { style.background = 'transparent'; }
    return style;
  }

  function labelClass() {
    let classes = 'v-label';
    if (props.required) { classes = `${classes} v-required`; }

    if (props.standardInputStyle) {
      classes += ' v-standard-input-label';
    }

    return classes;
  }

  function labelStyle() {
    if (props.standardInputStyle) {
      return {};
    }
    const style: StyleValue = {};
    if (props.labelWidth) {
      style.minWidth = props.labelWidth;
      style.width = props.labelWidth;
    } else if (props.inputWidth) {
      style.minWidth = props.inputWidth;
      style.width = props.inputWidth;
    }
    if (props.boldLabel) {
      style.color = 'black';
      style.fontWeight = 'bold';
    }
    return style;
  }

  const wrapperClasses = () => {
    let classes = props.isSelect ? 'v-select-wrapper' : 'v-input-wrapper';
    if (props.textarea) { classes += ' text-area'; }
    if (props.label) { classes += props.isSelect ? ' v-outset-select' : ' outset-input'; }
    if (props.icon) { classes += ' icon-input'; }
    if (props.standardInputStyle) {
      classes += ' v-standard-input-wrapper';
    }

    return classes;
  };

  return {
    inputClass, inputStyle, labelClass, labelStyle, wrapperClasses,
  };
};

interface IPositionProps extends Object {
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
}

export const usePositionMethods = (props: IPositionProps) => {
  const computePosition = () => {
    const { position } = props;
    const { top } = props;
    const { left } = props;
    const { bottom } = props;
    const { right } = props;
    return `position: ${position}; top: ${top}; right: ${right}; bottom: ${bottom}; left: ${left}`;
  };
  return {
    computePosition,
  };
};
