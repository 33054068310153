import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "v-image-gallery-column",
  style: {"row-gap":"1em"}
}
const _hoisted_2 = { class: "v-image-gallery-row v-image-gallery-text justify-content-end column-gap-1em v-image-gallery-desc" }
const _hoisted_3 = {
  key: 1,
  "data-id": "image-gallery-show-sensitive"
}
const _hoisted_4 = {
  key: 3,
  "data-id": "image-gallery-hide-sensitive"
}
const _hoisted_5 = {
  key: 0,
  class: "v-image-gallery-column v-image-gallery-images"
}
const _hoisted_6 = {
  key: 1,
  class: "v-image-gallery-separator"
}
const _hoisted_7 = {
  key: 2,
  class: "v-image-gallery-column v-image-gallery-images"
}

import {
  BaseCard, CloseRightPanelIcon, BaseRightPanel, BaseIcon,
} from '@/_shared/components';
import {
  computed, ref, watchEffect, onBeforeUnmount, onBeforeMount,
} from 'vue';
import use from '@/_shared/compositionApi';
import { useRouter, useRoute } from 'vue-router';
import { instancesStore } from '@/_shared/store/Instances';
import { BaseInstance } from '@/_shared/types/NourishInstance';
import useImageGalleryStore from '@/_shared/store/imageGallery';
import { storeToRefs } from 'pinia';
import { useImages, Image } from '@/_shared/components/modals/ImageGalleryModal/imageGalleryModalHelper';
import ImageGalleryModal from '@/_shared/components/modals/ImageGalleryModal/ImageGalleryModal.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import InstanceInfo from '@/_shared/components/general/image_gallery/InstanceInfo.vue';
import ImageGalleryActions
  from '@/_shared/components/general/image_gallery/ImageGalleryActions.vue';
import ImageGalleryInteraction from './ImageGalleryInteraction.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageGalleryMain',
  props: {
  clientId: {
    type: [Number, String],
    required: true,
  },
  instanceId: {
    type: [Number, String],
    required: true,
  },
  backRoute: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;
const { clientStore } = use.store.clients();
const { translate } = use.helpers();
const router = useRouter();
const route = useRoute();

const imageGalleryStore = useImageGalleryStore();
const { galleryViewInfo } = storeToRefs(imageGalleryStore);
const { $reset } = imageGalleryStore;

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('timeline');
});

/*
 * Instance Information.
 */
const instance = computed(() => instancesStore.clientInstanceByInstanceId(+props.clientId, +props.instanceId) as BaseInstance);

/*
 * Client Picture.
 */
const avatarPhoto = computed(() => {
  if (props.clientId) {
    return clientStore.photo(+props.clientId);
  }
  return undefined;
});

/*
 * Toggle to blur images.
 */
const blurImages = ref<boolean>(true);

watchEffect(() => {
  blurImages.value = galleryViewInfo.value?.isSensitive ?? true;
});

const toggleBlurImages = () => (blurImages.value = !blurImages.value);

/*
 * Routing methods.
 */
const onCloseComponent = () => {
  if (props.backRoute) {
    router.push({ name: props.backRoute, params: route.params, query: route.query });
  } else {
    router.back();
  }
};

/*
 * ImageGalleryModal
 */
const newestPictures = computed(() => galleryViewInfo.value.newestInteraction?.coercedValue.pictures || []);
const oldestPictures = computed(() => galleryViewInfo.value.oldestInteraction?.coercedValue.pictures || []);

const { images: newestImages } = useImages(newestPictures);
const { images: oldestImages } = useImages(oldestPictures);

const currentInteraction = ref<'newest' | 'oldest'>('newest');
const currentImages = ref<Image[]>([]);

const imageGalleryModalVisible = ref(false);
const currentPicturePosition = ref(0);

const onOpenImageGalleryModal = (position: number, interaction: 'newest' | 'oldest') => {
  currentInteraction.value = interaction;
  currentImages.value = interaction === 'newest' ? newestImages.value : oldestImages.value;
  currentPicturePosition.value = position;
  imageGalleryModalVisible.value = true;
};
const onCreateReport = () => {
  router.push({
    path: '/reports/new',
    query: {
      clientId: props.clientId,
      instanceId: props.instanceId,
    },
  });
};
onBeforeUnmount(() => $reset());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseRightPanel), { photo: avatarPhoto.value }, {
    default: _withCtx(() => [
      _createVNode(_unref(CloseRightPanelIcon), { onCloseRightPanel: onCloseComponent }),
      _createVNode(_unref(BaseCard), null, {
        header: _withCtx(() => [
          (instance.value)
            ? (_openBlock(), _createBlock(InstanceInfo, {
                key: 0,
                instance: instance.value,
                firstInteractionDate: _unref(galleryViewInfo).firstInteractionDate,
                lastInteractionDate: _unref(galleryViewInfo).lastInteractionDate
              }, null, 8, ["instance", "firstInteractionDate", "lastInteractionDate"]))
            : _createCommentVNode("", true)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_unref(galleryViewInfo).isSensitive)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleBlurImages())),
                    class: "v-image-gallery-row v-image-gallery-bold v-is-sensitive-wrapper"
                  }, [
                    (blurImages.value)
                      ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                          key: 0,
                          name: 'v-icon-eye',
                          compact: true,
                          "no-hover-background": "",
                          noHover: ""
                        }))
                      : _createCommentVNode("", true),
                    (blurImages.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(translate)('image_gallery.show_sensitive')), 1))
                      : _createCommentVNode("", true),
                    (!blurImages.value)
                      ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                          key: 2,
                          name: 'v-icon-eye_crossed',
                          compact: true,
                          "no-hover-background": "",
                          noHover: ""
                        }))
                      : _createCommentVNode("", true),
                    (!blurImages.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(translate)('image_gallery.hide_sensitive')), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (instance.value)
                ? (_openBlock(), _createBlock(ImageGalleryActions, {
                    key: 1,
                    instance: instance.value,
                    totalImages: _unref(galleryViewInfo).totalImages,
                    onCreateReport: onCreateReport
                  }, null, 8, ["instance", "totalImages"]))
                : _createCommentVNode("", true)
            ]),
            (_unref(galleryViewInfo).newestInteraction)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(ImageGalleryInteraction, {
                    "blur-images": blurImages.value,
                    "instance-id": __props.instanceId,
                    "client-id": __props.clientId,
                    "gallery-view-interaction": _unref(galleryViewInfo).newestInteraction,
                    "interaction-type": "newest",
                    onOpenImageGalleryModal: onOpenImageGalleryModal
                  }, null, 8, ["blur-images", "instance-id", "client-id", "gallery-view-interaction"])
                ]))
              : _createCommentVNode("", true),
            (_unref(galleryViewInfo).newestInteraction && _unref(galleryViewInfo).oldestInteraction)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
              : _createCommentVNode("", true),
            (_unref(galleryViewInfo).oldestInteraction)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(ImageGalleryInteraction, {
                    "blur-images": blurImages.value,
                    "instance-id": __props.instanceId,
                    "client-id": __props.clientId,
                    "gallery-view-interaction": _unref(galleryViewInfo).oldestInteraction,
                    "interaction-type": "oldest",
                    onOpenImageGalleryModal: onOpenImageGalleryModal
                  }, null, 8, ["blur-images", "instance-id", "client-id", "gallery-view-interaction"])
                ]))
              : _createCommentVNode("", true),
            (instance.value)
              ? (_openBlock(), _createBlock(ImageGalleryActions, {
                  key: 3,
                  instance: instance.value,
                  totalImages: _unref(galleryViewInfo).totalImages,
                  onCreateReport: onCreateReport
                }, null, 8, ["instance", "totalImages"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      (imageGalleryModalVisible.value)
        ? (_openBlock(), _createBlock(ImageGalleryModal, {
            key: 0,
            images: currentImages.value,
            "picture-position": currentPicturePosition.value,
            "show-info": true,
            "gallery-interaction": currentInteraction.value === 'newest' ? _unref(galleryViewInfo).newestInteraction : _unref(galleryViewInfo).oldestInteraction,
            onCloseImageGalleryModal: _cache[1] || (_cache[1] = ($event: any) => (imageGalleryModalVisible.value = false))
          }, null, 8, ["images", "picture-position", "gallery-interaction"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["photo"]))
}
}

})