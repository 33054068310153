import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-client-card" }
const _hoisted_2 = { class: "v-client-card-info" }
const _hoisted_3 = { class: "v-client-card-info-header" }
const _hoisted_4 = { class: "v-client-card-name" }
const _hoisted_5 = {
  key: 0,
  class: "v-client-card-age"
}
const _hoisted_6 = { class: "v-client-card-room" }
const _hoisted_7 = { class: "v-client-card-bio" }
const _hoisted_8 = { class: "v-client-card-reports" }
const _hoisted_9 = { class: "v-client-card-reports--title" }
const _hoisted_10 = {
  key: 0,
  class: "v-client-card-quick-notes"
}
const _hoisted_11 = { class: "v-client-card-quick-notes--title" }

import { computed, PropType } from 'vue';
import { differenceInYears } from 'date-fns';
import use from '@/_shared/compositionApi';
import clientStore from '@/_shared/store/clients';
import ClientBiography from './ClientBiography.vue';
import ClientReportLinks from './ClientReportLinks.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientCard',
  props: {
  criticalInfos: {
    type: Array as PropType<string[]>,
  },
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const client = computed(() => clientStore.byId(props.clientId));

const avatarStyle = computed(() => {
  const clientPhotoUrl = client.value.photo?.medium?.url;
  return {
    'background-image': `url(${clientPhotoUrl ?? 'default-avatar.png'})`,
    'background-size': !clientPhotoUrl ? 'calc(100% - 20px) calc(100% - 20px)' : 'contain',
    'background-repeat': 'no-repeat',
  };
});

const age = computed(() => client.value.birthDate && differenceInYears(new Date(), new Date(client.value.birthDate)));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "v-client-card-avatar",
      style: _normalizeStyle(avatarStyle.value)
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(client.value.preferredName), 1)
        ]),
        (age.value)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_5, [
              _createElementVNode("strong", null, _toDisplayString(age.value) + " years", 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-client-card-divider" }, null, -1)),
      _createElementVNode("h4", _hoisted_6, _toDisplayString(client.value.addressCaption), 1),
      _createElementVNode("p", _hoisted_7, [
        (client.value.biography)
          ? (_openBlock(), _createBlock(ClientBiography, {
              key: 0,
              biography: client.value.biography
            }, null, 8, ["biography"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h4", _hoisted_9, _toDisplayString(_unref(translate)('critical_information.quick_reports')), 1),
        _createVNode(ClientReportLinks, {
          clientId: client.value.id,
          fullName: client.value.preferredName,
          healthProfile: client.value.healthProfile,
          stateInOrg: client.value.stateInOrg,
          birthDate: client.value.birthDate
        }, null, 8, ["clientId", "fullName", "healthProfile", "stateInOrg", "birthDate"])
      ]),
      (__props.criticalInfos && __props.criticalInfos.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("h4", _hoisted_11, _toDisplayString(_unref(translate)('critical_information.quick_notes')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.criticalInfos, (criticalInfo) => {
              return (_openBlock(), _createElementBlock("div", {
                key: criticalInfo,
                class: "v-client-card-quick-notes--item"
              }, _toDisplayString(criticalInfo), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})