import { computed } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { NourishInstance } from '@/_shared/types/NourishInstance';

const useInstanceRoutes = (route: RouteLocationNormalizedLoaded) => {
  const isWithinSkinNestedRoutes = computed(() => route.path?.toString().includes('instances'));
  const isWithinEventNestedRoutes = computed(() => route.path?.toString().includes('events'));
  const isWithinInstanceNestedRoutes = computed(() => isWithinSkinNestedRoutes.value || isWithinEventNestedRoutes.value);

  const isSkinInstancesRootRoute = computed(() => isWithinSkinNestedRoutes.value && !route.params.instanceId);
  const isEventInstancesRootRoute = computed(() => isWithinEventNestedRoutes.value && !route.params.instanceId);
  const isInstancesRootRoute = computed(() => isSkinInstancesRootRoute.value || isEventInstancesRootRoute.value);

  const isSkinInstanceViewRoute = computed(() => isWithinSkinNestedRoutes.value && !!route.params?.instanceId);
  const isEventInstanceViewRoute = computed(() => isWithinEventNestedRoutes.value && !!route.params?.instanceId);
  const isInstanceViewRoute = computed(() => isSkinInstanceViewRoute.value || isEventInstanceViewRoute.value);

  const timelineRouteName = computed(() => {
    switch (true) {
      case isSkinInstancesRootRoute.value:
        return 'client.timeline.body_map';
      case isSkinInstanceViewRoute.value:
        return 'client.timeline.body_map.instance';
      case isEventInstancesRootRoute.value:
        return 'client.events';
      case isEventInstanceViewRoute.value:
        return 'client.events.instance';
      default:
        return 'client.timeline';
    }
  });

  // This is to build path for client.timeline.interactions
  const currentTimelineViewStartPath = computed(() => {
    let path = `/clients/${route.params.clientId}`;
    if (isWithinSkinNestedRoutes.value) {
      path += '/instances';
      return isInstanceViewRoute.value ? path.concat(`/${route.params.instanceId}`) : path;
    }
    if (isWithinEventNestedRoutes.value) {
      path += '/events';
      return isInstanceViewRoute.value ? path.concat(`/${route.params.instanceId}`) : path;
    }
    return path;
  });

  const serviceSelectorPath = computed(() => {
    const basePath = `/clients/${route.params.clientId}`;

    if (isWithinSkinNestedRoutes.value) {
      const instancePart = isInstanceViewRoute.value ? `/${route.params.instanceId}` : '';
      return `${basePath}/instances${instancePart}/interactions/service_selector`;
    }

    if (isWithinEventNestedRoutes.value) {
      const instancePart = isInstanceViewRoute.value ? `/${route.params.instanceId}` : '';
      return `${basePath}/events${instancePart}/interactions/service_selector`;
    }

    return basePath;
  });

  const currentInstancePath = (instance: NourishInstance) => computed(() => {
    const isSkinInstance = instance.instanceType === 'skin';

    const instancesRoutePattern = isSkinInstance ? /\/instances/ : /\/events/;
    const instanceViewerRoutePattern = isSkinInstance ? /\/instances\/\d+/ : /\/events\/\d+/;

    const pathName = isSkinInstance ? 'instances' : 'events';

    let path = '';

    if (instanceViewerRoutePattern.exec(route.path)) {
      path = route.path;
      // there is no why to go form instance to instance no need to check id
    } else {
      const rst = instancesRoutePattern.exec(route.path);
      if (rst) {
        path = route.path.replace(rst[0], `/${pathName}/${instance.id}`);
      } else {
        const index = (`/clients/${route.params.clientId}`).length;
        path = `${route.path.slice(0, index)}/${pathName}/${instance.id}${route.path.slice(index)}`;
      }
    }
    return path.endsWith('/') ? path.slice(0, -1) : path;
  });

  return {
    isWithinSkinNestedRoutes,
    isWithinEventNestedRoutes,
    isWithinInstanceNestedRoutes,
    isSkinInstancesRootRoute,
    isEventInstancesRootRoute,
    isInstancesRootRoute,
    isSkinInstanceViewRoute,
    isEventInstanceViewRoute,
    isInstanceViewRoute,
    timelineRouteName,
    currentTimelineViewStartPath,
    serviceSelectorPath,
    currentInstancePath,
  };
};

export default useInstanceRoutes;
