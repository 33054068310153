import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-list-entry" }
const _hoisted_2 = { class: "v-left-column-wrapper v-checkbox-wrapper" }
const _hoisted_3 = { class: "v-main-col" }
const _hoisted_4 = { class: "v-primary-text v-dataset-entry" }

import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import { PropType } from 'vue';
import { DataSetCompositeOption } from '@/_shared/types/baseSelect';


export default /*@__PURE__*/_defineComponent({
  __name: 'NestedDatasetModalEntry',
  props: {
  entry: {
    type: Object as PropType<DataSetCompositeOption>,
    required: true,
  },
  isSelected: {
    type: Boolean,
    required: false,
  },
  primaryText: {
    type: String,
  },
},
  emits: ['toggleSelectEntry'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const toggleChecked = () => {
  emit('toggleSelectEntry', props.entry);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-entry-wrapper",
    onClick: toggleChecked
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["v-list-entry-inner v-highlight-on-hover", `${__props.entry.parent ? 'v-child-entry' : 'v-parent-entry'}`])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(BaseCheckbox, { checked: __props.isSelected }, null, 8, ["checked"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(__props.primaryText), 1)
        ])
      ], 2)
    ])
  ]))
}
}

})