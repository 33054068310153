import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-map-filter-dropdowns-container" }

import { computed, PropType } from 'vue';
import { storeToRefs } from 'pinia';
import BaseFilterDropdown from '@/_shared/components/general/BaseFilterDropdown.vue';
import { instanceFilterOptions, instanceFilterStateOptions } from '@/_shared/services/instanceFilterHelper';
import useTimelineFiltersStore from '@/_shared/store/timelineFilters';
import clientStore from '@/_shared/store/clients';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapFilter',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  instancesIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const timelineFiltersStore = useTimelineFiltersStore();
const { timelineFilters } = storeToRefs(timelineFiltersStore);
const { setTimelineFilters } = timelineFiltersStore;

const instances = computed(() => clientStore.skinInstances(props.clientId).value);

const allInstances = computed(() => instances.value.filter(({ id }) => props.instancesIds.includes(id)));

const stateOptions = computed(() => {
  const values = allInstances.value.map(({ state }) => state);
  return instanceFilterStateOptions(values, 'body_map.state');
});

const subTypeOptions = computed(() => {
  const values = Array.from(new Set(allInstances.value.map(({ subType }) => subType)));
  return instanceFilterOptions(values, 'datasets');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseFilterDropdown, {
      class: "v-body-map-state-filter",
      currentOption: _unref(timelineFilters).state,
      allOptions: stateOptions.value,
      onValueChanged: _cache[0] || (_cache[0] = (option) => _unref(setTimelineFilters)({ state: option }))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      class: "v-body-map-sub-type-filter",
      currentOption: _unref(timelineFilters).subType,
      allOptions: subTypeOptions.value,
      onValueChanged: _cache[1] || (_cache[1] = (option) => _unref(setTimelineFilters)({ subType: option }))
    }, null, 8, ["currentOption", "allOptions"])
  ]))
}
}

})