import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import use from '@/_shared/compositionApi';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportPageBreakInput from '@/reports/inputs/ReportPageBreakInput.vue';
import ReportCarerInput from '@/reports/inputs/ReportCarerInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarerProfileReport',
  setup(__props) {


const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportCarerInput)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['html', 'pdf'] }),
        _createVNode(ReportPageBreakInput, { term: "carer" })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})