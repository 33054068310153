import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-hide-on-print" }
const _hoisted_2 = { class: "v-navbar-wrapper" }
const _hoisted_3 = { class: "v-navbar" }
const _hoisted_4 = { class: "v-title sp-page-title" }
const _hoisted_5 = { class: "v-title-container" }
const _hoisted_6 = { class: "v-title-with-dropdown-container" }
const _hoisted_7 = {
  key: 0,
  class: "v-bell-icon-count-icon-wrapper sp-alarms"
}
const _hoisted_8 = {
  key: 0,
  class: "v-notification-count"
}

import { alarmStore } from '@/_shared/store/alarms';
import { carerStore } from '@/_shared/store/carers';
import clientStore from '@/_shared/store/clients';
import ClientBadges from '@/clients/components/ClientBadges.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import hideElementsWithClassesNames from '@/_shared/helpers/useAngularPartHider';
import {
  computed, onBeforeMount, ref,
} from 'vue';
import use from '@/_shared/compositionApi';
import { useRouter, useRoute } from 'vue-router';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import useDataRetentionStore from '@/_shared/store/dataRetention';
import PersonMobileDropdown from './PersonMobileDropdown.vue';

const radixBase10 = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNavbar',
  setup(__props) {

hideElementsWithClassesNames(['nsx-alarm-notifications', 'nsx-navbar__title', 'nsx-navbar__brand-link']);

const { translate } = use.helpers();
const { currentOrganisationUnit, getFeatureToggle } = useUserStore();
const route = useRoute();
const router = useRouter();
const dataRetentionStore = useDataRetentionStore();
const {
  pendingDeletionsCount,
} = storeToRefs(dataRetentionStore);
const getTitleFromRoutePath = (path: string) => {
  const pathToTitleDict: Record<string, string> = {
    '/administration/group_services': translate('navigation.admin.group_services'),
    '/administration/services': translate('navigation.admin.services'),
    '/alarms': translate('client.alarm'),
    '/carers': currentOrganisationUnit.carerTermPlural,
    '/client_types': translate('navigation.admin.care_types'),
    '/clients': currentOrganisationUnit.clientTermPlural,
    '/dashboards': translate('navigation.other.dashboard'),
    '/data_exports': translate('navigation.admin.data_exports'),
    '/data_retention': translate('navigation.admin.data_retention.title', { client_term: clientStore.clientTerm(false) }),
    '/data_retention/deleted': translate('navigation.admin.data_retention.deleted_client', { client_term: clientStore.clientTerm() }),
    '/data_retention/pending': translate('navigation.admin.data_retention.pending', { client_term: clientStore.clientTerm(), count: pendingDeletionsCount.value }),
    '/data_retention/report': translate('navigation.admin.data_retention.report'),
    '/handovers': translate('navigation.other.handover'),
    '/integrations': translate('navigation.admin.extensions'),
    '/me': translate('me.your_profile'),
    '/messages': translate('navigation.other.messages'),
    '/notifications': translate('navigation.other.notifications'),
    '/onboarding': 'Onboarding',
    '/permissions': translate('navigation.admin.permissions'),
    '/reports': translate('navigation.admin.reports'),
    '/roles': translate('navigation.admin.roles'),
    '/service_counts': translate('navigation.other.service_counts'),
  };
  if (getFeatureToggle('vueInsights')) {
    pathToTitleDict['/dashboards'] = translate('navigation.other.insights');
  }

  const pathsWithTitles = Array.from(Object.keys(pathToTitleDict));
  const pathKey = pathsWithTitles.reduce((longestPath, currentPath) => {
    if (path.search(currentPath) === 0) {
      if (currentPath.length > longestPath.length) {
        return currentPath;
      }
    }
    return longestPath;
  }, '');

  if (pathKey != null) {
    return pathToTitleDict[pathKey];
  }
  return '';
};

const isNumber = (target: string) => /^\d+$/.test(target);

const routeConfig = computed(() => {
  if (route) {
    if (route.params) {
      if (route.params.carerId && isNumber(route.params.carerId as string)) {
        return {
          personId: parseInt(route.params.carerId as string, radixBase10),
          type: 'carer',
          title: '',
        };
      }

      if (route.params.clientId && isNumber(route.params.clientId as string)) {
        return {
          personId: parseInt(route.params.clientId as string, radixBase10),
          type: 'client',
          title: '',
        };
      }
    }

    if (route.path && route.path.includes('messages')) {
      return {
        personId: -1,
        type: 'messages',
        title: getTitleFromRoutePath(route.path),
      };
    }

    if (route.path && (route.path.includes('roles') || route.path.includes('notifications'))) {
      return {
        personId: -1,
        type: 'roles',
        title: getTitleFromRoutePath(route.path),
      };
    }

    if (route.path) {
      return {
        personId: -1,
        type: '',
        title: getTitleFromRoutePath(route.path),
      };
    }
  }

  return {
    personId: -1,
    type: '',
    title: '',
  };
});

const pageTitle = computed(() => {
  if (!route) {
    return '';
  }
  if (routeConfig.value.personId === -1) {
    return routeConfig.value.title;
  }
  if (routeConfig.value.type === 'carer') {
    return carerStore.displayName(routeConfig.value.personId).value;
  }
  return clientStore.preferredOrFirstName(routeConfig.value.personId).value;
});

function isFamilyPortal() {
  return window.familyPortal;
}

function routeToAlarm() {
  const path = router.resolve({ name: 'alarms' });
  router.push({
    path: path.fullPath,
    query: { state: 'admitted' },
  });
}

const alarmNotificationsCount = ref(0);

function fetchAlarmNotifications() {
  alarmStore.fetchNotificationsCount().then((count) => {
    alarmNotificationsCount.value = count.alarm;
  });
}

function startAlarmFetchingInterval() {
  fetchAlarmNotifications();
  setInterval(fetchAlarmNotifications, 900000);
}

onBeforeMount(() => {
  startAlarmFetchingInterval();
});

function getTitleClass() {
  let titleClass = 'v-text-title';
  if (routeConfig.value.type === 'client') {
    titleClass += ' sp-client-name-title';
  }

  return titleClass;
}
const showNavDropdown = computed(() => routeConfig.value.personId !== -1 || routeConfig.value.type === 'messages' || routeConfig.value.type === 'roles');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "v-navbar-placeholder" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BaseTooltip, {
          class: "v-nourish-logo-container",
          position: "right",
          tooltipText: _unref(translate)('common.navigateHome')
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("a", { href: "/" }, [
              _createElementVNode("img", {
                class: "v-nourish-logo-white sp-brand-logo",
                src: "/nourish_logo_navy.png",
                alt: "Nourish Organisations"
              })
            ], -1)
          ])),
          _: 1
        }, 8, ["tooltipText"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(getTitleClass())
              }, _toDisplayString(pageTitle.value), 3),
              (showNavDropdown.value)
                ? (_openBlock(), _createBlock(PersonMobileDropdown, {
                    key: 0,
                    personId: routeConfig.value.personId,
                    type: routeConfig.value.type,
                    class: "v-timeline-tab-picker"
                  }, null, 8, ["personId", "type"]))
                : _createCommentVNode("", true)
            ]),
            (routeConfig.value.type === 'client')
              ? (_openBlock(), _createBlock(ClientBadges, {
                  key: 0,
                  "client-id": +_unref(route).params.clientId
                }, null, 8, ["client-id"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (!_unref(getFeatureToggle)('covidTracker') && !isFamilyPortal())
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (routeToAlarm()))
              }, [
                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "v-bell-icon nr-icon-bell" }, null, -1)),
                (alarmNotificationsCount.value > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(alarmNotificationsCount.value), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})