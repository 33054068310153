import * as pbi from 'powerbi-client';
import useAnalyticsStore from '@/_shared/store/analytics';
import { storeToRefs } from 'pinia';
import { watch, ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';

interface VisualsData {
  title: string;
  data: string;
}

const useDataExport = () => {
  const visualsData = ref<VisualsData[]>([]);
  const { embeddedReport } = storeToRefs(useAnalyticsStore());

  const downloadVisualsDataAsCSV = () => {
    if (visualsData.value.length) {
      visualsData.value.forEach(({ title, data }) => {
        _downloadCsv(title, data);
      });
    }
  };

  const getVisualsExportData = async () => {
    if (!embeddedReport.value) { return []; }

    const page = await embeddedReport.value.getActivePage();
    const visuals = await page.getVisuals();
    return _extractDataFromVisuals(visuals);
  };

  const updateVisualsData = useDebounceFn(async () => {
    visualsData.value = await getVisualsExportData() as VisualsData[];
  }, 200);

  watch(embeddedReport, async (newVal) => {
    if (newVal) {
      newVal.on('pageChanged', updateVisualsData);
      newVal.on('visualRendered', updateVisualsData);
    }
  });

  return { downloadVisualsDataAsCSV, getVisualsExportData, visualsData };
};

const _extractDataFromVisuals = async (visuals: pbi.VisualDescriptor[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const powerbiClient = (window as any)['powerbi-client'] as typeof pbi;
  const { models } = powerbiClient;

  const results = await Promise.all(
    visuals.map(async (visual) => {
      if (visual.type !== 'tableEx') return null;

      try {
        const response = await visual.exportData(models.ExportDataType.Summarized);
        return { title: visual.title, data: response.data } as VisualsData;
      } catch {
        return null;
      }
    }),
  );

  return results.filter((result) => result !== null);
};

const _downloadCsv = (title: string, data: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(new Blob([data], {
    type: 'application/octet-stream',
  }));
  link.setAttribute('download', `${title}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default useDataExport;
