// construct and export default singleton client
// because of circular references (?) this must be in a separate file

import { AssessmentState } from '@/assessments/types/assessmentState';
import apiClient from '@/_shared/services/apiClient';
import type { NourishInstanceType } from '@/_shared/store/composables/datasetsInstances';

export interface ServiceFiltersApiResponse {
  nonAdHocServices: number[],
  editableServices: number[],
  clientTypeServices: Record<string, number[]>,
  stateFilters: AssessmentState[],
  newInstanceServices: NewInstanceServicesFilter[],
  managedInstanceServices: ManagedInstanceServicesFilter[],
}

export interface NewInstanceServicesFilter {
  id: number;
  instanceType: NourishInstanceType;
}

export interface ManagedInstanceServicesFilter {
  id: number;
  subType: string[];
  state: string;
  instanceType: NourishInstanceType;
}

export default async function fetchServiceFilters() {
  const url = '/api/v2/services/filters';
  try {
    const response = await apiClient.get<ServiceFiltersApiResponse>(url);
    return response;
  } catch (error) {
    return {} as ServiceFiltersApiResponse;
  }
}
