import use from '@/_shared/compositionApi';
import { DeepPartial } from '@/_shared/types/extended';
import { Report } from '@/_shared/types/report';

const { translate } = use.helpers();

const PARSE_INT_RADIX = 10;

const woundMultiPhotoReportParams = (clientId: string, instanceId: string): DeepPartial<Report> => ({
  reportClass: 'WoundMultiPhotoReport',
  organisationUnitId: window.currentOrganisationUnit.id,
  format: 'html',
  title: translate('image_gallery.wound_report_name', { wound_id: instanceId }),
  params: {
    clientIds: parseInt(clientId, PARSE_INT_RADIX) as DeepPartial<number>,
    woundId: parseInt(instanceId, PARSE_INT_RADIX) as DeepPartial<number>,
    photoOption: 'AllPhotosForThisWound',
    redactionOption: 'show_all',
  },
});

export default woundMultiPhotoReportParams;
