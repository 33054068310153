import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-reports-list" }
const _hoisted_2 = { class: "v-report-add-btn" }
const _hoisted_3 = { class: "v-table-cell-primary" }
const _hoisted_4 = { class: "v-created-at" }
const _hoisted_5 = { class: "v-actions" }
const _hoisted_6 = { class: "v-action v-report-state-detail" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "v-action" }
const _hoisted_9 = { class: "v-action v-report-menu" }

import {
  computed, onBeforeMount, onBeforeUnmount, ref,
} from 'vue';
import { storeToRefs } from 'pinia';
import { previewReport, downloadReport } from '@/_shared/services/reportsApi';
import { BaseCard, BaseIcon, LoadingSpinner } from '@/_shared/components';
import vClickOutside from '@/_shared/directives/ClickOutside';

import BaseListTable from '@/_shared/components/display/BaseListTable/BaseListTable.vue';
import BaseLandingStrip from '@/_shared/components/display/BaseLandingStrip/BaseLandingStrip.vue';
import BaseDropdownList from '@/_shared/components/general/BaseDropdownList.vue';
import useReportStore from '@/_shared/store/reports';
import BaseSearchText from '@/_shared/components/inputs/BaseSearchText/BaseSearchText.vue';
import { ListTableRow, ReportFrontendStatus } from '@/_shared/components/display/BaseListTable/types';
import { Report, reportClasses } from '@/_shared/types/report';
import { useRouter } from 'vue-router';
import { formatAmPmDateTime } from '@/_shared/helpers/useDateTimeHelper';
import useUserStore from '@/_shared/store/user';

type ReportListTableRow = ListTableRow & {
  status?: ReportFrontendStatus;
  stateDetail?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportList',
  setup(__props) {

const router = useRouter();

const reportStore = useReportStore();
const { reports, autoRefresh } = storeToRefs(reportStore);
const showMenu = ref<string | number>(0);

const searchTermRef = ref('');
const formattedReports = computed((): ReportListTableRow[] => reports.value.map((r) => ({
  identifier: r.id,
  cells: {
    title: r.title,
    createdAt: r.createdAt.toLocaleString(),
    format: r.format.toUpperCase(),
  },
  stateDetail: r.stateDetail,
  status: (r.state === 'ready' ? 'active' : 'neutral') as ReportFrontendStatus,
})));

const filteredReports = computed(() => formattedReports.value.filter((report) => {
  const titleCell = (report.cells.title as string).toLowerCase();
  return titleCell.includes(searchTermRef.value);
}));

const openLauncher = (id: string | number) => {
  if (id !== showMenu.value) {
    showMenu.value = id;
    return;
  }
  showMenu.value = 0;
};

const getReportById = (reportId: number): Report|undefined => reports.value.find((report: Report) => report.id === reportId);

const checkDisableBtn = (reportId: number, defaultStatus: boolean) => {
  const report = getReportById(reportId);

  if (report && ['csv', 'excel'].includes(report.format)) {
    return true;
  }

  return defaultStatus;
};

const getMenuOptions = (menuTitle: string, reportId: number, isVisible = true, isDisabled = false) => [{
  options: [
    {
      show: isVisible,
      disabled: checkDisableBtn(reportId, isDisabled),
      text: 'Preview',
      method: async () => {
        const target = getReportById(reportId);
        await previewReport(target as Report);
      },
    },
    {
      show: isVisible,
      disabled: isDisabled,
      text: 'Download',
      method: async () => {
        const target = getReportById(reportId);
        await downloadReport(target as Report);
      },
    },
    {
      show: isVisible,
      disabled: !reportClasses.includes(getReportById(reportId)!.reportClass),
      text: 'Use as template',
      method: async () => {
        await router.push(`/reports/new?templateId=${reportId}`);
      },
    },
    {
      show: true, // always shown
      disabled: false, // never disabled
      text: 'Delete',
      method: () => {
        reportStore.deleteReport(reportId);
      },
    },
  ],
  title: menuTitle,
}];

const clearShowMenu = (event: Event) => {
  const element = (event.target as HTMLInputElement);
  if (element.tagName !== 'I' && showMenu.value !== 0) {
    showMenu.value = 0;
  }
};

const updateFilterViaSearchTerm = (searchTerm: string) => {
  searchTermRef.value = searchTerm.toLowerCase();
};

const addNewReport = () => {
  router.push('/reports/new');
};

const showStateDetail = computed(() => useUserStore().currentUser.isImpersonated);

onBeforeMount(() => {
  autoRefresh.value = true;
  reportStore.autoRefreshFetchAll();
});

onBeforeUnmount(() => {
  autoRefresh.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseLandingStrip, { title: "Vue Reports Page Landing Strip" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(BaseIcon), {
            name: "v-icon-add-new",
            alt: "Add new report",
            size: "3x",
            backgroundColor: "teal",
            border: true,
            onClick: addNewReport
          })
        ]),
        _createVNode(BaseSearchText, {
          onSearch: updateFilterViaSearchTerm,
          inputId: 1,
          additionalStyles: "margin: 22px 0 0 0;"
        }),
        _createVNode(_unref(BaseCard), { title: "Reports" }, {
          header: _withCtx(() => [
            _createVNode(_unref(BaseIcon), {
              name: "v-icon-report-circled",
              size: "3x",
              backgroundColor: "black",
              foregroundColor: "white"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "v-base-card-title" }, "Reports", -1))
          ]),
          content: _withCtx(() => [
            _createVNode(BaseListTable, {
              "display-extra-slot-column": "",
              "header-black": "",
              rows: filteredReports.value
            }, {
              "slot-title": _withCtx((slotProps) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(slotProps.data), 1)
              ]),
              "slot-state": _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("   ")
              ])),
              "slot-createdAt": _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(formatAmPmDateTime)(slotProps.data)), 1)
              ]),
              actions: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (showStateDetail.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(slotProps.row.stateDetail), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", null, [
                      (slotProps.row?.status === 'neutral')
                        ? (_openBlock(), _createBlock(_unref(LoadingSpinner), {
                            key: 0,
                            class: "v-report-state"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      (_openBlock(), _createBlock(_unref(BaseIcon), {
                        name: "v-icon-launcher",
                        onClick: ($event: any) => (openLauncher(slotProps.row?.identifier)),
                        key: `icon-${slotProps.row?.identifier}`
                      }, null, 8, ["onClick"])),
                      (showMenu.value === slotProps.row?.identifier)
                        ? _withDirectives((_openBlock(), _createBlock(BaseDropdownList, {
                            class: "v-report-menu-dropdown",
                            key: `menu-${slotProps.row?.identifier}`,
                            menuSections: getMenuOptions('', +slotProps.row?.identifier, true, slotProps.row?.status !== 'active'),
                            show: showMenu.value === slotProps.row?.identifier,
                            enableItemSeparator: true,
                            position: "BottomLeft"
                          }, null, 8, ["menuSections", "show"])), [
                            [_unref(vClickOutside), clearShowMenu]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["rows"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})