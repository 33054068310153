import {
  Report, ReportClass, ReportParams, ReportTemplate,
} from '@/_shared/types/report';
import * as apiReports from '@/_shared/services/reportsApi';
import useUserStore from '@/_shared/store/user';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { defaultReport, getDefaultReport } from '@/reports/helpers/defaultReports';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import use from '@/_shared/compositionApi';
import { DeepPartial } from '@/_shared/types/extended';

const { translate } = use.helpers();

const useReportStore = defineStore('report', () => {
  const newReport = ref<Report>(defaultReport);
  const reports = ref<Report[]>([]);
  const reportValid = ref(true);
  const autoRefresh = ref(false);

  const buildNewReport = (reportClass: ReportClass = 'ClientInteractionsReport'): void => {
    newReport.value = getDefaultReport(reportClass, useUserStore().currentOrgUnitId) as Report;
  };

  const useReportAsTemplate = (templateId: number): void => {
    const template = reports.value.find((report) => report.id === templateId);
    if (!template) {
      toast(translate('reports.cannot_find_template'), ToastType.Danger);
      buildNewReport();
    } else {
      template.title += ' (copy)';
      setNewReportFromTemplate(template);
    }
  };

  const setNewReportFromTemplate = (template: ReportTemplate): void => {
    newReport.value = {
      ...template,
      id: 0,
      organisationUnitId: useUserStore().currentOrgUnitId,
      state: 'draft',
      file: { url: '' },
      createdAt: new Date(),
      updatedAt: new Date(),
      uuid: null,
    };
  };

  const buildNewCustomReport = (reportClass: ReportClass, customParams: DeepPartial<Report>) => {
    const baseReport = getDefaultReport(reportClass, useUserStore().currentOrgUnitId);

    newReport.value = {
      ...baseReport,
      ...customParams,
      params: {
        ...baseReport.params,
        ...customParams.params,
      },
    } as Report;
  };

  const fetchAll = async (orgUnitId = useUserStore().currentOrgUnitId): Promise<void> => {
    reports.value = await apiReports.fetchAllReports(orgUnitId);
  };

  const autoRefreshFetchAll = async (orgUnitId = useUserStore().currentOrgUnitId): Promise<void> => {
    if (autoRefresh.value && reports.value.filter((report) => ['waiting', 'processing'].includes(report.state)).length) {
      reports.value = await apiReports.fetchAllReports(orgUnitId);
      setTimeout(() => autoRefreshFetchAll(), 5000);
    }
  };

  const fetchReport = async (ids: number[]): Promise<Report> => apiReports.fetchReport(ids[0]);

  const deleteReport = (id: number): void => {
    apiReports.deleteReport(id).then(() => {
      reports.value = reports.value.filter((report) => report.id !== id);
    });
  };

  const createReport = async (): Promise<Report | undefined> => {
    const resp = await apiReports.createReport(newReport.value as Report);
    if (resp instanceof Error) {
      return undefined;
    }
    return resp.report;
  };

  const generateClientReport = (reportClass: ReportClass, title: string, params?: Partial<ReportParams>) => {
    buildNewReport(reportClass);
    newReport.value.format = 'pdf';
    newReport.value.title = title;
    newReport.value.reportClass = reportClass;
    newReport.value.params = {
      ...newReport.value.params,
      ...params,
    } as ReportParams;
    return createReport();
  };

  return {
    autoRefresh,
    reports,
    newReport,
    reportValid,
    buildNewReport,
    buildNewCustomReport,
    fetchAll,
    autoRefreshFetchAll,
    fetchReport,
    deleteReport,
    createReport,
    useReportAsTemplate,
    generateClientReport,
  };
});

export default useReportStore;
