import { Parameter, ShowIf } from '@/timeline/types/Parameter';
import { testShowIf } from './dynamicParameterHelper';

const VALUE_TYPE_FILE = 'file';
const VALUE_TYPE_PICTURE = 'picture';

class ParameterWrapper {
  private parameter: Parameter;

  private getParameterShowIf: (id: number | string) => ShowIf | null;

  constructor(parameter: Parameter, getParameterShowIf: (id: number | string) => ShowIf | null) {
    this.parameter = parameter;
    this.getParameterShowIf = getParameterShowIf;
  }

  isHidden(): boolean {
    if (this.parameter.valueType === VALUE_TYPE_FILE || this.parameter.valueType === VALUE_TYPE_PICTURE) {
      const showIfConfig = this.getParameterShowIf(this.parameter.id);
      if (!showIfConfig) return false;
      if (!showIfConfig.target || showIfConfig.target.hidden) return true;
      return !testShowIf(showIfConfig);
    }
    return false;
  }
}

export default ParameterWrapper;
