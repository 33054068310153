import { CoercedChoice } from '@/timeline/types/Parameter';
import type { Datapoint, DataPointValue } from '../store/dataPoints';

type Datapoints = Record<string, Datapoint>;

export const FALSEY_STRINGS = ['no', 'n/a', 'not applicable', 'none known', 'not recorded', 'none', '', 'false'];

export const hasValueProperty = (input: DataPointValue): input is { value: string } => typeof input === 'object' && 'value' in input;

export const isCoercedChoiceArray = (input?: DataPointValue): input is CoercedChoice[] => {
  if (!Array.isArray(input)) {
    return false;
  }
  type NarrowedInput = string | CoercedChoice;

  return (input as NarrowedInput[])
    .every((item: NarrowedInput): item is CoercedChoice => hasValueProperty(item));
};

export const stringIsFalsey = (value?: string) => {
  if (!value) {
    return true;
  }
  value = value.toLowerCase();
  return FALSEY_STRINGS.some((v) => v === value);
};

export const emergencyInformationDataPointValue = (dataPoint: Datapoint) => {
  if (typeof dataPoint.value === 'string') {
    return dataPoint.value;
  }
  if (Array.isArray(dataPoint.value) && typeof dataPoint.value[0] === 'string') {
    return `${dataPoint.value.sort().join('\n').trim()}`;
  } if (isCoercedChoiceArray(dataPoint.value)) {
    const values: unknown[] = [];
    dataPoint.value.forEach((val) => values.push(val.value));
    return `${values.sort().join('\n').trim()}`;
  }

  return 'activerecord.errors.models.interaction.parameters_wrong_type';
};

export const setEmergencySituation = (dataPoints: Datapoints) => {
  const codename = 'emergencySituationDetails';
  let content = '';
  if (dataPoints[codename] && dataPoints[codename].value) {
    content = emergencyInformationDataPointValue(dataPoints[codename]);
    return {
      emergency_situation_details: {
        heading: 'emergency_information.data_points_headings.emergencySituationDetails',
        content,
        changedAt: dataPoints[codename].changedAt,
      },
    };
  }
  return null;
};

export const setPEEP = (dataPoints: Datapoints): Partial<Record<'has_peep' | 'peep_location', FormattedDatapoint>> | null => {
  if (!dataPoints.hasPeep
    || !dataPoints.hasPeep.value
    || (hasValueProperty(dataPoints.hasPeep.value) && !dataPoints.hasPeep.value.value)
    || (hasValueProperty(dataPoints.hasPeep.value) && stringIsFalsey(dataPoints.hasPeep.value.value))
  ) {
    return null;
  }
  return {
    has_peep: {
      heading: 'emergency_information.data_points_headings.hasPeep',
      content: hasValueProperty(dataPoints.hasPeep.value) ? dataPoints.hasPeep.value.value : '',
      changedAt: dataPoints.hasPeep.changedAt,
    },
    peep_location: {
      heading: 'emergency_information.data_points_headings.peepLocation',
      content: emergencyInformationDataPointValue(dataPoints.peepLocation),
      changedAt: dataPoints.peepLocation.changedAt,
    },
  };
};

export const setEvacuationEquipment = (dataPoints: Datapoints): Partial<Record<'evacuation_equipment', FormattedDatapoint>> => {
  const codename = 'evacuationEquipment';
  const codenameAo = 'myEvacuationEquipmentNewAo';
  let content = '';
  let changedAt: string | undefined;

  if (dataPoints[codename] && dataPoints[codename].value) {
    changedAt = dataPoints[codename].changedAt;
    if (typeof dataPoints[codename].value === 'string'
      && stringIsFalsey(dataPoints[codename].value as string | undefined)) {
      content = 'None';
    } else {
      content = emergencyInformationDataPointValue(dataPoints[codename]);
    }
  }

  if (dataPoints[codenameAo] && dataPoints[codenameAo].value) {
    if (changedAt === null || dataPoints[codenameAo].changedAt! > changedAt!) {
      changedAt = dataPoints[codenameAo].changedAt;
    }
    if (typeof dataPoints[codenameAo].value === 'string'
      && stringIsFalsey(dataPoints[codenameAo].value as string | undefined)) {
      content = 'None';
    } else {
      content += emergencyInformationDataPointValue(dataPoints[codenameAo]);
    }
  }

  if (content.length === 0 && !changedAt) {
    return {};
  }

  return {
    evacuation_equipment: {
      heading: `emergency_information.data_points_headings.${codename}`,
      content,
      changedAt,
    },
  };
};

type FormattedDatapoint = {
  heading: string,
  content: string,
  changedAt?: string
}

export const setRescueMedication = (dataPoints: Datapoints): Partial<Record<'rescue_medication' | 'rescueMedicationLocation' | 'rescueMedicationDetails', FormattedDatapoint>> | null => {
  const codename = 'rescueMedication';
  let content;
  if (!dataPoints[codename]
    || !dataPoints[codename].value) {
    return null;
  }
  if (typeof dataPoints[codename].value === 'string'
    && stringIsFalsey(dataPoints[codename].value as string | undefined)) {
    content = 'None';
  } else {
    content = emergencyInformationDataPointValue(dataPoints[codename]);
  }
  const rescueMedication: {
    rescue_medication: FormattedDatapoint,
    rescueMedicationLocation?: FormattedDatapoint,
    rescueMedicationDetails?: FormattedDatapoint
  } = {
    rescue_medication: {
      heading: `emergency_information.data_points_headings.${codename}`,
      content,
      changedAt: dataPoints[codename].changedAt,
    },
  };
  if (content !== 'None') {
    const arr = ['rescueMedicationLocation', 'rescueMedicationDetails'] as const;
    arr.forEach((cname) => {
      if (dataPoints[cname] && dataPoints[cname].value) {
        rescueMedication[cname] = {
          heading: `emergency_information.data_points_headings.${cname}`,
          content: emergencyInformationDataPointValue(dataPoints[cname]),
          changedAt: dataPoints[cname].changedAt,
        };
      }
    });
  }
  return rescueMedication;
};

export const getContactPersonDataPointValue = (dataPoints: Datapoints): string[] | CoercedChoice[] => (dataPoints.contactPerson
  && dataPoints.contactPerson.value
  && Array.isArray(dataPoints.contactPerson.value)
  && dataPoints.contactPerson.value.length > 0 ? dataPoints.contactPerson.value : []);

export const hasEmergencyInfoDataPointsHelper = (dataPoints: Datapoints): boolean => !!(dataPoints.evacuationEquipment
  || dataPoints.hasPeep
  || dataPoints.myEvacuationEquipmentNewAo
  || dataPoints.rescueMedication);
