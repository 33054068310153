import { defineStore, storeToRefs } from 'pinia';
import { createNewMessage, folderType, MessageDTO } from '@/messages/types/messages';
import use from '@/_shared/compositionApi';
import {
  computed, nextTick, reactive, ref, watch,
} from 'vue';
import {
  fetchMessages,
  fetchRecipients,
  postMessage,
  setArchiveStatus,
  setReadStatus,
} from '@/messages/services/messagesApi';
import { Options } from '@/_shared/types/baseSelect';
import useUserStore from '@/_shared/store/user';

const useMessagesStore = defineStore('messages', () => {
  const { toast, ToastType, translate } = use.helpers();
  const { carerStore } = use.store.carers();

  const { currentUser } = storeToRefs(useUserStore());

  // Messages
  const messages = ref<MessageDTO[]>([]);
  const openedMessage = ref<null | MessageDTO>(null);
  const newMessage = ref<null | MessageDTO>(null);
  const recipientOptions = ref<Options>([]);

  const getMessages = async () => {
    messages.value = [];
    const resp = await fetchMessages(currentFolder.value, pagination.page, pagination.perPage);
    messages.value = resp.messages;
    setPagination(resp.totalPages);
  };

  const getMessageById = (id:number) : MessageDTO| undefined => messages.value.find((message: MessageDTO) => message.id === id);
  const filteredMessages = computed(() => (<MessageDTO[]>messages.value).filter((message) => {
    if (currentFolder.value === 'inbox') {
      return !message.archived;
    }
    if (currentFolder.value === 'sent') {
      return message.fromMe && !message.archived;
    }
    if (currentFolder.value === 'archived_inbox') {
      return message.archived;
    }
    if (currentFolder.value === 'archived_sent') {
      return message.fromMe && message.archived;
    }
    return false;
  }));

  const setPagination = (totalPages: number) => {
    pagination.totalPages = totalPages;
    if (pagination.page > pagination.totalPages) {
      pagination.page = pagination.totalPages;
    }
  };

  const createMessage = async (message?: MessageDTO, confidential?: boolean) => {
    // A message that we get from the API contains receiverIds
    // but when we send it we need recipientIds
    // This deals with the reply/reply_all receivers
    if (message && message.receiverIds) {
      message.recipientIds = message.receiverIds;
    }
    // todo find a way to cache recipients
    const recipients = await fetchRecipients(confidential);
    recipientOptions.value = recipients || [];
    closeDetail();
    if (recipientOptions.value!.length > 0) {
      newMessage.value = message || createNewMessage(currentUser.value.id);
    } else {
      toast(translate('message.errors.no_recipients'), ToastType.Danger);
    }
  };

  const sendMessage = async (message: MessageDTO) => {
    const errorMessage = getMessageErrors(message);
    if (errorMessage) {
      toast(errorMessage, ToastType.Danger);
      return false;
    }
    const rc = await postMessage(message);
    if (rc) {
      toast(translate('message.sent'), ToastType.Success);
      newMessage.value = null;
      await getMessages();
      return true;
    }
    toast(translate('message.not_sent'), ToastType.Danger);
    return false;
  };

  const openMessage = async (message: MessageDTO) => {
    try {
      if (!message.isRead) {
        await setReadStatus(message.id as number, true);
        message.isRead = true;
      }
    } catch (error) {
      toast(translate('ajax.errors.server'), ToastType.Danger); // Get proper translation for i.e. could not update the status
    } finally {
      openedMessage.value = message;
    }
  };

  const toggleRead = async (message: MessageDTO) => {
    const newStatus = !message.isRead;
    try {
      await setReadStatus(message.id as number, newStatus);
      message.isRead = newStatus;
    } catch (error) {
      toast(translate('message.not_sent'), ToastType.Danger); // Get proper translation for i.e. could not update the status
    } finally {
      closeDetail();
    }
  };

  const toggleArchive = async (message: MessageDTO) => {
    const newStatus = !message.archived;
    try {
      await setArchiveStatus(message.id as number, newStatus);
      message.archived = newStatus;
      forceReloadPage();
    } catch (error) {
      toast(translate('message.not_sent'), ToastType.Danger); // Get proper translation for i.e. could not update the status
    } finally {
      closeDetail();
    }
  };

  const closeDetail = () => {
    openedMessage.value = null;
  };

  // Folders
  const currentFolder = ref<folderType>('inbox');
  const isSentFolder = computed(() => ['sent', 'archived_sent'].includes(currentFolder.value));
  const folders = ['inbox', 'sent', 'archived_inbox', 'archived_sent'];

  // Pagination

  const initialPaginationState = {
    totalPages: 0,
    page: 1,
    perPage: 10,
  };

  const pagination = reactive({
    totalPages: initialPaginationState.totalPages,
    page: initialPaginationState.page,
    perPage: initialPaginationState.perPage,
  });

  const forceReloadPage = () => {
    const { page } = pagination;
    pagination.page = 0;
    pagination.page = page;
  };

  watch(currentFolder, async () => {
    pagination.page = 1;
    await getMessages();
  });

  const page = computed(() => pagination.page);

  watch(page, async () => {
    await getMessages();
  });

  // Getters

  const messageReadState = (carerId: number, message: MessageDTO) => {
    const fromMeAndReadByCarer = message.fromMe
      && message.isReadBy
      && message.isReadBy.includes(carerId);

    return !(message.senderId === carerId || fromMeAndReadByCarer);
  };

  const getRecipients = (message: MessageDTO) => {
    if (!message.receiverIds) return [];
    return carerStore.carers(message.receiverIds);
  };

  const getSender = (message: MessageDTO) => {
    if (!message.senderId) return null;
    return carerStore.carers([message.senderId])[0];
  };

  const $reset = async () => {
    currentFolder.value = 'inbox';
    openedMessage.value = null;
    pagination.page = initialPaginationState.page;
    pagination.perPage = initialPaginationState.perPage;
    await nextTick();
    pagination.totalPages = initialPaginationState.totalPages;
    messages.value = [];
    newMessage.value = null;
    recipientOptions.value = [];
  };

  const getMessageErrors = (message: MessageDTO) => {
    const errors = [];
    if (invalidMessageValue(message.recipientIds)) {
      errors.push(translate('message.to'));
    }
    if (invalidMessageValue(message.subject)) {
      errors.push(translate('message.subject'));
    }
    if (invalidMessageValue(message.body)) {
      errors.push(translate('message.message_body'));
    }
    if (errors.length) {
      return `${translate('message.missing_value')} ${errors.join(', ')}`;
    }
    return null;
  };

  const invalidMessageValue = (value: string | (string | number)[] | undefined) => !value || value.length === 0;

  return {
    currentFolder,
    isSentFolder,
    openedMessage,
    pagination,
    messages,
    filteredMessages,
    newMessage,
    recipientOptions,
    folders,
    getMessages,
    getMessageById,
    setPagination,
    createMessage,
    openMessage,
    sendMessage,
    toggleRead,
    toggleArchive,
    closeDetail,
    messageReadState,
    getRecipients,
    getSender,
    $reset,
  };
});

export default useMessagesStore;
