import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { BaseSelect } from '@/_shared/components';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportCarerInput',
  setup(__props) {

const { newReport } = useReportStore();

const { translate } = use.helpers();

const { carerStore } = use.store.carers();
const carers = computed(() => carerStore.carersForReports);
const carerOptions = computed(() => carers.value.map((c) => {
  const stateTermKey = `reports.filters.responsible_carer_states.${c.state}`;
  const stateTerm = translate(stateTermKey);
  return {
    value: c.id,
    text: `${c.displayName} - ${stateTerm}`,
  };
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseSelect), {
    label: _unref(translate)('reports.filters.responsible_carers'),
    modelValue: _unref(newReport).params.carerIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.carerIds) = $event)),
    options: carerOptions.value,
    "standard-input-style": "",
    placeholder: _unref(translate)('common.all')
  }, null, 8, ["label", "modelValue", "options", "placeholder"]))
}
}

})