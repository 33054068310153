import { defineStore } from 'pinia';
import { ref } from 'vue';
import { promiseMemorise } from '@/_shared/services/UseUtils';
import apiClient from '../services/apiClient';

export interface ClientLastSeen {
    id: number;
    lastSeen: string;
}

export interface PersonPreferenceApiResponse {
    personPreference: {
        id: number;
        personId: number;
        sharedPreferences: { clientsLastSeen: ClientLastSeen[] }
    }
}

export const usePersonPreferenceStore = defineStore('personPreference', () => {
  const clientsLastSeen = ref<ClientLastSeen[]>([]);

  const setPersonId = async (personId: number) => {
    const result = await apiClient.get<PersonPreferenceApiResponse>(`/api/v2/people/${personId}/person_preference`);
    clientsLastSeen.value = result.personPreference.sharedPreferences.clientsLastSeen;
  };

  return {
    setPersonId: promiseMemorise(setPersonId),
    clientsLastSeen,
  };
});
