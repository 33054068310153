import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useReportStore from '@/_shared/store/reports';

const useReportDateHelper = () => {
  const getDefaultDateRange = () => {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    return [startDate, endDate];
  };

  const dateRange = ref(getDefaultDateRange());

  watch(dateRange, (newVal) => {
    const report = storeToRefs(useReportStore()).newReport;
    report.value.params.fromDate = newVal[0]; // eslint-disable-line prefer-destructuring
    report.value.params.toDate = newVal[1]; // eslint-disable-line prefer-destructuring
  });

  return {
    dateRange,
    getDefaultDateRange,
  };
};

export default useReportDateHelper;
