import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-params-popup" }
const _hoisted_2 = { class: "v-conflict-management" }
const _hoisted_3 = { class: "v-update" }
const _hoisted_4 = { class: "v-button-container" }
const _hoisted_5 = {
  key: 0,
  class: "v-gap"
}
const _hoisted_6 = { class: "v-saved-by-you" }

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import InteractionSection from '@/timeline/components/InteractionSection.vue';
import use from '@/_shared/compositionApi';
import { format } from 'date-fns';
import { Parameter } from '@/timeline/types/Parameter';
import { provide } from 'vue';
import useCurrentInteractionStore from '../store/currentInteractionStore';
import getParameterType from '../helper/parameterComponentCreator';


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterConflictModal',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  conflictingParameterData: {
    type: Object as () => [Parameter, number | null, string | null],
    required: true,
  },
  modalRect: {
    type: Object as () => DOMRect,
    required: true,
  },
},
  emits: ['accept', 'reject', 'close'],
  setup(__props) {

const props = __props;



const { translate } = use.helpers();

// Tell all further nested parameters that they are in modal state
provide('isModal', true);

const { currentInteraction, simplifyParameter } = useCurrentInteractionStore();
const { carerStore } = use.store.carers();
const lastCloserName = props.conflictingParameterData[1] ? carerStore.name(props.conflictingParameterData[1]) : 'Unknown';
const lastClosedDate = props.conflictingParameterData[2] ? format(new Date(props.conflictingParameterData[2]), 'dd/MM/yyyy HH:mm') : 'Unknown';
const savedByYouDate = currentInteraction.savedAt ? format(new Date(currentInteraction.savedAt), 'dd/MM/yyyy HH:mm') : 'Unknown';


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    header: false,
    footer: false,
    width: __props.modalRect.width
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('common.update')), 1),
          _createElementVNode("b", null, _toDisplayString(_unref(currentInteraction).name), 1),
          _createElementVNode("span", null, [
            _createElementVNode("strong", null, _toDisplayString(`${_unref(translate)('common.updated')}: `), 1),
            _createTextVNode(_toDisplayString(_unref(lastClosedDate)), 1)
          ]),
          _createElementVNode("span", null, [
            _createElementVNode("strong", null, _toDisplayString(`${_unref(translate)('common.by')}: `), 1),
            _createTextVNode(_toDisplayString(_unref(lastCloserName)), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(BaseButton, {
              class: "v-reject-button",
              buttonType: "secondary",
              size: "medium",
              disabled: false,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reject', __props.parameter, __props.conflictingParameterData[0])))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('common.reject')), 1)
              ]),
              _: 1
            }),
            _createVNode(BaseButton, {
              class: "v-accept-button",
              buttonType: "primary",
              size: "medium",
              disabled: false,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('accept', __props.conflictingParameterData[0])))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('common.accept')), 1)
              ]),
              _: 1
            }),
            _createVNode(BaseTooltip, {
              position: "left",
              class: "v-icon-tooltip",
              tooltipText: _unref(translate)(`parameter.manage_conflict`)
            }, {
              default: _withCtx(() => [
                _createVNode(BaseIcon, {
                  name: "nsx-font-icon--help",
                  size: "2x",
                  foregroundColor: "teal",
                  noHover: ""
                })
              ]),
              _: 1
            }, 8, ["tooltipText"]),
            _createVNode(BaseButton, {
              class: "v-close",
              buttonType: "secondary",
              size: "medium",
              disabled: false,
              onClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('common.close')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([props.conflictingParameterData[0], _unref(simplifyParameter)(props.parameter)], (parameter, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(InteractionSection, null, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getParameterType)(parameter)), {
                  parameter: parameter,
                  readOnly: true,
                  index: index,
                  clientId: (parameter.valueType == 'picture') ? _unref(currentInteraction).clientId : '',
                  iconUrl: (parameter.valueType == 'picture') ? _unref(currentInteraction).iconUrl : ''
                }, null, 8, ["parameter", "index", "clientId", "iconUrl"]))
              ]),
              _: 2
            }, 1024),
            (index === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createElementVNode("strong", null, _toDisplayString(`${_unref(translate)('common.saved_by_you')}: `), 1),
                    _createTextVNode(_toDisplayString(_unref(savedByYouDate)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["width"]))
}
}

})