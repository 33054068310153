import { valueToCamel } from '@/_shared/services/keysToCamel';
import { ClientLastSeen } from '@/_shared/store/personPreference';
import { formatDistanceToNowStrict } from 'date-fns';

export interface ChangeableEntity {
  changedAt?: string | null,
  changed_at?: string | null
}

export const getSectionChangedAt = (codenames: string[], dataPoints: Record<string, ChangeableEntity>) => {
  if (!dataPoints || typeof codenames !== 'object') { return null; }
  let changedAt: string | undefined;
  codenames.forEach((codename) => {
    const dataPoint = dataPoints[valueToCamel(codename)];
    if (dataPoint && dataPoint.changedAt && (!changedAt || changedAt < dataPoint.changedAt)) {
      changedAt = dataPoint.changedAt;
    }
  });
  return changedAt;
};

export const lastSeenBeforeUpdated = (section: ChangeableEntity, clientId: number, clientsLastSeen: ClientLastSeen[]) => {
  const datesToCompare = formatChangedAtAndClientLastSeen(section, clientId, clientsLastSeen);
  return datesToCompare.clientLastSeenDate
    < datesToCompare.sectionUpdatedAt;
};

export const formatChangedAtAndClientLastSeen = (section: ChangeableEntity, clientId: number, clientsLastSeen: ClientLastSeen[]) => {
  if (!section) { return { clientLastSeenDate: '', sectionUpdatedAt: '' }; }
  const clientLastSeen = clientsLastSeen.find((c) => c.id === clientId);
  const clientLastSeenDate = clientLastSeen && clientLastSeen.lastSeen ? new Date(clientLastSeen.lastSeen) : new Date(0);
  alwaysShowFor1Day(clientLastSeenDate);
  const changedAt = section.changedAt || section.changed_at;
  const sectionUpdatedAt = changedAt ? new Date(changedAt) : new Date();
  return { clientLastSeenDate, sectionUpdatedAt };
};

export const alwaysShowFor1Day = (date: Date) => {
  date.setDate(date.getDate() - 1);
};

export const getUpdatedAgoText = (section: ChangeableEntity) => {
  const changedAt = section ? section.changedAt || section.changed_at : '';
  const date = changedAt ? new Date(changedAt) : new Date();
  return formatDistanceToNowStrict(date, { addSuffix: true });
};
