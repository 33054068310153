import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-header-options sp-interaction-header-options" }
const _hoisted_2 = { class: "v-save-for-later-text" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { computed, inject, ref } from 'vue';
import use from '@/_shared/compositionApi';
import ConfirmationModal from '@/_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';
import { InteractionTimePicker } from '@/timeline/components';
import { NavigationPromise } from '@/_shared/router/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionHeaderOptions',
  props: {
  notToday: {
    type: Boolean,
    default: false,
  },
  saveForLaterVisible: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const currentInteractionStore = useCurrentInteractionStore();
const {
  hasSaveForLater,
  showSaveForLaterConfirmationModal,
  isCancelled,
  currentInteraction,
  saveForLaterTriggerSource,
} = storeToRefs(currentInteractionStore);

const { translate } = use.helpers();



const onSaveForLaterClick = () => {
  saveForLaterTriggerSource.value = 'default';
  showSaveForLaterConfirmationModal.value = true;
};

const modalTexts = computed(() => {
  const defaults = {
    title: translate('alert.confirm'),
    message: translate('timeline.interaction.confirm_save_for_later'),
    confirmText: translate('common.save'),
    cancelText: translate('alert.cancel'),
  };

  return saveForLaterTriggerSource.value === 'default' ? defaults : {
    title: translate('timeline.interaction.unsaved_changes.title'),
    message: translate('timeline.interaction.unsaved_changes.message'),
    confirmText: translate('timeline.interaction.unsaved_changes.confirm'),
    cancelText: translate('timeline.interaction.unsaved_changes.cancel'),
  };
});

const saveInteractionHandler = inject('saveInteractionHandler') as (state: string, skipRouting: boolean) => void;
const navigationPromise = inject('navigationPromise', ref(null));

const handleSaveInteraction = () => {
  showSaveForLaterConfirmationModal.value = false;
  if (saveForLaterTriggerSource.value === 'InteractionsRightPanelMain') {
    window.vueUnsavedMessage = null;
    resolveNavigationPromise(true);
    saveInteractionHandler(currentInteraction.value.state, true);
  } else {
    saveInteractionHandler(currentInteraction.value.state, false);
  }
};

const handleCloseModal = (closeSource: string) => {
  if (closeSource === 'close_button' && saveForLaterTriggerSource.value === 'InteractionsRightPanelMain') {
    window.vueUnsavedMessage = null;
    resolveNavigationPromise(true);
  } else {
    resolveNavigationPromise(false);
  }
  showSaveForLaterConfirmationModal.value = false;
};

const resolveNavigationPromise = (result: boolean) => {
  if (navigationPromise?.value) {
    (navigationPromise.value as NavigationPromise).resolve?.(result);
    navigationPromise.value = null;
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_unref(isCancelled))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["v-interaction-time-picker-wrapper circular-border-left", _unref(hasSaveForLater) ? '' : 'circular-border-right'])
          }, [
            _createVNode(_unref(InteractionTimePicker), {
              "custom-date": __props.notToday || __props.saveForLaterVisible,
              "show-additional-detail": ""
            }, null, 8, ["custom-date"])
          ], 2))
        : _createCommentVNode("", true),
      (_unref(hasSaveForLater))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "v-save-for-later circular-border-right",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onSaveForLaterClick()))
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(translate)('common.save_for_later')), 1),
            _createVNode(BaseIcon, {
              name: "nr-icon-pencil",
              size: "lg",
              "no-hover": "",
              "foreground-color": "white",
              compact: ""
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_unref(showSaveForLaterConfirmationModal))
      ? (_openBlock(), _createBlock(ConfirmationModal, {
          key: 0,
          "title-text": modalTexts.value.title,
          "first-row-text": modalTexts.value.message,
          "confirm-text": modalTexts.value.confirmText,
          "cancel-text": modalTexts.value.cancelText,
          onConfirm: _cache[1] || (_cache[1] = ($event: any) => (handleSaveInteraction())),
          onCloseModal: handleCloseModal
        }, null, 8, ["title-text", "first-row-text", "confirm-text", "cancel-text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})