import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-events-filter-dropdowns-container" }

import { computed, PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import BaseFilterDropdown
  from '@/_shared/components/general/BaseFilterDropdown.vue';
import {
  filterEventPWSInstances,
  instanceFilterOptions,
  instanceFilterStateOptions,
  instanceFilterOptionsWithCount,
} from '@/_shared/services/instanceFilterHelper';
import useTimelineFiltersStore from '@/_shared/store/timelineFilters';
import clientStore from '@/_shared/store/clients';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsFilter',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  instancesIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const timelineFiltersStore = useTimelineFiltersStore();
const { timelineFilters } = storeToRefs(timelineFiltersStore);
const { setTimelineFilters } = timelineFiltersStore;

const instances = computed(() => clientStore.eventPWSInstances(props.clientId).value);

const allInstances = computed(() => instances.value.filter(({ id }) => props.instancesIds.includes(id)));

const stateOptions = computed(() => {
  const values = allInstances.value.map(({ state }) => state);
  return instanceFilterStateOptions(values, 'events.sections');
});

const severityOptions = computed(() => {
  const values = Array.from(new Set(allInstances.value.map(({ metadata }) => metadata.severity)));
  return instanceFilterOptions(values, 'datasets');
});

const subTypeValues = computed(() => Array.from(new Set(allInstances.value.map((instance) => instance.subType))));

const filteredInstances = computed(() => (
  timelineFilters.value.severity.codename === 'all' && timelineFilters.value.state.codename === 'all'
    ? allInstances.value
    : allInstances.value.filter((instance) => filterEventPWSInstances(timelineFilters.value.severity.codename, timelineFilters.value.state.codename, instance))));

function getInstanceCount(subType: string) {
  return filteredInstances.value.filter((instance) => instance.subType === subType).length;
}

function getSubTypeOptions() {
  const options: DropdownFilterOption[] = [];
  subTypeValues.value.forEach((subType) => options.push({
    name: subType,
    codename: subType,
    count: getInstanceCount(subType),
  }));
  return instanceFilterOptionsWithCount(options, 'datasets');
}

const subTypeOptions = computed(() => getSubTypeOptions());


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseFilterDropdown, {
      currentOption: _unref(timelineFilters).state,
      allOptions: stateOptions.value,
      onValueChanged: _cache[0] || (_cache[0] = (option) => _unref(setTimelineFilters)({ state: option }))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      currentOption: _unref(timelineFilters).subType,
      allOptions: subTypeOptions.value,
      onValueChanged: _cache[1] || (_cache[1] = (option) => _unref(setTimelineFilters)({ subType: option }))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      currentOption: _unref(timelineFilters).severity,
      allOptions: severityOptions.value,
      onValueChanged: _cache[2] || (_cache[2] = (option) => _unref(setTimelineFilters)({ severity: option }))
    }, null, 8, ["currentOption", "allOptions"])
  ]))
}
}

})