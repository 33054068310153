import apiClient from '@/_shared/services/apiClient';
import CollectionStore from './collectionStore';
// TODO Move PhotoDetail, PhotoSize, and Photo to shared file
export type PhotoDetail = {
  url?: string;
  contentType?: unknown;
}
export type PhotoSize = 'default' | 'large' | 'medium' | 'small' | 'xsmall'
export type Photo = { [photoSize in PhotoSize]?: PhotoDetail }

export interface Person extends Record<string, unknown> {
  id: number;
  name: string;
  photo: Photo;
  role?: string;
  firstName?: string;
}

export type PersonQueryResponse = {
  people: Person[];
}

export class PersonStore<T extends Person = Person, QueryResponse extends PersonQueryResponse = PersonQueryResponse > extends CollectionStore<T, QueryResponse> {
  // TODO handle errors

  fetch(ids: number[]): Promise<QueryResponse> {
    return apiClient.post('/api/v2/people/query?only=id,name,photo,role', { query: { ids } });
  }

  extractData(data: QueryResponse): T[] {
    return data?.people as T[];
  }

  name = (id: number) => this.byId(id).name;

  names = (ids: number[]) => ids?.map((id) => this.name(id))
    .filter((name) => name)
    .join(', ');

  namesSorted = (ids: number[]) => ids?.map((id) => this.name(id))
    .filter((name) => name)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');

  photo = (id: number) => this.byId(id).photo;

  photos = (ids: number[]) => ids.map((id) => this.photo(id));

  people = (ids: number[]) => ids.map((id) => this.byId(id));
}
export const personStore = new PersonStore();
