import { computed, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { RiskAssesment } from '@/timeline/types/Need';
import useClientDashboardStore from './clientDashboards';
import { useClientDataPointsStore } from './dataPoints';
import use from '../compositionApi';
import type { OrganisationUnitState } from '../services/careplanApi';
import { fetchOrganisationUnitStates, memorizedHasEmergencyPackQrCode } from '../services/careplanApi';
import useUserStore from './user';
import { fetchClientStatusTerm } from '../services/clientStateOverride';
import { HealthProfile } from '../types/healthProfile';

const clientType = ref<string | undefined>();
const clientTypeName = ref<string | undefined>();
const highRisks = ref<RiskAssesment[] | undefined>();
const organisationUnitStates = ref<OrganisationUnitState[] | undefined>();
const hasEmergencyQrCode = ref<boolean | undefined>();
const biography = ref<string | undefined>();
const criticalInfos = ref<string[] | undefined>();
const fullName = ref<string>();
const healthProfile = ref<HealthProfile | undefined>();
const stateInOrg = ref<string | undefined>();
const birthDate = ref<string | undefined>();

const useResidentDashboardStore = defineStore('residentDashboardStore', () => {
  const clientDashboardStore = useClientDashboardStore();
  const { initialize: initializeDashboard } = clientDashboardStore;
  const { criticalInformationDashboard, dashboards } = storeToRefs(clientDashboardStore);

  const clientDataPointsStore = useClientDataPointsStore();
  const { setClient: initializeDataPoints } = clientDataPointsStore;
  const { clientDataPoints } = storeToRefs(clientDataPointsStore);

  const { clientStore } = use.store.clients();

  const userStore = useUserStore();
  const { currentOrgUnitId } = userStore;

  const initialize = async (clientId: number) => {
    initializeDataPoints(clientId);
    initializeDashboard();

    biography.value = clientStore.biography(clientId);
    clientType.value = clientStore.clientType(clientId);
    clientTypeName.value = clientStore.clientTypeName(clientId);
    highRisks.value = clientStore.highRisks(clientId);
    criticalInfos.value = clientStore.criticalInfos(clientId);
    fullName.value = clientStore.fullName(clientId).value;
    healthProfile.value = clientStore.healthProfile(clientId);
    stateInOrg.value = clientStore.stateInOrg(clientId);
    birthDate.value = clientStore.birthDate(clientId);

    if (!window.familyPortal) {
      organisationUnitStates.value = await fetchOrganisationUnitStates(clientId);
    }

    hasEmergencyQrCode.value = await memorizedHasEmergencyPackQrCode(clientId, currentOrgUnitId);
  };

  const clientOrganisationUnit = computed(() => {
    if (organisationUnitStates.value) {
      return organisationUnitStates.value.find((ous) => ous.organisationUnitId === currentOrgUnitId);
    }
    return undefined;
  });

  const clientStatus = computed(() => {
    if (clientOrganisationUnit.value) {
      return fetchClientStatusTerm(clientOrganisationUnit.value.state);
    }
    return undefined;
  });

  // Match moments functionality
  const dayDiff = (date1: number, date2: number) => (date1 - date2) / (1000 * 60 * 60 * 24);

  const onboardingDays = computed(() => {
    if (clientOrganisationUnit.value && clientStatus.value) {
      if (['Onboarding', 'Emergency Admission'].includes(clientStatus.value)) {
        const date = new Date(clientOrganisationUnit.value.updatedAt);
        date.setHours(0, 0, 0);
        const days = Math.ceil(Math.abs(dayDiff(date.getTime(), Date.now())));
        return `${clientStatus.value} (${days} ${days > 1 ? 'days' : 'day'})`;
      }
    }
    return undefined;
  });

  return {
    initialize,
    clientDataPoints,
    criticalInformationDashboard,
    dashboards,
    clientType,
    clientTypeName,
    clientStatus,
    highRisks,
    hasEmergencyQrCode,
    clientOrganisationUnit,
    onboardingDays,
    biography,
    criticalInfos,
    fullName,
    healthProfile,
    stateInOrg,
    birthDate,
  };
});

export default useResidentDashboardStore;
