import { Datapoint, DataPointValue } from '@/_shared/store/dataPoints';
import { isCoercedChoiceArray } from '@/_shared/helpers/dataPoints.utils';

export const isNotNullObject = (input?: unknown): input is object => typeof input === 'object' && input !== null;

const isStringArray = (input?: DataPointValue): input is string[] => Array.isArray(input) && (input as unknown[]).every((item): item is string => typeof item === 'string');
const isCoercedSelection = (input?: DataPointValue): input is DataPointValue & { values: (string | number)[] } => isNotNullObject(input) && 'values' in input;
const hasLabelWithValue = (input?: DataPointValue): input is DataPointValue & { label: string } => isNotNullObject(input) && 'label' in input && input.label !== 'None';

export const getDataPointValues = (value?: DataPointValue): (string | number)[] => {
  if (isStringArray(value)) {
    return value;
  } if (isCoercedSelection(value)) {
    return value.values;
  } if (isCoercedChoiceArray(value)) {
    return value.filter((v) => !!v.value).map((v) => v.value!);
  } if (hasLabelWithValue(value)) {
    return [value.label];
  }
  return [];
};

export const filterDataPoints = (dataPoints: Record<string, Datapoint>, list: string[]): false | (string | number)[] => {
  if (dataPoints) {
    const result: (string | number)[] = [];
    let negativeAnswer = false;
    let dataPointFilledBefore = false;
    Object.entries(dataPoints)
      .filter(([key]) => list.includes(camelToSnakeCase(key)))
      .forEach(([, dataPoint]) => {
        if (typeof dataPoint !== 'string') {
          dataPointFilledBefore = true;
          result.push(...getDataPointValues(dataPoint.value));
        } else {
          negativeAnswer = true;
        }
      });
    if (result.length) {
      return result.sort();
    }
    if (negativeAnswer || dataPointFilledBefore) {
      return [];
    }
    return false;
  }
  return [];
};

export const camelToSnakeCase = (str: string) => {
  if (!str) return '';
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const getHighRiskChangedAt = (highRisks?: { updatedAt: string | null }[]) => {
  if (!highRisks || highRisks.length === 0) { return undefined; }
  let updatedAt = new Date(0);
  highRisks.forEach((highRisk) => {
    if (highRisk.updatedAt && new Date(highRisk.updatedAt) > new Date(updatedAt)) {
      updatedAt = new Date(highRisk.updatedAt);
    }
  });
  return updatedAt.toString();
};

export const getClientTypeCodename = (clientType?: string, clientTypeName?: string, clientTypeCodename?: string) => clientType || clientTypeName?.toLowerCase()?.replace(' ', '_') || clientTypeCodename || '';

export const shouldShowDashboard = (clientType?: string, clientTypeName?: string, clientTypeCodename?: string) => {
  const codeName = getClientTypeCodename(clientType, clientTypeName, clientTypeCodename);
  return !['care_template', 'service_management'].includes(codeName);
};
