import apiClient from '@/_shared/services/apiClient';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { Library } from '@/_shared/types/library';
import { Client } from '@/_shared/types/client';
import ClientType from '../types/client_type';

export interface ClientTypeWithClients extends ClientType {
  clients: Client[]
}

export const fetchClientTypes = async (orgId: number, forAdmin = true) => {
  const url = `organisations/${orgId}/client_types`;
  const params = {} as { for_admin?: boolean };
  if (forAdmin) params.for_admin = true;
  try {
    return await apiClient.get<ClientType[]>(url, { params, rootNode: 'clientTypes' });
  } catch (error) {
    return [] as ClientType[];
  }
};

export const fetchClientType = async (id: number, orgId: number): Promise<ClientTypeWithClients> => {
  const url = `organisations/${orgId}/client_types/${id}`;
  try {
    const config = { params: { for_admin: true }, rootNode: 'clientType' };
    return await apiClient.get<ClientTypeWithClients>(url, config);
  } catch (error) {
    return {} as ClientTypeWithClients;
  }
};

export const updateClientType = async (clientType: ClientType, orgId: number): Promise<boolean> => {
  const url = `organisations/${orgId}/client_types/${clientType.id}`;
  try {
    const data = { client_type: clientType };
    await apiClient.put<ClientType>(url, data);
    return true;
  } catch (error) {
    return false;
  }
};

export const createClientType = async (clientType: ClientType, orgId: number) => {
  const url = `organisations/${orgId}/client_types`;

  try {
    return await apiClient.post<ClientType>(url, { client_type: clientType }, { rootNode: 'clientType' });
  } catch (error) {
    return null;
  }
};

export const deleteClientType = async (clientTypeId: number, orgId: number): Promise<boolean> => {
  const url = `organisations/${orgId}/client_types/${clientTypeId}`;

  try {
    await apiClient.delete<ClientType>(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const organisationUnitsForClientTypes = async () => {
  try {
    return await apiClient.get<OrganisationUnit[]>('organisation_units', { rootNode: 'organisationUnits' });
  } catch (error) {
    return [] as OrganisationUnit[];
  }
};

export const librariesForClientTypes = async (orgId: number) => {
  try {
    return await apiClient.get<Library[]>(`organisations/${orgId}/client_types/libraries_for_client_types`);
  } catch (error) {
    return [{ name: '', codename: '' }];
  }
};

export type ClientTypeServices = {
  idsNotFromLibraries: number[]
  [key:string]: number[];
}

export const servicesForClientTypes = async (orgId: number) => {
  try {
    return await apiClient.get<ClientTypeServices>(`organisations/${orgId}/client_types/libraries`);
  } catch (error) {
    return {} as ClientTypeServices;
  }
};

export const assignToClientType = async (clientTypeId: number, clientsIds: Array<number>, orgId: number, orgUnitId: number) => {
  const url = `organisations/${orgId}/client_types/${clientTypeId}/assign`;
  try {
    return await apiClient.patch<ClientType>(url, { clients: [{ ou_id: orgUnitId, client_ids: clientsIds }] });
  } catch (error) {
    return false;
  }
};
