import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-reporting-period" }
const _hoisted_2 = { class: "v-reporting-period-checkboxes" }
const _hoisted_3 = { class: "v-reporting-period-date" }

import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import { BaseDatePickerInput } from '@/_shared/components';
import { CompositeOption } from '@/_shared/types/baseSelect';
import {
  computed, PropType, ref, watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import { subDays, subMonths } from 'date-fns';
import { DateRangePreset } from '@/_shared/types/dateInput';
import useReportDateHelper from '@/reports/helpers/useReportDateHelper';

type ReportingPeriodOptions = typeof props.reportingPeriodOption | 'select_date'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportingPeriod',
  props: {
  reportingPeriodOption: {
    type: String as PropType<'lastUpdated' | 'lastCompleted'>,
    required: true,
  },
},
  setup(__props) {

const report = storeToRefs(useReportStore()).newReport;

const { translate } = use.helpers();

const { dateRange, getDefaultDateRange } = useReportDateHelper();

const props = __props;

const reportingPeriodOptions: CompositeOption<ReportingPeriodOptions>[] = [
  { value: 'select_date', text: translate('reports.reporting_period.select_date') },
  { value: props.reportingPeriodOption, text: translate(`reports.reporting_period.${props.reportingPeriodOption}`) },
];

const reportingPeriod = ref<ReportingPeriodOptions>('select_date');

const customDatePresets: DateRangePreset[] = [
  { label: translate('inputs.timepicker.today'), value: [new Date(), new Date()] },
  {
    label: translate('inputs.timepicker.last_7_days'),
    value: [subDays(new Date(), 7), subDays(new Date(), 1)],
  },
  { label: translate('inputs.timepicker.last_month'), value: [subMonths(new Date(), 1), subDays(new Date(), 1)] },
  { label: translate('inputs.timepicker.last_3_months'), value: [subMonths(new Date(), 3), subDays(new Date(), 1)] },
];

const customPeriodSelected = computed(() => reportingPeriod.value === props.reportingPeriodOption);

watch(reportingPeriod, () => {
  if (customPeriodSelected.value) {
    report.value.params[props.reportingPeriodOption] = true;
    dateRange.value = getDefaultDateRange();
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(BaseCheckboxGroup, {
        label: _unref(translate)('reports.filters.date_range'),
        options: reportingPeriodOptions,
        modelValue: reportingPeriod.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reportingPeriod).value = $event)),
        "standard-input-style": "",
        "bold-label": "",
        "column-display": ""
      }, null, 8, ["label", "modelValue"])
    ]),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_unref(BaseDatePickerInput), {
        modelValue: _unref(dateRange),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dateRange) ? (dateRange).value = $event : null)),
        range: "",
        "show-presets": "",
        "custom-presets": customDatePresets,
        "standard-input-style": "",
        "read-only": customPeriodSelected.value,
        "input-width": "300px"
      }, null, 8, ["modelValue", "read-only"])
    ])
  ]))
}
}

})