import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import {
  computed, PropType,
} from 'vue';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import { ReportFormat } from '@/_shared/types/report';
import { CompositeOption } from '@/_shared/types/baseSelect';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportFormatInput',
  props: {
  formats: {
    type: Array as PropType<ReportFormat[]>,
    required: true,
  },
},
  setup(__props) {


const formatMap: Record<ReportFormat, string> = {
  html: 'HTML',
  pdf: 'PDF',
  excel: 'Excel',
  csv: 'CSV',
};

const props = __props;

const { newReport } = useReportStore();

const formatOptions = computed(() => {
  const options: CompositeOption[] = [];
  props.formats.forEach((format) => {
    options.push({ value: format, text: formatMap[format] });
  });
  return options;
});

const { translate } = use.helpers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCheckboxGroup, {
    modelValue: _unref(newReport).format,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).format) = $event)),
    options: formatOptions.value,
    label: _unref(translate)('reports.format'),
    "standard-input-style": ""
  }, null, 8, ["modelValue", "options", "label"]))
}
}

})