import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "v-message-wrapper-container" }
const _hoisted_2 = { class: "v-message-wrapper" }
const _hoisted_3 = { class: "v-single-panel-header" }
const _hoisted_4 = {
  key: 0,
  class: "pull-right"
}
const _hoisted_5 = { class: "v-single-panel-body body" }
const _hoisted_6 = { class: "messages-editor-footer" }
const _hoisted_7 = { class: "v-message-action sp-print-message" }
const _hoisted_8 = { class: "v-message-action sp-archive-message" }
const _hoisted_9 = { class: "v-message-action sp-mark-unread" }

import { storeToRefs } from 'pinia';
import { computed, onBeforeMount } from 'vue';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import { BaseIcon, DropdownButton, BaseButton } from '@/_shared/components';
import useMessagesStore from '@/messages/store/messagesStore';
import vBaseTooltip from '@/_shared/directives/BaseTooltip';
import { useRouter } from 'vue-router';
import MessageBody from './MessageBody.vue';
import { createNewMessage } from '../types/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageDetails',
  props: {
  messageId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const router = useRouter();

const { currentUser } = storeToRefs(useUserStore());
const { openedMessage } = storeToRefs(useMessagesStore());
const {
  toggleRead, toggleArchive, createMessage, closeDetail,
} = useMessagesStore();

const { translate } = use.helpers();

const isInbox = computed(() => !openedMessage.value?.fromMe);
const archiveStatusButtonLabel = computed(() => (openedMessage.value?.archived ? 'Unarchive' : 'Archive'));

onBeforeMount(() => {
  const message = useMessagesStore().getMessageById(+props.messageId);
  if (message) {
    useMessagesStore().openMessage(message);
    return;
  }
  router.push({ name: 'messages' });
});

const print = () => {
  window.print();
  return false;
};
const reply = async (toAll = false) => {
  const senderId = openedMessage.value?.senderId as number;
  const replyMessage = createNewMessage(currentUser.value.id);
  if (toAll) {
    const ids = new Set(openedMessage.value?.receiverIds);
    ids.delete(currentUser.value.id);
    ids.add(senderId);
    replyMessage.receiverIds = Array.from(ids);
    replyMessage.recipientIds = Array.from(ids);
  } else {
    replyMessage.receiverIds = [senderId];
    replyMessage.recipientIds = [senderId];
  }
  const subject = openedMessage.value?.subject || '';
  replyMessage.subject = subject.startsWith(translate('message.re'))
    ? subject
    : `${translate('message.re')} ${subject}`;
  replyMessage.body = `\n\n---------------------------------------------------\n${openedMessage.value?.body}`;
  await createMessage(replyMessage);
  router.push({ name: 'messagesNew' });
};

const goBackToMessages = () => {
  const state = parseInt(`${+!!openedMessage.value?.isSent}${+!!openedMessage.value?.archived}`, 2);
  closeDetail();
  switch (state) {
    case 1: router.push({ path: '/messages/archived_inbox' }); break;
    case 2: router.push({ path: '/messages/sent' }); break;
    case 3: router.push({ path: '/messages/archived_sent' }); break;
    default: router.push({ name: 'messages' });
  }
};
const makeUnread = () => {
  if (openedMessage.value) {
    toggleRead(openedMessage.value);
    router.push({ name: 'messages' });
  }
};
const markArchived = () => {
  if (openedMessage.value) {
    toggleArchive(openedMessage.value);
    router.push({ name: 'messages' });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BaseIcon), {
          transparent: "",
          onClick: goBackToMessages,
          name: "chevron-left",
          align: "left",
          size: "lg"
        }),
        (isInbox.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_unref(openedMessage)?.receiverIds && _unref(openedMessage).receiverIds.length > 1)
                ? (_openBlock(), _createBlock(_unref(DropdownButton), {
                    key: 0,
                    defaultLabel: _unref(translate)("message.reply"),
                    onOptionClicked: _cache[0] || (_cache[0] = (id)=> {reply(id===2)}),
                    buttonType: "secondary",
                    size: "small",
                    options: [
               {id: 1, label: _unref(translate)("message.reply")},
               {id: 2, label: _unref(translate)("message.reply_all")}]
                  }, null, 8, ["defaultLabel", "options"]))
                : (_openBlock(), _createBlock(_unref(BaseButton), {
                    key: 1,
                    class: "v-reply-1",
                    onClicked: _cache[1] || (_cache[1] = ($event: any) => (reply(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)('message.reply')), 1)
                    ]),
                    _: 1
                  }))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_unref(openedMessage))
          ? (_openBlock(), _createBlock(MessageBody, {
              key: 0,
              message: _unref(openedMessage),
              "with-hover": false
            }, null, 8, ["message"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _withDirectives(_createVNode(_unref(BaseIcon), {
              name: "print",
              onClick: print,
              size: "lg",
              transparent: "",
              "foreground-hover-color": "black",
              "foreground-color": "grey"
            }, null, 512), [
              [_unref(vBaseTooltip), {position: 'top', tooltipText: 'Print'}]
            ])
          ]),
          _createElementVNode("span", _hoisted_8, [
            _withDirectives(_createVNode(_unref(BaseIcon), {
              name: "archive",
              onClick: markArchived,
              size: "lg",
              transparent: "",
              "foreground-hover-color": "black",
              "foreground-color": "grey"
            }, null, 512), [
              [_unref(vBaseTooltip), {position: 'top', tooltipText: archiveStatusButtonLabel.value}]
            ])
          ]),
          _createElementVNode("span", _hoisted_9, [
            (isInbox.value)
              ? _withDirectives((_openBlock(), _createBlock(_unref(BaseIcon), {
                  key: 0,
                  name: "eye-slash",
                  onClick: makeUnread,
                  size: "lg",
                  transparent: "",
                  "foreground-hover-color": "black",
                  "foreground-color": "grey"
                }, null, 512)), [
                  [_unref(vBaseTooltip), {position: 'top', tooltipText: 'Mark as unread'}]
                ])
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})