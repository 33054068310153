import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-content"
}
const _hoisted_2 = {
  key: 1,
  class: "v-notifications-list"
}
const _hoisted_3 = {
  key: 2,
  class: "v-no-notifications-title"
}

import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import use from '@/_shared/compositionApi';
import useNotificationsStore from '@/_shared/store/notifications';
import { BaseAlert } from '@/_shared/components';
import type { NotificationConfiguration } from '@/notifications/types/NotificationConfiguration';

import NotificationsListItem from './NotificationsListItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsList',
  setup(__props) {

const { translate } = use.helpers();
const notificationsStore = useNotificationsStore();
const { notificationsLoaded } = storeToRefs(notificationsStore);
const { configurations } = storeToRefs(notificationsStore);
const loaded = computed(() => notificationsLoaded.value);
const currentSelectedNotificationConfiguration = ref({} as NotificationConfiguration);
const isArchiveAlertOpen = ref(false);

const deleteEntryConfirmToggle = (notificationConfiguration: NotificationConfiguration) => {
  isArchiveAlertOpen.value = !isArchiveAlertOpen.value;
  currentSelectedNotificationConfiguration.value = notificationConfiguration;
};

const deleteNotificationConfiguration = async () => {
  await notificationsStore.deleteConfiguration(currentSelectedNotificationConfiguration.value);
  isArchiveAlertOpen.value = false;
};


return (_ctx: any,_cache: any) => {
  return (loaded.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (isArchiveAlertOpen.value)
          ? (_openBlock(), _createBlock(_unref(BaseAlert), {
              key: 0,
              dangerAlert: false,
              icon: "info",
              onConfirmAlert: deleteNotificationConfiguration,
              onCancelAlert: deleteEntryConfirmToggle,
              confirmText: _unref(translate)('common.archive')
            }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('notifications.alerts.delete_confirm')), 1)
              ]),
              _: 1
            }, 8, ["confirmText"]))
          : _createCommentVNode("", true),
        (_unref(configurations).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(configurations), (notificationConfiguration) => {
                return (_openBlock(), _createBlock(NotificationsListItem, {
                  key: notificationConfiguration.id,
                  notificationConfiguration: notificationConfiguration,
                  onDeleteEntry: deleteEntryConfirmToggle
                }, null, 8, ["notificationConfiguration"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(translate)('notifications.no_notifications_configured')), 1))
      ]))
    : _createCommentVNode("", true)
}
}

})