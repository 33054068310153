import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-bio" }
const _hoisted_2 = {
  key: 0,
  class: "v-bio-text"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "v-bio-text"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import use from '@/_shared/compositionApi';
import { computed, ref } from 'vue';

const BIO_LIMIT_LENGTH = 140;


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientBiography',
  props: {
  biography: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const isOpen = ref<boolean>(false);

const toggleIsOpen = () => (isOpen.value = !isOpen.value);

const hasShowMoreButton = computed(() => props.biography.length > BIO_LIMIT_LENGTH);

const biographyText = computed(() => (isOpen.value ? props.biography : props.biography.slice(0, BIO_LIMIT_LENGTH)));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, [
      (!isOpen.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(biographyText.value), 1),
            (hasShowMoreButton.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "..."))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(biographyText.value), 1))
        : _createCommentVNode("", true),
      (hasShowMoreButton.value)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "v-bio-button",
            onClick: toggleIsOpen
          }, [
            (!isOpen.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(translate)('critical_information.show_text')), 1))
              : _createCommentVNode("", true),
            (isOpen.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(translate)('critical_information.hide_text')), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})