import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { BaseSelect } from '@/_shared/components';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import { providedServicesStore } from '@/_shared/store/providedServices';

// TODO: Get interactions

export default /*@__PURE__*/_defineComponent({
  __name: 'ReportInteractionInput',
  setup(__props) {

const interactions = computed(() => providedServicesStore.sortByStateAndName.value.map((interaction) => ({
  value: interaction.id,
  text: `${interaction.displayName} - ${interaction.deletedAt ? translate('reports.filters.interaction_states.disabled') : translate('reports.filters.interaction_states.active')}`,
})));
const { newReport } = useReportStore();

const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseSelect), {
    label: _unref(translate)('reports.filters.interactions'),
    modelValue: _unref(newReport).params.providedServiceIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.providedServiceIds) = $event)),
    options: interactions.value,
    "standard-input-style": "",
    placeholder: _unref(translate)('common.all'),
    multiple: "",
    searchable: ""
  }, null, 8, ["label", "modelValue", "options", "placeholder"]))
}
}

})