import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container sp-image-gallery-container" }
const _hoisted_2 = { class: "close-btn" }
const _hoisted_3 = { class: "angle-left" }
const _hoisted_4 = { class: "angle-right" }
const _hoisted_5 = { class: "image-panel" }
const _hoisted_6 = { class: "info-wrapper" }
const _hoisted_7 = { class: "padded-text interaction-name" }
const _hoisted_8 = { class: "padded-text" }
const _hoisted_9 = { class: "padded-text" }
const _hoisted_10 = { class: "image-taken" }
const _hoisted_11 = { class: "action-tools" }
const _hoisted_12 = { class: "row search-row" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = ["href", "download"]
const _hoisted_15 = { class: "hide" }
const _hoisted_16 = { class: "image-container" }
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = { class: "footer" }

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import {
  ref, Ref, computed,
} from 'vue';
import usePanzoom from '@/_shared/Composables/usePanzoom';
import use from '@/_shared/compositionApi';
import { BaseButton } from '@/_shared/components';
import { vBaseTooltip } from '@/_shared/directives';
import { format } from 'date-fns';
import { print, Image } from './imageGalleryModalHelper';

const MIN_SCALE = 1;
const MAX_SCALE = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageGalleryModal',
  props: {
  images: {
    type: Array as () => Image[],
    required: true,
  },
  picturePosition: {
    type: Number,
    default: 0,
  },
  showInfo: {
    type: Boolean,
    default: true,
  },
  galleryInteraction: {
    type: Object,
  },
},
  emits: ['closeImageGalleryModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const { translate } = use.helpers();

const infoTextElem = ref<HTMLElement | null>(null);
const panzoomElem = ref<HTMLElement | null>(null);
const currentIndex = ref(props.picturePosition);
const panzoomOptions = {
  panOnlyWhenZoomed: true,
  contain: 'outside',
};

const {
  zoomScale, zoomIn, zoomOut, panzoom,
} = usePanzoom(panzoomElem as Ref<HTMLElement | null>, MIN_SCALE, MAX_SCALE, 0.5, panzoomOptions);

const currentImage = computed(() => props.images
  .find((image) => image.position === currentIndex.value));

const leftAngleDisabled = computed(() => currentIndex.value === 0);
const rightAngleDisabled = computed(() => currentIndex.value === props.images.length - 1);

const nextImage = () => {
  currentIndex.value = currentIndex.value === props.images.length - 1 ? currentIndex.value : currentIndex.value + 1;
  panzoom.value?.reset({ animate: false });
};

const prevImage = () => {
  currentIndex.value = currentIndex.value === 0 ? currentIndex.value : currentIndex.value - 1;
  panzoom.value?.reset({ animate: false });
};

const formatDateTime = (date: string) => {
  if (!date) return '';
  return format(Date.parse(date), 'dd/MM/yyyy, HH:mm');
};

const closedByText = computed(() => `${translate('timeline.actions.closedBy')}: ${props.galleryInteraction?.closerName}`);
const photoTakenText = computed(() => `${translate('timeline.parameters.picture.photoTaken')}: ${currentImage.value?.dateTime}`);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    header: false,
    footer: false,
    width: 800,
    onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (emit('closeImageGalleryModal')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(BaseIcon, {
            transparent: "",
            name: "nsx-font-icon--cancel",
            foregroundColor: "black",
            size: "2x",
            compact: "",
            "foreground-hover-color": "grey",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeImageGalleryModal')))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BaseIcon, {
            name: "angle-left",
            disabled: leftAngleDisabled.value,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (prevImage())),
            "no-hover-background": "",
            "foreground-hover-color": "grey",
            size: "3x",
            compact: ""
          }, null, 8, ["disabled"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(BaseIcon, {
            name: "angle-right",
            disabled: rightAngleDisabled.value,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (nextImage())),
            "no-hover-background": "",
            "foreground-hover-color": "grey",
            size: "3x",
            compact: ""
          }, null, 8, ["disabled"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (props.showInfo)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "info-text",
                  ref_key: "infoTextElem",
                  ref: infoTextElem
                }, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(__props.galleryInteraction?.name), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(formatDateTime(__props.galleryInteraction?.createdAt as string)), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(closedByText.value), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(photoTakenText.value), 1)
                ], 512))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createVNode(BaseIcon, {
                name: "search-minus",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(zoomOut)())),
                disabled: _unref(zoomScale) <= MIN_SCALE,
                "no-hover-background": "",
                "foreground-hover-color": "grey",
                size: "2x",
                compact: ""
              }, null, 8, ["disabled"]), [
                [_unref(vBaseTooltip), { position: 'bottom', tooltipText: _unref(translate)('timeline.parameters.picture.zoomOut') }]
              ]),
              _withDirectives(_createVNode(BaseIcon, {
                name: "search-plus",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(zoomIn)())),
                disabled: _unref(zoomScale) >= MAX_SCALE,
                "no-hover-background": "",
                "foreground-hover-color": "grey",
                size: "2x",
                compact: ""
              }, null, 8, ["disabled"]), [
                [_unref(vBaseTooltip), { position: 'bottom', tooltipText: _unref(translate)('timeline.parameters.picture.zoomIn') }]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createVNode(BaseIcon, {
                name: "print",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(print)(currentImage.value?.src as string, infoTextElem.value as HTMLElement, currentImage.value?.fileName as string))),
                "no-hover-background": "",
                "foreground-hover-color": "grey",
                size: "2x",
                compact: ""
              }, null, 512), [
                [_unref(vBaseTooltip), { position: 'bottom', tooltipText: _unref(translate)('common.print') }]
              ]),
              _withDirectives((_openBlock(), _createElementBlock("a", {
                href: currentImage.value?.src,
                download: currentImage.value?.fileName
              }, [
                _createVNode(BaseIcon, {
                  class: "v-download-button",
                  name: "download",
                  "no-hover-background": "",
                  "foreground-hover-color": "grey",
                  size: "2x",
                  compact: ""
                }),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(translate)('common.download')), 1)
              ], 8, _hoisted_14)), [
                [_unref(vBaseTooltip), { position: 'bottom', tooltipText: _unref(translate)('common.download') }]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", {
            class: "panzoom-elem",
            ref_key: "panzoomElem",
            ref: panzoomElem
          }, [
            _createElementVNode("img", {
              class: _normalizeClass({ 'grab-cursor': _unref(zoomScale) > MIN_SCALE }),
              src: currentImage.value?.src,
              alt: currentImage.value?.fileName
            }, null, 10, _hoisted_17)
          ], 512)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_unref(BaseButton), {
            "button-type": "secondary",
            size: "medium",
            disabled: false,
            onClicked: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closeImageGalleryModal')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(translate)('alert.close')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})