import apiClient from '@/_shared/services/apiClient';
import {
  BodyMapDefaultLocations,
  NourishInstance, SkinInstance, SkinInstanceLocationInfo,
} from '@/_shared/types/NourishInstance';
import { GalleryViewInfo, GalleryViewInteraction } from '@/_shared/types/galleryViewInfo';

type InstanceApiResponse = {
  nourishInstances: SkinInstance[]
}

export type ClientInstances = {
  id: number;
  instances: NourishInstance[];
}

type BodyMapLocationsApiResponse = {
  bodyMapDefaultLocations: BodyMapDefaultLocations
}

export const fetchInstances = async (clientId: number): Promise<ClientInstances> => {
  const path = `/api/v2/clients/${clientId}/nourish_instances`;
  try {
    return apiClient.get<InstanceApiResponse>(path, { params: { include_location_info: true, include_start_interaction: true } }).then((result) => {
      const instances = result.nourishInstances;
      instances.sort(compareInstances);
      const skinInstances = instances.filter((i) => i.instanceType === 'skin');
      addCountToInstances(skinInstances);
      return { id: clientId, instances };
    });
  } catch (errorsResponse) {
    return { id: clientId, instances: [] };
  }
};

export const getInteractionsWithImages = async (clientId: number, instanceId: number): Promise<GalleryViewInteraction[]> => {
  const path = `/api/v2/clients/${clientId}/nourish_instances/${instanceId}/image_gallery/interactions`;
  try {
    return apiClient.get<GalleryViewInteraction[]>(path, { params: { include_location_info: true } });
  } catch (errorsResponse) {
    console.log(errorsResponse);
    return [];
  }
};

export const getInstanceTimeRange = async (clientId: number, instanceId: number): Promise<Partial<GalleryViewInfo>> => {
  const path = `/api/v2/clients/${clientId}/nourish_instances/${instanceId}/image_gallery/instance_time_range`;
  try {
    return apiClient.get<Partial<GalleryViewInfo>>(path);
  } catch (errorsResponse) {
    console.log(errorsResponse);
    return {};
  }
};

export const fetchBodyMapDefaultLocations = async (): Promise<BodyMapDefaultLocations> => {
  const path = '/api/v2/body_map_locations';
  try {
    return (await apiClient.get<BodyMapLocationsApiResponse>(path)).bodyMapDefaultLocations;
  } catch (errorsResponse) {
    return {} as BodyMapDefaultLocations;
  }
};
export const fetchInstancesById = async (instanceIds: number[]): Promise<NourishInstance[]> => {
  const path = '/api/v2/nourish_instances/query';
  try {
    return (await apiClient.post<InstanceApiResponse>(path, { ids: instanceIds })).nourishInstances;
  } catch (errorsResponse) {
    return [];
  }
};

const compareInstances = (a: NourishInstance, b: NourishInstance) => {
  const stateToValue: Record<string, number> = {
    new: 1,
    active: 2,
    archived: 3,
  };

  const getStateValue = (state: string) => {
    if (stateToValue[state]) {
      return stateToValue[state];
    }
    return 4;
  };

  const stateDifference = getStateValue(a.state) - getStateValue(b.state);
  const timeDifference = (a.stateTime < b.stateTime ? 1 : -1);
  return stateDifference === 0 ? timeDifference : stateDifference;
};

const addCountToInstances = (instances: SkinInstance[]) => {
  const locationToCount: Record<string, number> = {};

  instances.forEach((instance) => {
    if (instance.locationInfo) {
      let instancePositionKey = '';
      if (instance.locationInfo.position) instancePositionKey = instance.locationInfo.position.x.toString() + instance.locationInfo.position.y.toString();
      if (!(instancePositionKey in locationToCount)) locationToCount[instancePositionKey] = Object.keys(locationToCount).length + 1;
      instance.locationInfo.count = locationToCount[instancePositionKey];
    } else {
      instance.locationInfo = {} as SkinInstanceLocationInfo;
    }
  });
};
