import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, isRef as _isRef, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "v-action-bar-options" }
const _hoisted_2 = {
  key: 0,
  class: "v-lp-title"
}
const _hoisted_3 = {
  key: 1,
  class: "v-date-picker"
}
const _hoisted_4 = {
  key: 0,
  class: "v-body-map"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "v-filter-title"
}
const _hoisted_7 = {
  key: 0,
  class: "v-filter-text"
}
const _hoisted_8 = { class: "v-search-input-field" }
const _hoisted_9 = {
  key: 4,
  class: "v-timeline-controls sp-timeline-controls"
}

import { BaseIcon, BaseDatePickerInput } from '@/_shared/components';
import { format } from 'date-fns';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import use from '@/_shared/compositionApi';
import {
  PropType, ref, watch, computed, watchEffect,
} from 'vue';
import {
  onBeforeRouteLeave,
} from 'vue-router';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import { watchDebounced } from '@vueuse/core';
import useUserStore from '@/_shared/store/user';
import useTimelineFiltersStore from '@/_shared/store/timelineFilters';
import { storeToRefs } from 'pinia';
import LeftPanelDropdownList from './LeftPanelDropdownList.vue';
import vClickOutside from '../../../directives/ClickOutside';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftPanelActionBar',
  props: {
  currentDate: {
    type: Date,
    required: false,
    default: new Date(),
  },
  datePickerDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  selectedFilterLabel: {
    type: String,
    required: false,
  },
  filterOptions: {
    type: Array as PropType<DropdownOption[]>,
  },
  timelineControlsOptions: {
    type: Array as PropType<DropdownOption[]>,
  },
  showBodyMapButton: {
    type: Boolean,
    default: false,
  },
  instanceView: {
    type: Boolean,
    default: false,
  },
  filterText: {
    type: String,
    default: '',
  },
  isInstanceView: {
    type: Boolean,
    default: false,
  },
  searchDisabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
},
  emits: [
  'searchTerm',
  'updatedFilterOption',
  'updatedDate',
  'toggleBodyMap',
  'toggleInstanceView',
  'timelineControlsOptionSelected',
],
  setup(__props, { expose: __expose, emit: __emit }) {

__expose({ closeSearchHandler });

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const timelineFiltersStore = useTimelineFiltersStore();
const { isTimelineFilterOpen, timelineSearchTerm, timelineSearchOpen } = storeToRefs(timelineFiltersStore);
const { toggleTimelineFilter } = timelineFiltersStore;
const { featureToggles } = storeToRefs(useUserStore());

// Date picker
const selectedDate = ref(new Date(props.currentDate));

// TODO: this is temporary solution to update datepicker date on manual route date change, this whole datepicker logic should be refactored
watch(() => props.currentDate, (newValue) => {
  selectedDate.value = new Date(newValue);
});

const setPreviousDay = () => {
  const previous = new Date(selectedDate.value);
  selectedDate.value = new Date(previous.setDate(previous.getDate() - 1));
};

const setNextDay = () => {
  const previous = new Date(selectedDate.value);
  selectedDate.value = new Date(previous.setDate(previous.getDate() + 1));
};

const dateValue = computed(() => new Date(selectedDate.value).getTime());

watchDebounced(dateValue, (newValue) => {
  emit('updatedDate', newValue);
}, { debounce: 300 });

function setDateFormat() {
  return format(new Date(selectedDate.value), 'EEE dd MMM');
}

// Filter
const filterDropdownVisible = ref(false);
const filterOptions = computed(() => props.filterOptions || []);
const optionName = ref(filterOptions.value.length ? (props.selectedFilterLabel || filterOptions.value[0].label) : '');
watchEffect(() => {
  optionName.value = props.selectedFilterLabel || filterOptions.value[0]?.label;
});

function filterOptionClicked(option: DropdownOption) {
  optionName.value = option.label as string;
  emit('updatedFilterOption', option.codename);
  filterDropdownVisible.value = false;
}

const clickFilterButton = () => {
  if (props.instanceView) {
    toggleTimelineFilter();
  }
  filterDropdownVisible.value = !filterDropdownVisible.value;
};

const filterOptionsPresent = computed(() => filterOptions.value && filterOptions.value.length);

// Search
watchDebounced(timelineSearchTerm, (newValue) => {
  emit('searchTerm', newValue);
  timelineSearchTerm.value = newValue;
}, { debounce: 300 });

function openSearchHandler() { timelineSearchOpen.value = true; }

function closeSearchHandler() {
  useTimelineFiltersStore().$resetSearch();
}

// Bulk close
const timelineControlsDropdownVisible = ref(false);

const timelineControlsOptionClicked = (option: DropdownOption) => {
  timelineControlsDropdownVisible.value = false;
  emit('timelineControlsOptionSelected', option);
};

const isFutureDate = computed(() => {
  const today = new Date().setHours(0, 0, 0, 0);
  return new Date(selectedDate.value).setHours(0, 0, 0, 0) > today;
});

const timelineControlsPresent = computed(() => props.timelineControlsOptions && props.timelineControlsOptions.length);

const showFilter = computed(() => (filterOptionsPresent.value || props.instanceView) && !props.isInstanceView);

const searchStyle = computed(() => ({
  flexGrow: showFilter.value ? 0 : 1,
}));

onBeforeRouteLeave(async (to, from) => {
  const toWhere = to.name === 'client.timeline'
    || to.name === 'client.events.instance'
    || to.name === 'client.timeline.body_map.instance';
  const toInteraction = to.name === 'client.timeline.interaction';
  const fromInteraction = from.name === 'client.timeline.interaction' && toWhere;
  if (!toInteraction && !fromInteraction) {
    closeSearchHandler();
    emit('searchTerm', '');
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["v-left-column", { 'flex-hide': __props.instanceView }])
    }, [
      (__props.datePickerDisabled && __props.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.title), 1))
        : _createCommentVNode("", true),
      (!(_unref(timelineSearchOpen) || __props.datePickerDisabled || __props.instanceView))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, [
              _createElementVNode("i", {
                class: "fa fa-caret-left v-caret v-previous-day",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setPreviousDay()))
              }),
              _createVNode(_unref(BaseDatePickerInput), {
                position: "left",
                class: "v-base-date-picker-input",
                readOnly: false,
                "input-width": "auto",
                "no-icon": true,
                type: "date",
                "auto-apply": true,
                clearable: false,
                "custom-trigger-input": setDateFormat(),
                modelValue: selectedDate.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedDate).value = $event)),
                showDateExceedsToast: true
              }, null, 8, ["custom-trigger-input", "modelValue"]),
              _createElementVNode("i", {
                class: "fa fa-caret-right v-caret v-next-day",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (setNextDay()))
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_unref(featureToggles).bodyMapInstances && !_unref(featureToggles).newInteractionCardsAndTopBar)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(BaseTooltip, {
            position: "bottom",
            tooltipText: _unref(translate)('body_map.body_map')
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseIcon), {
                name: "nsx-font-icon--body-map",
                compact: true,
                class: "v-icon v-body-map-icon v-body-map-open",
                square: true,
                height: __props.instanceView? '36px': '24px',
                width: __props.instanceView? '36px': '24px',
                fontSize: "24px",
                "background-color": __props.instanceView ? 'black' : 'transparent',
                "foreground-color": __props.instanceView ? 'white' : 'black',
                "foreground-hover-color": __props.instanceView ? 'white' : 'black',
                "no-hover-background": true,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('toggleBodyMap')))
              }, null, 8, ["height", "width", "background-color", "foreground-color", "foreground-hover-color"])
            ]),
            _: 1
          }, 8, ["tooltipText"])
        ], 512)), [
          [_vShow, __props.showBodyMapButton && !_unref(timelineSearchOpen) && !timelineControlsPresent.value]
        ])
      : _createCommentVNode("", true),
    (_unref(featureToggles).bodyMapInstances)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (emit('toggleInstanceView'))),
            class: "v-instance-back-button"
          }, [
            _createVNode(_unref(BaseIcon), {
              name: "nsx-font-icon--expand-left",
              compact: true,
              "no-hover": true
            }),
            _createElementVNode("div", null, _toDisplayString(_unref(translate)('common.back')), 1)
          ])
        ], 512)), [
          [_vShow, __props.isInstanceView && !_unref(timelineSearchOpen)]
        ])
      : _createCommentVNode("", true),
    (showFilter.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["v-filter", { 'flex-grow': __props.instanceView }])
        }, [
          (__props.instanceView && !_unref(timelineSearchOpen))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(__props.filterText), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", {
            class: "v-filter-option",
            onClick: _withModifiers(clickFilterButton, ["stop"])
          }, [
            _createVNode(BaseTooltip, {
              position: "bottom",
              tooltipText: _unref(translate)('common.filter')
            }, {
              default: _withCtx(() => [
                (!__props.instanceView)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(optionName.value), 1))
                  : _createCommentVNode("", true),
                _createVNode(_unref(BaseIcon), {
                  name: "nsx-font-icon--filter",
                  compact: true,
                  class: "v-icon v-filter-icon",
                  square: true,
                  height: _unref(isTimelineFilterOpen)? '36px': '24px',
                  width: _unref(isTimelineFilterOpen)? '36px': '24px',
                  fontSize: "24px",
                  "background-color": _unref(isTimelineFilterOpen) ? 'black' : 'transparent',
                  "foreground-color": _unref(isTimelineFilterOpen) ? 'white' : 'black',
                  "foreground-hover-color": _unref(isTimelineFilterOpen) ? 'white' : 'black',
                  "no-hover-background": true
                }, null, 8, ["height", "width", "background-color", "foreground-color", "foreground-hover-color"])
              ]),
              _: 1
            }, 8, ["tooltipText"])
          ], 512), [
            [_vShow, !_unref(timelineSearchOpen)]
          ]),
          _createVNode(LeftPanelDropdownList, {
            "show-dropdown": !__props.instanceView && filterDropdownVisible.value,
            options: filterOptions.value,
            onOptionSelected: filterOptionClicked
          }, null, 8, ["show-dropdown", "options"])
        ], 2)), [
          [_unref(vClickOutside), () => filterDropdownVisible.value = false]
        ])
      : _createCommentVNode("", true),
    (!__props.searchDisabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "v-search",
          style: _normalizeStyle(searchStyle.value)
        }, [
          _createVNode(BaseTooltip, {
            position: "bottom",
            tooltipText: _unref(translate)('common.search')
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseIcon), {
                name: "nsx-font-icon--search",
                width: "24px",
                height: "24px",
                compact: "",
                class: "v-icon v-search-icon",
                "foreground-color": "black",
                "foreground-hover-color": "black",
                "no-hover-background": true,
                onClick: _cache[5] || (_cache[5] = () => openSearchHandler())
              })
            ]),
            _: 1
          }, 8, ["tooltipText"])
        ], 4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      _createVNode(BaseInput, {
        setFocus: true,
        class: "v-sidebar-search",
        border: false,
        transparent: true,
        modelValue: _unref(timelineSearchTerm),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(timelineSearchTerm) ? (timelineSearchTerm).value = $event : null)),
        placeholder: _unref(translate)('search.placeholder')
      }, null, 8, ["modelValue", "placeholder"]),
      _createVNode(_unref(BaseIcon), {
        class: "v-close-button",
        transparent: true,
        name: "nr-icon-remove",
        "foreground-hover-color": "black",
        size: "sm",
        closeButtonBlack: true,
        onClick: _cache[7] || (_cache[7] = ($event: any) => (closeSearchHandler()))
      })
    ], 512), [
      [_vShow, _unref(timelineSearchOpen)]
    ]),
    (!_unref(timelineSearchOpen) && timelineControlsPresent.value && !isFutureDate.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "v-kebab-menu",
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (timelineControlsDropdownVisible.value = !timelineControlsDropdownVisible.value), ["stop"]))
          }, [
            _createVNode(BaseTooltip, {
              position: "left",
              tooltipText: _unref(translate)('common.more_options')
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseIcon), {
                  name: "nsx-font-icon--kebab-menu",
                  compact: "",
                  width: "24px",
                  height: "24px",
                  "no-hover-background": "",
                  "foreground-hover-color": "black"
                })
              ]),
              _: 1
            }, 8, ["tooltipText"])
          ]),
          _createVNode(LeftPanelDropdownList, {
            "show-dropdown": timelineControlsDropdownVisible.value,
            options: __props.timelineControlsOptions ? __props.timelineControlsOptions : [],
            onOptionSelected: timelineControlsOptionClicked,
            "show-check-icon": ""
          }, null, 8, ["show-dropdown", "options"])
        ])), [
          [_unref(vClickOutside), () => timelineControlsDropdownVisible.value = false]
        ])
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "custom-action-bar-options")
  ]))
}
}

})