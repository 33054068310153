import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-card" }
const _hoisted_2 = { class: "v-card-entry" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
  headerBackground: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: Boolean,
    default: true,
  },
  contentTopBorder: {
    type: Boolean,
    default: true,
  },
  stickyHeader: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["v-card-heading", [
       __props.headerBackground ? 'v-grey-card-background' : '',
       __props.stickyHeader ? 'v-sticky-header sp-base-card-sticky-header' : ''
     ]])
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["v-card-body", `${__props.padding ? 'v-card-padding' : ''} ${__props.contentTopBorder ? 'v-card-body-top-border' : ''}`])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ], 2)
  ]))
}
}

})