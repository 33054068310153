import apiClient from '@/_shared/services/apiClient';
import { Client, ClientForReports } from '@/_shared/types/client';

type ClientApiResponse = {
  client: Client
}

export const postNewClient = async (ouId: number, client: Client): Promise<ClientApiResponse | null> => {
  if (client.birthDate === 'not_known') delete client.birthDate;
  if (client.startOn === 'not_known') delete client.startOn;
  const url = `clients?ou_id=${ouId}`;
  try {
    return await apiClient.post(url, { client });
  } catch (error) {
    return null;
  }
};

type ClientTemplatesApiResponse = {
  clients: Client[]
}

export const getClientTemplates = async (ouId: number): Promise<ClientTemplatesApiResponse | null> => {
  const url = `organisation_units/${ouId}/clients?always_show_template=true&per_page=100&`
    + 'hide_template_if_no_access=false&include_health_profile=true&state%5B%5D=template';
  try {
    return await apiClient.get(url);
  } catch (error) {
    return null;
  }
};

type ClientOrganisationUnitForReports = {
  id: number,
  personId: number,
  organisationUnitId: number,
  name: string,
  state: string
};
type ClientOrganisationUnitForReportsResponse = {
  clientOrganisationUnits: ClientOrganisationUnitForReports[]
};

export const getClientsForReports = async (ouId: number): Promise<ClientForReports[] | null> => {
  const url = `/api/v2/organisation_units/${ouId}/client_organisation_units_for_reports`;
  try {
    const response: ClientOrganisationUnitForReportsResponse = await apiClient.get(url);
    return response.clientOrganisationUnits.map((c) => ({
      id: c.personId,
      displayName: c.name,
      state: c.state,
    }));
  } catch (error) {
    return null;
  }
};
