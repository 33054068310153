import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-careplan-responsible-people" }
const _hoisted_2 = { class: "v-careplan-responsible-people-title" }
const _hoisted_3 = { class: "v-careplan-responsible-people-content" }
const _hoisted_4 = {
  key: 0,
  class: "v-missing-text"
}

import { computed, PropType } from 'vue';
import { Person } from '@/_shared/store/people';
import use from '@/_shared/compositionApi';
import CarePlanPerson from './CarePlanPerson.vue';
import { getResponsiblePeople } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanResponsiblePeople',
  props: {
  responsiblePersonIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const responsibleCarers = computed<Person[]>(() => getResponsiblePeople(props.responsiblePersonIds));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('need.responsible_people')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(responsibleCarers.value, (responsibleCarer) => {
        return (_openBlock(), _createBlock(CarePlanPerson, {
          key: responsibleCarer.id,
          person: responsibleCarer,
          class: "v-careplan-responsible-people-item"
        }, null, 8, ["person"]))
      }), 128)),
      (!responsibleCarers.value.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(translate)('common.empty_field_data')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})