import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-alert-banner-wrapper" }
const _hoisted_2 = { class: "v-alert-icon-and-message" }
const _hoisted_3 = { class: "v-alert-banner-icon" }
const _hoisted_4 = { class: "v-alert-banner-title" }
const _hoisted_5 = { class: "v-alert-banner-message" }
const _hoisted_6 = { class: "v-errors-list" }
const _hoisted_7 = { class: "v-interaction-error" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 1 }

import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { parameterHasNoValue } from '@/timeline/helper/dynamicParameterHelper';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import { computed } from 'vue';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';
import addPreferredTermsToText from '../../_shared/services/clientHelpers';
import InteractionSection from './InteractionSection.vue';

type NumberOrStringArray = (number | string)[];

export default /*@__PURE__*/_defineComponent({
  __name: 'AlertBanner',
  emits: ['closeBanner'],
  setup(__props, { emit: __emit }) {

const { currentInteraction } = storeToRefs(useCurrentInteractionStore());
const interactionErrors = currentInteraction.value?.errors;

const emit = __emit;

const closeBanner = (keyType: string) => {
  if (!currentInteraction.value?.errors) return;
  delete (currentInteraction.value.errors as { [key: string]: unknown })[keyType];
  emit('closeBanner');
};

const parseClientName = (error: string | NumberOrStringArray) => {
  let formattedError = '';
  if (Array.isArray(error)) {
    formattedError = error[0] as string;
  } else {
    formattedError = error as string;
  }
  const clientId = currentInteraction.value?.clientId;
  return clientId ? addPreferredTermsToText(+clientId, formattedError).value : error;
};

const isClickable = (error: string | NumberOrStringArray) => Array.isArray(error) && typeof error[1] === 'number';

const parameterForError = (error: string | NumberOrStringArray) => {
  if (Array.isArray(error)) {
    return currentInteraction.value.parameters.find((param) => param.id === error[1]);
  }
  return null;
};
const hasNoValue = (error: string | NumberOrStringArray) => {
  const parameter = parameterForError(error);
  if (parameter?.required) {
    return parameter ? parameterHasNoValue(parameter) : true;
  }
  return false;
};

const hasValidationErrors = (err: string | NumberOrStringArray) => {
  const parameter = parameterForError(err);
  return parameter ? useParameterValidator(parameter)?.errorMessage?.value : false;
};

const hasOtherErrors = computed(() => Object.keys(interactionErrors || {}).some((key) => key !== 'parameterValuesCoercedValue'));

const anyParameterHasError = computed(() => {
  if (!interactionErrors) return false;
  return currentInteraction.value?.parameters?.some((param) => hasNoValue(['', param.id]) || hasValidationErrors(['', param.id]));
});

const showError = (error: string | NumberOrStringArray) => {
  if (Array.isArray(error)) {
    return hasNoValue(error) || hasValidationErrors(error);
  }
  return true;
};

const maybeDirectToParameter = (error: string | NumberOrStringArray) => {
  const element = document.getElementById(`v-clickable-parameter-${error[1]}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.style.backgroundColor = '#DAD9D6';
    element.style.border = '1px solid #EAE7E3';
    setTimeout(() => {
      element.style.backgroundColor = '';
    }, 2000);
  }
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(interactionErrors), (errorType, key) => {
      return (_openBlock(), _createBlock(InteractionSection, {
        class: "alert-banner-section",
        key: key
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(BaseIcon, {
                  class: "no-hover",
                  name: "bell",
                  noHoverBackground: "",
                  "foreground-color": "white"
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "bannerTitle")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errorType, (err, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _withDirectives(_createElementVNode("div", _hoisted_7, [
                        (isClickable(err))
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              onClick: ($event: any) => (maybeDirectToParameter(err)),
                              class: "v-clickable-error"
                            }, _toDisplayString(parseClientName(err)), 9, _hoisted_8))
                          : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(parseClientName(err)), 1))
                      ], 512), [
                        [_vShow, showError(err)]
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createVNode(BaseIcon, {
              class: "v-alert-banner-close-btn",
              noHoverBackground: "",
              name: "times",
              "foreground-color": "white",
              onClick: ($event: any) => (closeBanner(key))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 512)), [
    [_vShow, anyParameterHasError.value || hasOtherErrors.value]
  ])
}
}

})