import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-careplan-edit-item" }
const _hoisted_2 = { class: "v-careplan-edit-item-title" }
const _hoisted_3 = { class: "v-careplan-edit-item-content" }
const _hoisted_4 = { class: "v-careplan-edit-item-content-date" }
const _hoisted_5 = { class: "v-careplan-edit-item-content-note" }
const _hoisted_6 = { class: "v-careplan-edit-item-content-next-review" }
const _hoisted_7 = { key: 0 }

import { PropType } from 'vue';
import { Need } from '@/timeline/types/Need';
import use from '@/_shared/compositionApi';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import IInteraction from '@/timeline/types/IInteraction';
import { ReviewValidationErrors } from './edit-utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditReview',
  props: {
  review: {
    type: Object as PropType<IInteraction>,
    required: true,
  },
  need: {
    type: Object as PropType<Need>,
    required: true,
  },
  errors: {
    type: Object as PropType<ReviewValidationErrors>,
    default: null,
  },
},
  emits: ['cancel'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();



const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('need.review_note')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(BaseDatePickerInput, {
          type: "dateTime",
          modelValue: __props.review.finishAt,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.review.finishAt) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BaseInput, {
          border: true,
          textarea: true,
          modelValue: __props.review.notePublic,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.review.notePublic) = $event)),
          placeholder: _unref(translate)('need.placeholders.review_note_placeholder'),
          "test-id": "notePublic"
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h4", null, _toDisplayString(_unref(translate)('need.next_review_on')), 1),
        _createVNode(BaseDatePickerInput, {
          type: "date",
          modelValue: __props.need.nextReviewOn,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.need.nextReviewOn) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(BaseButton, {
          class: "v-next-review-item-action",
          onClicked: _cache[3] || (_cache[3] = ($event: any) => (emit('cancel'))),
          "button-type": "primary",
          size: "large",
          disabled: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('need.cancel_update')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (__props.errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              key: error,
              class: "alert-banner"
            }, _toDisplayString(_unref(translate)(`need.errors.${error}`)), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})