import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-reports-header" }

import BaseLandingStrip from '@/_shared/components/display/BaseLandingStrip/BaseLandingStrip.vue';
import {
  BaseCard,
} from '@/_shared/components';
import { storeToRefs } from 'pinia';
import CareplanReport from '@/reports/CareplanReport.vue';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import ReportType from '@/reports/ReportType.vue';
import ReportActions from '@/reports/ReportActions.vue';
import ReportRedaction from '@/reports/ReportRedaction.vue';
import TimelineReport from '@/reports/TimelineReport.vue';
import ClientProfileReport from '@/reports/ClientProfileReport.vue';
import InteractionChangesReport from '@/reports/InteractionChangesReport.vue';
import CarerProfileReport from '@/reports/CarerProfileReport.vue';
import WoundReport from '@/reports/WoundReport.vue';
import { computed, watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportNew',
  setup(__props) {


const { translate } = use.helpers();

const reportStore = useReportStore();
const { newReport, reportValid } = storeToRefs(reportStore);

const isWoundReport = () => (newReport.value.reportClass === 'WoundMultiPhotoReport') || (newReport.value.reportClass === 'WoundSinglePhotoReport');

watchEffect(() => {
  if (isWoundReport()) {
    if (newReport.value.params.woundId && newReport.value.params.clientIds && newReport.value.params.photoOption) {
      reportValid.value = true;
    } else {
      reportValid.value = false;
    }
  } else reportValid.value = true;
});

const reportComponent = computed(() => ({
  ClientCareplanReport: CareplanReport,
  ClientInteractionsReport: TimelineReport,
  WoundMultiPhotoReport: WoundReport,
  WoundSinglePhotoReport: WoundReport,
  ClientProfileReport,
  InteractionChangesReport,
  CarerProfileReport,
  EmergencyPackReport: CareplanReport,
  InteractionReport: CareplanReport,
}[newReport.value.reportClass]));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseLandingStrip, null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseCard), { padding: false }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(translate)('reports.new')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(ReportType),
          (_openBlock(), _createBlock(_resolveDynamicComponent(reportComponent.value))),
          _createVNode(ReportRedaction),
          _createVNode(ReportActions)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})