import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-dropdown-section-title"
}
const _hoisted_2 = ["onClick", "content"]
const _hoisted_3 = {
  key: 1,
  class: "break"
}

import {
  PropType, computed, ref, nextTick, watchEffect,
} from 'vue';

import { MenuSection } from '@/_shared/types/baseDropdownList';

type Position = 'BottomRight' | 'BottomLeft' | 'TopCenter'

const MENU_CLIENT_WIDTH_DIVISOR = 2;
const MENU_CLIENT_WIDTH_REDUCTION = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDropdownList',
  props: {
  menuSections: {
    type: Object as PropType<MenuSection[]>,
    required: true,
  },
  show: {
    type: Boolean,
    required: true,
  },
  position: {
    type: String as PropType<Position>,
    default: 'BottomRight',
  },
  enableItemSeparator: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "199c64e9": (__props.show ? 'visible' : 'hidden'),
  "9d4a9cac": (menuLeftPosition.value),
  "b94289f8": (menuTopPosition.value)
}))

const props = __props;

const dropdownMenu = ref();
const dropdownWrapper = ref();
const menuLeftPosition = ref('0');
const menuTopPosition = ref('0');

const setPosition = async () => {
  if (!dropdownMenu.value || !dropdownWrapper.value) {
    return;
  }
  switch (props.position) {
    case 'BottomRight':
      menuLeftPosition.value = '0px';
      menuTopPosition.value = '5px';
      break;
    case 'BottomLeft':
      await nextTick();
      menuLeftPosition.value = `${-Math.abs(dropdownMenu.value.clientWidth) + dropdownWrapper.value.clientWidth}px`;
      menuTopPosition.value = '5px';
      break;
    case 'TopCenter':
      await nextTick();
      menuLeftPosition.value = `${-Math.abs(dropdownMenu.value.clientWidth / MENU_CLIENT_WIDTH_DIVISOR) + (dropdownWrapper.value.clientWidth / MENU_CLIENT_WIDTH_DIVISOR)}px`;
      menuTopPosition.value = `${-Math.abs(dropdownMenu.value.clientHeight + dropdownWrapper.value.clientWidth) - MENU_CLIENT_WIDTH_REDUCTION}px`;
      break;
    default:
  }
};

watchEffect(async () => {
  if (props.show) {
    await setPosition();
  }
});

const filteredMenuSections = computed(
  () => props.menuSections.filter((section) => section.title || section.options.some((o) => o.show)),
);

const getVisibleOptions = (section: MenuSection) => section.options.filter((option) => option.show);


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "v-dropdown",
    ref_key: "dropdownWrapper",
    ref: dropdownWrapper
  }, [
    _createElementVNode("div", {
      class: "v-dropdown-menu",
      ref_key: "dropdownMenu",
      ref: dropdownMenu
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredMenuSections.value, (section, index) => {
        return (_openBlock(), _createElementBlock("ul", {
          class: _normalizeClass(['v-dropdown-menu-section', section.title === '' || section.title === undefined ? 'blank-title' : '']),
          key: section.title
        }, [
          (section.title)
            ? (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString(section.title), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getVisibleOptions(section), (option) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(['v-dropdown-menu-option', props.enableItemSeparator ? 'list-separator' : '']),
              key: option.text,
              onClick: ($event: any) => (!option.disabled && option.method()),
              content: option.text
            }, [
              _createElementVNode("span", {
                class: _normalizeClass({ 'option-disabled': option.disabled })
              }, _toDisplayString(option.text), 3)
            ], 10, _hoisted_2))
          }), 128)),
          (index + 1 !== filteredMenuSections.value.length)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, _cache[0] || (_cache[0] = [
                _createElementVNode("hr", null, null, -1)
              ])))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ], 512)
  ], 512)), [
    [_vShow, __props.show]
  ])
}
}

})