import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-nourish-instance-parameter-container" }
const _hoisted_2 = { class: "v-nourish-instance-paramter-wrapper" }
const _hoisted_3 = { class: "v-datasets-selector-outer-wrapper" }

import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import {
  CoercedSkinInstance,
  CoercedSingleSelect,
  Parameter,
} from '@/timeline/types/Parameter';
import SingleSelectDataSetParameter
  from '@/timeline/interaction_parameters/SingleSelectDataSetParameter.vue';
import {
  computed, ref, PropType, ComputedRef, Ref, watch, watchPostEffect,
} from 'vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import useDatasetStore from '@/_shared/store/datasets';
import use from '@/_shared/compositionApi';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import { DATA_SET_SKIN_BODYMAP_LOCATIONS } from '@/_shared/store/bodyMapLocations';
import useNourishInstanceParameterHelper from '@/timeline/helper/nourishInstanceParameterHelper';
import { otherInstanceCodenames } from '@/_shared/store/composables/datasetsInstances';


export default /*@__PURE__*/_defineComponent({
  __name: 'NourishInstanceParameter',
  props: {
  parameter: {
    type: Object as PropType<Parameter>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {


const { translate } = use.helpers();

const props = __props;

const { getSpecificTypeIndex, nourishInstanceTypeDataSets } = useDatasetStore();

const { currentInteraction } = storeToRefs(useCurrentInteractionStore());
const { setCoercedValueByParameterId } = useCurrentInteractionStore();

const parameterCoercedValue = props?.parameter?.coercedValue as CoercedSkinInstance;

const { locationModalConfig, woundModalConfig } = useNourishInstanceParameterHelper();

const nourishInstanceLocationParameter : Ref<Parameter> = ref(setUpSubParameter(props.parameter.id, 'location', 1, parameterCoercedValue?.metadata?.location));
const nourishInstanceTypeParameter : Ref<Parameter> = ref(setUpSubParameter(props.parameter.id, 'specificType', 2, parameterCoercedValue?.metadata?.specificType));

const locationCodename : ComputedRef<string> = computed(() => (props.parameter?.coercedValue as CoercedSkinInstance)?.metadata?.location);
const { instanceLocations, defaultSide } = useBodyMapLocationsHelper().forLocationCodeName(locationCodename);
const woundTypesLookup = computed(() => getSpecificTypeIndex(props.parameter.config!.nourishInstanceType!).value || {});

const lookupSubType = (): string => {
  const subType = woundTypesLookup.value[(nourishInstanceTypeParameter.value.coercedValue as CoercedSingleSelect).values[0]];
  return subType || otherInstanceCodenames[props.parameter.config!.nourishInstanceType!];
};

watch(() => ({
  metadata: {
    location: (nourishInstanceLocationParameter.value.coercedValue as CoercedSingleSelect)?.values[0],
    specificType: (nourishInstanceTypeParameter.value.coercedValue as CoercedSingleSelect)?.values[0],
  },
}), (partialCoercedNourishInstance) => {
  // todo  add logic when is considered to be true
  const newCoercedValue = {
    subType: lookupSubType(),
    existing: false,
  } as CoercedSkinInstance;
  newCoercedValue.metadata = { location: '', specificType: '' };
  if (partialCoercedNourishInstance.metadata.location) {
    newCoercedValue.metadata.location = partialCoercedNourishInstance.metadata.location;
  }

  if (partialCoercedNourishInstance.metadata.specificType) {
    newCoercedValue.metadata.specificType = partialCoercedNourishInstance.metadata.specificType;
  }

  setCoercedValueByParameterId(props.parameter.id as number, newCoercedValue);
  props.parameter.edited = true;
});

// TODO unify with handleNourishInstanceParams in parametersHelper
function setUpSubParameter(paramId: string | number, parameterType: string, position: number, value: string) {
  const param = {} as Parameter;
  param.name = translate(`body_map.${parameterType}`);
  param.codename = `${paramId}_${parameterType.toLowerCase()}`;
  param.required = props.parameter.required;
  param.config = {};
  param.valueType = 'single_selector_search';
  if (parameterType === 'location') {
    param.config.dataSet = DATA_SET_SKIN_BODYMAP_LOCATIONS;
  } else if (props.parameter.config?.nourishInstanceType) {
    param.config.dataSet = nourishInstanceTypeDataSets(props.parameter.config.nourishInstanceType);
  }
  param.id = Number(`${paramId}.${position}`);
  const coercedValue = { values: [] as string[] } as CoercedSingleSelect;
  if (value) {
    coercedValue.values.push(value);
  }
  param.coercedValue = coercedValue;
  currentInteraction.value.parameters.push(param);
  return param;
}

const openLocationModal = () => {
  locationParameterBaseSelect.value?.click();
};
const locationParameterComponent = ref<InstanceType<typeof SingleSelectDataSetParameter>|null>(null);
const locationParameterBaseSelect = ref<HTMLElement|null>(null);
watchPostEffect(() => {
  locationParameterBaseSelect.value = locationParameterComponent.value?.$el ? locationParameterComponent.value.$el.querySelector(':scope  .v-data-set-container.sp-multi-value-box > .v-multiselect') : null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true
    }, {
      "lhs-slot-second-line": _withCtx(() => _cache[0] || (_cache[0] = [])),
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "v-body-map-wrapper",
            onClick: openLocationModal
          }, [
            _createVNode(BodyMapImage, {
              "white-background": "",
              showSideSelector: false,
              "instance-locations": _unref(instanceLocations),
              "side-to-show": _unref(defaultSide)
            }, null, 8, ["instance-locations", "side-to-show"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (nourishInstanceLocationParameter.value)
              ? (_openBlock(), _createBlock(SingleSelectDataSetParameter, {
                  id: `v-location-selector${props.parameter?.id}`,
                  ref_key: "locationParameterComponent",
                  ref: locationParameterComponent,
                  key: nourishInstanceLocationParameter.value.codename,
                  parameter: nourishInstanceLocationParameter.value,
                  readOnly: __props.readOnly,
                  modalConfiguration: _unref(locationModalConfig),
                  showStatus: false
                }, null, 8, ["id", "parameter", "readOnly", "modalConfiguration"]))
              : _createCommentVNode("", true),
            (nourishInstanceTypeParameter.value)
              ? (_openBlock(), _createBlock(SingleSelectDataSetParameter, {
                  id: `v-type-selector${props.parameter?.id}`,
                  key: nourishInstanceTypeParameter.value.codename,
                  parameter: nourishInstanceTypeParameter.value,
                  readOnly: __props.readOnly,
                  modalConfiguration: _unref(woundModalConfig),
                  showStatus: false
                }, null, 8, ["id", "parameter", "readOnly", "modalConfiguration"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})