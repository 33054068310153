import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-confidential-interaction-toggle-wrapper" }
const _hoisted_2 = { class: "v-confidential-interaction-toggle-header" }
const _hoisted_3 = { class: "v-confidential-interaction-toggle-buttons" }
const _hoisted_4 = { class: "v-confidential-interaction-toggle-buttons" }
const _hoisted_5 = {
  class: "btn-primary",
  disabled: ""
}
const _hoisted_6 = {
  class: "btn-cancel",
  disabled: ""
}
const _hoisted_7 = { class: "v-confidential-interaction-toggle-footer" }
const _hoisted_8 = { class: "v-confidential-interaction-toggle-roles" }
const _hoisted_9 = { class: "v-confidential-interaction-toggle-roles-header" }
const _hoisted_10 = { class: "v-confidential-interaction-toggle-roles-list" }

import use from '@/_shared/compositionApi';
import InteractionSection from './InteractionSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfidentialInteractionToggle',
  props: {
  confidentialityLevel: {
    type: [String, null],
    required: true,
  },
  isConfidential: {
    type: Boolean,
    required: true,
  },
  setIsConfidential: {
    type: Function,
    required: true,
  },
  viewRoles: {
    type: Array,
    required: true,
  },
},
  setup(__props) {



const { translate } = use.helpers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InteractionSection, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.confidentialityLevel === 'default_on' || __props.confidentialityLevel === 'default_off')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.title')), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  class: _normalizeClass(__props.isConfidential ? 'btn-primary' : 'btn-cancel'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.setIsConfidential(true)))
                }, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.confidential')), 3),
                _createElementVNode("button", {
                  class: _normalizeClass(__props.isConfidential ? 'btn-cancel' : 'btn-primary'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (__props.setIsConfidential(false)))
                }, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.visible_to_all')), 3)
              ]),
              _withDirectives(_createElementVNode("div", { class: "v-confidential-interaction-toggle-footer" }, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.after_submission')), 513), [
                [_vShow, __props.isConfidential]
              ])
            ], 64))
          : _createCommentVNode("", true),
        (__props.confidentialityLevel === 'always')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", _hoisted_5, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.confidential')), 1),
                _createElementVNode("button", _hoisted_6, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.visible_to_all')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.always')), 1)
            ], 64))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(translate)('interaction.confidentiality_toggle.roles')), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(__props.viewRoles.join(', ')), 1)
        ], 512), [
          [_vShow, __props.isConfidential]
        ])
      ])
    ]),
    _: 1
  }))
}
}

})