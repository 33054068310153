import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import use from '@/_shared/compositionApi';
import ReportClientInput from '@/reports/inputs/ReportClientInput.vue';
import ReportInteractionInput from '@/reports/inputs/ReportInteractionInput.vue';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportingPeriod from '@/reports/ReportingPeriod.vue';
import { BaseDatePickerInput } from '@/_shared/components';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import useReportDateHelper from '@/reports/helpers/useReportDateHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionChangesReport',
  setup(__props) {

const { translate } = use.helpers();

const { featureToggles } = storeToRefs(useUserStore());

const { dateRange } = useReportDateHelper();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        (_unref(featureToggles).enableReportEnhancementFeatures)
          ? (_openBlock(), _createBlock(ReportingPeriod, {
              key: 0,
              "reporting-period-option": "lastUpdated"
            }))
          : (_openBlock(), _createBlock(_unref(BaseDatePickerInput), {
              key: 1,
              modelValue: _unref(dateRange),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(dateRange) ? (dateRange).value = $event : null)),
              label: _unref(translate)('reports.filters.date_range'),
              range: "",
              "show-presets": "",
              "standard-input-style": ""
            }, null, 8, ["modelValue", "label"])),
        _createVNode(ReportClientInput),
        _createVNode(ReportInteractionInput)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['excel', 'csv'] })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})