import type { NotificationConfigurationPutData, NotificationConfigurationResponse, NotificationConfigurationListResponse }
  from '@/notifications/types/NotificationConfiguration';
import apiClient from '@/_shared/services/apiClient';

interface Options {
  roleId?: number;
  superUser?: boolean;
}

export const getNotificationConfigurations = async (options: Options) : Promise<NotificationConfigurationListResponse> => {
  try {
    const url = '/api/v2/notifications/configurations';
    let data = {};
    if (options.roleId) {
      data = { role_id: options.roleId };
    }
    if (options.superUser) {
      data = { superuser: options.superUser };
    }
    return apiClient.get(url, { params: data });
  } catch (error) {
    return error;
  }
};

export const saveNotificationConfiguration = async (notificationConfiguration: NotificationConfigurationPutData): Promise<NotificationConfigurationResponse> => {
  try {
    const url = '/api/v2/notifications/configurations';
    const data = { notification_configuration: notificationConfiguration };
    return apiClient.post<NotificationConfigurationResponse>(url, data);
  } catch (error) {
    return error;
  }
};

export const deleteNotificationConfiguration = async (notificationConfigurationId: number): Promise<NotificationConfigurationResponse> => {
  try {
    const url = `/api/v2/notifications/configurations/${notificationConfigurationId}`;
    return apiClient.delete<NotificationConfigurationResponse>(url);
  } catch (error) {
    return error;
  }
};

export default saveNotificationConfiguration;
