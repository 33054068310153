import { RouteAuthContext } from '@/_shared/router/types';
import NotificationsMain from '@/notifications/components/NotificationsMain.vue';
import NotificationsTopBar from '@/notifications/components/NotificationsTopBar.vue';
import MessagesMain from '@/messages/MessagesMain.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import { RouteLocationNormalized } from 'vue-router';
import MessageCreate from '@/messages/components/MessageCreate.vue';
import { createNewMessage } from '@/messages/types/messages';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import useMessagesStore from '@/messages/store/messagesStore';
import NewMessageButton from '@/messages/NewMessageButton.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import MessageDetails from '@/messages/components/MessageDetails.vue';
import MessagesTopBar from '@/messages/components/MessagesTopBar.vue';
import BaseEmptyView from '@/_shared/components/routing/BaseEmptyView.vue';
import componentBaseOnFT, { ftEnabled } from '@/_shared/router/ComponentHelper';
import DashboardsRightPanel from '@/dashboards/DashboardsRightPanel.vue';
import DashboardsLeftPanel from '@/dashboards/DashboardsLeftPanel.vue';
import useAnalyticsStore from '@/_shared/store/analytics';
import NotificationsLeftPanel from '@/notifications/components/NotificationsLeftPanel.vue';
import NotificationsPlusButton from '@/notifications/components/NotificationsPlusButton.vue';
import NotificationsForm from '@/notifications/components/NotificationsForm.vue';
import useNotificationsStore from '@/_shared/store/notifications';

export const otherRoutes = [
  {
    path: '/messages',
    components: {
      TopBar: MessagesTopBar,
    },
    beforeEnter: async () => {
      useMessagesStore().$reset();
      await loadModuleLanguageFileAsync('messages'); // TODO WHEN PURE VUE remove
      await useMessagesStore().getMessages();
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          Action: NewMessageButton,
        },
        children: [
          {
            path: '',
            name: 'messages',
            alias: ['sent', 'archived_inbox', 'archived_sent'],
            component: MessagesMain,
          },
          {
            path: 'new',
            name: 'messagesNew',
            component: MessageCreate,
            beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
              const { currentUser } = storeToRefs(useUserStore());
              const { createMessage } = useMessagesStore();
              if (from.name !== 'messages.message') {
                const message = createNewMessage(currentUser.value.id);
                message.body = decodeURIComponent(to.query.body?.toString() ?? '');
                message.subject = decodeURIComponent(to.query.subject?.toString() ?? '');
                await createMessage(message, to.query.confidential === 'true');
              }
              return true;
            },
            props: true,
          },
          {
            path: ':messageId',
            name: 'messages.message',
            component: MessageDetails,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: '/dashboards',
    components: {
      TopBar: BaseEmptyView,
    },
    beforeEnter: async (to: RouteLocationNormalized) => {
      const vueInsightsEnabled = await ftEnabled('vueInsights');
      to.meta = { ...to.meta, isAngular: !vueInsightsEnabled };
      if (vueInsightsEnabled) {
        to.meta.isAngular = true;
        await loadModuleLanguageFileAsync('dashboards'); // TODO WHEN PURE VUE remove
        useAnalyticsStore().fetchCurrentPersonReports();
      }
      return true;
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          SideBar: async () => componentBaseOnFT('vueInsights', DashboardsLeftPanel),
        },
        children: [
          {
            path: '',
            name: 'dashboards',
            component: async () => componentBaseOnFT('vueInsights', DashboardsRightPanel),
          },
        ],
      },
      {
        path: 'category',
        components: {
          SideBar: async () => componentBaseOnFT('vueInsights', DashboardsLeftPanel),
        },
        children: [
          {
            path: ':category_id?',
            name: 'dashboards.category',
            props: true,
            component: async () => componentBaseOnFT('vueInsights', DashboardsRightPanel),
          },
          {
            path: ':category_id/subcategory/:subcategory_id?',
            name: 'dashboards.category.subcategory',
            props: true,
            component: async () => componentBaseOnFT('vueInsights', DashboardsRightPanel),
          },
        ],
      },
      {
        path: 'legacycategory',
        components: {
          SideBar: async () => componentBaseOnFT('vueInsights', DashboardsLeftPanel),
        },
        children: [
          {
            path: ':category_id?',
            name: 'dashboards.legacycategory',
            meta: { isAngular: true },
            props: false,
            component: async () => BasePlaceHolderView,
          },
          {
            path: ':category_id/subcategory/:subcategory_id?',
            name: 'dashboards.legacycategory.subcategory',
            meta: { isAngular: true },
            props: false,
            component: async () => BasePlaceHolderView,
          },
        ],
      },
    ],
  },
  {
    path: '/me',
    name: 'me',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
    children: [
      {
        path: 'sections',
        name: 'me.sections',
        component: BasePlaceHolderView,
        children: [
          {
            path: 'general_info',
            name: 'me.sections.general_info',
            component: BasePlaceHolderView,

          },
          {
            path: 'access_information',
            name: 'me.sections.access_information',
            component: BasePlaceHolderView,
          },
          {
            path: 'settings',
            name: 'me.sections.settings',
            component: BasePlaceHolderView,
          },
        ],
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: BasePlaceHolderView,
    children: [
      {
        path: ':pathMatch(.*)*',
        name: 'onboarding.clients',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/alarms',
    name: 'alarms',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: ':state(\\d+)',
        name: 'alarms.state',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/notifications',
    components: {
      TopBar: NotificationsTopBar,
    },
    beforeEnter: async () => {
      await loadModuleLanguageFileAsync('notifications');
      await loadModuleLanguageFileAsync('events');
      await loadModuleLanguageFileAsync('timeline');
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          Action: NotificationsPlusButton,
          SideBar: NotificationsLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'notifications',
            component: NotificationsMain,
          },
          {
            path: 'new',
            name: 'notifications.new',
            component: NotificationsForm,
            beforeEnter: () => {
              const notificationsStore = useNotificationsStore();
              if (notificationsStore.selectedRole === null) {
                return { name: 'notifications' };
              }
              return true;
            },
          },
        ],
      },
    ],
  },
];

export const allowedOtherRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];

  allowedRoutes.push(
    'me',
    'me.sections',
    'me.sections.general_info',
    'me.sections.access_information',
    'me.sections.settings',
  );

  allowedRoutes.push(
    'onboarding',
    'onboarding.clients',
  );

  allowedRoutes.push(
    'alarms',
    'alarms.state',
  );

  if (authContext.getFeatureToggle('dashboard')
    && authContext.userHasPermission('viewDashboard')) {
    allowedRoutes.push(
      'dashboards',
      'dashboards.category',
      'dashboards.category.subcategory',
      'dashboards.subSection',
      'dashboards.legacycategory',
      'dashboards.legacycategory.subcategory',
    );
  }

  if (authContext.userHasPermission('viewHandovers')) {
    allowedRoutes.push(
      'handovers',
      'handovers.interaction',
      'handovers.subSection',
    );
  }

  if (authContext.userHasPermission('viewMessages')) {
    allowedRoutes.push(
      'messages',
      'messagesNew',
      'messages.sent',
      'messages.archived_inbox',
      'messages.message',
      'messages.archived_sent',
    );
  }

  if (authContext.getFeatureToggle('configurableEscalations')
    && authContext.userHasPermission('manageRoles')) {
    allowedRoutes.push(
      'notifications',
      'notifications.new',
    );
  }

  return allowedRoutes;
};
