import apiClient from '@/_shared/services/apiClient';
import { CarePlanNeedVersions } from '@/_shared/types/carePlan';
import { promiseMemorise } from './UseUtils';

export interface OrganisationUnitState {
  organisationUnitId: number;
  state: string;
  updatedAt: string;
}

export interface OrganisationUnitStatesApiResponse {
  organisationUnitStates: OrganisationUnitState[];
}

export interface HasEmergencyPackQrCodeApiResponse {
  qrCodeExists: boolean;
}

export const getNeedsVersions = async (clientId: number) => {
  try {
    const response = await apiClient.get(`/api/v2/clients/${clientId}/careplan/need_versions`);
    return response as CarePlanNeedVersions;
  } catch (error) {
    return error;
  }
};

export const generateCarePlanReport = async (clientId: number) => {
  try {
    const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/reports`;
    const params = {
      organisationUnitId: window.currentOrganisationUnit.id,
      format: 'pdf',
      reportClass: 'ClientCareplanReport',
      params: {
        clientIds: [clientId],
      },
    };
    return await apiClient.post(url, { report: params, wait_until_ready: true });
  } catch (error) {
    return error;
  }
};

const fetchOrganisationUnitStates = async (clientId: number) => {
  const result = await apiClient.get<OrganisationUnitStatesApiResponse>(`/api/v2/people/${clientId}/organisation_units/states`, {
    params: { forCurrentOuOnly: true, only: 'state,organisation_unit_id,updated_at' },
  });
  return result.organisationUnitStates;
};

const fetchHasEmergencyPackQrCode = async (clientId: number, organisationUnitId: number) => {
  const result = await apiClient.get<HasEmergencyPackQrCodeApiResponse>(`/api/v2/clients/${clientId}/careplan/emergency_pack_qr/has_qr_code`, {
    params: { organisationUnitId, only: 'qr_code_exists' },
  });
  return result.qrCodeExists;
};

const revokeEmergencyPackQrCode = async (clientId: number, organisationUnitId: number) => apiClient.delete(`/api/v2/clients/${clientId}/careplan/emergency_pack_qr/revoke_qr_code?organisation_unit_id=${organisationUnitId}`);

const fetchEmergencyPackQrCode = async (clientId: number, organisationUnitId: number) => apiClient.post<ArrayBuffer>(`/api/v2/clients/${clientId}/careplan/emergency_pack_qr`, { organisationUnitId }, { responseType: 'arraybuffer' });

const memorizedHasEmergencyPackQrCode = promiseMemorise(fetchHasEmergencyPackQrCode);
const memorizedEmergencyPackQrCode = promiseMemorise(fetchEmergencyPackQrCode);

export {
  fetchOrganisationUnitStates, memorizedHasEmergencyPackQrCode, memorizedEmergencyPackQrCode, revokeEmergencyPackQrCode,
};

export default getNeedsVersions;
