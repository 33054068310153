import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "v-closer-name"
}
const _hoisted_3 = { class: "v-interaction-buttons" }
const _hoisted_4 = { class: "v-extended-dynamic-content" }
const _hoisted_5 = {
  key: 0,
  class: "v-interaction-parameters"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["id"]
const _hoisted_9 = {
  key: 1,
  class: "v-interaction-handover"
}
const _hoisted_10 = { class: "sp-note-public v-note-public-text" }

import {
  computed, PropType, ref, toRef,
} from 'vue';
import IInteraction from '@/timeline/types/IInteraction';

import {
  InteractionLink, interactionStateCss, responsiblePersonId,
} from '@/timeline/helper/interactionsHelper';
import { useRoute } from 'vue-router';
import { BaseAvatar, BaseListEntry, BaseIcon } from '@/_shared/components';
import InteractionListEntryButton from '@/timeline/components/InteractionListEntry/InteractionListEntryButton.vue';
import { CoercedChoice } from '@/timeline/types/Parameter';
import ParameterInfo from '@/timeline/components/ParameterInfo.vue';
import {
  getScoreCssClass,
  hasTotalScoreStyle,
} from '@/timeline/helper/parametersHelper';
import HandoverFlag from '@/timeline/components/HandoverFlag.vue';
import ConfidentialBadge from '@/timeline/components/ConfidentialBadge.vue';
import useUserStore from '@/_shared/store/user';
import useInteractionListEntryParameters from '@/timeline/components/InteractionListEntry/interactionListEntryParameters';
import useInteractionListEntryTime from '@/timeline/components/InteractionListEntry/interactionListEntryTime';
import useInteractionListEntryPeople from '@/timeline/components/InteractionListEntry/interactionListEntryPeople';
import useInteractionListEntryCorner from '@/timeline/components/InteractionListEntry/interactionListEntryCorner';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionsListEntryGeneric',
  props: {
  interaction: {
    type: Object as PropType<IInteraction>,
    required: true,
  },
  link: {
    type: Object as PropType<InteractionLink>,
    required: true,
  },
  timeOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const refInteraction = toRef(props, 'interaction');

const {
  cornerIcon,
  showCorner,
} = useInteractionListEntryCorner(refInteraction);

const {
  hasResponsiblePeople,
  responsiblePeopleNames,
  responsiblePersonPhoto,
} = useInteractionListEntryPeople(refInteraction);

const {
  timeClass,
  timeFirstRow,
  timeSecondRow,
} = useInteractionListEntryTime(refInteraction);

const {
  showParameters,
  showParameterButton,
  showNoteButton,
  computedParameters,
  sortedParameters,
  hasFilledParameters,
  hasPerformedComputedParameterValues,
} = useInteractionListEntryParameters(refInteraction);

const interactionTitle = computed(() => props.interaction.name || props.interaction.displayName);
const interactionState = computed(() => props.interaction.state);
const showConfidentialityBadge = computed(() => featureToggles.value.confidentialInteractions && props.interaction.confidential);

const showState = computed(() => {
  const i = props.interaction;
  return i.closedAt || i.alarms?.length || (!i.closedAt && !i.startAt);
});
const interactionStateClasses = computed(() => interactionStateCss(props.interaction));
const route = useRoute();

const interactionSelected = computed(() => route?.params?.id === props.interaction.id?.toString());

const showNotes = ref(false);

const nourishStore = useUserStore();
const showHandover = computed(() => props.interaction.handover
    && nourishStore.userHasPermission('viewHandovers'));

const { featureToggles } = storeToRefs(useUserStore());

const toggleParameters = () => {
  showParameters.value = !showParameters.value;
  if (showParameters.value) {
    showNotes.value = false;
  }
};

const toggleNotes = () => {
  showNotes.value = !showNotes.value;
  if (showNotes.value) {
    showParameters.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: props.link
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseListEntry), {
        active: interactionSelected.value,
        title: interactionTitle.value,
        fontSize: "16px",
        padding: "12px",
        class: _normalizeClass(`sp-interaction-${__props.interaction.id} sp-${__props.interaction.state}`),
        id: `interaction-${__props.interaction.id}`
      }, _createSlots({
        customIcon: _withCtx(() => [
          (_unref(showCorner))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(`v-top-left-corner-info v-top-left-corner-${_unref(cornerIcon)}`)
              }, [
                _createVNode(_unref(BaseIcon), {
                  name: _unref(cornerIcon),
                  size: "xs",
                  "no-hover": "",
                  "no-hover-background": "",
                  position: "absolute",
                  compact: ""
                }, null, 8, ["name"])
              ], 2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "icon"),
          (__props.interaction.bulkCloseUuid)
            ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                key: 1,
                name: "group",
                "no-hover": "",
                "no-hover-background": "",
                square: "",
                size: "2x",
                class: "v-group-close sp-group-closed"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (showConfidentialityBadge.value)
          ? {
              name: "headerMainCol",
              fn: _withCtx(() => [
                _createVNode(ConfidentialBadge)
              ]),
              key: "0"
            }
          : undefined,
        (interactionState.value !== 'sticky')
          ? {
              name: "headerRightCol",
              fn: _withCtx(() => [
                (!__props.timeOnly)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["v-interaction-time-text sp-time-text-primary", _unref(timeClass)])
                    }, _toDisplayString(_unref(timeFirstRow)), 3))
                  : _createCommentVNode("", true),
                (_unref(timeSecondRow))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["v-interaction-time-text sp-time-text-secondary", _unref(timeClass)])
                    }, _toDisplayString(_unref(timeSecondRow)), 3))
                  : _createCommentVNode("", true)
              ]),
              key: "1"
            }
          : undefined,
        (_unref(responsiblePeopleNames)?.length || _unref(showParameterButton) || _unref(showNoteButton))
          ? {
              name: "extendedLeftCol",
              fn: _withCtx(() => [
                (_unref(responsiblePeopleNames).length)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_unref(hasResponsiblePeople) ? "v-icon-border top vertical-line-top" : "v-icon-no-border")
                    }, [
                      (_unref(hasResponsiblePeople))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_unref(BaseAvatar), {
                              photo: _unref(responsiblePersonPhoto),
                              size: "xsmall",
                              class: _normalizeClass([`sp-avatar-${_unref(responsiblePersonId)(__props.interaction)}`, "v-interaction-avatar"])
                            }, null, 8, ["photo", "class"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              key: "2"
            }
          : undefined,
        (_unref(responsiblePeopleNames)?.length || _unref(showParameterButton) || _unref(showNoteButton))
          ? {
              name: "extendedMainCol",
              fn: _withCtx(() => [
                (_unref(responsiblePeopleNames).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(responsiblePeopleNames)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  (_unref(showParameterButton))
                    ? (_openBlock(), _createBlock(InteractionListEntryButton, {
                        key: 0,
                        class: "v-interaction-list-entry-button-parameters",
                        icon: _unref(showParameters) ? 'parameters_clicked' : 'parameters',
                        onAction: toggleParameters,
                        selected: _unref(showParameters)
                      }, null, 8, ["icon", "selected"]))
                    : _createCommentVNode("", true),
                  (_unref(showNoteButton))
                    ? (_openBlock(), _createBlock(InteractionListEntryButton, {
                        key: 1,
                        class: "v-interaction-list-entry-button-notes",
                        icon: showNotes.value ? 'notes_clicked' : 'notes',
                        onAction: toggleNotes,
                        selected: showNotes.value
                      }, null, 8, ["icon", "selected"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_unref(showParameters))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_unref(hasFilledParameters))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedParameters), (parameter) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: `${__props.interaction.id}-${parameter?.id}`
                                }, [
                                  _createVNode(ParameterInfo, {
                                    parameter: parameter,
                                    clientId: __props.interaction.clientId
                                  }, null, 8, ["parameter", "clientId"])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (_unref(hasPerformedComputedParameterValues))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedParameters), (parameter) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: `${__props.interaction.id}-${parameter?.id}`
                                }, [
                                  _createVNode(ParameterInfo, {
                                    parameter: parameter,
                                    clientId: __props.interaction.clientId,
                                    "computed-parameter": ""
                                  }, null, 8, ["parameter", "clientId"]),
                                  (_unref(hasTotalScoreStyle)(parameter))
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: _normalizeClass(_unref(getScoreCssClass)(parameter)),
                                        id: `sp-${parameter?.id}-value`
                                      }, _toDisplayString((parameter.coercedValue as CoercedChoice).value), 11, _hoisted_8))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (showNotes.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (showHandover.value)
                          ? (_openBlock(), _createBlock(HandoverFlag, {
                              key: 0,
                              "hide-checkbox": "",
                              size: "sm",
                              class: "v-note-handover-flag-small",
                              checked: false,
                              disabled: false
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(__props.interaction.notePublic), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              key: "3"
            }
          : undefined,
        (showState.value && (_unref(showParameterButton) || _unref(showNoteButton)))
          ? {
              name: "extendedRightCol",
              fn: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["sp-interaction-state-class", interactionStateClasses.value])
                }, null, 2)
              ]),
              key: "4"
            }
          : undefined
      ]), 1032, ["active", "title", "class", "id"])
    ]),
    _: 3
  }, 8, ["to"]))
}
}

})