import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "btn-group clearfix report-links" }
const _hoisted_2 = {
  class: "dropdown-menu dropdown-reports pull-right",
  role: "menu"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 7 }
const _hoisted_12 = { key: 8 }
const _hoisted_13 = { key: 9 }
const _hoisted_14 = { key: 10 }
const _hoisted_15 = { key: 11 }

import use from '@/_shared/compositionApi';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import useReportStore from '@/_shared/store/reports';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import { computed, PropType, ref } from 'vue';
import {
  endOfMonth, endOfWeek, startOfMonth, startOfWeek,
} from 'date-fns';
import { Report } from '@/_shared/types/report';
import { useRoute, useRouter } from 'vue-router';
import { memorizedEmergencyPackQrCode, revokeEmergencyPackQrCode } from '@/_shared/services/careplanApi';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import { HealthProfile } from '@/_shared/types/healthProfile';
import sendAnalyticsEvent from '@/_shared/helpers/analyticsHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientReportLinks',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  fullName: {
    type: String,
  },
  healthProfile: {
    type: Object as PropType<HealthProfile>,
    default: undefined,
  },
  stateInOrg: {
    type: String,
  },
  birthDate: {
    type: String,
  },
},
  setup(__props) {

const { translate, toast, ToastType } = use.helpers();
const router = useRouter();

const route = useRoute();

const userStore = useUserStore();
const { featureToggles, permissions } = storeToRefs(userStore);
const { userHasPermission } = userStore;

const reportStore = useReportStore();
const { generateClientReport } = reportStore;

const clientHasEmergencyQrCode = ref<boolean>(false);
const noDobQrEmergencyPackModelOpen = ref<boolean>(false);
const confirmQrEmergencyPackModalOpen = ref<boolean>(false);
const revokeQrModalOpen = ref<boolean>(false);

const props = __props;

const canReadCareplan = computed(() => userHasPermission('readCareplan'));

const canSeeInteractions = computed(() => permissions.value.readableServiceIds.length > 0);

const careplanAuditReportLink = computed(() => router.resolve({
  path: `/reports/${props.clientId}/careplan`,
}).fullPath);

const hospitalPassportIsEnabled = computed(() => featureToggles.value.hospitalPassport);

const showEmergencyPack = computed(() => featureToggles.value.emergencyPack);

const covidTracker = computed(() => featureToggles.value.covidTracker);

const showEmergencyPackQR = computed(() => {
  const emergencyPackQrStates = ['admitted', 'onboarding', 'enrolled', 'template', 'suspended'];
  return featureToggles.value.qrCodeEmergencyPack && userHasPermission('manageCareplan') && props.stateInOrg && emergencyPackQrStates.includes(props.stateInOrg);
});

const clientTerm = computed(() => window.currentOrganisationUnit.client_term);

const generateDailyReport = () => {
  const today = route.params.date ? new Date(route.params.date.toString()) : new Date();
  generateClientReport(
    'InteractionReport',
    appendClientNameToString(translate('client.reports.daily')),
    {
      clientId: props.clientId,
      fromDate: today,
      toDate: today,
      filter: 'daily',
      state: 'closed',
    },
  );
};

const generateWeeklyReport = () => {
  const today = route.params.date ? new Date(route.params.date.toString()) : new Date();
  generateClientReport(
    'InteractionReport',
    appendClientNameToString(translate('client.reports.weekly')),
    {
      clientId: props.clientId,
      fromDate: startOfWeek(today),
      toDate: endOfWeek(today),
      filter: 'weekly',
      state: 'closed',
      groupBy: 'service_id',
    },
  ).then(goToReports);
};

const generateMonthlyReport = () => {
  const today = route.params.date ? new Date(route.params.date.toString()) : new Date();
  generateClientReport(
    'InteractionReport',
    appendClientNameToString(translate('client.reports.monthly')),
    {
      clientId: props.clientId,
      fromDate: startOfMonth(today),
      toDate: endOfMonth(today),
      filter: 'monthly',
      groupBy: 'service_id',
    },
  ).then(goToReports);
};

const generateCareplanReport = (includeLogs: boolean) => {
  const fields = [includeLogs ? 'interaction_logs' : 'critical_information'];
  generateClientReport(
    'ClientCareplanReport',
    appendClientNameToString(translate('client.care_plan')),
    { fields },
  ).then(goToReports);
};

const generateClientProfileReport = () => {
  generateClientReport(
    'ClientProfileReport',
    appendClientNameToString(translate('client.reports.profile')),
    {
      fields: ['status_in_organisation', 'biography'],
    },
  ).then(goToReports);
};

const generateEmergencyPack = (eRedBag?: boolean) => {
  document.body.classList.add('loading-reports');
  generateClientReport(
    'EmergencyPackReport',
    appendClientNameToString(translate('client.emergency_pack')),
    {
      fields: ['status_in_organisation', 'biography'],
      eRedBag,
    },
  ).then(openReportInNewTab)
    .finally(() => document.body.classList.remove('loading-reports'));
};

const confirmQrCallback = () => {
  sendAnalyticsEvent('clients-generate_emergency_pack_qr_code_continues_through_to_generate_code');
  memorizedEmergencyPackQrCode(props.clientId, window.currentOrganisationUnit.id!).then((res) => {
    const blob = new Blob([res], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_empty');
    clientHasEmergencyQrCode.value = true;
  });
};

const cancelQrCallback = () => {
  sendAnalyticsEvent('clients-generate_emergency_pack_qr_code_journey_abandoned');
  confirmQrEmergencyPackModalOpen.value = false;
};

const generateEmergencyPackQR = () => {
  if (!props.birthDate) {
    noDobQrEmergencyPackModelOpen.value = true;
  } else {
    sendAnalyticsEvent('clients-generate_emergency_pack_qr_code_clicked_option_to_create');
    confirmQrEmergencyPackModalOpen.value = true;
  }
};

const revokeQrCallback = async () => {
  toast(translate('client.emergency_pack_modal.revoke_qr_toast'), ToastType.Success);
  sendAnalyticsEvent('clients-generate_emergency_pack_qr_code_revoke_confirmed');
  await revokeEmergencyPackQrCode(props.clientId, window.currentOrganisationUnit.id!);
  revokeQrModalOpen.value = false;
};

const revokeEmergencyPackQR = () => {
  revokeQrModalOpen.value = true;
};

const showEredBag = computed(() => featureToggles.value.eRedBag
  && featureToggles.value.emergencyPack
  && !!props.birthDate
  && !!props.healthProfile?.healthNumber
  && !!window.currentOrganisationUnit.ods);

const goToReports = () => {
  router.push({
    path: '/reports',
  });
};

const goToNewReport = () => {
  router.push({
    path: '/reports/new?clientId',
    query: { clientId: props.clientId },
  });
};

const goToHospitalPassport = () => {
  router.push({ path: `/clients/${props.clientId}/hospital_passport/print` });
};

const openReportInNewTab = (report: Report | undefined) => {
  if (report && report.file) {
    const deviceUuid = window.localStorage.getItem('deviceUUID');
    const url = `${report.file?.url}?device_uuid=${deviceUuid}`;
    window.open(url, '_blank');
  }
};

const appendClientNameToString = (input: string) => `${input} - ${props.fullName}`;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!covidTracker.value)
        ? (_openBlock(), _createBlock(BaseButton, {
            key: 0,
            "data-toggle": "dropdown",
            size: "small",
            buttonType: "secondary",
            class: "dropdown-toggle sp-report-links"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("i", { class: "fa fa-file-text-o" }, null, -1),
              _createElementVNode("span", { class: "caret" }, null, -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_2, [
        (canSeeInteractions.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createElementVNode("a", { onClick: generateDailyReport }, _toDisplayString(_unref(translate)('client.reports.daily')), 1)
            ]))
          : _createCommentVNode("", true),
        (canSeeInteractions.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("a", { onClick: generateWeeklyReport }, _toDisplayString(_unref(translate)('client.reports.weekly')), 1)
            ]))
          : _createCommentVNode("", true),
        (canSeeInteractions.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("a", { onClick: generateMonthlyReport }, _toDisplayString(_unref(translate)('client.reports.monthly')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: generateClientProfileReport,
            class: "sp-client-profile-link"
          }, _toDisplayString(_unref(translate)('client.reports.profile')), 1)
        ]),
        (canReadCareplan.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (generateCareplanReport(true))),
                class: "sp-care-plan-link"
              }, _toDisplayString(_unref(translate)('client.reports.with_logs')), 1)
            ]))
          : _createCommentVNode("", true),
        (canReadCareplan.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (generateCareplanReport(false))),
                class: "sp-care-plan-link-with-logs"
              }, _toDisplayString(_unref(translate)('client.reports.without_logs')), 1)
            ]))
          : _createCommentVNode("", true),
        (hospitalPassportIsEnabled.value && canReadCareplan.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
              _createElementVNode("a", {
                target: "_blank",
                onClick: goToHospitalPassport
              }, _toDisplayString(_unref(translate)('client.hospital_passport')), 1)
            ]))
          : _createCommentVNode("", true),
        (canReadCareplan.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createElementVNode("a", {
                target: "_blank",
                href: careplanAuditReportLink.value
              }, _toDisplayString(_unref(translate)('client.reports.careplan_audit')), 9, _hoisted_10)
            ]))
          : _createCommentVNode("", true),
        (showEmergencyPack.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (generateEmergencyPack(false))),
                class: "sp-emergency-pack-link"
              }, _toDisplayString(_unref(translate)('client.emergency_pack')), 1)
            ]))
          : _createCommentVNode("", true),
        (showEmergencyPackQR.value && !clientHasEmergencyQrCode.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
              _createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (generateEmergencyPackQR())),
                class: "qr-code-link sp-emergency-pack-qr-link"
              }, _toDisplayString(_unref(translate)('client.emergency_pack_qr_generate')), 1)
            ]))
          : _createCommentVNode("", true),
        (showEmergencyPackQR.value && clientHasEmergencyQrCode.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
              _createElementVNode("a", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (revokeEmergencyPackQR())),
                class: "qr-code-link sp-emergency-pack-qr-revoke"
              }, _toDisplayString(_unref(translate)('client.emergency_pack_qr_revoke')), 1)
            ]))
          : _createCommentVNode("", true),
        (showEredBag.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
              _createElementVNode("a", {
                class: "sp-e-red-bag-link",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (generateEmergencyPack(true)))
              }, "Send eRedBag")
            ]))
          : _createCommentVNode("", true),
        (canSeeInteractions.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
              _createElementVNode("a", {
                onClick: goToNewReport,
                target: "_blank"
              }, _toDisplayString(_unref(translate)('client.reports.custom')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (noDobQrEmergencyPackModelOpen.value)
      ? (_openBlock(), _createBlock(BaseAlert, {
          key: 0,
          icon: "info",
          onConfirmAlert: _cache[6] || (_cache[6] = () => noDobQrEmergencyPackModelOpen.value = false),
          onCancelAlert: _cache[7] || (_cache[7] = () => noDobQrEmergencyPackModelOpen.value = false),
          confirmText: _unref(translate)('common.cancel')
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('client.emergency_pack_modal.missing_dob_title')), 1)
          ]),
          body: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('client.emergency_pack_modal.missing_dob_body', { client_term: clientTerm.value })), 1)
          ]),
          "footer-right": _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("span", null, null, -1)
          ])),
          _: 1
        }, 8, ["confirmText"]))
      : _createCommentVNode("", true),
    (confirmQrEmergencyPackModalOpen.value)
      ? (_openBlock(), _createBlock(BaseAlert, {
          key: 1,
          icon: "info",
          onConfirmAlert: confirmQrCallback,
          onCancelAlert: cancelQrCallback,
          confirmText: _unref(translate)('common.yes')
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('client.emergency_pack_modal.confirm_qr_title')), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_unref(translate)('client.emergency_pack_modal.confirm_qr_body')), 1),
            _createElementVNode("p", null, _toDisplayString(_unref(translate)('client.emergency_pack_modal.confirm_qr_body_question')), 1)
          ]),
          _: 1
        }, 8, ["confirmText"]))
      : _createCommentVNode("", true),
    (revokeQrModalOpen.value)
      ? (_openBlock(), _createBlock(BaseAlert, {
          key: 2,
          icon: "info",
          onConfirmAlert: revokeQrCallback,
          onCancelAlert: _cache[8] || (_cache[8] = () => revokeQrModalOpen.value = false),
          confirmText: _unref(translate)('common.yes')
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('client.emergency_pack_modal.confirm_qr_title')), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_unref(translate)('client.emergency_pack_modal.revoke_qr')), 1),
            _createElementVNode("p", null, _toDisplayString(_unref(translate)('client.emergency_pack_modal.revoke_qr_question')), 1)
          ]),
          _: 1
        }, 8, ["confirmText"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})