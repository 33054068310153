import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-signature-consent-container" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-signature-consent-title" }
const _hoisted_5 = { class: "v-header-right" }
const _hoisted_6 = {
  key: 0,
  class: "v-not-completed"
}
const _hoisted_7 = { key: 1 }

import { computed, ref, onBeforeMount } from 'vue';
import { fetchLatestCareplanAgreement } from '@/timeline/services/interactionsApi';
import IInteraction from '@/timeline/types/IInteraction';
import use from '@/_shared/compositionApi';
import clientStore from '@/_shared/store/clients';
import FileParameter from '@/timeline/interaction_parameters/FileParameter.vue';
import SignatureParameter
  from '@/timeline/interaction_parameters/SignatureParameter/SignatureParameter.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import CarePlanResponsibleCarers from '@/careplan/components/CarePlanResponsibleCarers.vue';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LatestCarePlanConsent',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  carePlanTerm: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const { translate } = use.helpers();
const interaction = ref<IInteraction|null>(null);
const preferredName = computed(() => clientStore.preferredOrFirstName(props.clientId).value);

const fileParameter = computed(() => interaction.value?.parameters?.find((parameter) => parameter.valueType === 'file'));
const signatureParameters = computed(() => interaction.value?.parameters?.filter((parameter) => parameter.valueType === 'signature'));
const loading = ref(true);
onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('timeline');
  interaction.value = await fetchLatestCareplanAgreement(props.clientId);
  loading.value = false;
});

const print = () => {
  window.print();
  return false;
};

return (_ctx: any,_cache: any) => {
  const _directive_base_tooltip = _resolveDirective("base-tooltip")!

  return (!loading.value)
    ? (_openBlock(), _createBlock(BaseCard, {
        key: 0,
        headerBackground: true,
        padding: !interaction.value
      }, _createSlots({
        content: _withCtx(() => [
          (interaction.value === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(translate)('careplan.agreement_not_completed', { care_plan_term: __props.carePlanTerm, first_name: preferredName.value })), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (fileParameter.value)
                  ? (_openBlock(), _createBlock(FileParameter, {
                      key: 0,
                      parameter: fileParameter.value,
                      readOnly: "",
                      hideHeading: ""
                    }, null, 8, ["parameter"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(signatureParameters.value, (parameter) => {
                  return (_openBlock(), _createBlock(SignatureParameter, {
                    key: parameter.id,
                    parameter: parameter,
                    carePlanView: "",
                    hideHeading: ""
                  }, null, 8, ["parameter"]))
                }), 128)),
                _createVNode(CarePlanResponsibleCarers, { interaction: interaction.value }, null, 8, ["interaction"])
              ]))
        ]),
        _: 2
      }, [
        (interaction.value)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(BaseIcon, {
                        name: "round-icon nsx-font-icon--care_plan_signature",
                        size: "2x",
                        noHover: "",
                        compact: "",
                        backgroundColor: "black",
                        foregroundColor: "white",
                        foregroundHoverColor: "white",
                        class: "v-service-icon"
                      }),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)(`careplan.latest_consent_title`, {
                      care_plan_term: __props.carePlanTerm,
                    })), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _withDirectives(_createVNode(BaseIcon, {
                        name: "print",
                        onClick: print,
                        size: "sm",
                        transparent: "",
                        "foreground-hover-color": "blue",
                        "foreground-color": "black"
                      }, null, 512), [
                        [_directive_base_tooltip, { position: 'top', tooltipText: 'Print' }]
                      ])
                    ])
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["padding"]))
    : _createCommentVNode("", true)
}
}

})