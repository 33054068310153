import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-list-table" }
const _hoisted_2 = { class: "v-thead" }
const _hoisted_3 = {
  key: 0,
  class: "v-header-text"
}

import {
  ref,
  PropType, onBeforeMount,
} from 'vue';
import BaseListTableRow from '@/_shared/components/display/BaseListTable/BaseListTableRow.vue';
import { ListTableRow } from '@/_shared/components/display/BaseListTable/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseListTable',
  props: {
  headers: {
    type: Array as PropType<string[]>,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: [],
  },
  rows: {
    type: Array as PropType<ListTableRow[]>,
    required: true,
  },
  displayExtraSlotColumn: {
    type: Boolean,
    default: false,
  },
  extraSlotColumnHeader: {
    type: String,
    default: ' ',
  },
  headerBlack: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const defaultHeaders = ref<string[]>([]);

const headerReformat = (header: string) => header.charAt(0).toUpperCase() + header.slice(1).replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1').toLowerCase();

onBeforeMount(() => {
  if (props.headers.length) {
    defaultHeaders.value = props.headers.map(
      (header) => header.charAt(0).toUpperCase() + header.slice(1),
    );
    return;
  }
  const rows = [...props.rows];
  if (rows.length) {
    const lastRow = rows[0];
    const cellHeaders = Object.keys(lastRow.cells);
    defaultHeaders.value = cellHeaders.map(
      (header) => headerReformat(header),
    );
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", _hoisted_2, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(defaultHeaders.value, (header, index) => {
          return (_openBlock(), _createElementBlock("th", {
            key: header+index,
            class: _normalizeClass(["v-header-text", __props.headerBlack ? 'v-header-text-black' : ''])
          }, _toDisplayString(header), 3))
        }), 128)),
        (__props.displayExtraSlotColumn)
          ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString(__props.extraSlotColumnHeader), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rows, (row) => {
        return (_openBlock(), _createBlock(BaseListTableRow, {
          key: row.identifier,
          row: row,
          displayExtraSlotColumn: __props.displayExtraSlotColumn
        }, _createSlots({
          actions: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "actions", _mergeProps({ ref_for: true }, slotProps || {}))
          ]),
          _: 2
        }, [
          _renderList(_ctx.$slots, (_, slot) => {
            return {
              name: slot,
              fn: _withCtx((slotProps) => [
                _renderSlot(_ctx.$slots, slot, _mergeProps({ ref_for: true }, slotProps || {}))
              ])
            }
          })
        ]), 1032, ["row", "displayExtraSlotColumn"]))
      }), 128))
    ])
  ]))
}
}

})