import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';
import { DataViewKey } from '@/_shared/types/report';
import { CompositeOption } from '@/_shared/types/baseSelect';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportDataViewSelect',
  setup(__props) {


const { translate } = use.helpers();

const dataViewOptions: CompositeOption<DataViewKey>[] = [
  { value: 'normal', text: translate('reports.data_view.normal') },
  { value: 'extended', text: translate('reports.data_view.extended') },
];

const { newReport } = useReportStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCheckboxGroup, {
    modelValue: _unref(newReport).viewType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).viewType) = $event)),
    options: dataViewOptions,
    label: _unref(translate)('reports.data_view.title'),
    "standard-input-style": ""
  }, null, 8, ["modelValue", "label"]))
}
}

})