import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "v-bodymap-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "v-bodymap-wrapper-header"
}
const _hoisted_3 = { class: "resident-dashboard-section-icon" }
const _hoisted_4 = { class: "resident-dashboard-section-text" }
const _hoisted_5 = { class: "resident-dashboard-section-value sp-resident-dashboard-nourish-instances v-bodymap-wrapper-text" }
const _hoisted_6 = { class: "v-bodymap-wrapper-title-text" }
const _hoisted_7 = { class: "resident-dashboard-section-value v-resident-dashboard-nourish-instances-subtitle sp-resident-dashboard-subtitle" }
const _hoisted_8 = {
  key: 0,
  class: "v-resident-dashboard-plain-text"
}
const _hoisted_9 = {
  key: 1,
  class: "v-resident-dashboard-italic-text"
}
const _hoisted_10 = { class: "v-tooltip v-resident-dashboard-skin-and-wounds-scrollable-wrapper" }

import {
  ref, computed, watch,
} from 'vue';
import { NourishInstance, SkinInstance } from '@/_shared/types/NourishInstance';
// yarn type-check errors on masonry-layout
// eslint-disable-next-line
// @ts-ignore
import Masonry from 'masonry-layout';
import { useRoute, useRouter } from 'vue-router';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { safeTranslate } from '@/_shared/translations/i18n';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import vBaseTooltip from '@/_shared/directives/BaseTooltip';
import clientStore from '@/_shared/store/clients';
import instancesHelper from '@/_shared/helpers/useInstancesHelper';
import BodyMapTimeline from '../../timeline/components/BodyMap/BodyMapTimeline.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapWrapper',
  props: {
  clientId: {
    type: String,
    required: true,
  },
},
  setup(__props) {
const masonry = ref<Masonry>();
const route = useRoute();
const router = useRouter();
const props = __props;

const hideSectionTooltip = ref(true);

const { groupInstancesByState } = instancesHelper();

const routeToInteraction = async (instance: NourishInstance) => {
  router.push({
    name: 'client.timeline.body_map.instance',
    params: { clientId: instance.clientId, instanceId: instance.id },
    force: true,
    query: route.query,
  });
};

const routeToBodymap = async () => {
  router.push({ name: 'client.timeline.body_map', force: true, query: route.query });
};

const updateMargin = (skinInstancesElement: HTMLElement) => {
  const firstInstanceElement = skinInstancesElement.getElementsByClassName('v-list-entry')[0] as HTMLElement;
  if (firstInstanceElement && firstInstanceElement.style?.marginTop !== '0') {
    firstInstanceElement.style.marginTop = '0';
  }
};

const resizeSkinInstancesList = () => {
  const skinInstancesElement = document.getElementsByClassName('v-bodymap-wrapper')?.[0] as HTMLElement;

  if (!skinInstancesElement || skinInstancesElement?.childElementCount <= 0) {
    return;
  }

  updateMargin(skinInstancesElement);
  updateElementHeight(skinInstancesElement);
};

const handleScrollBar = () => {
  document.querySelectorAll('.v-resident-dashboard-skin-and-wounds-scrollable').forEach((element) => {
    const scrollToShowLength = 2;
    if (instancesRequiringAttention.value <= scrollToShowLength) {
      element.classList.add('hide-scrollbar');
    } else {
      element.classList.remove('hide-scrollbar');
    }
  });
};

const runReflowMasonry = () => {
  if (!masonry.value) {
    masonry.value = new Masonry('.v-critical-info-categories', {
      itemSelector: '.v-critical-info-category',
      columnWidth: '.v-critical-info-category',
      horizontalOrder: true,
      percentPosition: true,
      transitionDuration: 0,
      initLayout: true,
    });
  } else if (masonry.value.layout) {
    masonry.value.layout();
  }
};

const alignElements = () => {
  resizeSkinInstancesList();
  handleScrollBar();
  // Appease vue-mess-detector
  const ONE_SECOND = 1000;
  setTimeout(() => {
    // If there are no instances, remove the section
    const skinInstancesWrapper = document.getElementsByClassName('v-bodymap-wrapper')?.[0] as HTMLElement;
    if (skinInstancesWrapper && skinInstancesWrapper.childElementCount <= 0) {
      skinInstancesWrapper.remove();
    }
  }, ONE_SECOND);
  setTimeout(() => {
    // If there are no instances, remove the section
    runReflowMasonry();
  }, 5);
};

const skinInstances = computed(() => clientStore.skinInstances(Number(props.clientId)).value);
const groupedInstances = computed(() => groupInstancesByState<SkinInstance>(skinInstances.value, 'body_map.state'));
const filteredInstances = computed(() => {
  let instances = [] as SkinInstance[];
  groupedInstances.value.forEach((instanceGroup) => {
    instances = instances.concat(instanceGroup.instances.filter((instance) => instance.state === 'active' || instance.state === 'new'));
  });
  return instances;
});
const instanceCount = computed(() => filteredInstances.value.length);
const instancesRequiringAttention = computed(() => filteredInstances.value.filter((instance) => instance.state === 'new'
  || (
    instance.state === 'active' && (
      (instance.missedInteractions?.length)
      || (
        !instance.interactions.some((interaction) => interaction.state === 'planned')
      )
    ))).length);

const calculateHeight = () => {
  let initialSize = 250;
  const lengthThatFits = 42;
  const specificTypeLength = filteredInstances.value[0].metadata.specificType.length;
  if (specificTypeLength > lengthThatFits) {
    initialSize += (Math.ceil(specificTypeLength / lengthThatFits) - 1) * 24;
  }
  return `${initialSize}px`;
};

const updateElementHeight = (skinInstancesElement: HTMLElement) => {
  let size = '32rem';
  switch (instancesRequiringAttention.value) {
    case 0:
      size = '80px';
      break;
    case 1:
      size = calculateHeight();
      break;
    case 2:
      size = '415px';
      break;
    default:
      size = '32rem';
      break;
  }

  if (skinInstancesElement?.style?.height !== size) {
    skinInstancesElement.style.height = size;
    skinInstancesElement.style.maxHeight = size;
  }
};

// Whenever the list size changes we may need to reflow masonry layout; Fixes the initial size problem
watch(filteredInstances, (val) => val.length && setTimeout(alignElements, 100), { immediate: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (skinInstances.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(BaseTooltip, {
              position: "left",
              tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.icon_tooltip')
            }, {
              default: _withCtx(() => [
                _createVNode(BaseIcon, {
                  name: "nsx-font-icon--body-map",
                  "background-shape": "circle",
                  "background-color": "black",
                  foregroundColor: "white",
                  class: "v-icon v-body-map-icon v-body-map-open v-bodymap-wrapper-icon",
                  height: "60px",
                  width: "60px",
                  noHover: ""
                })
              ]),
              _: 1
            }, 8, ["tooltipText"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                onClick: routeToBodymap,
                class: "v-resident-dashboard-nourish-instances-title sp-resident-dashboard-title-link"
              }, [
                _createVNode(BaseTooltip, {
                  position: "top",
                  tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.title_tooltip')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.title")) + " (" + _toDisplayString(instanceCount.value) + ")", 1)
                  ]),
                  _: 1
                }, 8, ["tooltipText"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (instanceCount.value > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.wounds_requiring_attention")) + " (" + _toDisplayString(instancesRequiringAttention.value) + ") ", 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.no_wounds_requiring_attention")), 1))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
      (instancesRequiringAttention.value > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "v-instance-requiring-attention v-resident-dashboard-skin-and-wounds-scrollable",
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (hideSectionTooltip.value = false)),
            onFocusin: _cache[1] || (_cache[1] = ($event: any) => (hideSectionTooltip.value = false)),
            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (hideSectionTooltip.value = true)),
            onFocusout: _cache[3] || (_cache[3] = ($event: any) => (hideSectionTooltip.value = true))
          }, [
            _createVNode(BodyMapTimeline, {
              "client-id": __props.clientId,
              "show-body-map-filter": false,
              "list-only": true,
              onSelectInstance: routeToInteraction,
              fluid: true
            }, null, 8, ["client-id"])
          ], 32))
        : _createCommentVNode("", true)
    ])), [
      [_unref(vBaseTooltip), { tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.section_tooltip'), position: 'left', hide: hideSectionTooltip.value }]
    ])
  ]))
}
}

})