import CollectionStore from '@/_shared/store/collectionStore';
import GenericFile from '@/generic_file/types/GenericFile';
import apiClient from '@/_shared/services/apiClient';
import { ApiRequestConfig } from '@/_shared/services/apiClientBase';
import { CoercedChoice, CoercedSelection } from '@/timeline/types/Parameter';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  getContactPersonDataPointValue,
  hasEmergencyInfoDataPointsHelper,
  setEmergencySituation, setEvacuationEquipment, setPEEP, setRescueMedication,
} from '../helpers/dataPoints.utils';

const EMERGENCY_DATAPOINTS = [
  'emergencySituationDetails',
  'hasPeep',
  'peepLocation',
  'evacuationEquipment',
  'myEvacuationEquipmentNewAo',
  'rescueMedication',
  'rescueMedicationLocation',
  'rescueMedicationDetails',
  'contactPerson',
  'paramedicAssistance'];

interface ClientDataPointsFromAPI extends Datapoints {
  [key: string]: unknown
  clientId: number;

}

interface ClientDataPoints extends Datapoints {
  id: number;
}

export interface Datapoints {

  dataPoints?: Record<string, Datapoint>;

  items?: string[];
}

export type DatapointsApiResponse = ClientDataPointsFromAPI[];
// If generic file type changes, we may consider typing here
export class Datapoint {
  value?: DataPointValue;

  changedAt?: string;

  finishAt?: string;

  interactionId?: string;

  changed_at?: string;
}
export type DataPointValue = string[] | string | GenericFile | CoercedChoice[] | CoercedChoice | CoercedSelection;

class DataPointsStore extends CollectionStore<ClientDataPoints, DatapointsApiResponse> {
  fetch(ids: number[]): Promise<DatapointsApiResponse> {
    const data = { ids };
    const config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>> = {};
    apiClient.addHeader(config, 'Organisation-Unit-id', `${window.currentOrganisationUnit.id}`);
    return apiClient.post<DatapointsApiResponse>('/api/v2/data_points', data, config);
  }

  protected extractData(resp: DatapointsApiResponse): ClientDataPoints[] {
    return resp.map((datapoint) => {
      const { clientId, ...dp } = datapoint;
      return { id: clientId, ...dp };
    });
  }

  clientDataPoints = (id: number) => this.byId(id).dataPoints;
}

const timeout = 10 * 60 * 1000; // 10 minutes

export const dataPointsStore: DataPointsStore = new DataPointsStore(timeout);

export const useClientDataPointsStore = defineStore('clientDataPoints', () => {
  const clientDataPoints = ref<Record<string, Datapoint> | undefined>();

  const setClient = async (clientId: number) => {
    clientDataPoints.value = await dataPointsStore.clientDataPoints(clientId);
  };

  const emergencyDatapoints = computed(() => clientDataPoints.value && Object.fromEntries(Object.entries(clientDataPoints.value).filter(([key]) => EMERGENCY_DATAPOINTS.includes(key))));
  const formattedEmergencyInformationDataPoints = computed(() => emergencyDatapoints.value && ({
    ...setEmergencySituation(emergencyDatapoints.value),
    ...setPEEP(emergencyDatapoints.value),
    ...setEvacuationEquipment(emergencyDatapoints.value),
    ...setRescueMedication(emergencyDatapoints.value),
  }));

  const formattedAmbulanceDataPointValue = computed(() => {
    if (emergencyDatapoints.value) {
      let datapoint = emergencyDatapoints.value.paramedicAssistance;
      if (!datapoint || !datapoint.value) {
        datapoint = { value: '' };
      }
      return {
        heading: 'emergency_information.paramedic_assistance.heading',
        phone: 'emergency_information.paramedic_assistance.phone_number',
        details: datapoint.value || null,
        changedAt: datapoint.changedAt,
      };
    }
    return undefined;
  });

  const contactPersonDataPointValue = computed(() => emergencyDatapoints.value && getContactPersonDataPointValue(emergencyDatapoints.value));
  const hasEmergencyInfoDataPoints = computed(() => emergencyDatapoints.value && hasEmergencyInfoDataPointsHelper(emergencyDatapoints.value));

  return {
    setClient,
    clientDataPoints,
    emergencyDatapoints,
    formattedEmergencyInformationDataPoints,
    formattedAmbulanceDataPointValue,
    contactPersonDataPointValue,
    hasEmergencyInfoDataPoints,
  };
});
