import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-fixed-items" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "v-non-fixed-items" }
const _hoisted_4 = {
  key: 0,
  class: "v-scrollable-items"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "v-timeline-section v-timeline-date" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

import {
  computed, onBeforeMount, onMounted, onUnmounted, PropType, ref, watchEffect,
} from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
import useInteractionsStore from '@/_shared/store/interactions';
import IInteraction from '@/timeline/types/IInteraction';
import InstanceInteractionListEntry
  from '@/timeline/components/InteractionListEntry/InstanceInteractionListEntry.vue';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';
import { BaseIcon } from '@/_shared/components';
import NoResultsMessage from '@/_shared/components/general/left_panel/NoResultsMessage.vue';
import use from '@/_shared/compositionApi';
import { formatInteractionDate } from '@/_shared/helpers/useDateTimeHelper';
import { NourishInstance } from '@/_shared/types/NourishInstance';
import InteractionOrgUnitRestrictedView from '@/timeline/components/InteractionOrgUnitRestrictedView.vue';
import useUserStore from '@/_shared/store/user';
import { InteractionGroup, InteractionGroupWithRestrictedAccess } from '@/_shared/types/interactionExt';
import InteractionRestrictedGroupView from './InteractionRestrictedGroupView.vue';
import OrgUnitData from '../types/OtherOrganisationUnit';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceViewTimeline',
  props: {
  instanceId: {
    type: Number,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
  searchTerm: {
    type: String,
    default: '',
  },
  selectedInstance: {
    type: Object as PropType<NourishInstance>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const {
  fetchBulkInteractions,
  searchForClient,
  interactionsSortByDate,
  getCurrentInteractionIdsForInstance,
  findInteraction,
  compareInteractionDate,
} = useInteractionsStore();
const { interactions } = storeToRefs(useInteractionsStore());
const { currentUser } = storeToRefs(useUserStore());
const selectedInstanceId = ref<number>();

const interactionsByDate = ref<InteractionGroup[]>([]);

const startInteraction = ref<IInteraction>();
const fixedStartInteraction = ref(true);
const showPreviousClicked = ref(false);
const router = useRouter();
const allreadyFetchedInteactionIds = ref<number[]>([]);

const storeInstanceInteractions = computed(() => interactions.value.filter((i) => i.nourishInstanceId === props.selectedInstance.id).sort((a, b) => interactionsSortByDate(b, a)));

const currentInteractions = computed(() => getCurrentInteractionIdsForInstance(storeInstanceInteractions.value, props.selectedInstance).map((interactionId) => interactions.value.find((i) => i.id === interactionId) as IInteraction));

const remainingInteractionIds = computed(() => {
  const remainingIds:number[] = props.selectedInstance.interactions.filter(
    (interaction) => interaction.organisationUnitId === window.currentOrganisationUnit.id && storeInstanceInteractions.value.length > 0 && storeInstanceInteractions.value.find(
      (i) => i.id === interaction.id,
    ) === undefined,
  ).map((i) => i.id as number);
  return remainingIds.filter((id) => !allreadyFetchedInteactionIds.value.includes(id));
});

const pastInteractions = computed(() => storeInstanceInteractions.value.filter((interaction) => !currentInteractions.value.includes(interaction) && interaction.id !== startInteraction.value?.id).sort((a, b) => interactionsSortByDate(a, b)));

const fetchInteractions = async (ids: (number | undefined)[]) => {
  // TODO: Align fetch from store with updated store structure
  if (ids.length) {
    await fetchBulkInteractions(ids.filter((id) => id) as number[], true, false);
  }
};

const pageLoading = ref(false);

const resetInteractionsState = () => {
  fixedStartInteraction.value = true;
  resetState();
  useInteractionsStore().$reset();
};

const resetState = () => {
  interactionsByDate.value = [];
};

const queryCurrentInstanceInteractions = async () => {
  if (props.selectedInstance.id === selectedInstanceId.value) return;

  selectedInstanceId.value = props.selectedInstance.id;
  resetState();
  let instanceInteractions: IInteraction[] = [];
  if (props.selectedInstance) instanceInteractions = props.selectedInstance.interactions;
  const idsToFetch = getCurrentInteractionIdsForInstance(instanceInteractions, props.selectedInstance);
  await fetchInteractions([props.selectedInstance.startInteractionId, ...idsToFetch]);
  allreadyFetchedInteactionIds.value.push(...idsToFetch);
  if (props.selectedInstance.startInteractionId) allreadyFetchedInteactionIds.value.push(props.selectedInstance.startInteractionId);
  startInteraction.value = findInteraction(props.selectedInstance.startInteractionId);
  currentInstance.value = props.selectedInstance;
};

onBeforeMount(async () => {
  if (router.currentRoute.value.name === 'client.timeline.body_map.instance'
    || router.currentRoute.value.name === 'client.events.instance'
  ) {
    resetInteractionsState();
  }
  await queryCurrentInstanceInteractions();
});

onBeforeRouteUpdate(async () => {
  await queryCurrentInstanceInteractions();
});

const groupCurrentInteractions = (plannedInteractions: IInteraction[], interactionWithDate: InteractionGroup[], restrictedView = false) => {
  plannedInteractions
    .sort((a, b) => interactionsSortByDate(a, b))
    .forEach((interaction) => groupInteraction(interaction, interactionWithDate, restrictedView));
};

const groupInteraction = (interaction: IInteraction, interactionWithDate: InteractionGroup[], restrictedView: boolean) => {
  const stripHour = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const interactionDate = interaction.startAt || interaction.finishAt;
  if (interactionDate) {
    const group = interactionWithDate.find((g) => g.date === stripHour(interactionDate));
    if (group) {
      group.interactions.push(interaction);
    } else {
      interactionWithDate.push({
        date: stripHour(interactionDate),
        interactions: [interaction],
        restrictedView,
      });
    }
    interactionWithDate.forEach((g) => {
      g.interactions = [...new Set(g.interactions)];
    });
  }
};
watchEffect(() => groupCurrentInteractions(currentInteractions.value, interactionsByDate.value));

const fetchRemainingInteractionsBatch = async () => {
  showPreviousClicked.value = true;
  const idsToFetch: number[] = [];
  const entries = Math.min(10, remainingInteractionIds.value.length);
  for (let i = 0; i < entries; i += 1) idsToFetch.push(remainingInteractionIds.value[i]);
  pageLoading.value = true;
  allreadyFetchedInteactionIds.value.push(...idsToFetch);
  await fetchInteractions(idsToFetch);
  fixedStartInteraction.value = false;
  pageLoading.value = false;
};

const serviceFilteringStore = useServiceFilteringStore();
const { currentInstance } = storeToRefs(serviceFilteringStore);
// todo check what the new behaviour from place button
// todo remove code from onMounted, to be able to use keepAlive -->
onMounted(async () => {
  currentInstance.value = props.selectedInstance;
});

onUnmounted(() => {
  currentInstance.value = null;
});

const sortedInteractionGroups = computed(() => [...interactionsByDate.value].sort((a, b) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);
  return compareInteractionDate(aDate, bDate);
}));

const sortedInteractionGroupsWithRestrictedView = computed(() => [...interactionGroupByDateUserRestricted.value, ...interactionsByDate.value].sort((a, b) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);
  return compareInteractionDate(aDate, bDate);
}));

const compactGroupsWithRestrictedView = computed(() => {
  const compactGroups: InteractionGroupWithRestrictedAccess[] = [];

  if (sortedInteractionGroupsWithRestrictedView.value?.length === 0) return compactGroups;
  let restrictedView = sortedInteractionGroupsWithRestrictedView.value[0]?.restrictedView;
  let group: InteractionGroup[] = [];

  sortedInteractionGroupsWithRestrictedView.value.forEach((interactionGroup) => {
    if (interactionGroup.restrictedView === restrictedView) {
      group.push(interactionGroup);
    } else {
      compactGroups.push({ restrictedView, interactionGroups: group });
      group = [interactionGroup];
      restrictedView = interactionGroup.restrictedView;
    }
  });

  compactGroups.push({ restrictedView, interactionGroups: group });
  return compactGroups;
});

const showRestrictedInteractionInfo = computed(() => useUserStore().getFeatureToggle('interactionAccessRestrictionSkinTimelineMessage'));
const organisationUnitId = window.currentOrganisationUnit.id;
const organisationUnits = window?.currentPerson?.organisation_units;
const carerOrganisationUnits = currentUser.value?.carer?.carerOrganisationUnits;
const searchedInteractions = ref<IInteraction[]>([]);
watchEffect(async () => {
  if (props.searchTerm.length === 0 || !organisationUnitId) {
    searchedInteractions.value = [];
    return;
  }
  searchedInteractions.value = await searchForClient(organisationUnitId, props.clientId, props.searchTerm, null, props.instanceId);
});

watchEffect(() => {
  if (router.currentRoute.value.name === 'client.timeline.interaction' && showPreviousClicked.value) {
    fixedStartInteraction.value = false;
  }
});

const searchTermEmpty = computed(() => props.searchTerm.length === 0);
const getOrgUnitInfo = (interaction: IInteraction) => {
  const carerOrgUnit = Array.isArray(carerOrganisationUnits) ? carerOrganisationUnits.find((cou) => (cou?.organisationUnitId === interaction.organisationUnitId && cou?.state === 'active')) : undefined;
  const orgUnit = Array.isArray(organisationUnits) ? organisationUnits.find((ou) => ou.id === carerOrgUnit?.organisationUnitId) : undefined;
  return orgUnit ? orgUnit.name : undefined;
};

const interactionGroupByDateUserRestricted = computed(() => {
  const firstInteraction = startInteraction.value;
  const viewableInteractionIds = storeInstanceInteractions.value.map((interaction) => interaction.id);
  if (remainingInteractionIds.value.length > 0) {
    viewableInteractionIds.push(...remainingInteractionIds.value);
  }
  if (firstInteraction && firstInteraction.organisationUnitId === organisationUnitId) viewableInteractionIds.push(firstInteraction.id);
  const allInteractionsForInstance = props.selectedInstance?.interactions as IInteraction[];
  const interactionsInCurrentOrgUnit = (allInteractionsForInstance || []).filter((interaction) => interaction.organisationUnitId === organisationUnitId);
  const interactionsWithRestrictedAccess = interactionsInCurrentOrgUnit.filter((interaction) => !viewableInteractionIds.includes(interaction.id));
  const restrictedInteractionsByDate: InteractionGroup[] = [];
  groupCurrentInteractions(interactionsWithRestrictedAccess, restrictedInteractionsByDate, true);

  return restrictedInteractionsByDate;
});

const interactionsNotInCurrentOrgUnitData = computed(() => {
  if (!showRestrictedInteractionInfo.value) {
    return undefined;
  }
  const selectedInteractions = props.selectedInstance?.interactions;
  const interactionsNotInCurrentOrganisationUnit:IInteraction[] = (selectedInteractions || []).filter((interaction) => interaction.organisationUnitId !== organisationUnitId);
  if (interactionsNotInCurrentOrganisationUnit?.length === 0) return undefined;

  const previousOrgUnitsData: OrgUnitData[] = [];
  const groupedByOrganisationUnit = interactionsNotInCurrentOrganisationUnit.reduce((acc: Record<number, IInteraction[]>, item) => {
    const key = item.organisationUnitId;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  Object.values(groupedByOrganisationUnit).forEach((interactionArray) => {
    previousOrgUnitsData.push({
      count: interactionArray.length,
      startDate: interactionArray.reduce((acc, curr) => (acc?.finishAt && curr.finishAt && acc.finishAt < curr.finishAt ? acc : curr)).finishAt || new Date().toISOString(),
      endDate: interactionArray.reduce((acc, curr) => (acc.finishAt && curr.finishAt && acc.finishAt < curr.finishAt ? curr : acc)).finishAt || new Date().toISOString(),
      orgUnitName: getOrgUnitInfo(interactionArray[0]),
    });
  });
  return previousOrgUnitsData.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      (startInteraction.value && fixedStartInteraction.value && searchTermEmpty.value && remainingInteractionIds.value.length)
        ? (_openBlock(), _createBlock(InstanceInteractionListEntry, {
            key: 0,
            class: "v-instance-start-interaction",
            interaction: startInteraction.value
          }, null, 8, ["interaction"]))
        : _createCommentVNode("", true),
      (interactionsNotInCurrentOrgUnitData.value)
        ? (_openBlock(), _createBlock(InteractionOrgUnitRestrictedView, {
            key: 1,
            data: interactionsNotInCurrentOrgUnitData.value
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      (remainingInteractionIds.value.length && searchTermEmpty.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-interaction-divider-wrapper" }, [
              _createElementVNode("hr", { class: "v-interaction-divider" })
            ], -1)),
            (!pageLoading.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: fetchRemainingInteractionsBatch,
                  class: "v-show-previous-button"
                }, [
                  _createVNode(_unref(BaseIcon), {
                    name: "nsx-font-icon--timeline-dotted",
                    "no-hover": true,
                    foregroundColor: "white"
                  }),
                  _createTextVNode(" " + _toDisplayString(_unref(translate)('timeline.interaction.showPrevious')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (startInteraction.value && (!fixedStartInteraction.value || !remainingInteractionIds.value.length) && searchTermEmpty.value)
        ? (_openBlock(), _createBlock(InstanceInteractionListEntry, {
            key: 0,
            class: "v-instance-start-interaction",
            interaction: startInteraction.value
          }, null, 8, ["interaction"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pastInteractions.value, (interaction) => {
        return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
          key: interaction.id!,
          class: "v-current-interaction sp-past-interaction",
          interaction: interaction
        }, null, 8, ["interaction"]))
      }), 128))
    ]),
    (searchTermEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (showRestrictedInteractionInfo.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(compactGroupsWithRestrictedView.value, (group, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(InteractionRestrictedGroupView, { data: group }, null, 8, ["data"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedInteractionGroups.value, (interactionGroup) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "v-instance-current-interactions",
                    key: interactionGroup.date
                  }, [
                    _createElementVNode("h4", _hoisted_7, _toDisplayString(_unref(formatInteractionDate)(interactionGroup.date)), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interactionGroup.interactions, (interaction) => {
                      return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
                        key: interaction.id!,
                        class: "v-current-interaction sp-grouped-interaction",
                        interaction: interaction
                      }, null, 8, ["interaction"]))
                    }), 128))
                  ]))
                }), 128))
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (!searchedInteractions.value || (searchedInteractions.value && searchedInteractions.value.length === 0))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(NoResultsMessage, { noResultsTerm: __props.searchTerm }, {
                  no_results_message: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)('search.no_records_for')), 1)
                  ]),
                  _: 1
                }, 8, ["noResultsTerm"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchedInteractions.value, (interaction) => {
                  return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
                    key: interaction.id!,
                    class: "v-current-interaction sp-searched-interaction",
                    interaction: interaction
                  }, null, 8, ["interaction"]))
                }), 128))
              ]))
        ]))
  ], 64))
}
}

})