import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "checked", "type"]
const _hoisted_2 = {
  key: 1,
  class: "v-radio"
}

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCheckbox',
  props: {
  checked: {
    type: Boolean,
  },
  radio: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  light: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  blackTick: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:checked'],
  setup(__props) {

const props = __props;


const hasLabel = computed(() => props.label.length);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-checkbox-wrapper", hasLabel.value ? 'v-checkbox-wrapper-with-label' : ''])
  }, [
    _createElementVNode("input", {
      class: _normalizeClass([`${__props.light ? 'v-checkbox-light' : 'v-checkbox'} ${__props.center ? 'v-centered' : ''} ${__props.blackTick? 'v-black' : ''}`, "sp-v-checkbox"]),
      disabled: __props.disabled,
      checked: __props.checked,
      type: __props.radio ? 'radio' : 'checkbox',
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checked', ($event.target as HTMLInputElement).checked)))
    }, null, 42, _hoisted_1),
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["v-checkbox-label", __props.radio ? 'v-radio-label' : ''])
        }, _toDisplayString(__props.label), 3))
      : _createCommentVNode("", true),
    (__props.radio)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})