import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-dataset-modal-outer-content-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "v-left-content-wrapper"
}
const _hoisted_3 = { class: "v-dataset-modal-right-content-wrapper" }
const _hoisted_4 = { class: "v-dataset-modal--content-wrapper" }
const _hoisted_5 = { class: "v-dataset-modal--search-wrapper" }
const _hoisted_6 = { class: "v-multi v-dataset-modal--search-input-field" }
const _hoisted_7 = { class: "v-search-icon" }
const _hoisted_8 = { class: "v-data-set" }
const _hoisted_9 = { class: "v-dataset-modal--list-wrapper" }
const _hoisted_10 = { class: "v-all-options" }
const _hoisted_11 = { class: "v-options-title" }
const _hoisted_12 = { class: "v-dataset-entries" }

import {
  computed, inject, PropType, ref, watch,
} from 'vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import {
  DataSetCompositeOption, Option,
} from '@/_shared/types/baseSelect';
import use from '@/_shared/compositionApi';
import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';
import NestedDatasetModalEntry from './NestedDatasetModalEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NestedDatasetModalContent',
  props: {
  searchInputPlaceholder: {
    type: String,
  },
  allSelectableEntries: {
    type: Object as PropType<DataSetCompositeOption[]>,
    required: true,
  },
  primaryTextKey: {
    type: String as PropType<keyof DataSetCompositeOption>,
    required: true,
  },
  secondaryTextKey: {
    type: String,
  },
  selectedEntries: {
    type: Object as PropType<Option[]>,
    required: true,
  },
},
  emits: ['selectedEntries'],
  setup(__props, { emit: __emit }) {

const props = __props;
const modalConfig = inject('dataSetModalConfig', undefined) as DataSetModalConfig | undefined;

const emit = __emit;
const { translate } = use.helpers();

const searchTerm = ref('');
const localSelectedEntries = ref(props.selectedEntries);
const filteredSelectableEntries = ref<DataSetCompositeOption[]>(props.allSelectableEntries);

const searchTermsDictionary: {
  [key: string]: RegExp
} | undefined = modalConfig?.searchTermsDictionary;

const searchTermMatch = computed(() => {
  if (!(searchTerm.value && searchTermsDictionary)) {
    return '';
  }
  const term = searchTerm.value.toLowerCase();
  for (let i = 0; i < Object.entries(searchTermsDictionary).length; i += 1) {
    const [synonym, matchRegExp] = Object.entries(searchTermsDictionary)[i];
    if (matchRegExp.test(term)) {
      return term.replace(matchRegExp, synonym);
    }
  }
  return '';
});

const entryMatchesSearchTerm = (entry: DataSetCompositeOption) => entry.text
  && (entry.text.toLowerCase()
    .includes(searchTerm.value.toLowerCase())
    || (searchTermMatch.value && entry.text.toLowerCase()
      .includes(searchTermMatch.value.toLowerCase())));

const entryPrimaryText = (entry: DataSetCompositeOption) => entry[props.primaryTextKey]?.toString();

const toggleSelectEntry = (entry: DataSetCompositeOption) => {
  if (entry.parent) {
    toggleChildEntry(entry);
  } else {
    toggleParentEntry(entry, false);
  }
};

const toggleChildEntry = (entry: DataSetCompositeOption) => {
  const parentEntry: DataSetCompositeOption | undefined = getSelectableEntry(entry.parent!);
  if (!parentEntry) return;
  if (localSelectedEntries.value.includes(parentEntry.value)) {
    toggleParentEntry(parentEntry, true);
  }

  if (localSelectedEntries.value.includes(entry.value)) {
    deselectEntry(entry.value);
  } else {
    localSelectedEntries.value.push(entry.value);
  }

  if (allChildrenSelected(parentEntry)) {
    toggleParentEntry(parentEntry, true);
  }
};

const allChildrenSelected = (parent: DataSetCompositeOption) => parent.children!.every((child: DataSetCompositeOption) => localSelectedEntries.value.includes(child.value));

const getSelectableEntry = (value: string) => props.allSelectableEntries.find((entry) => entry.value === value);

const toggleParentEntry = (entry: DataSetCompositeOption, selectChildren: boolean) => {
  if (localSelectedEntries.value.includes(entry.value)) {
    deselectEntry(entry.value);
    if (selectChildren) {
      entry.children?.forEach((child: DataSetCompositeOption) => {
        localSelectedEntries.value.push(child.value);
      });
    }
  } else {
    localSelectedEntries.value.push(entry.value);
    entry.children?.forEach((child: DataSetCompositeOption) => {
      if (localSelectedEntries.value.includes(child.value)) {
        deselectEntry(child.value);
      }
    });
  }
};

const deselectEntry = (entryToRemove: Option) => {
  const removeIndex = localSelectedEntries.value?.findIndex((entry) => entry === entryToRemove);
  localSelectedEntries.value.splice(removeIndex, 1);
};

const filterEntries = () => {
  if (!props.allSelectableEntries || !filteredSelectableEntries.value) {
    return;
  }

  filteredSelectableEntries.value = props.allSelectableEntries.filter(
    (entry: DataSetCompositeOption) => {
      if (entry.disabled) {
        return false;
      }

      if (entry.parent) {
        return entryMatchesSearchTerm(entry) || entryMatchesSearchTerm(getSelectableEntry(entry.parent)!);
      }

      if (entry.children) {
        return entryMatchesSearchTerm(entry) || entry.children.some((child) => entryMatchesSearchTerm(child));
      }

      return false;
    },
  );
};
filterEntries();

const entrySelected = ((entry: DataSetCompositeOption) => localSelectedEntries.value
  && (localSelectedEntries.value?.includes(String(entry.value)) || localSelectedEntries.value?.includes(entry.parent!)));

watch(localSelectedEntries, (newSelectedEntries) => {
  emit('selectedEntries', newSelectedEntries);
}, {
  deep: true,
});

const filter = (acceptedValues: string[]) => {
  filteredSelectableEntries.value = props.allSelectableEntries.filter((entry) => acceptedValues.includes(entry.value as string));
  searchTerm.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(modalConfig)?.leftContentComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(modalConfig).leftContentComponent), {
            onFilter: filter,
            selectedEntries: localSelectedEntries.value,
            "onUpdate:selectedEntries": _cache[0] || (_cache[0] = ($event: any) => ((localSelectedEntries).value = $event))
          }, null, 40, ["selectedEntries"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(BaseIcon, {
                name: "search no-margin no-hover",
                size: "lg",
                foregroundColor: "grey"
              })
            ]),
            _createVNode(BaseInput, {
              class: "v-dataset-search",
              border: false,
              modelValue: searchTerm.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchTerm).value = $event)),
              placeholder: __props.searchInputPlaceholder,
              onInput: filterEntries
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(BaseSelect, {
            class: "v-data-set-container sp-multi-value-box",
            modelValue: localSelectedEntries.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localSelectedEntries).value = $event)),
            options: __props.allSelectableEntries,
            "custom-tags": true,
            "show-options": false,
            multiple: true,
            searchable: false
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h5", null, [
                _createElementVNode("strong", null, _toDisplayString(_unref(translate)('common.all_options')) + ":", 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSelectableEntries.value, (entry) => {
                return (_openBlock(), _createBlock(NestedDatasetModalEntry, {
                  key: entry['value'],
                  entry: entry,
                  onToggleSelectEntry: toggleSelectEntry,
                  primaryText: entryPrimaryText(entry),
                  isSelected: entrySelected(entry)
                }, null, 8, ["entry", "primaryText", "isSelected"]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})