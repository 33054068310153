import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-multiselect-parameter-container" }

import DataSetPicker from '@/timeline/interaction_parameters/DataSetPicker.vue';
import { Parameter } from '@/timeline/types/Parameter';
import { provide } from 'vue';
import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleSelectDataSetParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  isBodyMapLocation: {
    type: Boolean,
    default: false,
  },
  modalConfiguration: {
    type: Object as () => DataSetModalConfig,
    required: false,
  },
  showStatus: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const datasetCodenames : string[] = Array.isArray(props.parameter.config!.dataSet!) ? props.parameter.config!.dataSet! : [props.parameter.config!.dataSet!];
provide('dataSetModalConfig', props.modalConfiguration);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(DataSetPicker, {
      parameter: __props.parameter,
      readOnly: __props.readOnly,
      "dataset-codenames": _unref(datasetCodenames),
      selectorType: "single",
      showStatus: props.showStatus
    }, null, 8, ["parameter", "readOnly", "dataset-codenames", "showStatus"])
  ]))
}
}

})