import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-generic-file-container" }
const _hoisted_2 = { class: "v-generic-file-left-col" }
const _hoisted_3 = { class: "v-generic-file-image" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "v-generic-file-main-col" }
const _hoisted_6 = {
  key: 0,
  class: "v-input-div"
}
const _hoisted_7 = { class: "v-edit-pencil" }
const _hoisted_8 = {
  key: 1,
  class: "v-file-label-no-edit"
}
const _hoisted_9 = { class: "v-file-info" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "v-file-description" }
const _hoisted_12 = { class: "v-generic-file-right-col" }

import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';
import { onBeforeMount, ref, toRef } from 'vue';
import BaseExpandingText from '@/_shared/components/display/BaseExpandingText.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import GenericFile from '@/generic_file/types/GenericFile';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import use from '@/_shared/compositionApi';
import GenericFileDeleteConfirmation from './components/GenericFileDeleteConfirmation.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericFileMain',
  props: {
  genericFile: {
    type: Object as () => GenericFile,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const fileLabel = ref<string | undefined>(props.genericFile.fileLabel);
const showDeleteConfirmation = ref<boolean>(false);
const showMoreText = ref('');
const showLessText = ref('');
const createdAtText = ref('');
const originalFileNameText = ref('');

onBeforeMount(async () => {
  // TODO - Rado - why it would be nice to have reactive translate
  await loadModuleLanguageFileAsync('generic_file');
  showMoreText.value = translate('generic_file.see_more');
  showLessText.value = translate('generic_file.collapse_text');
  createdAtText.value = translate('generic_file.created_at');
  originalFileNameText.value = translate('generic_file.original_file_name');
});

const genericFileRef = toRef(props, 'genericFile');
const documentURL = (inline: boolean) => {
  const deviceUuid = window.localStorage.getItem('deviceUUID');
  const { relativeUrl, url } = props.genericFile;
  const fileUrl = relativeUrl || new URL(url!).pathname;
  const params = `?device_uuid=${deviceUuid}`;
  if (inline) {
    window.open(`${fileUrl}${params.concat('&disposition=inline')}`, '_blank');
  } else {
    window.open(`${fileUrl}${params}`);
  }
};

const previewFile = () => {
  documentURL(true);
};

const downloadFile = () => {
  documentURL(false);
};

function onLabelChange(event: Event) {
  const target = event.target as HTMLInputElement;
  fileLabel.value = target.value;
  genericFileRef.value.fileLabel = target.value;
  genericFileRef.value.updatedLabel = true;
}

function showDeleteAlert() {
  showDeleteConfirmation.value = true;
}

function deleteFileAction() {
  hideDeleteAlert();
  genericFileRef.value.deleted = true;
}

function hideDeleteAlert() {
  showDeleteConfirmation.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: genericFileRef.value.iconPath,
            alt: genericFileRef.value.fileLabel
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!__props.readOnly)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(BaseInput, {
                modelValue: fileLabel.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fileLabel).value = $event)),
                transparent: "",
                onChange: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (onLabelChange($event)), ["stop"]))
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(BaseIcon, {
                  name: "pencil no-margin no-hover v-edit-icon",
                  size: "lg"
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(fileLabel.value || genericFileRef.value.fileName), 1)
            ])),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(createdAtText.value) + " " + _toDisplayString(genericFileRef.value.createdAt ? _unref(formatDateTime)(genericFileRef.value.createdAt) : '-') + " ", 1),
            (fileLabel.value !== genericFileRef.value.fileName && genericFileRef.value.fileName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(originalFileNameText.value) + ": " + _toDisplayString(genericFileRef.value.fileName), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(BaseExpandingText, {
            text: genericFileRef.value.fileDescription||'',
            showMoreText: showMoreText.value,
            showLessText: showLessText.value
          }, null, 8, ["text", "showMoreText", "showLessText"])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(BaseTooltip, {
          position: "bottom",
          tooltipText: _unref(translate)('common.preview')
        }, {
          default: _withCtx(() => [
            _createVNode(BaseIcon, {
              name: "eye",
              size: "lg",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (previewFile()))
            })
          ]),
          _: 1
        }, 8, ["tooltipText"]),
        _createVNode(BaseTooltip, {
          position: "bottom",
          tooltipText: _unref(translate)('common.download')
        }, {
          default: _withCtx(() => [
            _createVNode(BaseIcon, {
              name: "download",
              size: "lg",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (downloadFile()))
            })
          ]),
          _: 1
        }, 8, ["tooltipText"]),
        (!__props.readOnly)
          ? (_openBlock(), _createBlock(BaseTooltip, {
              key: 0,
              position: "bottom",
              tooltipText: _unref(translate)('common.delete')
            }, {
              default: _withCtx(() => [
                _createVNode(BaseIcon, {
                  class: "v-file-delete-icon",
                  name: "trash",
                  size: "lg",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (showDeleteAlert()))
                })
              ]),
              _: 1
            }, 8, ["tooltipText"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (showDeleteConfirmation.value)
      ? (_openBlock(), _createBlock(GenericFileDeleteConfirmation, {
          key: 0,
          onDeleteFileAction: _cache[5] || (_cache[5] = ($event: any) => (deleteFileAction())),
          onHideDeleteAlert: _cache[6] || (_cache[6] = ($event: any) => (hideDeleteAlert()))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})