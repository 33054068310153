import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import use from '@/_shared/compositionApi';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportClientInput from '@/reports/inputs/ReportClientInput.vue';
import clientStore from '@/_shared/store/clients';
import useReportStore from '@/_shared/store/reports';
import { BaseSelect } from '@/_shared/components';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { WoundOptions } from '@/_shared/types/report';


export default /*@__PURE__*/_defineComponent({
  __name: 'WoundReport',
  setup(__props) {


const { translate } = use.helpers();

const reportStore = useReportStore();
const { newReport } = storeToRefs(reportStore);

const woundOptions = computed(() => {
  if (!newReport.value.params!.clientIds || typeof newReport.value.params!.clientIds !== 'number') return [];
  return clientStore.skinInstances(newReport.value.params!.clientIds as number).value.map((i) => ({ value: i.id, text: i.reference }));
});
const photoOptions: CompositeOption<WoundOptions>[] = [
  { value: 'AllPhotosForThisWound', text: translate('reports.wounds.all') },
  { value: 'MostRecentPhoto', text: translate('reports.wounds.most_recent') },
  { value: 'FirstAndMostRecentPhoto', text: translate('reports.wounds.first_and_most_recent') },
  { value: 'Last5Photos', text: translate('reports.wounds.last_five') },
  { value: 'Last10Photos', text: translate('reports.wounds.last_ten') },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportClientInput, { multiple: false }),
        _createVNode(_unref(BaseSelect), {
          label: "Wound ID",
          options: woundOptions.value,
          modelValue: _unref(newReport).params.woundId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.woundId) = $event)),
          "standard-input-style": ""
        }, null, 8, ["options", "modelValue"]),
        _createVNode(_unref(BaseSelect), {
          label: "Photo(s)",
          options: photoOptions,
          modelValue: _unref(newReport).params.photoOption,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(newReport).params.photoOption) = $event)),
          "standard-input-style": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['html', 'pdf'] })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})