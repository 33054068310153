import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-critical-info"
}
const _hoisted_2 = { class: "v-critical-info-left" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "v-critical-info-title" }
const _hoisted_7 = {
  key: 0,
  class: "v-critical-info-category sp-resident-dashboard-section-skin-instances"
}
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "v-critical-info-category-content" }
const _hoisted_10 = {
  key: 0,
  class: "v-critical-info-category-name--no-brand sp-resident-dashboard-section-label-no-badge"
}
const _hoisted_11 = {
  key: 0,
  class: "v-critical-info-category-value sp-resident-dashboard-client-type"
}
const _hoisted_12 = {
  key: 1,
  class: "v-critical-info-category-value"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 1,
  class: "v-critical-info-category sp-emergency-pack-btn"
}
const _hoisted_16 = { class: "ambulance-icon" }
const _hoisted_17 = { class: "emergency-text" }

import use from '@/_shared/compositionApi';
import { Report } from '@/_shared/types/report';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
// yarn type-check errors on masonry-layout
// eslint-disable-next-line
// @ts-ignore
import Masonry from 'masonry-layout';
import { storeToRefs } from 'pinia';
import {
  computed, nextTick, onMounted, ref, watch,
} from 'vue';
import { onBeforeRouteUpdate, RouterLink } from 'vue-router';
import { RiskAssesment } from '@/timeline/types/Need';
import useResidentDashboardStore from '@/_shared/store/residentDashboardStore';
import { usePersonPreferenceStore } from '@/_shared/store/personPreference';
import useReportStore from '@/_shared/store/reports';
import useUserStore from '@/_shared/store/user';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import {
  camelToSnakeCase, filterDataPoints, getHighRiskChangedAt, isNotNullObject,
  shouldShowDashboard,
} from './CriticalInformationDashboard.utils';
import ClientCard from './ClientCard.vue';
import {
  ChangeableEntity, getSectionChangedAt, getUpdatedAgoText, lastSeenBeforeUpdated,
} from '../client.utils';
import EmergencyInformationDashboard from './EmergencyInformationDashboard.vue';
import BodyMapWrapper from './BodyMapWrapper.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CriticalInformationDashboard',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  showEmergencyInformation: {
    type: Boolean,
  },
},
  setup(__props) {

const masonry = ref<Masonry>();
const masonryHostRef = ref(null);

const { translate } = use.helpers();
const residentDashboardStore = useResidentDashboardStore();
const { initialize } = residentDashboardStore;
const {
  criticalInformationDashboard, clientDataPoints, clientType, clientTypeName, highRisks, onboardingDays,
  criticalInfos, fullName,
} = storeToRefs(residentDashboardStore);

const { getIconUrl } = useCategoryIconStore();

const personPreferenceStore = usePersonPreferenceStore();
const { setPersonId } = personPreferenceStore;
const { clientsLastSeen } = storeToRefs(personPreferenceStore);

const { currentUser, featureToggles } = storeToRefs(useUserStore());

const reportStore = useReportStore();
const { generateClientReport } = reportStore;

const props = __props;

onMounted(() => {
  initialize(props.clientId);
  setPersonId(props.clientId);
});

onBeforeRouteUpdate(async (to) => {
  initialize(+to.params.clientId);
  setPersonId(+to.params.clientId);
});

const showOldEmergencyDownloadButton = computed(() => !featureToggles.value.emergencyDashboard && !window.familyPortal && featureToggles.value.emergencyPack);
const showSkinManagement = computed(() => featureToggles.value.skinManagementOnCritInfoDashboard);
const clientTerm = computed(() => window.currentOrganisationUnit.client_term);

const getHighRisks = computed(() => {
  if (!highRisks.value) { return []; }
  const result = [...highRisks.value];
  result.sort((a, b) => {
    if (!a.description) {
      return -1;
    } if (!b.description) {
      return 1;
    }
    return (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1;
  });

  return result.map((risk) => {
    const isNavigable = risk && risk.clientCategoryId && risk.clientSubcategoryId && currentUser.value.isImpersonated;
    return {
      ...risk,
      link: isNavigable ? `/clients/${props.clientId}/careplan/client_categories/${risk.clientCategoryId}/client_subcategories/${risk.clientSubcategoryId}` : undefined,
    };
  });
});

const dashboardCategories = computed(() => {
  if (!criticalInformationDashboard.value) {
    return [];
  }
  return criticalInformationDashboard.value.map((category) => {
    const formattedCategoryName = camelToSnakeCase(category.categoryCodename);
    return {
      ...category,
      codeName: category.categoryCodename,
      name: `client.resident_dashboard.categories.${formattedCategoryName}`,
      translatedName: translate(`client.resident_dashboard.categories.${formattedCategoryName}`),
      values: formattedCategoryName === 'high_risk' ? getHighRisks.value : filterDataPoints(clientDataPoints.value!, category.dataPoints!),
      badge: category.badgeColour,
      badgeTextColour: category.badgeTextColour,
      iconUrl: getIconUrl(category.categoryCodename)?.value,
      changedAt: formattedCategoryName === 'high_risk' ? getHighRiskChangedAt(highRisks.value) : getSectionChangedAt(category.dataPoints!, clientDataPoints.value!),
    };
  });
});

const isRisk = (value: string | number | RiskAssesment): value is RiskAssesment => isNotNullObject(value) && 'id' in value;
const isClientType = (category: { codeName: string }) => category.codeName === 'client_type';

const showUpdatedAgo = (category: ChangeableEntity & { name: string, codeName: string, values: unknown }) => !isClientType(category)
  && ((Array.isArray(category.values) && category.values.length) || (!Array.isArray(category.values) && category.values))
  && lastSeenBeforeUpdated(category, props.clientId, clientsLastSeen.value)
  && getUpdatedAgoText(category);

const showDashboard = computed(() => shouldShowDashboard(clientType.value, clientTypeName.value, ''));

const appendClientNameToString = (input: string) => `${input} - ${fullName}`;

const generateEmergencyPack = () => {
  document.body.classList.add('loading-reports');
  generateClientReport('EmergencyPackReport', appendClientNameToString(translate('client.emergency_pack')), {
    fields: ['status_in_organisation', 'biography'],
  }).then(openReportInNewTab)
    .finally(() => document.body.classList.remove('loading-reports'));
};

const openReportInNewTab = (report: Report | undefined) => {
  if (report && report.file) {
    const deviceUuid = window.localStorage.getItem('deviceUUID');
    const url = `${report.file?.url}?device_uuid=${deviceUuid}`;
    window.open(url, '_blank');
  }
};

const initMasonry = () => {
  if (!masonryHostRef.value || !dashboardCategories.value.length) {
    return;
  }

  if (!masonry.value) {
    masonry.value = new Masonry('.v-critical-info-categories', {
      itemSelector: '.v-critical-info-category',
      columnWidth: '.v-critical-info-category',
      horizontalOrder: true,
      percentPosition: true,
      transitionDuration: 0,
      initLayout: true,
    });
  } else if (masonry.value.layout) {
    masonry.value.layout();
  }
};

watch([dashboardCategories, () => props.showEmergencyInformation, () => masonryHostRef.value], async (newValue) => {
  if (!newValue.length) {
    return;
  }
  await nextTick();
  initMasonry();
}, { deep: true });


return (_ctx: any,_cache: any) => {
  return (showDashboard.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(ClientCard, {
            clientId: __props.clientId,
            criticalInfos: _unref(criticalInfos)
          }, null, 8, ["clientId", "criticalInfos"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(`v-critical-info-right ${__props.showEmergencyInformation ? 'v-emergency-info-open' : ''}`)
        }, [
          (__props.showEmergencyInformation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(EmergencyInformationDashboard, { clientId: __props.clientId }, null, 8, ["clientId"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (dashboardCategories.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h2", _hoisted_6, _toDisplayString(_unref(translate)('client.resident_dashboard.title')), 1),
                      _createElementVNode("div", {
                        ref_key: "masonryHostRef",
                        ref: masonryHostRef,
                        class: "v-critical-info-categories"
                      }, [
                        (showSkinManagement.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(BodyMapWrapper, {
                                "client-id": __props.clientId.toString()
                              }, null, 8, ["client-id"])
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dashboardCategories.value, (category) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: category.categoryCodename,
                            class: _normalizeClass(`v-critical-info-category sp-resident-dashboard-section-${category.translatedName}`)
                          }, [
                            _createElementVNode("img", {
                              src: category.iconUrl,
                              alt: category.name,
                              class: "v-critical-info-category-image"
                            }, null, 8, _hoisted_8),
                            _createElementVNode("div", _hoisted_9, [
                              (isClientType(category))
                                ? (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_unref(translate)('client.resident_dashboard.categories.client_type', {
                      client_term:
                        clientTerm.value
                    })), 1))
                                : (_openBlock(), _createElementBlock("h4", {
                                    key: 1,
                                    style: _normalizeStyle({ 'background': category.badgeColour, 'color': category.badgeTextColour }),
                                    class: _normalizeClass(category.badge ? 'v-critical-info-category-name--brand sp-resident-dashboard-section-label-badge' :
                  'v-critical-info-category-name--no-brand sp-resident-dashboard-section-label-no-badge')
                                  }, _toDisplayString(_unref(translate)(category.name)), 7)),
                              _createElementVNode("div", null, [
                                (isClientType(category))
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(clientTypeName)) + " " + _toDisplayString(_unref(onboardingDays)), 1))
                                  : (!category.values)
                                    ? (_openBlock(), _createElementBlock("i", _hoisted_12, [
                                        (!isClientType(category))
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(translate)('client.resident_dashboard.assessment_not_completed')), 1))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true),
                                (Array.isArray(category.values) && !category.values.length)
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 2,
                                      class: _normalizeClass(`v-critical-info-category-value sp-no-value-${category.translatedName.replace(' ', '-').toLowerCase()}`)
                                    }, _toDisplayString(_unref(translate)('client.resident_dashboard.no_values')), 3))
                                  : (Array.isArray(category.values))
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(category.values, (value) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: isRisk(value) ? value.id : value,
                                          class: "v-critical-info-category-value"
                                        }, [
                                          (isRisk(value) && value.link)
                                            ? (_openBlock(), _createBlock(_unref(RouterLink), {
                                                key: 0,
                                                class: _normalizeClass(`sp-high-risk-link ${value.link ? 'v-critical-info-category-value--link' : ''}`),
                                                to: { path: value.link },
                                                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(value.description), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["class", "to"]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(value), 1))
                                        ]))
                                      }), 128))
                                    : _createCommentVNode("", true)
                              ]),
                              (showUpdatedAgo(category))
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 2,
                                    class: _normalizeClass(`resident-dashboard-updated-ago sp-updated-ago-${category.translatedName}`)
                                  }, [
                                    _cache[1] || (_cache[1] = _createElementVNode("svg", {
                                      width: "1.2em",
                                      height: "1.2em",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        "fill-rule": "evenodd",
                                        d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                                      })
                                    ], -1)),
                                    _createElementVNode("span", {
                                      class: _normalizeClass(`resident-dashboard-updated-ago--main-text sp-updated-at-main-text-${category.translatedName}`)
                                    }, _toDisplayString(_unref(translate)('common.updated')), 3),
                                    _createElementVNode("span", {
                                      class: _normalizeClass(`resident-dashboard-updated-ago--time sp-updated-at-time-${category.translatedName}`)
                                    }, _toDisplayString(_unref(getUpdatedAgoText)(category)), 3)
                                  ], 2))
                                : _createCommentVNode("", true)
                            ])
                          ], 2))
                        }), 128)),
                        (showOldEmergencyDownloadButton.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createElementVNode("div", {
                                onClick: generateEmergencyPack,
                                class: "emergency"
                              }, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createVNode(BaseIcon, {
                                    name: "ambulance",
                                    class: "ambulance-icon",
                                    "foreground-color": "white"
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(translate)('client.resident_dashboard.emergency_pack')), 1),
                                _createVNode(BaseIcon, { name: "download" })
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 512)
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
}

})