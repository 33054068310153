import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["value", "placeholder", "disabled", "data-test-id"]
const _hoisted_2 = ["value", "placeholder", "disabled", "data-test-id"]

import { useInputMethods } from '@/_shared/helpers/useInputMethods';
import { onMounted, ref, watchEffect } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInput',
  props: {
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  textarea: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  labelWidth: {
    type: String,
    default: undefined,
  },
  boldLabel: {
    type: Boolean,
    default: undefined,
  },
  inputWidth: {
    type: String,
    default: '100%',
  },
  inputHeight: {
    type: String,
    default: '42px',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  setFocus: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
  },
  // standardInputStyle is the new standardised way that we show inputs. We can slowly move across to this
  standardInputStyle: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    required: false,
  },
},
  emits: ['update:modelValue', 'validateInput'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const {
  inputClass, inputStyle, labelClass, labelStyle, wrapperClasses,
} = useInputMethods(props);

const validateAndUpdateInput = (inputValue: string) => {
  emit('update:modelValue', inputValue);
  emit('validateInput', inputValue);
};

const setTextAreaHeight = () => {
  const textArea = document.querySelector('textarea');
  if (textArea && textArea.scrollHeight > textArea.clientHeight) {
    textArea.style.height = `${textArea.scrollHeight}px`;
  }
};

const focus = ref();
watchEffect(() => {
  if (props.setFocus && focus.value) {
    focus.value.focus();
  }
});

onMounted(() => {
  setTextAreaHeight();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(wrapperClasses)())
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    (__props.icon)
      ? (_openBlock(), _createBlock(BaseIcon, {
          key: 1,
          name: __props.icon,
          disabled: "",
          "no-hover": "",
          class: "icon"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (__props.textarea)
      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({ key: 2 }, _ctx.$attrs, {
          value: __props.modelValue,
          placeholder: __props.placeholder,
          onInput: _cache[0] || (_cache[0] = ($event: any) => {setTextAreaHeight(); emit('update:modelValue', ($event.target as HTMLInputElement).value)}),
          class: _unref(inputClass)(),
          style: _unref(inputStyle)(),
          disabled: __props.disabled,
          ref_key: "focus",
          ref: focus,
          "data-test-id": __props.testId
        }), null, 16, _hoisted_1))
      : (_openBlock(), _createElementBlock("input", _mergeProps({ key: 3 }, _ctx.$attrs, {
          value: __props.modelValue,
          placeholder: __props.placeholder,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (validateAndUpdateInput(($event.target as HTMLInputElement).value))),
          class: ["v-input-box", _unref(inputClass)()],
          style: _unref(inputStyle)(),
          disabled: __props.disabled,
          ref_key: "focus",
          ref: focus,
          "data-test-id": __props.testId
        }), null, 16, _hoisted_2))
  ], 2))
}
}

})