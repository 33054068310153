import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

import use from '@/_shared/compositionApi';
import { Need } from '@/timeline/types/Need';
import {
  computed,
  onUnmounted,
  PropType,
  ref,
  watch,
} from 'vue';
import CarePlanReview from './CarePlanReview.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReviewsWrapper',
  props: {
  needsEvent: {
    type: Object as PropType<{ needs: Need[], showArchived: boolean }>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const needs = ref<Need[]>([]);

const isEditing = ref(0);

const componentKey = ref(0);

const toggleEditing = (id: number) => {
  isEditing.value = id;
  window.vueUnsavedMessage = id ? translate('common.unsaved_changes') : null;
};

onUnmounted(() => { window.vueUnsavedMessage = null; });

const props = __props;

watch(() => props.needsEvent, (event) => {
  needs.value = event.needs;
  componentKey.value += 1;
}, { immediate: true });

const needsToShow = computed(() => needs.value?.filter((n) => (props.needsEvent.showArchived ? true : !n.archived)).sort((a, b) => a.title.localeCompare(b.title)));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, {
    to: "#careplan-for-vue",
    key: componentKey.value
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(needsToShow.value, (need) => {
      return (_openBlock(), _createElementBlock("div", {
        key: need.id
      }, [
        _createVNode(CarePlanReview, {
          need: need,
          isEditing: need.id === isEditing.value,
          actionText: _unref(translate)('need.update_care_plan'),
          onAction: toggleEditing,
          onSaved: toggleEditing,
          showActionButtons: true,
          canEdit: true
        }, null, 8, ["need", "isEditing", "actionText"])
      ]))
    }), 128))
  ]))
}
}

})