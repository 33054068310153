import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import InteractionPlusButton from '@/timeline/components/InteractionPlusButton.vue';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelinePlusButtonWrapper',
  setup(__props) {

const serviceFilteringStore = useServiceFilteringStore();
const {
  timelineInstanceTypeMounted,
  currentInstance,
  matchingManagedInstanceServices,
  newInstanceServices,
} = storeToRefs(serviceFilteringStore);

const showPlusButton = computed(() => {
  if (timelineInstanceTypeMounted.value && !newInstanceServices.value.length) {
    return false;
  }
  return !(currentInstance.value && !matchingManagedInstanceServices.value.length);
});


return (_ctx: any,_cache: any) => {
  return (showPlusButton.value)
    ? (_openBlock(), _createBlock(InteractionPlusButton, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})