import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "v-timeline-section v-timeline-date" }

import { InteractionGroupWithRestrictedAccess } from '@/_shared/types/interactionExt';
import { PropType } from 'vue';
import { formatInteractionDate } from '@/_shared/helpers/useDateTimeHelper';
import InteractionRestrictedView from '@/timeline/components/InteractionRestrictedView.vue';
import InstanceInteractionListEntry
  from '@/timeline/components/InteractionListEntry/InstanceInteractionListEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionRestrictedGroupView',
  props: {
  data: {
    type: Object as PropType<InteractionGroupWithRestrictedAccess>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.data.restrictedView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(InteractionRestrictedView, {
          data: props.data.interactionGroups
        }, null, 8, ["data"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data.interactionGroups, (interactionGroup) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "v-instance-current-interactions",
            key: interactionGroup.date
          }, [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(_unref(formatInteractionDate)(interactionGroup.date)), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interactionGroup.interactions, (interaction) => {
              return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
                key: interaction.id!,
                class: "v-current-interaction sp-grouped-interaction",
                interaction: interaction
              }, null, 8, ["interaction"]))
            }), 128))
          ]))
        }), 128))
      ]))
}
}

})