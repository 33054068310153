import PupilSizeParameter from '@/timeline/interaction_parameters/PupilSizeParameter.vue';
import InlineMultipleChoiceParameter
  from '@/timeline/interaction_parameters/InlineMultipleChoiceParameter.vue';
import MultipleChoiceDropdownParameter
  from '@/timeline/interaction_parameters/MultipleChoiceDropdownParameter.vue';
import MultipleChoiceArrayParameter
  from '@/timeline/interaction_parameters/MultipleChoiceArrayParameter.vue';
import NourishInstanceParameter
  from '@/timeline/interaction_parameters/NourishInstanceParameter.vue';
import EventPWSParameter from '@/events/components/EventPWSParameter.vue';
import CheckboxParameter from '@/timeline/interaction_parameters/CheckboxParameter.vue';
import CombinedDataSetParameter from '@/timeline/interaction_parameters/CombinedDataSetParameter.vue';
import DateParameter from '@/timeline/interaction_parameters/DateParameter.vue';
import DateTimeParameter from '@/timeline/interaction_parameters/DateTimeParameter.vue';
import DurationParameter from '@/timeline/interaction_parameters/DurationParameter.vue';
import FileParameter from '@/timeline/interaction_parameters/FileParameter.vue';
import MedicationParameter from '@/timeline/interaction_parameters/MedicationParameter.vue';
import MultiSelectDataSetParameter from '@/timeline/interaction_parameters/MultiSelectDataSetParameter.vue';
import NumberParameter from '@/timeline/interaction_parameters/NumberParameter.vue';
import PersonPickerParameter from '@/timeline/interaction_parameters/PersonPickerParameter.vue';
import PictureParameter from '@/timeline/interaction_parameters/PictureParameter.vue';
import ReadOnlyParameter from '@/timeline/interaction_parameters/ReadOnlyParameter.vue';
import SingleSelectDataSetParameter from '@/timeline/interaction_parameters/SingleSelectDataSetParameter.vue';
import TextParameter from '@/timeline/interaction_parameters/TextParameter.vue';
import SignatureParameter from '@/timeline/interaction_parameters/SignatureParameter/SignatureParameter.vue';
import OldSignatureParameter from '@/timeline/interaction_parameters/SignatureParameter/OldSignatureParameter.vue';
import { Component } from 'vue';
import { isOldVersionSignatureParameter } from './parametersHelper';
import { Parameter } from '../types/Parameter';

const TYPE_TO_COMPONENT_MAPPING: Record<string, Component> = {
  checkbox: CheckboxParameter,
  combined_multi_search: CombinedDataSetParameter,
  date: DateParameter,
  datetime: DateTimeParameter,
  duration: DurationParameter,
  file: FileParameter,
  medication: MedicationParameter,
  multi: MultiSelectDataSetParameter,
  person_picker: PersonPickerParameter,
  picture: PictureParameter,
  read_only_text: ReadOnlyParameter,
  single_selector_search: SingleSelectDataSetParameter,
};

function getParameterType(parameter: Parameter) {
  if (parameter.valueType === 'number' || parameter.valueType === 'string') {
    if (!parameter.coercedChoices || parameter.coercedChoices.length === 0) {
      if (parameter.valueType === 'number') {
        return NumberParameter;
      }
      return TextParameter;
    }
    if (parameter.codename && (parameter.codename.includes('pupil-size') || parameter.codename.includes('pupil_size'))) {
      return PupilSizeParameter;
    }
    if (parameter.isArray) {
      return MultipleChoiceArrayParameter;
    }
    if (parameter.coercedChoices.length < 4) {
      return InlineMultipleChoiceParameter;
    }
    if (parameter.coercedChoices.length > 3 || parameter.valueType === 'number') {
      return MultipleChoiceDropdownParameter;
    }
  } else if (parameter.valueType === 'signature') {
    return !isOldVersionSignatureParameter(parameter) ? SignatureParameter : OldSignatureParameter;
  } else if (parameter.valueType === 'nourish_instance') {
    if (parameter.config!.nourishInstanceType === 'skin') {
      return NourishInstanceParameter;
    }
    if (parameter.config!.nourishInstanceType === 'event_pws') {
      return EventPWSParameter;
    }
  } else {
    return TYPE_TO_COMPONENT_MAPPING[parameter.valueType];
  }
  return '';
}

export default getParameterType;
