import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import useReportStore from '@/_shared/store/reports';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { AlarmState } from '@/_shared/types/report';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportAlarmStateSelect',
  setup(__props) {


const { newReport } = useReportStore();

const { translate } = use.helpers();

const interactionStates: CompositeOption<AlarmState>[] = [
  { text: translate('reports.filters.alarm_state.ok'), value: 'ok' },
  { text: translate('reports.filters.alarm_state.warning'), value: 'warning' },
  { text: translate('reports.filters.alarm_state.alarm'), value: 'alarm' },
];


return (_ctx: any,_cache: any) => {
  return (_unref(newReport).params.alarmState)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(BaseCheckboxGroup, {
          modelValue: _unref(newReport).params.alarmState,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.alarmState) = $event)),
          options: interactionStates,
          label: _unref(translate)('reports.filters.alarm_state.title'),
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"])
      ]))
    : _createCommentVNode("", true)
}
}

})