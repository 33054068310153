import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-badges person-badges"
}

import { computed } from 'vue';
import clientStore from '@/_shared/store/clients';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientBadges',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const badgeToDisplayName: Record<string, string> = {
  abilities: 'ABILITIES',
  allergies: 'ALLERGIES',
  awi: 'AWI',
  c19: 'C-19',
  diet: 'DIET',
  dnar: 'DNACPR',
  dols: 'DoLS',
  respect: 'ReSPECT',
};

function getBadgeClass(badge: string) {
  return `v-badge v-badge-${badge} nsx-badge--${badge} sp-client-badges-title`;
}

const badges = computed(() => clientStore.badges(props.clientId).value);

return (_ctx: any,_cache: any) => {
  return (badges.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(badges.value, (badge) => {
          return (_openBlock(), _createElementBlock("span", {
            key: badge,
            class: _normalizeClass(getBadgeClass(badge))
          }, _toDisplayString(badgeToDisplayName[badge]), 3))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})