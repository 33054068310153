import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import BaseCardSection from '@/_shared/components/display/BaseCard/BaseCardSection.vue';
import use from '@/_shared/compositionApi';
import ReportFormatInput from '@/reports/inputs/ReportFormatInput.vue';
import ReportClientInput from '@/reports/inputs/ReportClientInput.vue';
import BaseCheckboxGroup from '@/_shared/components/inputs/BaseCheckboxGroup.vue';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { ClientProfileFields } from '@/_shared/types/report';
import ReportPageBreakInput from '@/reports/inputs/ReportPageBreakInput.vue';
import useReportStore from '@/_shared/store/reports';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientProfileReport',
  setup(__props) {


const { translate } = use.helpers();

const { newReport } = useReportStore();

const reportSections: CompositeOption<ClientProfileFields>[] = [
  { value: 'status_in_organisation', text: translate('reports.fields.client_profile.status_in_organisation') },
  { value: 'biography', text: translate('reports.fields.client_profile.biography') },
  { value: 'documents', text: translate('reports.fields.client_profile.documents') },
  { value: 'circle_of_care', text: translate('reports.fields.client_profile.circle_of_care') },
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.filters.title')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportClientInput)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.fields.title')
    }, {
      default: _withCtx(() => [
        _createVNode(BaseCheckboxGroup, {
          modelValue: _unref(newReport).params.fields,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.fields) = $event)),
          options: reportSections,
          label: _unref(translate)('reports.fields.client_profile.title'),
          "standard-input-style": ""
        }, null, 8, ["modelValue", "label"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(BaseCardSection, {
      title: _unref(translate)('reports.report_format')
    }, {
      default: _withCtx(() => [
        _createVNode(ReportFormatInput, { formats: ['html', 'pdf'] }),
        _createVNode(ReportPageBreakInput, { term: "client" })
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}
}

})