import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-interaction-restricted-access-container" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInteractionRestictedView',
  props: {
  data: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { class: "v-interaction-restricted-access-body" }, () => [
      _createTextVNode(_toDisplayString(props.data), 1)
    ])
  ]))
}
}

})