import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { BaseSelect } from '@/_shared/components';
import useReportStore from '@/_shared/store/reports';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportClientInput',
  props: {
  multiple: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {



// TODO: In angular we get from this endpoint "/api/v2/people/people_for_reports"
// We then map this to the states so that we show the state next to the client
// Eg. Bob Smith (archived)
// Q: Do we really need this specific endpoint? Can we just use the people endpoint and filter by state?

const { newReport } = useReportStore();

const { translate } = use.helpers();
const { clientStore } = use.store.clients();
const clients = computed(() => clientStore.clientsForReports.map((client) => {
  const clientStateTerm = translate(`reports.filters.resident_states.${client.state}`);
  return {
    value: client.id,
    text: `${client.displayName} - ${clientStateTerm}`,
  };
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseSelect), {
    label: _unref(translate)('custom_terms.clients'),
    modelValue: _unref(newReport).params.clientIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newReport).params.clientIds) = $event)),
    options: clients.value,
    "standard-input-style": "",
    placeholder: __props.multiple ? _unref(translate)('common.all') : _unref(translate)('common.select_one'),
    multiple: __props.multiple,
    searchable: ""
  }, null, 8, ["label", "modelValue", "options", "placeholder", "multiple"]))
}
}

})