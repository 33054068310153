import componentBaseOnFT from '@/_shared/router/ComponentHelper';
import InteractionsLeftPanel from '@/timeline/components/InteractionsLeftPanel.vue';
import ClientTopBar from '@/clients/components/ClientTopBar.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import InteractionsRightPanelMain from '@/timeline/InteractionsRightPanelMain.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';

export const familyPortalRoutes = [
  {
    path: '/portal/clients',
    name: 'portalClientList',
    components: {
      TopBar: BasePlaceHolderView,
    },
    meta: { isAngular: true },
  },
  {
    path: '/portal/clients/:clientId',
    components: {
      TopBar: async () => componentBaseOnFT('aoFabMenu', ClientTopBar),
    },
    props: true,
    beforeEnter: async () => {
      await loadModuleLanguageFileAsync('events');
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          Action: BasePlaceHolderView,
          SideBar: InteractionsLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'portalClient.timeline',
            component: BasePlaceHolderView,
          },
        ],
        props: true,
      },
      {
        path: 'interactions',
        components: {
          Action: BasePlaceHolderView,
          SideBar: InteractionsLeftPanel,
        },
        children: [
          {
            path: ':id(\\d+)',
            name: 'portalClient.timeline.interaction',
            component: InteractionsRightPanelMain,
            props: true,
          },
        ],
      },
      {
        path: 'careplan_report',
        name: 'portalClient.careplanReport',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        meta: { isAngular: true },
      },
      {
        path: 'profile',
        alias: 'profile/sections',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'portalClient.profile',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
          // TODO this route is  temporary until we implement the rest of profile sub routes
          {
            path: ':subSection',
            alias: '/clients/:clientId/profile/:subSection',
            name: 'portalClient.profile.subSections',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
        ],
      },
    ],
  },
];

export const allowedFamilyPortalRoutes = () => {
  // TODO add access permissions only for family members (both vue and angular)
  const allowedRoutes: string[] = [];
  allowedRoutes.push(
    'portalClientList',
    'portalClient.timeline',
    'portalClient.timeline.interaction',
    'portalClient.careplanReport',
    'portalClient.profile',
    'portalClient.profile.subSections',
  );
  return allowedRoutes;
};
