import apiClient from '@/_shared/services/apiClient';
import { CarerForReports } from '@/_shared/types/carer';

export type CarerOrganisationUnitForReports = {
  id: number,
  personId: number,
  organisationUnitId: number,
  name: string,
  state: string
};

type CarerOrganisationUnitForReportsResponse = CarerOrganisationUnitForReports[];

export const getCarersForReports = async (ouId: number): Promise<CarerForReports[] | null> => {
  const url = `/api/v2/organisation_units/${ouId}/carer_organisation_units_for_reports`;
  try {
    const carers: CarerOrganisationUnitForReportsResponse = await apiClient.get(url);
    return carers.map((c) => ({
      id: c.personId,
      displayName: c.name,
      state: c.state,
    }));
  } catch (error) {
    return null;
  }
};
