import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-image-gallery-row v-image-gallery-text justify-content-end" }
const _hoisted_2 = { class: "v-image-gallery-row column-gap-1em v-image-gallery-report" }
const _hoisted_3 = {
  class: "v-image-gallery-bold",
  "data-id": "image-gallery-total-images-info-bottom"
}

import use from '@/_shared/compositionApi';
import { BaseInstance } from '@/_shared/types/NourishInstance';
import useImageGalleryInstanceInfo from '@/_shared/Composables/useImageGalleryInstanceInfo';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageGalleryActions',
  props: {
    instance: {},
    totalImages: {}
  },
  setup(__props: any) {

const props = __props;

const { translate } = use.helpers();
const { totalImagesText, displayReportButton } = useImageGalleryInstanceInfo(props.instance, props.totalImages);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(totalImagesText)), 1),
      (_unref(displayReportButton))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "v-image-gallery-report-button",
            "data-id": "image-gallery-report-button-bottom",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createReport')))
          }, _toDisplayString(_unref(translate)('image_gallery.generate_report_button')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})